import React from "react";

import userIdentificationTypeConstants from "constants/userIdentificationType";
import Layout from "components/common/Layout";
import {InnerAddBankAccountDialog} from "containers/step/bankAccounts/AddBankAccountDialog";

export default function BankAccountOwn(props) {
  const bankAccountType = props.userIdentificationType !== userIdentificationTypeConstants.MANUAL ? 'bankAccountMojAs' : 'bankAccountNoMojAs';
  return (
    <Layout>
      <Layout.OneColumn width="100%" maxWidth={470} justifyContent="center">
        <InnerAddBankAccountDialog {...props} name={bankAccountType} showConfirmation={false}/>
      </Layout.OneColumn>
    </Layout>
  );
}