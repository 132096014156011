import React, {Component} from "react";
import {connect} from "react-redux";

import _ from "lodash";

import {getUserData} from "actions/flow";
import Text from "components/common/Text";
import SummaryHeader from "components/common/SummaryHeader";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import userIdentificationTypeConstants from "constants/userIdentificationType";
import {ConfigContext} from "containers/context/ConfigContainer";
import Header from "containers/step/Header";
import GeneraliLoginRight from "containers/step/userLogin/GeneraliLoginRight";
import InsuranceInfoLoginRight from "containers/step/userLogin/InsuranceInfoLoginRight";
import SplitViewContainer from "containers/view/SplitViewContainer";
import Yup, {getMax} from "selectors/yup";

const insuranceInfoLoginValidationSchema = Yup.object().shape({
  policyNumber: getMax(Yup.string().trim().required("validation.required"), fieldLengthConstants[flowConstants.steps.USER_LOGIN.NAME].policyNumber),
  taxNumber: getMax(Yup.string().trim().required("validation.taxNumber").validateSloTaxNumber("validation.taxNumber.format"), fieldLengthConstants[flowConstants.steps.USER_LOGIN.NAME].taxNumber),
});

const generaliLoginValidationSchema = Yup.object().shape({
  username: Yup.string().trim().required("validation.required"),
  password: Yup.string().trim().required("validation.required"),
});

class UserLoginStepContainer extends Component {
  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="insuranceInfoLogin.sidebar.title"/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        {this.props.userIdentificationType === userIdentificationTypeConstants.INSURANCE_INFO
          ? <InsuranceInfoLoginRight/>
          : <GeneraliLoginRight/>
        }
      </SplitViewContainer.Right>
    );
  };

  handleSubmit = (url) => async (values) => {

    const data = {
      policyNumber: values.policyNumber,
      taxNumber: values.taxNumber,
    };

    this.props.getUserData(url.customerDataByInsuranceInfo, data);
  }

  validationSchema = () => {
    if (this.props.userIdentificationType === userIdentificationTypeConstants.INSURANCE_INFO) {
      return insuranceInfoLoginValidationSchema;
    } else {
      return generaliLoginValidationSchema;
    }
  }

  render() {
    return (
      <ConfigContext.Consumer>
        {context =>
          <SplitViewContainer left={this.left}
                              right={this.right}
                              validationSchema={this.validationSchema}
                              onSubmit={this.handleSubmit(context.url)}/>
        }
      </ConfigContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => ({
  step: state.flow.step,
  userIdentificationType: _.at(state.flow.forms, "userIdentificationType.userIdentificationType")[0] || userIdentificationTypeConstants.INSURANCE_INFO,
});

const mapDispatchToProps = (dispatch) => ({
  getUserData: (url, userData) => dispatch(getUserData(url, userData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserLoginStepContainer);