import React from "react";

import Layout from "components/common/Layout";
import Link from "components/common/Link";
import Text from "components/common/Text";
import TextField from "components/form/TextField";
import ToggleButton from "components/form/ToggleButton";
import booleanTypesConstants from "constants/booleanTypes";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import {capitalize} from "utility/textTransform";

export default function OwnVehiclePassenger({index, onRemove, showRemove}) {
  return (
    <Layout width="100%" spacing={10} maxWidth={450}>
      <Layout.OneColumn spacing={30}></Layout.OneColumn>
      <Layout.OneColumn>
        <Text element="h4" variant="variant1" label={`ownVehiclePassengers.title`} parameters={[index + 1]}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name={`passengers[${index}].name`}
                   transform={capitalize}
                   label="ownVehiclePassengers.name.label"
                   placeholder="ownVehiclePassengers.name.placeholder"
                   maxLength={fieldLengthConstants[flowConstants.steps.OWN_VEHICLE_INFO_PASSENGERS.NAME].name+ 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name={`passengers[${index}].surname`}
                   transform={capitalize}
                   label="ownVehiclePassengers.surname.label"
                   placeholder="ownVehiclePassengers.surname.placeholder"
                   maxLength={fieldLengthConstants[flowConstants.steps.OWN_VEHICLE_INFO_PASSENGERS.NAME].surname+ 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn spacing={20}></Layout.OneColumn>
      <Layout.OneColumn>
        <Text element="span" variant="variant2" label="ownVehiclePassengers.injury.title"/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <ToggleButton name={`passengers[${index}].injuryType`}>
          <Layout.OneRow flex={1} spacing={10}>
            <ToggleButton.Choice label="button.yes"
                                 value={booleanTypesConstants.YES}
                                 variant="variant11"/>
            <ToggleButton.Choice label="button.no"
                                 value={booleanTypesConstants.NO}
                                 variant="variant11"/>
            <ToggleButton.Choice label="button.dontKnow"
                                 value={booleanTypesConstants.DONT_KNOW}
                                 variant="variant11"/>
          </Layout.OneRow>
        </ToggleButton>
      </Layout.OneColumn>
      <Layout.OneColumn textAlign="right">
        {showRemove ? <Link variant="variant1" label="ownVehiclePassengers.button.remove" onClick={onRemove}/> : null}
      </Layout.OneColumn>
    </Layout>
  );
}
