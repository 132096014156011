import React, {Component} from "react";

import axios from "axios";
import {connect} from "react-redux";
import {omit} from "underscore";

import {storeSaveId, storeSaveVersion, storeElectronicSignatureEnabled, storeCompany} from "actions/flow";
import Loading from "components/common/Loading";
import {ConfigContext} from "containers/context/ConfigContainer";

export const RegistryContext = React.createContext({});

class RegistryContainer extends Component {
  static contextType = ConfigContext;

  state = {
    loaded: false,
    registry: {},
  };

  async componentDidMount() {
    const {storeSaveId, storeSaveVersion, storeElectronicSignatureEnabled, storeCompany} = this.props;

    const response = await axios.get(this.context.url.registry);

    const registry = {
      ...response.data,
      mapDamageCenters: response.data.damageCenters.filter(damageCenter => damageCenter.branchOffice !== true).map(damageCenter => (omit(damageCenter, "showOnMap", "showInDropDown"))),
      mapBusinessUnits: response.data.damageCenters.filter(damageCenter => damageCenter.branchOffice === true).map(damageCenter => (omit(damageCenter, "showOnMap", "showInDropDown"))),
    };

    this.setState({
      loaded: true,
      registry,
    });

    storeSaveId(response.data.id);
    storeSaveVersion(response.data.version);
    storeCompany(response.data.company);
    storeElectronicSignatureEnabled(response.data.electronicSignatureEnabled);
  }

  render() {
    const {children} = this.props;
    const {loaded} = this.state;

    if (!loaded) {
      return <Loading/>;
    }

    return (
      <RegistryContext.Provider value={this.state}>
        {children}
      </RegistryContext.Provider>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  storeSaveId: (id) => dispatch(storeSaveId(id)),
  storeSaveVersion: (version) => dispatch(storeSaveVersion(version)),
  storeElectronicSignatureEnabled: (electronicSignatureEnabled) => dispatch(storeElectronicSignatureEnabled(electronicSignatureEnabled)),
  storeCompany: (company) => dispatch(storeCompany(company)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistryContainer);