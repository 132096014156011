import React, {Component} from "react";

import {NextStepContext} from "containers/context/NextStepContainer";

export default function NextStepButton(props) {
  return (
    <NextStepContext.Consumer>
      {nextStepContext => (
        <InnerNextStepButton {...props} context={nextStepContext}/>
      )}
    </NextStepContext.Consumer>
  )
}

export class InnerNextStepButton extends Component {
  componentWillUnmount() {
    this.props.context.enableNextStep();
  }

  onClick = (value, callback) => {
    const {isValid, onClick, onNextStep, condition, context} = this.props;
    const finalOnNextStep = context.onNextStep === null ? onNextStep : context.onNextStep;
    const finalCondition = condition ? condition(value) : true;

    if (isValid && finalCondition) {
      context.disableNextStep();
    }

    if (!finalCondition && callback) {
      callback();
    }

    if (context.onClick) {
      context.onClick(value);
    } else if (onClick) {
      onClick(value);
    }

    if (context.nextStepEnabled && finalCondition) {
      setTimeout(finalOnNextStep, 0);
    }
  };

  render() {
    const {buttonProps, component: Button, children, context} = this.props;

    const finalButtonProps = {
      ...buttonProps,
      isDisabled: context.disabled || buttonProps.isDisabled,
    };

    return (
      <Button onClick={this.onClick} {...finalButtonProps}>
        {children}
      </Button>
    );
  }
}