import React, {Component} from "react";
import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import {RegistryContext} from "containers/context/RegistyContainer";
import DeceasedPersonInformationRight from "containers/step/deceasedPersonInformation/DeceasedPersonInformationRight";
import Header from "containers/step/Header";
import SplitViewContainer from "containers/view/SplitViewContainer";
import Yup, {getMax} from "selectors/yup";
import {isSloAddress} from "utility/utilityFunctions";

const addressValidationSchema = Yup.object().shape({
  address: Yup.object().shape({
    streetNumber: getMax(Yup.string().typeError("validation.address.streetNo").trim(), fieldLengthConstants[flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME].streetNumber),
    streetName: getMax(Yup.string().typeError("validation.address.streetNo").trim(), fieldLengthConstants[flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME].streetName),
  }),
  city: Yup.object().shape({
    zip: getMax(Yup.string().trim(), fieldLengthConstants[flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME].zip),
    city: getMax(Yup.string().trim(), fieldLengthConstants[flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME].city),
  }),
});

const deceasedPersonInformationValidationSchema = (slovenia) => Yup.object().shape({
  name: getMax(Yup.string().required("validation.required"), fieldLengthConstants[flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME].name),
  surname: getMax(Yup.string().required("validation.required"), fieldLengthConstants[flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME].name),
  birthDate: Yup.string().nullable().trim().required("validation.birthDate"),
  address: addressValidationSchema,
  policyNumber: getMax(Yup.string(), fieldLengthConstants[flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME].policyNumber),
  taxNumber: taxNumberValidationSchema(slovenia),
});

const taxNumberValidationSchema = (slovenia) => Yup.string().when("address", {
  is: address => isSloAddress(address, slovenia),
  then: getMax(Yup.string().validateSloTaxNumber("validation.taxNumber.format"), fieldLengthConstants[flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME].taxNumberSlo),
  otherwise: getMax(Yup.string(), fieldLengthConstants[flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME].taxNumber),
});

const validationSchema = (slovenia) => Yup.object().shape({
  deceasedPersonInformation: deceasedPersonInformationValidationSchema(slovenia),
});

export default class DeceasedPersonInformationStepContainer extends Component {

  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="deceasedPersonInformation.sidebar.title"/>
        <Text element="div" label="deceasedPersonInformation.sidebar.subtitle"/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <DeceasedPersonInformationRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <RegistryContext.Consumer>
        {context => (
          <SplitViewContainer left={this.left} right={this.right}
                              validationSchema={validationSchema(context.registry.slovenia)}/>
        )}
      </RegistryContext.Consumer>
    );
  }
}
