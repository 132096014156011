import {isUndefined} from "underscore";
import _ from "lodash";

export function formatCustomerData (customerData) {
  const countryName = _.at(customerData, "address.country.name")[0];
  const countryId = _.at(customerData, "address.country.id")[0];
  const countryCode = _.at(customerData, "address.country.code")[0];
  const city = _.at(customerData, "address.city")[0];
  const zip = _.at(customerData, "address.zipCode")[0];
  const streetName = _.at(customerData, "address.street")[0];
  const streetNo = _.at(customerData, "address.houseNumber")[0];

  return {
    name: customerData.firstName || "",
    surname: customerData.lastName || "",
    birthDate: formatBirthDate(customerData),
    sex: customerData.sex,
    address: {
      countryName: countryName || "",
      country: {
        id: countryId || "",
        name: countryName || "",
        code: countryCode || "",
      },
      city: {
        city: city || "",
        zip: zip || "",
      },
      address: {
        street: {
          name: streetName || "",
        },
        streetNumber: streetNo || "",
        streetName: streetName || "",
      }
    },
    telephoneNumber: customerData.phoneNumber || "",
    email: customerData.email || "",
    taxNumber: customerData.taxNumber || "",
  };
}

export function formatCustomerDataLegal (customerData) {
  const countryName = _.at(customerData, "address.country.name")[0];
  const countryId = _.at(customerData, "address.country.id")[0];
  const countryCode = _.at(customerData, "address.country.code")[0];
  const city = _.at(customerData, "address.city")[0];
  const zip = _.at(customerData, "address.zipCode")[0];
  const streetName = _.at(customerData, "address.street")[0];
  const streetNo = _.at(customerData, "address.houseNumber")[0];

  return {
    name: customerData.firstName || "",
    address: {
      countryName: countryName || "",
      country: {
        id: countryId || "",
        name: countryName || "",
        code: countryCode || "",
      },
      city: {
        city: city || "",
        zip: zip || "",
      },
      address: {
        street: {
          name: streetName || "",
        },
        streetNumber: streetNo || "",
        streetName: streetName || "",
      }
    },
    telephoneNumber: customerData.phoneNumber || "",
    email: customerData.email || "",
  };
}

export function formatBirthDate(customerData) {
  const day = _.at(customerData, "dateOfBirth.dayOfMonth")[0];
  const month = _.at(customerData, "dateOfBirth.monthValue")[0];
  const year = _.at(customerData, "dateOfBirth.year")[0];
  const dayUndefined = isUndefined(day);
  const monthUndefined = isUndefined(month);
  const yearUndefined = isUndefined(year);

  if (dayUndefined || monthUndefined || yearUndefined) {
    return "";
  } else {
    return new Date(year, month - 1, day);
  }
}

export function formatPolicyData(customerData) {
  const {policies = []} = customerData;

  return policies.map(policy => ({
    policyNumber: policy.number,
    policyName: policy.productDescription,
    dateFrom: policy.dateFrom,
    dateTo: policy.dateTo,
  }));
}