import React, {Component} from "react";

import Switch, {Case, Default} from "react-switch-case";

import Button from "components/common/Button";
import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import Text from "components/common/Text";
import CheckBoxGroup from "components/form/CheckBox/CheckBoxGroup";
import ToggleButton from "components/form/ToggleButton";
import FadeView from "components/view/FadeView";
import booleanTypesConstants from "constants/booleanTypes";
import TaxInfo from "containers/step/lifeInsuranceClaim/TaxInfo";
import styles from "containers/step/lifeInsuranceClaim/LifeInsuranceClaimRight.module.scss";

import {FieldArray} from "formik";

export default class LifeInsuranceClaimRight extends Component {
  render() {
    const {values, onNextStep, setFieldValue} = this.props;

    return (
      <Layout flex={1} spacing={5}>
        <Text label="lifeInsuranceClaim.content.title" element="h3" variant="variant1"/>
        <Layout.OneColumn spacing={40}/>
        <Layout>
          <Layout.OneColumn flex={1}>
            <NextStepButton component={ToggleButton}
                            condition={value => value === booleanTypesConstants.NO}
                            isValid={true}
                            buttonProps={{name: "lifeInsuranceClaim"}}
                            onNextStep={onNextStep}>
              <Layout.OneRow spacing={10} flex={1}>
                <ToggleButton.Choice value={booleanTypesConstants.YES} label="button.yes"/>
                <ToggleButton.Choice value={booleanTypesConstants.NO} label="button.no"/>
              </Layout.OneRow>
            </NextStepButton>
          </Layout.OneColumn>
        </Layout>
        <Layout.OneColumn>
          <FadeView step={values.lifeInsuranceClaim}>
            <Switch condition={values.lifeInsuranceClaim}>
              <Case value={booleanTypesConstants.YES}>
                <Layout width="100%" maxWidth={450} justifyContent="center" spacing={5}>
                  <Layout.OneColumn spacing={40}/>
                  <Text label="lifeInsuranceClaim.content.tax.note" element="div" variant="variant5"/><br /><br />
                  <Text label="lifeInsuranceClaim.content.tax.title" element="h3" variant="variant1"/>
                  <Text label="lifeInsuranceClaim.content.tax.subtitle" element="div"/>
                  <Layout.OneColumn spacing={30}/>
                  <Layout.OneColumn>
                    <Layout>
                      <Text className={styles.item} label="lifeInsuranceClaim.content.ZDA.title" element="h3"
                            variant="variant2"/>
                      <Layout.OneColumn>
                        <CheckBoxGroup name="isZDA">
                          <CheckBoxGroup.Choice name="ZDA" label="lifeInsuranceClaim.content.ZDA.ZDA" value="ZDA" variant="variant12" isHtml={true}/>
                          <CheckBoxGroup.Choice name="taxZDA" label="lifeInsuranceClaim.content.ZDA.taxZDA" value="taxZDA" variant="variant12" isHtml={true}/>
                          <CheckBoxGroup.Choice name="none" label="lifeInsuranceClaim.content.ZDA.none" value="none" variant="variant12" isHtml={true}/>
                        </CheckBoxGroup>
                      </Layout.OneColumn>
                    </Layout>
                  </Layout.OneColumn>
                  <Layout.OneColumn spacing={30}/>
                  <Layout.OneColumn>
                    <Layout>
                      <Text className={styles.item} label="lifeInsuranceClaim.content.slo.title" element="h3"
                            variant="variant2"/>
                      <Layout.OneColumn>
                        <CheckBoxGroup name="isSlovenia">
                          <CheckBoxGroup.Choice name="slovenia" label="lifeInsuranceClaim.content.slo.slovenia" value="slovenia" variant="variant12" isHtml={true}/>
                          <CheckBoxGroup.Choice name="abroad" label="lifeInsuranceClaim.content.slo.abroad" value="abroad" variant="variant12" isHtml={true}/>
                        </CheckBoxGroup>
                      </Layout.OneColumn>
                    </Layout>
                  </Layout.OneColumn>
                  <Layout.OneColumn>
                    <TaxInfosData setFieldValue={setFieldValue}/>
                  </Layout.OneColumn>
                </Layout>
              </Case>
              <Default>
                {null}
              </Default>
            </Switch>
          </FadeView>
        </Layout.OneColumn>
      </Layout>
    );
  }
}

function TaxInfosData({setFieldValue}) {
  return (
    <FieldArray name="taxInfos" render={arrayHelpers => (
      <TaxInfos arrayHelpers={arrayHelpers} setFieldValue={setFieldValue}/>
    )}/>
  );
}

class TaxInfos extends Component {
  add = () => {
    this.props.arrayHelpers.push({});
  };

  render() {
    const {arrayHelpers, setFieldValue} = this.props;
    const taxInfos = arrayHelpers.form.values.taxInfos;

    const values =
      taxInfos.map((taxInfo, i) => (
        <FadeView.Child childKey={i} key={i}>
          <TaxInfo index={i} onRemove={() => arrayHelpers.remove(i)}
                   showRemove={taxInfos.length > 1} taxInfo={taxInfo} setFieldValue={setFieldValue}/>
        </FadeView.Child>
      ));

    return (
      <Layout flex={1} spacing={10}>
        <Layout.OneColumn>
          <FadeView>
            {values}
          </FadeView>
        </Layout.OneColumn>
        <Layout.OneColumn textAlign="right">
          <Button variant="variant10" label="lifeInsuranceClaim.content.taxInfo.button.add" onClick={this.add}/>
        </Layout.OneColumn>
      </Layout>
    );
  }
}
