import React, {Component} from "react";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import ChoiceButton from "components/form/ChoiceButton";
import RemoveButton from "components/form/RemoveButton";
import BikeOwnVehicleInfo from "components/step/BikeOwnVehicleInfo"
import PedestrianInfo from "components/step/PedestrianInfo"
import VehicleInfoHeader from "components/step/VehicleInfoHeader";
import VehicleInfoInputContainer from "components/step/VehicleInfoInputContainer";
import booleanTypesConstants from "constants/booleanTypes";
import vehicleTypesConstants from "constants/vehicleTypes";

export default class ParticipantsInfoRight extends Component {
  render() {
    const {values, onSkipStep, isValid, onRemoveParticipantAndSkip, step, saveId, uploadContext} = this.props;

    return (
      <Layout spacing={10} width="100%" maxWidth={590} justifyContent="center">
        <Participant values={values} saveId={saveId} step={step}/>
        <Layout.OneColumn textAlign="right">
          {
            !values.info.isOwnVehicle
              ? (
                <Layout.OneRow>
                  <RemoveButton label={`removeParticipant.${values.info.type}.label`}
                                onClick={() => onRemoveParticipantAndSkip(step)}/>
                </Layout.OneRow>
              ) : null
          }
          <Layout.OneRow spacing={10} textAlign="right" justifyContent="flex-end">
            {
              !values.info.isOwnVehicle
                ? (
                  <Layout.OneRow>
                    <NextStepButton component={ChoiceButton}
                                    isValid={isValid}
                                    condition={() => !uploadContext.uploading}
                                    onNextStep={onSkipStep}
                                    buttonProps={{
                                      name: "participantInfo.choice",
                                      value: booleanTypesConstants.DONT_KNOW,
                                      label: "button.dontKnow",
                                    }}/>
                  </Layout.OneRow>
                ) : null
            }
          </Layout.OneRow>
        </Layout.OneColumn>
      </Layout>
    );
  }
}

function Participant({values, step, ...props}) {
  const info = values.info;
  const labelKey = info.isOwnVehicle ? "ownVehicle" : "otherVehicle";

  return (
    <>
      <Layout.OneColumn {...props}>
        <VehicleInfoHeader info={info}
                           headerLabel={`participantsInfo.vehicleInfo.header.${labelKey}.${info.type}.header`}
                           contentLabel={`participantsInfo.vehicleInfo.header.${labelKey}.${info.type}.content`}/>
      </Layout.OneColumn>
      <Layout.OneColumn {...props} width="100%">
        {
          info.type === vehicleTypesConstants.BIKE || info.type === vehicleTypesConstants.PEDESTRIAN
            ? info.type === vehicleTypesConstants.BIKE && info.isOwnVehicle
            ? <BikeOwnVehicleInfo name="participantInfo" {...values} {...props}/>
            : <PedestrianInfo name="participantInfo" info={info}/>
            : <VehicleInfoInputContainer name="participantInfo" info={info} step={step}/>
        }
      </Layout.OneColumn>
    </>
  );
}
