import React, {Component} from "react";

import * as Yup from "yup";


import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import Header from "containers/step/Header";
import OwnVehicleInfoReportedPoliceRight from "containers/step/ownVehicleInfo/OwnVehicleInfoReportedPoliceRight";
import SplitViewContainer from "containers/view/SplitViewContainer";
import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import VehicleInfoContainer from "components/step/VehicleInfoContainer";
import {getMax} from "selectors/yup";

const validationSchema = Yup.object().shape({
  reportedPolice: Yup.string().required("validation.required"),
  reportedPoliceStation: getMax(Yup.string().trim(), fieldLengthConstants[flowConstants.steps.OWN_VEHICLE_INFO_REPORTED_POLICE.NAME].reportedPoliceStation),
});

// step 21
export default class OwnVehicleInfoReportedPoliceStepContainer extends Component {
  left = ({fromSummary, step}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <div><Text element="h2" label="ownVehicle.reportedPolice.content.title"/></div>
        <div><Text label="ownVehicle.reportedPolice.content.subtitle"/></div>
        <VehicleInfoContainer {...step.participant}/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <OwnVehicleInfoReportedPoliceRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema} />
    );
  }
}
