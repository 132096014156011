import React from "react";

import Layout from "components/common/Layout";
import SummaryBankAccounts from "containers/step/summary/bankAccounts/SummaryBankAccounts";
import SummaryInfo from "containers/step/summary/car/SummaryInfo";
import SummaryParticipants from "containers/step/summary/car/SummaryParticipants";
import SummaryDocuments from "containers/step/summary/documents/SummaryDocuments";
import SummaryDocumentsGeneral from "containers/step/summary/documentsGeneral/SummaryDocumentsGeneral";
import InspectionAndRepair from "containers/step/summary/inspectionAndRepair/InspectionAndRepair";
import SummaryPhotos from "containers/step/summary/photos/SummaryPhotos";
import SummaryResponsiblePerson from "containers/step/summary/responsiblePerson/SummaryResponsiblePerson";
import SummaryUser from "containers/step/summary/userInfo/SummaryUser";
import SummaryApplicant from "./userInfo/SummaryApplicant";

export default function SummaryCar({claimType, repairServiceType, ...props}) {
  return (
    <>
      <Layout.OneColumn {...props}>
        <SummaryInfo claimType={claimType}/>
      </Layout.OneColumn>

      <Layout.OneColumn {...props}>
        <SummaryParticipants claimType={claimType}/>
      </Layout.OneColumn>

      <Layout.OneColumn {...props}>
        <SummaryResponsiblePerson/>
      </Layout.OneColumn>

      <Layout.OneColumn {...props}>
        <SummaryPhotos claimType={claimType}/>
      </Layout.OneColumn>

      <Layout.OneColumn {...props}>
        <SummaryDocuments claimType={claimType}/>
      </Layout.OneColumn>

      <Layout.OneColumn {...props}>
        <SummaryDocumentsGeneral/>
      </Layout.OneColumn>

      <Layout.OneColumn {...props}>
        <InspectionAndRepair repairServiceType={repairServiceType}/>
      </Layout.OneColumn>

      <Layout.OneColumn {...props}>
        <SummaryApplicant/>
      </Layout.OneColumn>

      <Layout.OneColumn {...props}>
        <SummaryUser/>
      </Layout.OneColumn>

      <Layout.OneColumn {...props}>
        <SummaryBankAccounts/>
      </Layout.OneColumn>
    </>
  );
}