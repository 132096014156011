/* eslint-disable import/no-webpack-loader-syntax */

import participants from "!url-loader!images/sketch/participants.svg";
import cloudy from "!url-loader!images/sketch/weather/cloudy-navigation.svg";
import cloudyNavigation from "!raw-loader!images/sketch/weather/cloudy-navigation.svg";
import cloudySketch from "!raw-loader!images/sketch/weather/cloudy-sketch.svg";
import street from "!url-loader!images/sketch/street.svg";
import shapes from "!url-loader!images/sketch/shapes.svg";
import text from "!url-loader!images/sketch/text.svg";
import markerPen from "!url-loader!images/sketch/marker-pen.svg";
import eraser from "!url-loader!images/sketch/eraser.svg";
import personNavigation from "!raw-loader!images/sketch/participants/person-navigation.svg";
import personSketch from "!raw-loader!images/sketch/participants/person-sketch.svg";
import cyclingNavigation from "!raw-loader!images/sketch/participants/cycling-navigation.svg";
import cyclingSketch from "!raw-loader!images/sketch/participants/cycling-sketch.svg";
import deerNavigation from "!raw-loader!images/sketch/participants/deer-navigation.svg";
import deerSketch from "!raw-loader!images/sketch/participants/deer-sketch.svg";
import sunNavigation from "!raw-loader!images/sketch/weather/sun-navigation.svg";
import sunSketch from "!raw-loader!images/sketch/weather/sun-sketch.svg";
import snowStormNavigation from "!raw-loader!images/sketch/weather/snow-storm-navigation.svg";
import snowStormSketch from "!raw-loader!images/sketch/weather/snow-storm-sketch.svg";
import windNavigation from "!raw-loader!images/sketch/weather/wind-navigation.svg";
import windSketch from "!raw-loader!images/sketch/weather/wind-sketch.svg";
import winterNavigation from "!raw-loader!images/sketch/weather/winter-navigation.svg";
import winterSketch from "!raw-loader!images/sketch/weather/winter-sketch.svg";
import lightRainNavigation from "!raw-loader!images/sketch/weather/light-rain-navigation.svg";
import lightRainSketch from "!raw-loader!images/sketch/weather/light-rain-sketch.svg";
import rainNavigation from "!raw-loader!images/sketch/weather/rain-navigation.svg";
import rainSketch from "!raw-loader!images/sketch/weather/rain-sketch.svg";
import stormNavigation from "!raw-loader!images/sketch/weather/storm-navigation.svg";
import stormSketch from "!raw-loader!images/sketch/weather/storm-sketch.svg";
import singleRoadNavigation from "!url-loader!images/sketch/roadCrossing/single-road-navigation.svg";
import singleRoadSketch from "!raw-loader!images/sketch/roadCrossing/single-road-sketch.svg";
import doubleRoadNavigation from "!url-loader!images/sketch/roadCrossing/double-road-navigation.svg";
import doubleRoadSketch from "!raw-loader!images/sketch/roadCrossing/double-road-sketch.svg";
import fourRoadsNavigation from "!url-loader!images/sketch/roadCrossing/four-roads-navigation.svg";
import fourRoadsSketch from "!raw-loader!images/sketch/roadCrossing/four-roads-sketch.svg";
import doubleRightDiagonalNavigation from "!url-loader!images/sketch/roadCrossing/double-right-diagonal-navigation.svg";
import doubleRightDiagonalSketch from "!raw-loader!images/sketch/roadCrossing/double-right-diagonal-sketch.svg";
import doubleLeftNavigation from "!url-loader!images/sketch/roadCrossing/double-left-navigation.svg";
import doubleLeftSketch from "!raw-loader!images/sketch/roadCrossing/double-left-sketch.svg";
import singleLeftDiagonalNavigation from "!url-loader!images/sketch/roadCrossing/single-left-diagonal-navigation.svg";
import singleLeftDiagonalSketch from "!raw-loader!images/sketch/roadCrossing/single-left-diagonal-sketch.svg";
import fourRoadsCircleNavigation from "!url-loader!images/sketch/roadCrossing/four-roads-circle-navigation.svg";
import fourRoadsCircleSketch from "!raw-loader!images/sketch/roadCrossing/four-roads-circle-sketch.svg";
import singleLeftNavigation from "!url-loader!images/sketch/roadCrossing/single-left-navigation.svg";
import singleLeftSketch from "!raw-loader!images/sketch/roadCrossing/single-left-sketch.svg";
import circleNavigation from "!raw-loader!images/sketch/shapes/circle-navigation.svg";
import circleSketch from "!raw-loader!images/sketch/shapes/circle-sketch.svg";
import triangleNavigation from "!raw-loader!images/sketch/shapes/triangle-navigation.svg";
import triangleSketch from "!raw-loader!images/sketch/shapes/triangle-sketch.svg";
import rectangleNavigation from "!raw-loader!images/sketch/shapes/rectangle-navigation.svg";
import rectangleSketch from "!raw-loader!images/sketch/shapes/rectangle-sketch.svg";
import crosswalkHorizontalNavigation from "!raw-loader!images/sketch/shapes/crosswalk-horizontal-navigation.svg";
import crosswalkHorizontalSketch from "!raw-loader!images/sketch/shapes/crosswalk-horizontal-sketch.svg";
import crosswalkVerticalNavigation from "!raw-loader!images/sketch/shapes/crosswalk-vertical-navigation.svg";
import crosswalkVerticalSketch from "!raw-loader!images/sketch/shapes/crosswalk-vertical-sketch.svg";
import wrongWay from "!raw-loader!images/sketch/shapes/wrong-way.svg";
import trafficLightRed from "!raw-loader!images/sketch/shapes/traffic-light-red.svg";
import trafficLightYellow from "!raw-loader!images/sketch/shapes/traffic-light-yellow.svg";
import trafficLightGreen from "!raw-loader!images/sketch/shapes/traffic-light-green.svg";
import giveWaySign from "!raw-loader!images/sketch/shapes/give-way-sign.svg";
import mainLeftSign from "!raw-loader!images/sketch/shapes/main-left-sign.svg";
import mainRightSign from "!raw-loader!images/sketch/shapes/main-right-sign.svg";
import mainRightBottomSign from "!raw-loader!images/sketch/shapes/main-right-bottom-sign.svg";
import mainLeftBottomSign from "!raw-loader!images/sketch/shapes/main-left-bottom-sign.svg";
import uTurnLeftNavigation from "!raw-loader!images/sketch/shapes/u-turn-left-navigation.svg";
import uTurnLeftSketch from "!raw-loader!images/sketch/shapes/u-turn-left-sketch.svg";
import uTurnRightNavigation from "!raw-loader!images/sketch/shapes/u-turn-right-navigation.svg";
import uTurnRightSketch from "!raw-loader!images/sketch/shapes/u-turn-right-sketch.svg";
import rightTurnNavigation from "!raw-loader!images/sketch/shapes/right-turn-navigation.svg";
import rightTurnSketch from "!raw-loader!images/sketch/shapes/right-turn-sketch.svg";
import leftTurnNavigation from "!raw-loader!images/sketch/shapes/left-turn-navigation.svg";
import leftTurnSketch from "!raw-loader!images/sketch/shapes/left-turn-sketch.svg";
import stopSign from "!raw-loader!images/sketch/shapes/stop-sign.svg";
import prioritySign from "!raw-loader!images/sketch/shapes/priority-sign.svg";
import parked from "!raw-loader!images/sketch/shapes/parked.svg";
import driving from "!raw-loader!images/sketch/shapes/driving.svg";
import squareNavigation from "!raw-loader!images/sketch/shapes/square-navigation.svg";
import squareSketch from "!raw-loader!images/sketch/shapes/square-sketch.svg";
import dottedSquareNavigation from "!raw-loader!images/sketch/shapes/dotted-square-navigation.svg";
import dottedSquareSketch from "!raw-loader!images/sketch/shapes/dotted-square-sketch.svg";
import tLineNavigation from "!raw-loader!images/sketch/shapes/t-line-navigation.svg";
import tLineSketch from "!raw-loader!images/sketch/shapes/t-line-sketch.svg";
/* eslint-enable import/no-webpack-loader-syntax */

export default {
  DIMENSIONS: {
    CANVAS: {
      WIDTH: 5000,
      HEIGHT: 5000,
    },
    CROSS_ROAD: {
      WIDTH: 311,
      HEIGHT: 311,
    },
  },
  DROP_KEYS: ["PARTICIPANTS", "WEATHER", "SHAPES", "ROAD_CROSSINGS"],
  TYPES: {
    PARTICIPANT: "participant",
    ITEM: "item",
    TEXT: "text",
    DRAW: "draw",
    ERASER: "eraser",
  },
  ITEM_DIMENSION: {
    SOURCE_WIDTH: 50, SOURCE_HEIGHT: 50,
    TARGET_WIDTH: 50 * 10, TARGET_HEIGHT: 50 * 10,
  },
  PARTICIPANT_DIMENSION: {
    SOURCE_WIDTH: 59, SOURCE_HEIGHT: 92,
    TARGET_WIDTH: 59 * 8, TARGET_HEIGHT: 92 * 8,
  },
  TEXT_DIMENSION: {
    SOURCE_WIDTH: 230, SOURCE_HEIGHT: 150,
    TARGET_WIDTH: 230 * 4, TARGET_HEIGHT: 150 * 4,
  },
  TEXT_SIZE: {
    SMALL: 22,
    MEDIUM: 26,
    LARGE: 32,
  },
  NAVIGATION: {
    PARTICIPANTS: {
      NAME: "participants",
      IMAGE: participants,
    },
    WEATHER: {
      NAME: "weather",
      IMAGE: cloudy,
    },
    ROAD_CROSSINGS: {
      NAME: "roadCrossings",
      IMAGE: street,
    },
    SHAPES: {
      NAME: "shapes",
      IMAGE: shapes,
    },
    TEXT: {
      NAME: "text",
      IMAGE: text,
    },
    DRAW: {
      NAME: "draw",
      IMAGE: markerPen,
    },
    ERASER: {
      NAME: "eraser",
      IMAGE: eraser,
    },
  },
  PARTICIPANTS: {
    PERSON: {
      NAME: "person",
      IMAGE_NAVIGATION: personNavigation,
      IMAGE_SKETCH: personSketch,
    },
    CYCLING: {
      NAME: "cycling",
      IMAGE_NAVIGATION: cyclingNavigation,
      IMAGE_SKETCH: cyclingSketch,
    },
    DEER: {
      NAME: "deer",
      IMAGE_NAVIGATION: deerNavigation,
      IMAGE_SKETCH: deerSketch,
    },
  },
  WEATHER: {
    SUN: {
      NAME: "sun",
      IMAGE_NAVIGATION: sunNavigation,
      IMAGE_SKETCH: sunSketch,
    },
    SNOW_STORM: {
      NAME: "snow-storm",
      IMAGE_NAVIGATION: snowStormNavigation,
      IMAGE_SKETCH: snowStormSketch,
    },
    WIND: {
      NAME: "wind",
      IMAGE_NAVIGATION: windNavigation,
      IMAGE_SKETCH: windSketch,
    },
    WINTER: {
      NAME: "winter",
      IMAGE_NAVIGATION: winterNavigation,
      IMAGE_SKETCH: winterSketch,
    },
    CLOUDY: {
      NAME: "cloudy",
      IMAGE_NAVIGATION: cloudyNavigation,
      IMAGE_SKETCH: cloudySketch,
    },
    LIGHT_RAIN: {
      NAME: "light-rain",
      IMAGE_NAVIGATION: lightRainNavigation,
      IMAGE_SKETCH: lightRainSketch,
    },
    STORM: {
      NAME: "storm",
      IMAGE_NAVIGATION: stormNavigation,
      IMAGE_SKETCH: stormSketch,
    },
    RAIN: {
      NAME: "rain",
      IMAGE_NAVIGATION: rainNavigation,
      IMAGE_SKETCH: rainSketch,
    },
  },
  ROAD_CROSSINGS: {
    SINGLE_ROAD: {
      NAME: "single-road",
      IMAGE_NAVIGATION: singleRoadNavigation,
      IMAGE_SKETCH: singleRoadSketch,
    },
    DOUBLE_ROAD: {
      NAME: "double-road",
      IMAGE_NAVIGATION: doubleRoadNavigation,
      IMAGE_SKETCH: doubleRoadSketch,
    },
    FOUR_ROADS: {
      NAME: "four-roads",
      IMAGE_NAVIGATION: fourRoadsNavigation,
      IMAGE_SKETCH: fourRoadsSketch,
    },
    DOUBLE_RIGHT_DIAGONAL: {
      NAME: "double-right-diagonal",
      IMAGE_NAVIGATION: doubleRightDiagonalNavigation,
      IMAGE_SKETCH: doubleRightDiagonalSketch,
    },
    DOUBLE_LEFT: {
      NAME: "double-left",
      IMAGE_NAVIGATION: doubleLeftNavigation,
      IMAGE_SKETCH: doubleLeftSketch,
    },
    SINGLE_LEFT_DIAGONAL: {
      NAME: "single-left-diagonal",
      IMAGE_NAVIGATION: singleLeftDiagonalNavigation,
      IMAGE_SKETCH: singleLeftDiagonalSketch,
    },
    FOUR_ROADS_CIRCLE: {
      NAME: "four-roads-circle",
      IMAGE_NAVIGATION: fourRoadsCircleNavigation,
      IMAGE_SKETCH: fourRoadsCircleSketch,
    },
    SINGLE_LEFT: {
      NAME: "single-left",
      IMAGE_NAVIGATION: singleLeftNavigation,
      IMAGE_SKETCH: singleLeftSketch,
    },
  },
  SHAPES: {
    CIRCLE: {
      NAME: "circle",
      IMAGE_NAVIGATION: circleNavigation,
      IMAGE_SKETCH: circleSketch,
    },
    TRIANGLE: {
      NAME: "triangle",
      IMAGE_NAVIGATION: triangleNavigation,
      IMAGE_SKETCH: triangleSketch,
    },
    RECTANGLE: {
      NAME: "rectangle",
      IMAGE_NAVIGATION: rectangleNavigation,
      IMAGE_SKETCH: rectangleSketch,
    },
    SQUARE: {
      NAME: "square",
      IMAGE_NAVIGATION: squareNavigation,
      IMAGE_SKETCH: squareSketch,
    },
    DOTTED_SQUARE: {
      NAME: "dottedSquare",
      IMAGE_NAVIGATION: dottedSquareNavigation,
      IMAGE_SKETCH: dottedSquareSketch,
    },
    T_LINE: {
      NAME: "tLine",
      IMAGE_NAVIGATION: tLineNavigation,
      IMAGE_SKETCH: tLineSketch,
    },
    CROSSWALK_VERTICAL: {
      NAME: "crosswalk-vertical",
      IMAGE_NAVIGATION: crosswalkVerticalNavigation,
      IMAGE_SKETCH: crosswalkVerticalSketch,
    },
    CROSSWALK_HORIZONTAL: {
      NAME: "crosswalk-horizontal",
      IMAGE_NAVIGATION: crosswalkHorizontalNavigation,
      IMAGE_SKETCH: crosswalkHorizontalSketch,
    },
    WRONG_WAY: {
      NAME: "wrong-way",
      IMAGE_NAVIGATION: wrongWay,
      IMAGE_SKETCH: wrongWay,
    },
    TRAFFIC_LIGHT_RED: {
      NAME: "traffic-light-red",
      IMAGE_NAVIGATION: trafficLightRed,
      IMAGE_SKETCH: trafficLightRed,
    },
    TRAFFIC_LIGHT_YELLOW: {
      NAME: "traffic-light-yellow",
      IMAGE_NAVIGATION: trafficLightYellow,
      IMAGE_SKETCH: trafficLightYellow,
    },
    TRAFFIC_LIGHT_GREEN: {
      NAME: "traffic-light-green",
      IMAGE_NAVIGATION: trafficLightGreen,
      IMAGE_SKETCH: trafficLightGreen,
    },
    GIVE_WAY_SIGN: {
      NAME: "give-way-sign",
      IMAGE_NAVIGATION: giveWaySign,
      IMAGE_SKETCH: giveWaySign,
    },
    MAIN_LEFT_SIGN: {
      NAME: "main-left-sign",
      IMAGE_NAVIGATION: mainLeftSign,
      IMAGE_SKETCH: mainLeftSign,
    },
    MAIN_RIGHT_SIGN: {
      NAME: "main-right-sign",
      IMAGE_NAVIGATION: mainRightSign,
      IMAGE_SKETCH: mainRightSign,
    },
    MAIN_LEFT_BOTTOM_SIGN: {
      NAME: "main-left-bottom-sign",
      IMAGE_NAVIGATION: mainLeftBottomSign,
      IMAGE_SKETCH: mainLeftBottomSign,
    },
    MAIN_RIGHT_BOTTOM_SIGN: {
      NAME: "main-right-bottom-sign",
      IMAGE_NAVIGATION: mainRightBottomSign,
      IMAGE_SKETCH: mainRightBottomSign,
    },
    U_TURN_LEFT: {
      NAME: "u-turn-left",
      IMAGE_NAVIGATION: uTurnLeftNavigation,
      IMAGE_SKETCH: uTurnLeftSketch,
    },
    U_TURN_RIGHT: {
      NAME: "u-turn-right",
      IMAGE_NAVIGATION: uTurnRightNavigation,
      IMAGE_SKETCH: uTurnRightSketch,
    },
    RIGHT_TURN: {
      NAME: "right-turn",
      IMAGE_NAVIGATION: rightTurnNavigation,
      IMAGE_SKETCH: rightTurnSketch,
    },
    LEFT_TURN: {
      NAME: "left-turn",
      IMAGE_NAVIGATION: leftTurnNavigation,
      IMAGE_SKETCH: leftTurnSketch,
    },
    STOP_SIGN: {
      NAME: "stop-sign",
      IMAGE_NAVIGATION: stopSign,
      IMAGE_SKETCH: stopSign,
    },
    PRIORITY_SIGN: {
      NAME: "priority-sign",
      IMAGE_NAVIGATION: prioritySign,
      IMAGE_SKETCH: prioritySign,
    },
    PARKED: {
      NAME: "parked",
      IMAGE_NAVIGATION: parked,
      IMAGE_SKETCH: parked,
    },
    DRIVING: {
      NAME: "driving",
      IMAGE_NAVIGATION: driving,
      IMAGE_SKETCH: driving,
    },
  }
};
