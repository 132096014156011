import React, {Component} from "react";

export default class SvgText extends Component {
  state = {
    lines: []
  };

  componentWillMount() {
    const {wordsWithComputedWidth, spaceWidth} = this.calculateWordWidths();
    this.wordsWithComputedWidth = wordsWithComputedWidth;
    this.spaceWidth = spaceWidth;

    const lines = this.calculateLines(this.wordsWithComputedWidth, this.spaceWidth, this.props.width);
    this.setState({lines});
  }

  calculateWordWidths() {
    // Calculate length of each word to be used to determine number of words per line
    const words = this.props.children.split(/\s+/);
    const container = document.createElement("div");
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    Object.assign(text.style, this.props.style);
    svg.appendChild(text);
    container.appendChild(svg);
    document.body.appendChild(container);

    const wordsWithComputedWidth = words.map(word => {
      text.textContent = word;
      return {word, width: text.getComputedTextLength()};
    });

    text.textContent = '\u00A0'; // Unicode space
    const spaceWidth = text.getComputedTextLength();

    document.body.removeChild(container);

    return {wordsWithComputedWidth, spaceWidth};
  }

  calculateLines(wordsWithComputedWidth, spaceWidth, lineWidth) {
    const wordsByLines = wordsWithComputedWidth.reduce((result, {word, width}) => {
      const lastLine = result[result.length - 1] || {words: [], width: 0};

      if (lastLine.words.length === 0) {
        // First word on line
        const newLine = {words: [word], width};
        result.push(newLine);
      } else if (lastLine.width + width + (lastLine.words.length * spaceWidth) < lineWidth) {
        // Word can be added to an existing line
        lastLine.words.push(word);
        lastLine.width += width;
      } else {
        // Word too long to fit on existing line
        const newLine = {words: [word], width};
        result.push(newLine);
      }

      return result;
    }, []);

    return wordsByLines.map(line => line.words.join(' '));
  }

  render() {
    return (
      <text {...this.props} dy={"1em"}>
        {this.state.lines.map((word, index) => (
          <tspan key={index} x={5} y={"1em"} dy={`${index}em`}>
            {word}
          </tspan>
        ))}
      </text>
    )
  }
}