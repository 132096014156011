import React, {Component} from 'react';

import {isUndefined} from "underscore";

import Button from "components/common/Button";
import Layout from "components/common/Layout";
import MapForm from "components/form/MapForm";
import PickMarkerMapField from "components/form/PickMarkerMapField";
import MapSearchForm from "components/map/MapSearchForm";
import InfoWindowHelper from "components/map/InfoWindowHelper";
import MapSlider from "components/step/map/MapSlider";
import repairServiceTypeConstants from "constants/repairServiceTypes";
import SplitViewContainer from "containers/view/SplitViewContainer";
import VehicleDamageInspectionPointContent
  from "containers/step/vehicleDamageInspectionPoint/VehicleDamageInspectionPointContent";
import {mapSearchStateHelper} from "utility/mapSearch";
import isUndefinedOrNull from "utility/utilityFunctions";

import styles from "containers/step/vehicleDamageInspectionPoint/VehicleDamageInspectionPointStepContainer.module.scss";

const searchFieldName = "vehicleDamageInspectionPointSearch";

export default class VehicleDamageInspectionPointRight extends Component {
  state = {
    showVehicleDamageInspectionPointList: false,
  };

  toggleSlider = () => {
    this.setState(state => ({
      showVehicleDamageInspectionPointList: !state.showVehicleDamageInspectionPointList,
    }));
  };

  onLocationClick = (inspectionPoint) => {
    const {setFieldValue, repairServiceType} = this.props;
    this.focusInspectionPoint(inspectionPoint);

    setFieldValue(repairServiceType, inspectionPoint, false);

    this.closeSlider();
  };

  closeSlider = () => {
    this.setState({
      showVehicleDamageInspectionPointList: false,
    });
  };

  static getDerivedStateFromProps(props, state) {
    const {focusLocation, inspectionPoint} = props.values;

    return mapSearchStateHelper(focusLocation, inspectionPoint, state);
  }

  searchSelected = (inspectionPoint) => {
    if (isUndefinedOrNull(inspectionPoint)) {
      this.clearSearch();
    } else {
      this.focusInspectionPoint(inspectionPoint);
    }
  };

  focusInspectionPoint = (inspectionPoint) => {
    const {setFieldValue} = this.props;

    setFieldValue(
      "focusLocation",
      inspectionPoint
        ? {
          lat: inspectionPoint.x,
          lng: inspectionPoint.y,
          id: inspectionPoint.id
        }
        : null
    );

    this.setState({
      selectedMarkerId: inspectionPoint.id,
      center: {
        lat: inspectionPoint.x,
        lng: inspectionPoint.y,
      },
    });
  };

  clearSearch = () => {
    const {setFieldValue} = this.props;

    setFieldValue(searchFieldName, null);
  };

  render() {
    const {values, repairServiceType, registryContext} = this.props;
    const vehicleDamageInspectionPoint = values[repairServiceType];
    const vehicleDamageInspectionPointSelected = !(isUndefined(vehicleDamageInspectionPoint) || vehicleDamageInspectionPoint === null);
    const damageCenters = repairServiceType === repairServiceTypeConstants.VEHICLE_DAMAGE_INSPECTION_POINT ? registryContext.registry.mapDamageCenters : registryContext.registry.mapBusinessUnits;

    return (
      <SplitViewContainer.Right noSpacing={true} map={true} {...this.props}>
        <>
          <MapForm buttonLabel={`${repairServiceType}.content.openMap.label`} position="center" width="auto">
            <MapSearchForm name={searchFieldName}
                           markers={damageCenters}
                           searchSelected={this.searchSelected}>
              <PickMarkerMapField name={repairServiceType}
                                  markers={damageCenters}
                                  clearSearch={this.clearSearch}
                                  markerInfoComponent={InfoWindowHelper}
                                  center={this.state.center}
                                  selectedMarkerId={this.state.selectedMarkerId}/>
            </MapSearchForm>
          </MapForm>
          <Layout>
            <Layout.OneColumn className={styles.content} maxWidth={700} width="100%" justifyContent="center">
              <VehicleDamageInspectionPointContent
                vehicleDamageInspectionPointSelected={vehicleDamageInspectionPointSelected}
                vehicleDamageInspectionPoint={vehicleDamageInspectionPoint}
                repairServiceType={repairServiceType}/>
            </Layout.OneColumn>
            <Layout.OneRow spacing={10} justifyContent="center">
              <Button label={`${repairServiceType}.content.openSlider`} onClick={this.toggleSlider}/>
            </Layout.OneRow>
            <Layout.OneColumn spacing={30}/>
          </Layout>
          <MapSlider isOpened={this.state.showVehicleDamageInspectionPointList} onLocationClick={this.onLocationClick}
                     locations={damageCenters} onClose={this.closeSlider}/>
        </>
      </SplitViewContainer.Right>
    );
  }
}
