import React, {Component} from "react";

import {contains} from "underscore";

import Button from "components/common/Button";
import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import DatePicker from "components/form/DatePicker";
import DateRangePicker from "components/form/DateRangePicker";
import ToggleButton from "components/form/ToggleButton";
import IcoMoon from "components/icon/IcoMoon";
import FadeView from "components/view/FadeView";
import accidentDateTypesConstants from "constants/accidentDateTypes";
import fontConstants from "constants/font";

export default class AccidentDateRight extends Component {
  onClick = () => {
    const {setFieldValue} = this.props;

    setFieldValue("dateToday", new Date());
  };

  render() {
    const {onNextStep, values, isValid} = this.props;

    return (
      <Layout>
        <AccidentDateType onClick={this.onClick} onNextStep={onNextStep}/>
        <FadeView step={values.accidentDateType}>
          <Range accidentDateType={values.accidentDateType} onNextStep={onNextStep} isValid={isValid}/>
          <Calendar accidentDateType={values.accidentDateType} onNextStep={onNextStep} isValid={isValid}/>
        </FadeView>
      </Layout>
    );
  }
}

function AccidentDateType({onClick, onNextStep}) {
  return (
    <NextStepButton component={ToggleButton}
                    onNextStep={onNextStep}
                    isValid={true}
                    onClick={onClick}
                    condition={value => contains([accidentDateTypesConstants.TODAY, accidentDateTypesConstants.YESTERDAY], value)}
                    buttonProps={{name: "accidentDateType"}}>
      <Layout spacing={10} flex={1}>
        <Layout.RowChildren>
          <ToggleButton.Choice value={accidentDateTypesConstants.TODAY}
                               label="accidentDate.content.button.today.label"/>
          <ToggleButton.Choice value={accidentDateTypesConstants.YESTERDAY}
                               label="accidentDate.content.button.yesterday.label"/>
        </Layout.RowChildren>
        <Layout.RowChildren>
          <ToggleButton.Choice value={accidentDateTypesConstants.CALENDAR}
                               icon={<IcoMoon icon={fontConstants.ICOMOON.CALENDAR}/>}
                               label="accidentDate.content.button.calendar.label"/>
          <ToggleButton.Choice value={accidentDateTypesConstants.RANGE}
                               icon={<IcoMoon icon={fontConstants.ICOMOON.CALENDAR}/>}
                               label="accidentDate.content.button.range.label"/>
        </Layout.RowChildren>
      </Layout>
    </NextStepButton>
  );
}

function Range({accidentDateType, onNextStep, isValid}) {
  if (accidentDateType !== accidentDateTypesConstants.RANGE) {
    return null;
  }

  return (
    <>
      <DateRangePicker nameFrom="accidentDateFrom" nameTo="accidentDateTo"/>

      <Layout.OneRow justifyContent="center">
        <NextStepButton onNextStep={onNextStep}
                        isValid={isValid}
                        component={Button}
                        buttonProps={{
                          label: "button.next",
                          isDisabled: !isValid,
                        }}/>
      </Layout.OneRow>
      <Layout.OneColumn spacing={40}/>
    </>
  );
}

function Calendar({accidentDateType, onNextStep, isValid}) {
  if (accidentDateType !== accidentDateTypesConstants.CALENDAR) {
    return null;
  }

  return (
    <>
      <DatePicker name="accidentDate"/>

      <Layout.OneRow justifyContent="center">
        <NextStepButton onNextStep={onNextStep}
                        isValid={isValid}
                        component={Button}
                        buttonProps={{
                          label: "button.next",
                          isDisabled: !isValid,
                        }}/>
      </Layout.OneRow>
    </>
  );
}
