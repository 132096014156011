import React, { Component } from 'react';

import cx from "classnames";

import Button from "components/common/Button";

import styles from "components/form/CheckBox/CheckboxShowMoreWrapper.module.scss";

export default class CheckboxShowMoreWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    }
  }

  onClick = () => {
    this.setState(state => ({
      ...state,
      expanded: true
    }))
  }

  render() {
    const showMoreClasses = cx({
      [styles.showMoreWrapper]: true,
      [styles.shown]: this.state.expanded
    });
    
    return (
      <>
        <div className={showMoreClasses}>
          {this.props.children}
          {
            !this.state.expanded && 
            <div className={styles.overlay}></div>
          }
        </div>
        {
          !this.state.expanded &&
          <Button
            onClick={this.onClick}
            variant="variant10" className={styles.button}>Prikaži več</Button>
        }
      </>
      
    )
  }
}
