import React, {Component} from "react";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import ToggleButton from "components/form/ToggleButton";
import TextField from "components/form/TextField";
import FadeView from "components/view/FadeView";
import booleanTypeConstants from "constants/booleanTypes";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";

export default class PastMedicalConditionsRight extends Component {
  render() {
    const {values, onNextStep} = this.props;

    return (
      <Layout spacing={10}>
        <Layout.OneColumn flex={1}>
          <NextStepButton component={ToggleButton}
                          onNextStep={onNextStep}
                          isValid={true}
                          condition={value => value === booleanTypeConstants.NO}
                          buttonProps={{
                            name: "pastMedicalConditions",
                          }}>
            <Layout spacing={10}>
              <Layout.OneColumn flex={1}>
                <Layout.OneRow spacing={10} flex={1}>
                  <ToggleButton.Choice value={booleanTypeConstants.YES} label="button.yes"/>
                  <ToggleButton.Choice value={booleanTypeConstants.NO} label="button.no"/>
                </Layout.OneRow>
              </Layout.OneColumn>
            </Layout>
          </NextStepButton>
        </Layout.OneColumn>
        <Layout.OneColumn width="100%" maxWidth={450} justifyContent="center">
          <FadeView step={values.pastMedicalConditions}>
            {values.pastMedicalConditions === booleanTypeConstants.YES
              ? <TextField name="description"
                           label="pastMedicalConditions.content.description.label"
                           placeholder="pastMedicalConditions.content.description.placeholder"
                           multiLine={true}
                           maxLength={fieldLengthConstants[flowConstants.personSteps.PAST_MEDICAL_CONDITIONS.NAME].description + 1}/>
              : null
            }
          </FadeView>
        </Layout.OneColumn>
      </Layout>
    );
  }
}
