import React, {Component} from "react";

import cx from "classnames";

import Layout from "components/common/Layout";
import Link from "components/common/Link";
import Text from "components/common/Text";
import {SummaryContext} from "components/step/summary/SummaryContainer";
import SummaryRefContainer, {SummaryRefContext} from "components/step/summary/SummaryRefContainer";

import styles from "components/step/summary/SummaryTable.module.scss";

import pencil from "images/pencil.svg";

const SummaryTableContext = React.createContext();

class SummaryTable extends Component {
  state = {
    onJumpStep: this.props.onJumpStep,
  };

  render() {
    const {title, refName, children} = this.props;

    return (
      <SummaryTableContext.Provider value={this.state}>
        <div>
          <Text className={styles.header} element="h5" label={title}/>
          <SummaryRefContainer refName={refName}>
            {children}
          </SummaryRefContainer>
        </div>
      </SummaryTableContext.Provider>
    );
  }
}

function SummaryTablePart({children, refName}) {
  return (
    <div className={styles.part}>
      <SummaryRefContainer refName={refName}>
        {children}
      </SummaryRefContainer>
    </div>
  );
}

function SummaryTableItemValue({refName, ...props}) {
  return (
    <SummaryRefContainer refName={refName}>
      <SummaryRefContext.Consumer>
        {summaryRefContext => {
          return (
            <InnerSummaryTableItemValue {...props} refName={summaryRefContext.join("-")} />
          );
        }}
      </SummaryRefContext.Consumer>
    </SummaryRefContainer>
  )
}

class InnerSummaryTableItemValue extends Component {
  ref = React.createRef();

  componentDidMount() {
    const {summaryContext, refName} = this.props;
    summaryContext.addRef(refName, this.ref.current);
  }

  render() {
    const {jumpTo, children, summaryTableContext, refName, variant, clickyTable = true} = this.props;

    const valueClassName = cx({
      [styles.value]: true,
      [styles.variant1]: variant === "variant1",
    });

    return (
      <div ref={this.ref}>
        <Layout layoutClassName={valueClassName}>
          <Layout.Row onClick={clickyTable ? summaryTableContext.onJumpStep(jumpTo, refName) : () => {}}>
            <Layout.Column flex={1}>
              <div className={styles.content}>
                {children}
              </div>
            </Layout.Column>

            <Layout.Column width={22}>
              <div onClick={clickyTable ? () => {} : summaryTableContext.onJumpStep(jumpTo, refName)}>
                <Edit/>
              </div>
            </Layout.Column>
          </Layout.Row>
        </Layout>
      </div>
    );
  }
}

function SummaryTableItemText({label, ...props}) {
  const labelElement =
    typeof label === 'string'
      ? <Text className={styles.name} element="div" label={label}/>
      : React.cloneElement(label, {className: styles.name, element: "div"});

  return (
    <SummaryContext.Consumer>
      {summaryContext => (
        <SummaryTableContext.Consumer>
          {summaryTableContext => (
            <Layout layoutClassName={styles.item}>
              <Layout.Row>
                <Layout.Column flex={4}>
                  {labelElement}
                </Layout.Column>
                <Layout.Column flex={6}>
                  <SummaryTableItemValue {...props}
                                         summaryContext={summaryContext}
                                         summaryTableContext={summaryTableContext}/>
                </Layout.Column>
              </Layout.Row>
            </Layout>
          )}
        </SummaryTableContext.Consumer>
      )}
    </SummaryContext.Consumer>
  );
}

function SummaryTableItemContent(props) {
  return (
    <SummaryContext.Consumer>
      {summaryContext => (
        <SummaryTableContext.Consumer>
          {summaryTableContext => (
            <Layout.OneRow layoutClassName={styles.item} width="100%">
              <SummaryTableItemValue {...props}
                                     summaryContext={summaryContext}
                                     summaryTableContext={summaryTableContext}
                                     variant="variant1"/>
            </Layout.OneRow>
          )}
        </SummaryTableContext.Consumer>
      )}
    </SummaryContext.Consumer>
  );
}

function SummaryTableHeaderContent({children}) {
  return (
    <div className={styles.headerContent}>
      {children}
    </div>
  );
}

function SummaryTableHeaderText({label}) {
  return (
    <div className={styles.headerText}>
      <Text label={label}/>
    </div>
  );
}

function SummaryTableOnePart({children, ...props}) {
  return (
    <SummaryTable {...props}>
      <SummaryTablePart>
        {children}
      </SummaryTablePart>
    </SummaryTable>
  );
}

function SummaryTableSimple({jumpTo, children, ...props}) {
  return (
    <SummaryTableOnePart {...props}>
      <SummaryTableItemContent jumpTo={jumpTo}>
        {children}
      </SummaryTableItemContent>
    </SummaryTableOnePart>
  );
}

function Edit() {
  return (
    <Link className={styles.edit} variant="variant3">
      <img className={styles.icon} src={pencil} alt="pencil"/>
      <Text className={styles.text} element="div" label="button.edit"/>
    </Link>
  );
}

SummaryTable.Part = SummaryTablePart;
SummaryTable.ItemContent = SummaryTableItemContent;
SummaryTable.ItemText = SummaryTableItemText;
SummaryTable.HeaderContent = SummaryTableHeaderContent;
SummaryTable.HeaderText = SummaryTableHeaderText;
SummaryTable.OnePart = SummaryTableOnePart;
SummaryTable.Simple = SummaryTableSimple;

export default SummaryTable;
