import React, {Component} from "react";

import _ from "lodash";
import {isEmpty, isFunction, isUndefined, some} from "underscore";

import SummaryTable from "components/step/summary/SummaryTable";
import {getStoreKey} from "selectors/flow";

export const SummaryContext = React.createContext();

function getForm(forms, formName) {
  return _.at(forms, getStoreKey(formName))[0];
}

class Summary extends Component {
  static contextType = SummaryContext;

  render() {
    const {children, onePart = false, ...props} = this.props;

    const hasVisibleChild = some(
      React.Children.toArray(children),
      child => {
        const form = getForm(this.context.values, child.props.formName);
        return !isUndefined(form) && child.props.condition(form);
      },
      this,
    );

    if (!hasVisibleChild) {
      return null;
    }

    const Component = onePart ? SummaryTable.OnePart : SummaryTable;

    return (
      <Component {...props} onJumpStep={this.context.onJumpFromSummaryStep}>
        {children}
      </Component>
    );
  }
}

class SummaryItems extends Component {
  static defaultProps = {
    condition: () => true,
  };

  static contextType = SummaryContext;

  render() {
    const {formName, fieldName, condition, children} = this.props;

    const formValue = getForm(this.context.values, formName);

    if (isUndefined(formValue) || !condition(formValue)) {
      return null;
    }

    const fieldValue = isUndefined(fieldName) ? formValue : formValue[fieldName];

    if (isEmpty(fieldValue)) {
      return null;
    }

    return children(fieldValue);
  }
}

class SummaryItemValue extends Component {
  static defaultProps = {
    condition: () => true,
  };

  render() {
    const {formName, fieldName, label, condition, children, refName} = this.props;

    return (
      <SummaryItems formName={formName} fieldName={fieldName} condition={condition}>
        {form => (
          <SummaryTable.ItemText label={label} jumpTo={form.jumpTo} refName={refName}>
            {isFunction(children) ? children(form) : children}
          </SummaryTable.ItemText>
        )}
      </SummaryItems>
    );
  }
}

Summary.Items = SummaryItems;
Summary.ItemValue = SummaryItemValue;

export default Summary;
