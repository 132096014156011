import React, {Component} from "react";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import ToggleButton from "components/form/ToggleButton";
import theftTypeConstants from "constants/theftTypes"

export default class TheftTypeRight extends Component {
  render() {
    const {isValid, onNextStep} = this.props;

    return (
      <NextStepButton component={ToggleButton}
                      buttonProps={{name: "theftType"}}
                      onNextStep={onNextStep}
                      isValid={isValid}>
        <Layout.OneRow spacing={10} flex={1}>
          <ToggleButton.Choice value={theftTypeConstants.VEHICLE_PART}
                               label="theftType.content.button.label.vehicle.part"
                               descriptionLabel="theftType.content.button.description.vehicle.part"
                               variant="variant25"
          />
          <ToggleButton.Choice value={theftTypeConstants.VEHICLE}
                               label="theftType.content.button.label.vehicle"
                               descriptionLabel="theftType.content.button.description.vehicle"
                               variant="variant25"
          />
        </Layout.OneRow>
      </NextStepButton>
    );
  }
}
