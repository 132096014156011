import React from "react";
import Switch, {Case, Default} from "react-switch-case";

import AccidentLocationStepContainer from "containers/step/accidentLocation/AccidentLocationStepContainer";
import AccidentDateStepContainer from "containers/step/accidentDate/AccidentDateStepContainer";
import AccidentTimeStepContainer from "containers/step/accidentTime/AccidentTimeStepContainer";
import BankAccountsStepContainer from "containers/step/bankAccounts/BankAccountsStepContainer";
import HomeBurglaryPropertyTypeStepContainer from "containers/step/homeBurglary/HomeBurglaryPropertyTypeStepContainer";
import HomeBurglaryViolentStepContainer from "containers/step/homeBurglary/HomeBurglaryViolentStepContainer";
import HomeBurglaryWhoStepContainer from "containers/step/homeBurglary/HomeBurglaryWhoStepContainer";
import HomeCalculationTypeStepContainer from "containers/step/homeCalculationType/HomeCalculationTypeStepContainer";
import HomeDamagedGoodsStepContainer from "containers/step/homeDamagedGoods/HomeDamagedGoodsStepContainer";
import HomeDamagedGoodsInfoStepContainer from "containers/step/homeDamagedGoodsInfo/HomeDamagedGoodsInfoStepContainer";
import HomeDamageTypeStepContainer from "containers/step/homeDamageType/HomeDamageTypeStepContainer";
import DocumentsStepContainer from "containers/step/documents/DocumentsStepContainer";
import HomeVehicleInfoStepContainer from "containers/step/homeVehicleInfo/HomeVehicleInfoStepContainer";
import HomeReportedPoliceStepContainer from "containers/step/homeReportedPolice/HomeReportedPoliceStepContainer";
import HomeResponsiblePersonStepContainer from "containers/step/homeResponsiblePerson/HomeResponsiblePersonStepContainer";
import HomeSomeoneElseResponsibleStepContainer from "containers/step/homeSomeoneElseResponsible/HomeSomeoneElseResponsibleStepContainer";
import PhoneNumberStepContainer from "containers/step/phoneNumber/PhoneNumberStepContainer";
import SummaryStepContainer from "containers/step/summary/SummaryStepContainer";
import ThankYouStepContainer from "containers/step/thankYou/ThankYouStepContainer";
import InsuranceOwnerStepContainer from "containers/step/insuranceOwner/InsuranceOwnerStepContainer";
import UserIdentificationStepContainer from "containers/step/userIdentification/UserIdentificationStepContainer";
import flowConstants from "constants/flow";
import UserIdentificationTypeStepContainer from "./step/userIdentificationType/UserIdentificationTypeStepContainer";
import ApplicantDataStepContainer from "./step/applicantData/ApplicantDataStepContainer";
import UserLoginStepContainer from "./step/userLogin/UserLoginStepContainer";

export default function StateRouterHomeContainer({step}) {
  return (
    <Switch condition={step.name}>
      <Case value={flowConstants.homeSteps.HOME_BURGLARY_WHO.NAME}>{/* step  */}
        <HomeBurglaryWhoStepContainer/>
      </Case>
      <Case value={flowConstants.homeSteps.HOME_BURGLARY_PROPERTY_TYPE.NAME}>{/* step  */}
        <HomeBurglaryPropertyTypeStepContainer/>
      </Case>
      <Case value={flowConstants.homeSteps.HOME_BURGLARY_VIOLENT.NAME}>{/* step  */}
        <HomeBurglaryViolentStepContainer/>
      </Case>
      <Case value={flowConstants.homeSteps.HOME_DAMAGE_TYPE.NAME}>{/* step 3 */}
        <HomeDamageTypeStepContainer/>
      </Case>
      <Case value={flowConstants.homeSteps.HOME_DAMAGED_GOODS.NAME}>{/* step 4 */}
        <HomeDamagedGoodsStepContainer/>
      </Case>
      <Case value={flowConstants.homeSteps.HOME_DAMAGED_GOODS_INFO.NAME}>{/* step 5 */}
        <HomeDamagedGoodsInfoStepContainer/>
      </Case>
      <Case value={flowConstants.steps.ACCIDENT_DATE.NAME}>{/* step 6 */}
        <AccidentDateStepContainer/>
      </Case>
      <Case value={flowConstants.steps.ACCIDENT_TIME.NAME}>{/* step 7 */}
        <AccidentTimeStepContainer/>
      </Case>
      <Case value={flowConstants.steps.ACCIDENT_LOCATION.NAME}>{/* step 8 */}
        <AccidentLocationStepContainer/>
      </Case>
      <Case value={flowConstants.homeSteps.HOME_VEHICLE_INFO.NAME}>{/* step  */}
        <HomeVehicleInfoStepContainer/>
      </Case>
      <Case value={flowConstants.homeSteps.HOME_REPORTED_POLICE.NAME}>{/* step 10 */}
        <HomeReportedPoliceStepContainer/>
      </Case>
      <Case value={flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME}>{/* step 10 */}
        <HomeResponsiblePersonStepContainer/>
      </Case>
      <Case value={flowConstants.homeSteps.HOME_DOCUMENTS.NAME}>{/* step 12 */}
        <DocumentsStepContainer/>
      </Case>
      <Case value={flowConstants.homeSteps.HOME_SOMEONE_ELSE_RESPONSIBLE.NAME}>{/* step  */}
        <HomeSomeoneElseResponsibleStepContainer/>
      </Case>
      <Case value={flowConstants.homeSteps.HOME_CALCULATION_TYPE.NAME}>{/* step 13 */}
        <HomeCalculationTypeStepContainer/>
      </Case>
      <Case value={flowConstants.steps.INSURANCE_OWNER.NAME}>{/* step 33 */}
        <InsuranceOwnerStepContainer/>
      </Case>
      <Case value={flowConstants.steps.USER_IDENTIFICATION_TYPE.NAME}>{/* step 33 */}
        <UserIdentificationTypeStepContainer/>
      </Case>
      <Case value={flowConstants.steps.APPLICANT_DATA.NAME}>{/* step 33 */}
        <ApplicantDataStepContainer/>
      </Case>
      <Case value={flowConstants.steps.USER_LOGIN.NAME}>{/* step 33 */}
        <UserLoginStepContainer/>
      </Case>
      <Case value={flowConstants.steps.USER_IDENTIFICATION.NAME}>{/* step 14 */}
        <UserIdentificationStepContainer/>
      </Case>
      <Case value={flowConstants.steps.BANK_ACCOUNTS.NAME}>{/* step 15 */}
        <BankAccountsStepContainer/>
      </Case>
      <Case value={flowConstants.steps.SUMMARY.NAME}>{/* step 16 */}
        <SummaryStepContainer/>
      </Case>
      <Case value={flowConstants.steps.PHONE_NUMBER.NAME}>{/* step 17 */}
        <PhoneNumberStepContainer/>
      </Case>
      <Case value={flowConstants.steps.THANK_YOU.NAME}>{/* step 18 */}
        <ThankYouStepContainer/>
      </Case>
      <Default>
        <div>
          Unknown step: {step.name}.
        </div>
      </Default>
    </Switch>
  );
}
