import {range} from "underscore";

import vehicleTypesConstants from "constants/vehicleTypes";
import CarSvg from "components/svg/CarSvg";
import SemiTruckSvg from "components/svg/SemiTruckSvg";
import MotorcycleSvg from "components/svg/MotorcycleSvg";
import BikeSvg from "components/svg/BikeSvg";

export default {
  COMPONENT_MAPPING: {
    [vehicleTypesConstants.CAR]: CarSvg,
    [vehicleTypesConstants.SEMI_TRUCK]: SemiTruckSvg,
    [vehicleTypesConstants.MOTORCYCLE]: MotorcycleSvg,
    [vehicleTypesConstants.BIKE]: BikeSvg,
  },
  FIELD_MAPPING: {
    [vehicleTypesConstants.CAR]: "carDamage",
    [vehicleTypesConstants.SEMI_TRUCK]: "semiTruckDamage",
    [vehicleTypesConstants.MOTORCYCLE]: "motorcycleDamage",
    [vehicleTypesConstants.BIKE]: "bikeDamage",
  },
  COUNT_PARTS_MAPPING: {
    [vehicleTypesConstants.CAR]: range(0, 28),
    [vehicleTypesConstants.SEMI_TRUCK]: range(0, 32),
    [vehicleTypesConstants.MOTORCYCLE]: range(0, 17),
    [vehicleTypesConstants.BIKE]: range(0, 9),
  },
}