import React from "react";

import cx from "classnames";
import {DragLayer} from "react-dnd";
import {some} from "underscore";

import SketchSvg from "components/sketch/canvas/SketchSvg";
import SketchSvgContainer from "components/sketch/canvas/SketchSvgContainer";
import SketchCanvasItem from "components/sketch/item/SketchCanvasItem";
import {getCanvasItemPosition} from "selectors/sketch";

import styles from "components/sketch/canvas/SketchCanvasPreview.module.scss";

function SketchCanvasPreview({isDragging, canDrop, item: {item, initialClientOffset}, currentOffset, dimensions}) {
  if (!dimensions) {
    return null;
  }

  const position = getCanvasItemPosition(currentOffset, initialClientOffset, item, dimensions, canDrop);
  const svgItem = isDragging ? <SketchCanvasItem item={{...item, ...position}}/> : null;

  const groupClassName = cx({
    [styles.cannotDrop]: !canDrop,
  });

  return (
    <SketchSvgContainer dimensions={dimensions} className={styles.root} shouldCenter={false}>
      <SketchSvg dimensions={dimensions} groupClassName={groupClassName} shouldCenter={false}>
        {svgItem}
      </SketchSvg>
    </SketchSvgContainer>
  );
}

function collect(monitor) {
  const targetIds = monitor.isDragging() ? monitor.getTargetIds() : [];
  const canDrop = some(targetIds, id => monitor.isOverTarget(id) && monitor.canDropOnTarget(id));

  return {
    item: monitor.getItem() || {},
    itemType: monitor.getItemType(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
    canDrop: canDrop,
  };
}

export default DragLayer(collect)(SketchCanvasPreview);
