import React, {Component} from "react";

import {connect} from "react-redux";
import {batch} from "redux-act";
import {Form, Formik} from "formik";
import _ from "lodash";
import {isFunction} from "underscore";

import {
  jumpFromSummaryStep,
  jumpStep,
  nextStep,
  previousStep,
  reloadStep,
  removeDamagedGood,
  removeParticipants,
  resetAfter,
  setDirty,
  skipParticipant,
  storeClaim,
  storeSummaryHTML,
  submitClaim,
  updateFormState,
} from "actions/flow";
import {ConditionalContext} from "components/form/ConditionalField";
import FormikOnChange from "components/form/FormikOnChange";
import booleanTypeConstants from "constants/booleanTypes";
import claimTypesConstants from "constants/claimTypes";
import customerDataStatusTypeConstants from "constants/customerDataStatusTypes";
import medicalCareDescriptionTypes from "constants/medicalCareDescriptionTypes";
import repairServiceTypeConstants from "constants/repairServiceTypes";
import userIdentificationTypeConstants from "constants/userIdentificationType";
import {ConfigContext} from "containers/context/ConfigContainer";
import NextStepContainer from "containers/context/NextStepContainer";
import {getFieldConfiguration, getStoreKey, hasPreviousStep} from "selectors/flow";

class ViewContainer extends Component {
  static contextType = ConfigContext;

  timer = null;
  stepKey = this.props.stepKey;

  componentDidMount() {
    this.props.saveClaim(this.context.url.saveClaim);

    this.timer = setInterval(() => this.props.saveClaim(this.context.url.saveClaim), 10 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.stepKey === this.stepKey;
  }

  onChange = (values) => {
    this.props.updateFormState(this.context.url.saveClaim, this.props.step, values);
  };

  handleSubmit = (values) => {
    this.props.nextStep(this.props.step, values);
  };

  render() {
    const {validationSchema, formState, stepKey, ...props} = this.props;

    const formValidationSchema =
      isFunction(validationSchema)
        ? validationSchema({
          step: props.step,
          fieldConfiguration: props.fieldConfiguration,
          loggedInFromCounter: props.loggedInFromCounter
        })
        : validationSchema;

    return (
      <Formik key={stepKey}
              initialValues={formState}
              validationSchema={formValidationSchema}
              onSubmit={props.onSubmit || this.handleSubmit}>
        {(formProps) => {
          return (
            <ViewContainerForm {...props} formProps={formProps} stepKey={stepKey} onChange={this.onChange}/>
          );
        }}
      </Formik>
    );
  }
}

class ViewContainerForm extends Component {
  stepKey = this.props.stepKey;

  componentDidMount() {
    const {formProps} = this.props;
    formProps.validateForm(formProps.values);
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.stepKey === this.stepKey;
  }

  onSkipStep = (name, value) => {
    const {formProps: {values}, step} = this.props;

    const newValues = _.set(_.cloneDeep(values), name, value);
    this.props.nextStep(step, newValues);
  };

  render() {
    const {
      direction,
      step,
      hasPreviousStep,
      jumpStep,
      jumpFromSummaryStep,
      jumpAndResetAfterStep,
      previousStep,
      submitClaim,
      removeParticipants,
      removeParticipantAndSkip,
      fromSummary,
      saveId,
      submittingClaim,
      jumpFromSummaryRef,
      electronicSignatureEnabled,
      company
    } = this.props;
    const {
      formProps,
      onChange,
      render,
      fieldConfiguration,
      claimType,
      loggedInFromCounter,
      loadingUserData,
      loadingCascoData,
      hasCasco
    } = this.props;
    const {removeDamagedGood, repairServiceType} = this.props;
    const {storeSummaryHTML} = this.props;
    const {submitId} = this.props;
    const {insuranceOwner, userIdentificationType, userDataStatus} = this.props;
    const {userId} = this.props;

    const viewProps = {
      ...formProps,
      fieldConfiguration,
      claimType,
      isValid: Object.keys(formProps.errors).length === 0,
      direction: direction,
      step: step,
      hasPreviousStep: hasPreviousStep,
      onJumpStep: jumpStep,
      onJumpFromSummaryStep: jumpFromSummaryStep,
      onJumpStepAndResetAfter: jumpAndResetAfterStep,
      onPreviousStep: previousStep,
      onNextStep: formProps.submitForm,
      onSkipStep: this.onSkipStep,
      submitClaim: submitClaim,
      onRemoveParticipants: removeParticipants,
      onRemoveParticipantAndSkip: removeParticipantAndSkip,
      fromSummary: fromSummary,
      saveId: saveId,
      storeSummaryHTML,
      submittingClaim: submittingClaim,
      loadingUserData: loadingUserData,
      jumpFromSummaryRef,
      loggedInFromCounter,
      electronicSignatureEnabled: electronicSignatureEnabled,
      company: company,
      removeDamagedGood,
      repairServiceType,
      submitId,
      insuranceOwner,
      userIdentificationType,
      userDataStatus,
      loadingCascoData,
      hasCasco,
      userId,
    };

    return (
      <Form>
        <FormikOnChange onChange={onChange}/>
        <ConditionalContext.Provider value={fieldConfiguration}>
          <NextStepContainer>
            {render(viewProps)}
          </NextStepContainer>
        </ConditionalContext.Provider>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
    direction: state.flow.direction,
    step: state.flow.step,
    stepKey: getStoreKey(state.flow.step),
    formState: _.at(state.flow.forms, getStoreKey(state.flow.step))[0] || {},
    claimType: _.at(state.flow.forms, "claimType.choiceType")[0] || claimTypesConstants.GENERAL,
    repairServiceType: _.at(state.flow.forms, "mapsIntro.repairServiceType")[0] || repairServiceTypeConstants.VEHICLE_DAMAGE_INSPECTION_POINT,
    insuranceOwner: _.at(state.flow.forms, "insuranceOwner.owner")[0]
      || (_.at(state.flow.forms, "medicalCareType.medicalCareTypeDescription")[0] === medicalCareDescriptionTypes.DEATH
        ? booleanTypeConstants.YES : booleanTypeConstants.NO),
    userDataStatus: _.at(state.flow.forms, "userLogin.userData.customerDataStatus")[0] || customerDataStatusTypeConstants.FOUND,
    userIdentificationType: _.at(state.flow.forms, "userIdentificationType.userIdentificationType")[0] || userIdentificationTypeConstants.MANUAL,
    hasPreviousStep: hasPreviousStep(state.flow),
    mail: state.flow.mail,
    fieldConfiguration: getFieldConfiguration(state.flow),
    fromSummary: state.flow.inSummary || false,
    submittingClaim: state.flow.submittingClaim || false,
    loadingUserData: state.flow.loadingUserData || false,
    loadingCascoData: state.flow.loadingCascoData || false,
    hasCasco: state.flow.loadingCascoData || false,
    submitId: _.get(state, "flow.submit.submitId", ""),
    saveId: state.flow.save.id,
    jumpFromSummaryRef: state.flow.jumpFromSummaryRef || "",
    loggedInFromCounter: _.get(state, "flow.counterUser.authenticated") || false,
    electronicSignatureEnabled: state.flow.electronicSignatureEnabled,
    company: state.flow.company,
  });

const mapDispatchToProps = (dispatch) => ({
  jumpStep: (step) => () => dispatch(jumpStep(step)),
  jumpAndResetAfterStep: (step) => () => dispatch(batch(resetAfter(step), jumpStep(step))),
  jumpFromSummaryStep: (step, refName) => () => dispatch(jumpFromSummaryStep({step, refName})),
  previousStep: () => dispatch(previousStep()),
  nextStep: (step, values) => dispatch(batch(updateFormState({step, values}), nextStep())),
  saveClaim: (url) => dispatch(storeClaim(url, true)),
  submitClaim: (url, step, values, shouldWaitBeforeNextStep) => dispatch(submitClaim(url, step, values, shouldWaitBeforeNextStep)),
  storeSummaryHTML: (url, html) => dispatch(storeSummaryHTML(url, html)),
  updateFormState: (url, step, values) => dispatch(batch(setDirty(), updateFormState({step, values}))),
  removeParticipants: () => dispatch(removeParticipants()),
  removeParticipantAndSkip: (step) => dispatch(batch(skipParticipant(step.index), nextStep(), removeParticipants(), reloadStep())),
  removeDamagedGood: (damagedGood) => dispatch(removeDamagedGood(damagedGood)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewContainer);
