import React from "react";

import cx from "classnames";

import Text from "components/common/Text";
import Layout from "components/common/Layout";
import ChoiceButton from "components/form/ChoiceButton";
import CheckBox from "components/form/CheckBox/CheckBox";
import Asdom from "components/icon/Asdom";
import fontsConstants from "constants/font";

import styles from "components/form/CheckBox/CheckBoxTree.module.scss";

export default function CheckBoxTree({treeSelected, children, icon, label, onClick}) {
  const treeClass = cx({
    [styles.tree]: true,
    [styles.selected]: treeSelected,
  });

  const arrowClass = cx({
    [styles.arrow]: true,
    [styles.selected]: treeSelected,
  });

  const childrenClass = cx({
    [styles.children]: true,
    [styles.collapsed]: !treeSelected,
  });

  return (
    <Layout.OneColumn spacing={10} width="100%">
      <ChoiceButton className={treeClass}>
        <div className={styles.label} onClick={onClick}>
          <div className={styles.icon}>{icon}</div>
          <Text element="p" variant="variant27" label={label}/>
          {children
            ? <div className={arrowClass}>
              <Asdom icon={fontsConstants.ASDOM_ICON.EXPAND_ARROW} variant="variant1"/>
            </div>
            : null}
        </div>
        <div className={childrenClass}>
          {children}
        </div>
      </ChoiceButton>
    </Layout.OneColumn>
  );
}

export function CheckBoxTreeOption({childSelected, expanded, children, label, onClick}) {
  const childArrowClass = cx({
    [styles.arrow]: true,
    [styles.selected]: expanded,
  });

  const childrenClass = cx({
    [styles.children]: true,
    [styles.collapsed]: !expanded,
  });

  return (
    <div className={children ? styles.list : styles.option}>
      <div onClick={onClick} className={styles.childLabel}>
        <CheckBox labelProps={{label}}
                  checked={childSelected}
                  onChange={() => null}
                  variant="variant1"/>
        {children
          ? <div className={childArrowClass}>
            <Asdom icon={fontsConstants.ASDOM_ICON.EXPAND_ARROW} variant="variant2"/>
          </div>
          : null}
      </div>
      <div className={childrenClass}>
        {children}
      </div>
    </div>
  );
}