import React, {Component} from "react";

import cx from "classnames";
import styles from "components/icon/FontIcon.module.scss";

export default class FontIcon extends Component {
  render() {
    const {icon, font, variant} = this.props;

    const className = cx({
      [styles.root]: true,
      [styles.tocaPoplavaSteklo]: font === "GeneraliTocaPoplavaSteklo",
      [styles.asDom]: font === "asDom",
      [styles.domOseba]: font === "domOseba",
      [styles.repairService]: font === "repairService",
      [styles.icoMoon]: font === "icoMoon",
      [styles.variant1]: variant === "variant1",
      [styles.variant2]: variant === "variant2",
      [styles.variant3]: variant === "variant3",
    });

    return (
      <div className={className}>
        {icon}
      </div>
    );
  }
}