import React from "react";

import { Formik } from "formik";
import { connect } from "react-redux";
import { batch } from "redux-act";
import * as Yup from "yup";

import { closeSaveDialog, closeLogOut, storeClaim, storeMail, storeCounterUser } from "actions/flow";
import Button from "../components/common/Button";
import Layout from "../components/common/Layout";
import Modal from "../components/common/Modal";
import Text from "../components/common/Text";
import TextField from "../components/form/TextField";
import CookieDisclaimer from "../components/step/CookieDisclaimer";
import LogOutDialogContent from "../containers/LogOutDialog";
import StateRouterContainer from "../containers/StateRouterContainer";
import { ConfigContext } from "../containers/context/ConfigContainer";

function StateAppContainer({ mail, saveVisible, closeSaveDialog, saveMail, fromSummary, logOutVisible, closeLogOut, onLogOut, counterUser }) {
  return (
    <>
      {fromSummary ? (
        <Modal.Content fromSummary={fromSummary} visible={fromSummary}>
          <StateRouterContainer fromSummary={fromSummary} />
        </Modal.Content>
      ) : (
        <StateRouterContainer />
      )}
      <Modal visible={saveVisible} variant="variant1" onClose={closeSaveDialog}>
        <StoreMailDialogContent mail={mail} onSave={saveMail} />
      </Modal>
      <Modal visible={logOutVisible} variant="variant1" onClose={closeLogOut}>
        <LogOutDialogContent onLogOut={onLogOut} counterUser={counterUser} />
      </Modal>
      <CookieDisclaimer />
    </>
  );
}

const validationSchema = Yup.object().shape({
  mail: Yup.string()
    .email("validation.email")
    .required("validation.email"),
});

function StoreMailDialogContent({ onSave, mail }) {
  const config = React.useContext(ConfigContext);

  const onSubmit = (values) => {
    onSave(config.url.saveClaim, values.mail);
  };

  return (
    <Formik initialValues={{ mail }} onSubmit={onSubmit} validationSchema={validationSchema}>
      {(formProps) => (
        <Layout spacing={16} flex={1}>
          <Layout.OneColumn>
            <Text element="div" label="save.mail.content.header" />
          </Layout.OneColumn>

          <Layout.OneColumn>
            <Text element="div" label="save.mail.content.description" />
          </Layout.OneColumn>

          <Layout.OneColumn>
            <TextField name="mail" placeholder="save.mail.placeholder" />
          </Layout.OneColumn>

          <Layout.OneColumn textAlign="right">
            <Button label="save.mail.button" onClick={formProps.submitForm} isDisabled={!formProps.isValid} />
          </Layout.OneColumn>
        </Layout>
      )}
    </Formik>
  );
}

const mapStateToProps = (state) => ({
  mail: state.flow.save.mail || "",
  saveVisible: state.flow.saveDialogVisible || false,
  fromSummary: state.flow.inSummary || false,
  logOutVisible: state.flow.logOutVisible || false,
  counterUser: state.flow.counterUser,
});

const mapDispatchToProps = (dispatch) => ({
  closeSaveDialog: (url) => dispatch(closeSaveDialog()),
  saveMail: (url, mail) => {
    dispatch(batch(storeMail(mail), closeSaveDialog()));
    dispatch(storeClaim(url, false));
  },
  closeLogOut: () => dispatch(closeLogOut()),
  onLogOut: (user) => dispatch(batch(storeCounterUser(user), closeLogOut())),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StateAppContainer);
