import React, {Component} from "react";

import {connect} from "react-redux";

import Layout from "components/common/Layout";
import Link from "components/common/Link";
import Text from "components/common/Text";
import DateField from "components/form/DateField";
import SexField from "components/form/SexField";
import TextField from "components/form/TextField";
import Address from "components/step/Address";
import TaxNumber from "components/step/TaxNumber"
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import {capitalize} from "utility/textTransform";
import {isSloAddress} from "utility/utilityFunctions";
import _ from "lodash";
import RelationshipToggleButton from "../userIdentification/RelationshipToggleButton";

class BeneficiaryPerson extends Component {

  render() {
    const {slovenia, index, onRemove, showRemove, isBeneficiary} = this.props;
    const address = _.get(this.props, `beneficiaryPersons[${index}].address`, {});
    const beneficiaryIndex = isBeneficiary ? index + 2 : index + 1;

    return (
      <Layout spacing={5} width="100%" maxWidth={450} justifyContent="center">
        <Layout.OneColumn spacing={20}/>
        <Layout.OneColumn>
          <Text element="h4" label="beneficiaryPersons.content.title" parameters={[beneficiaryIndex]} variant="variant2"/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <TextField name={`beneficiaryPersons[${index}].name`}
                     transform={capitalize}
                     placeholder="beneficiaryPersons.content.name.placeholder"
                     label="beneficiaryPersons.content.name.label"
                     isRequired={true}
                     maxLength={fieldLengthConstants[flowConstants.personSteps.BENEFICIARY_PERSONS.NAME].name + 1}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <TextField name={`beneficiaryPersons[${index}].surname`}
                     transform={capitalize}
                     placeholder="beneficiaryPersons.content.surname.placeholder"
                     label="beneficiaryPersons.content.surname.label"
                     isRequired={true}
                     maxLength={fieldLengthConstants[flowConstants.personSteps.BENEFICIARY_PERSONS.NAME].surname + 1}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <DateField name={`beneficiaryPersons[${index}].birthDate`}
                     isRequired={true}
                     placeholder="beneficiaryPersons.content.birthDate.placeholder"
                     label="beneficiaryPersons.content.birthDate.label"
                     legalAgeField={true}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <SexField name={`beneficiaryPersons[${index}].sex`} isRequired={true}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <Address name={`beneficiaryPersons[${index}].address`}
                   isRequired={true}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <TextField name={`beneficiaryPersons[${index}].telephoneNumber`}
                     placeholder="beneficiaryPersons.content.telephoneNumber.placeholder"
                     label="beneficiaryPersons.content.telephoneNumber.label"
                     isRequired={true}
                     maxLength={fieldLengthConstants[flowConstants.personSteps.BENEFICIARY_PERSONS.NAME].telephoneNumber + 1}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <Text element="span" variant="variant8" label="beneficiaryPersons.content.telephoneNumber.info"/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <TextField name={`beneficiaryPersons[${index}].email`}
                     placeholder="beneficiaryPersons.content.email.placeholder"
                     label="beneficiaryPersons.content.email.label"
                     maxLength={fieldLengthConstants[flowConstants.personSteps.BENEFICIARY_PERSONS.NAME].email + 1}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <TaxNumber name={`beneficiaryPersons[${index}].taxNumber`}
                     placeholder="beneficiaryPersons.content.taxNumber.placeholder"
                     label="beneficiaryPersons.content.taxNumber.label"
                     isSlovenia={isSloAddress(address, slovenia)}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <RelationshipToggleButton name={`beneficiaryPersons[${index}]`} label="beneficiaryPersons"/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          {showRemove ? <Link label="beneficiaryPersons.button.remove" onClick={onRemove}/> : null}
        </Layout.OneColumn>
        <Layout.OneColumn spacing={40}></Layout.OneColumn>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  isBeneficiary: _.at(state, "flow.forms.userIdentification.userInfo.isBeneficiary")[0] || false
});

export default connect(mapStateToProps)(BeneficiaryPerson);
