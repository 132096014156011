import React, {Component} from "react";

import Layout from "components/common/Layout";
import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import ToggleButton from "components/form/ToggleButton";
import sufferedEventTypes from "constants/sufferedEventTypeTypes"
import Header from "containers/step/Header";
import SplitViewContainer from "containers/view/SplitViewContainer";

export default class SufferedEventTypeStepContainer extends Component {
  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="sufferedEventType.sidebar.title"/>
        <Text element="div" label="sufferedEventType.sidebar.subtitle" isHtml={true}/>
      </SplitViewContainer.Left>
    );
  };

  right(props) {
    return (
      <SplitViewContainer.Right {...props}>
        <ToggleButton name="sufferedEventTypes" multiple={true}>
          <Layout flex={1} spacing={10}>
            <Layout.OneColumn><ToggleButton.Choice label="sufferedEventType.content.button.label.physicalPain"
                                                   value={sufferedEventTypes.PHYSICAL_PAIN}/></Layout.OneColumn>
            <Layout.OneColumn><ToggleButton.Choice label="sufferedEventType.content.button.label.fear"
                                                   value={sufferedEventTypes.FEAR}/></Layout.OneColumn>
            <Layout.OneColumn><ToggleButton.Choice
              label="sufferedEventType.content.button.label.lifeActivitiesMentalPain"
              value={sufferedEventTypes.LIFE_ACTIVITIES_MENTAL_PAIN}/></Layout.OneColumn>
            <Layout.OneColumn><ToggleButton.Choice label="sufferedEventType.content.button.label.distortionMentalPain"
                                                   value={sufferedEventTypes.DISTORTION_MENTAL_PAIN}/></Layout.OneColumn>
            <Layout.OneColumn><ToggleButton.Choice label="sufferedEventType.content.button.label.otherMentalPain"
                                                   value={sufferedEventTypes.OTHER_MENTAL_PAIN}/></Layout.OneColumn>
            <Layout.OneColumn><ToggleButton.Choice label="sufferedEventType.content.button.label.otherWithDocuments"
                                                   value={sufferedEventTypes.OTHER_WITH_DOCUMENTS}/></Layout.OneColumn>
          </Layout>
        </ToggleButton>
      </SplitViewContainer.Right>
    );
  }

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right}/>
    );
  }
}
