import React from "react";

import SplitView from "../../components/view/SplitView";
import ViewContainer from "../../containers/view/ViewContainer";

function SplitViewContainer({ left, right, reverseFlex = false, company, ...props }) {
  const renderChildren = (viewProps) => {
    return (
      <SplitView reverseFlex={reverseFlex} fromSummary={viewProps.fromSummary}>
        {left(viewProps, company)}
        {right(viewProps, company)}
      </SplitView>
    );
  };

  return <ViewContainer {...props} render={renderChildren} />;
}

SplitViewContainer.Left = SplitView.Left;
SplitViewContainer.Right = SplitView.Right;
SplitViewContainer.RightSteps = SplitView.RightSteps;
SplitViewContainer.Step = SplitView.Step;

export default SplitViewContainer;
