import React from "react";

import Layout from "components/common/Layout";
import TextField from "components/form/TextField";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import homeDamagedGoodsInfoTypeConstants from "constants/homeDamagedGoodsInfoTypes";
import HomeDamagedGoodsInfoList from "containers/step/homeDamagedGoodsInfo/HomeDamagedGoodsInfoList";

export default function HomeDamagedGoodsInfoRight(props) {
  const {values: {homeDamagedGoodsInfoType}} = props;

  return (
    <Layout flex={1} spacing={10}>
      <Layout.OneColumn>
        <TextField name={`${homeDamagedGoodsInfoType}Description`}
                   placeholder={`homeDamagedGoodsInfo.content.description.placeholder`}
                   label={`homeDamagedGoodsInfo.content.description.label`}
                   multiLine={true}
                   isRequired={true}
                   maxLength={fieldLengthConstants[flowConstants.homeSteps.HOME_DAMAGED_GOODS_INFO.NAME].description + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        {homeDamagedGoodsInfoType === homeDamagedGoodsInfoTypeConstants.ESTIMATE
          ? <TextField name="damageEstimate"
                       number={true}
                       maxLength={fieldLengthConstants[flowConstants.homeSteps.HOME_DAMAGED_GOODS_INFO.NAME].damageEstimate + 1}
                       placeholder={`homeDamagedGoodsInfo.content.damageEstimate.placeholder`}
                       label={`homeDamagedGoodsInfo.content.damageEstimate.label`}
                       currency={true} />
          : <HomeDamagedGoodsInfoList {...props}/>}
      </Layout.OneColumn>
    </Layout>
  );
}
