import React from "react";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import ToggleButton from "components/form/ToggleButton";
import IcoMoon from "components/icon/IcoMoon";
import accidentOccurrenceTypes from "constants/accidentOccurrenceTypes";
import fontConstants from "constants/font";

export default function AccidentOccurrenceTypeRight(props) {
  const {onNextStep} = props;

  return (
    <Layout flex={1}>
      <Layout.OneColumn>
        <Layout width="100%" justifyContent="center">
          <Layout.OneColumn>
            <NextStepButton component={ToggleButton}
                            onNextStep={onNextStep}
                            isValid={true}
                            condition={value => value}
                            buttonProps={{name: "accidentOccurrenceType"}}>
              <Layout spacing={10} flex={1}>
                <Layout.RowChildren>
                  <ToggleButton.Choice value={accidentOccurrenceTypes.CAR_ACCIDENT}
                                       label="accidentOccurrenceType.content.button.carAccident.label"/>
                  <ToggleButton.Choice value={accidentOccurrenceTypes.FIGHT}
                                       label="accidentOccurrenceType.content.button.fight.label"/>
                </Layout.RowChildren>
                <Layout.RowChildren>
                  <ToggleButton.Choice value={accidentOccurrenceTypes.ACCIDENT_ON_WORK}
                                       label="accidentOccurrenceType.content.button.accidentOnWork.label"/>
                  <ToggleButton.Choice value={accidentOccurrenceTypes.ACCIDENT_PUBLIC}
                                       label="accidentOccurrenceType.content.button.accidentPublic.label"/>
                </Layout.RowChildren>
                <Layout.OneColumn>
                  <ToggleButton.Choice value={accidentOccurrenceTypes.OTHER}
                                       icon={<IcoMoon icon={fontConstants.ICOMOON.THREE_DOTS}/>}
                                       label="accidentOccurrenceType.content.button.other.label"/>
                </Layout.OneColumn>
              </Layout>
            </NextStepButton>
          </Layout.OneColumn>
        </Layout>
      </Layout.OneColumn>
    </Layout>
  );
}
