import React from "react";

import Layout from "components/common/Layout";
import TextField from "components/form/TextField";
import TaxNumber from "components/step/TaxNumber";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";

export default function InsuranceInfoLoginRight() {
  return (
    <Layout maxWidth={450} spacing={5} width="100%" justifyContent="center">
      <Layout.OneColumn>
        <TextField name="policyNumber"
                   placeholder="insuranceInfoLogin.content.policyNumber.placeholder"
                   label="insuranceInfoLogin.content.policyNumber.label"
                   maxLength={fieldLengthConstants[flowConstants.steps.USER_LOGIN.NAME].policyNumber + 1}
                   isRequired={true}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TaxNumber name="taxNumber"
                   placeholder="insuranceInfoLogin.content.taxNumber.placeholder"
                   label="insuranceInfoLogin.content.taxNumber.label"
                   isRequired={true}
                   isSlovenia={true}/>
      </Layout.OneColumn>
    </Layout>
  )
}