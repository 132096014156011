import {find, isArray, isUndefined, some,} from "underscore";

import _ from "lodash";
import isUndefinedOrNull from "utility/utilityFunctions";
import accidentDateTypeConstants from "constants/accidentDateTypes";
import accidentOccurrenceTypes from "constants/accidentOccurrenceTypes";
import booleanTypesConstants from "constants/booleanTypes"
import carDamageTypeConstants from "constants/carDamageTypes";
import causeOfDeathTypes from "constants/causeOfDeathTypes";
import claimTypesConstants from "constants/claimTypes";
import flowConstants from "constants/flow";
import homeDamageTypes from "constants/homeDamageTypes";
import medicalCareDescriptionTypes from "constants/medicalCareDescriptionTypes";
import personResponsibleTypesMapping from "constants/personResponsibleTypesMapping";
import responsiblePersonTypeConstants from "constants/responsiblePerson";
import vehicleInfoInputTypesConstants from "constants/vehicleInfoInputTypes";
import vehicleTypesConstants from "constants/vehicleTypes";
import theftTypeConstants from "constants/theftTypes";
import userIdentificationTypeConstants from "constants/userIdentificationType";
import wasDriverTypesConstants from "constants/wasDriverTypes";
import {evaluateRule, getFilterData} from "selectors/flow";
import filterParticipantsInfo from "selectors/participantsInfo";

export function getSteps(flowState) {
  const result = [];
  const steps = flowConstants.steps;

  result.push({name: steps.INTRO.NAME}); // step 1
  result.push({name: steps.CLAIM_TYPE.NAME}); // step 2

  const claimTypeForm = flowState.forms[steps.CLAIM_TYPE.NAME] || {};
  if (claimTypeForm.choiceType === claimTypesConstants.CAR) {
    result.push(...getCarSteps(flowState));
  } else if (claimTypeForm.choiceType === claimTypesConstants.HOME) {
    result.push(...getHomeSteps(flowState));
  } else if (claimTypeForm.choiceType === claimTypesConstants.PERSON) {
    result.push(...getPersonSteps(flowState));
  } else if (claimTypeForm.choiceType === claimTypesConstants.GENERAL) {
    result.push(...getGeneralSteps(flowState));
  }

  return filterWithConfiguration(result, getFilterData(flowState.forms), flowState.configuration.data);
}

function getCarSteps(flowState) {
  const result = [];
  const steps = flowConstants.steps;

  result.push({name: steps.ON_SITE.NAME}); // step 3

  const onSiteForm = flowState.forms[steps.ON_SITE.NAME] || {};
  if (onSiteForm.isOnSite) {
    result.push({name: steps.HELP_INFORMATION.NAME}); // step 4
  }

  result.push({name: steps.CAR_DAMAGE_TYPE.NAME}); // step 5

  const carDamageForm = flowState.forms[steps.CAR_DAMAGE_TYPE.NAME] || {};
  if (carDamageForm.carDamageType === carDamageTypeConstants.MECHANICAL_DAMAGE) {
    result.push({name: steps.MECHANICAL_DAMAGE.NAME}); // step 5.1
  }

  const mechanicalDamageForm = flowState.forms[steps.MECHANICAL_DAMAGE.NAME] || {};
  if (carDamageForm.carDamageType === carDamageTypeConstants.MECHANICAL_DAMAGE && mechanicalDamageForm.isOwnVehicle === booleanTypesConstants.YES) {
    result.push({name: steps.MECHANICAL_DAMAGE_OWN.NAME}); // step 5.2
  } else {
    result.push({name: steps.THEFT_TYPE.NAME}); // step 5.3
    result.push({name: steps.ACCIDENT_DATE.NAME}); // step 6
    result.push({name: steps.ACCIDENT_TIME.NAME}); // step 7
    result.push({name: steps.ACCIDENT_LOCATION.NAME}); // step 8
    result.push({name: steps.VEHICLE_TYPE.NAME}); //step 9

    if (!isTheftVehicle(flowState)) {
      const vehicleTypeForm = flowState.forms[steps.VEHICLE_TYPE.NAME] || {};
      const isWorkVehicleType = vehicleTypeForm.vehicleType === vehicleTypesConstants.WORK_OTHER;

      if (isWorkVehicleType) {
        result.push({name: steps.VEHICLE_DAMAGE_CURRENT_DESCRIPTION.NAME}); // 9d
      } else {
        result.push({name: steps.VEHICLE_DAMAGE_CURRENT.NAME}); // step 9a
      }

      result.push({name: steps.PREVIOUS_DAMAGE.NAME}); // step 9b

      const previousDamageForm = flowState.forms[steps.PREVIOUS_DAMAGE.NAME] || {};
      const hasPreviousDamage = previousDamageForm.hasPreviousDamage === booleanTypesConstants.YES;

      if (hasPreviousDamage && isWorkVehicleType) {
        result.push({name: steps.VEHICLE_DAMAGE_PREVIOUS_DESCRIPTION.NAME}); // step 9d
      } else if (hasPreviousDamage) {
        result.push({name: steps.VEHICLE_DAMAGE_PREVIOUS.NAME}); // step 9c
      }

      result.push({name: steps.ABLE_TO_DRIVE.NAME}); // step 10
    }

    result.push({name: steps.PARKED.NAME}); // step 11

    result.push({
      name: steps.PARTICIPANTS_NUMBER.NAME,
      claimType: claimTypesConstants.CAR
    });

    result.push({name: steps.DRAWING_INTRO.NAME}); // step 13

    const drawingIntroForm = flowState.forms[steps.DRAWING_INTRO.NAME] || {};
    if (drawingIntroForm.wantDrawing === booleanTypesConstants.YES) {
      result.push({name: steps.DRAWING.NAME}); // step 14
    }
    result.push({name: steps.EVENT_DESCRIPTION.NAME}); // step 15

    const participantsNumberForm = flowState.forms[steps.PARTICIPANTS_NUMBER.NAME] || {};

    const accidentDateForm = flowState.forms[steps.ACCIDENT_DATE.NAME] || {};
    const accidentDate = getAccidentDate(accidentDateForm);
    if (!isUndefined(participantsNumberForm.finalParticipants)) {
      participantsNumberForm.finalParticipants.forEach(participant => {
        // step 16-18
        result.push({
          name: steps.PARTICIPANTS_INFO.NAME,
          index: participant.index,
          accidentDate,
          participant,
          carDamageType: carDamageForm.carDamageType,
          skip: isUndefined(participant.skip) ? false : participant.skip,
        });
      });
    }

    const participantsInfoForm = flowState.forms[steps.PARTICIPANTS_INFO.NAME];
    if (!isUndefinedOrNull(participantsInfoForm)) {
      const filtered = filterParticipantsInfo(participantsNumberForm.finalParticipants, participantsInfoForm);
      const ownVehicle = find(filtered, participantInfo => participantInfo && participantInfo.info.isOwnVehicle);
      const otherParticipants = filtered.filter(participantInfo => participantInfo && !participantInfo.info.isOwnVehicle);

      result.push({
        name: steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME,
        claimType: claimTypesConstants.CAR,
        participant: ownVehicle
      }); // step 19

      const ownVehicleInfoWasDriverForm = flowState.forms[steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME] || {};
      if (ownVehicleInfoWasDriverForm.wasDriver !== wasDriverTypesConstants.NO_DRIVER) {
        result.push({name: steps.OWN_VEHICLE_INFO_DRIVER_HURT.NAME, participant: ownVehicle}); // step 20
      }

      result.push({name: steps.OWN_VEHICLE_INFO_REPORTED_POLICE.NAME, participant: ownVehicle}); // step 21
      result.push({name: steps.OWN_VEHICLE_INFO_PASSENGERS.NAME, participant: ownVehicle}); // step 22

      otherParticipants.forEach((participant) => {
        if (participant.info.isVehicle) {
          result.push({
            name: steps.OTHER_PARTICIPANTS_INFO.NAME,
            index: participant.info.index,
            participant: participant,
            isOwnVehicle: mechanicalDamageForm.isOwnVehicle,
            skip: isUndefined(participant.info.skip) ? false : participant.info.skip
          }); // step 24
        }
      });
    }

    result.push({name: steps.RESPONSIBLE_PERSON.NAME}); // step 28

    if (!isTheftVehicle(flowState)) {
      result.push({name: steps.MAPS_INTRO.NAME}); //step 29

      const mapsIntroForm = flowState.forms[steps.MAPS_INTRO.NAME] || {};

      result.push({
        name: steps.VEHICLE_DAMAGE_INSPECTION_POINT.NAME,
        repairServiceType: mapsIntroForm.repairServiceType,
      }); // step 30
    }

    result.push({name: steps.INSURANCE_OWNER.NAME})

    const insuranceOwnerForm = flowState.forms[steps.INSURANCE_OWNER.NAME] || {};
    if (insuranceOwnerForm.owner === booleanTypesConstants.YES && !flowState.mobileUserId) {
      result.push({name: steps.USER_IDENTIFICATION_TYPE.NAME});

      const userIdentificationTypeForm = flowState.forms[steps.USER_IDENTIFICATION_TYPE.NAME] || {};
      if (userIdentificationTypeForm.userIdentificationType === userIdentificationTypeConstants.INSURANCE_INFO) {
        result.push({name: steps.USER_LOGIN.NAME});
      }
    } else if (insuranceOwnerForm.owner === booleanTypesConstants.NO) {
      result.push({name: steps.APPLICANT_DATA.NAME});
    }

    result.push({name: steps.USER_IDENTIFICATION.NAME}); // step 31

    if (shouldReportWithOtherCompany(flowState)) {
      result.push({name: steps.REPORT_WITH_OTHER_COMPANY.NAME}); // step 32
    } else {

      showHasCasco(result, flowState);

      result.push({name: steps.BANK_ACCOUNTS.NAME}); // step 32

      if (!isTheftVehicle(flowState)) {
        // result.push({name: steps.PHOTOS.NAME}); // step 33
      }

      result.push({name: steps.DOCUMENTS.NAME}); // step 34
      result.push({name: steps.SUMMARY.NAME}); // step 35
      result.push({name: steps.PHONE_NUMBER.NAME}); // step 36
      result.push({name: steps.THANK_YOU.NAME}); // step 37
    }

  }

  return result;
}

function getHomeSteps(flowState) {
  const result = [];
  const steps = flowConstants.steps;
  const homeSteps = flowConstants.homeSteps;

  result.push({name: homeSteps.HOME_DAMAGE_TYPE.NAME}); // step 3
  result.push({name: homeSteps.HOME_DAMAGED_GOODS.NAME}); // step 4
  result.push({name: homeSteps.HOME_BURGLARY_WHO.NAME}); // step
  result.push({name: homeSteps.HOME_BURGLARY_PROPERTY_TYPE.NAME}); // step
  result.push({name: homeSteps.HOME_BURGLARY_VIOLENT.NAME}); // step
  result.push({name: homeSteps.HOME_DAMAGED_GOODS_INFO.NAME}); // step 5
  result.push({name: steps.ACCIDENT_DATE.NAME}); // step 6
  result.push({name: steps.ACCIDENT_TIME.NAME}); // step 7
  result.push({name: steps.ACCIDENT_LOCATION.NAME}); // step 8

  const accidentDateForm = flowState.forms[steps.ACCIDENT_DATE.NAME] || {};
  const accidentDate = getAccidentDate(accidentDateForm);
  result.push({name: homeSteps.HOME_VEHICLE_INFO.NAME, accidentDate}); // step 10

  const vehicleInfoForm = flowState.forms[homeSteps.HOME_VEHICLE_INFO.NAME] || {};
  const homeDamageTypeForm = flowState.forms[homeSteps.HOME_DAMAGE_TYPE.NAME] || {};
  const vehicleInfo = {
    info: {
      ...vehicleInfoForm.info,
    },
    participantInfo: {
      ...vehicleInfoForm.vehicleInfo,
    },
  };
  const homeDamageType = homeDamageTypeForm.finalSelected ? homeDamageTypeForm.finalSelected[0] : homeDamageTypes.BURGLARY;
  result.push({name: homeSteps.HOME_REPORTED_POLICE.NAME, vehicleInfo, homeDamageType}); // step 11
  result.push({name: homeSteps.HOME_CALCULATION_TYPE.NAME}); // step 12
  result.push({name: homeSteps.HOME_SOMEONE_ELSE_RESPONSIBLE.NAME});
  result.push({name: homeSteps.HOME_RESPONSIBLE_PERSON.NAME}); // step 12
  result.push({name: steps.INSURANCE_OWNER.NAME})

  const insuranceOwnerForm = flowState.forms[steps.INSURANCE_OWNER.NAME] || {};
  if (insuranceOwnerForm.owner === booleanTypesConstants.YES && !flowState.mobileUserId) {
    result.push({name: steps.USER_IDENTIFICATION_TYPE.NAME});

    const userIdentificationTypeForm = flowState.forms[steps.USER_IDENTIFICATION_TYPE.NAME] || {};
    if (userIdentificationTypeForm.userIdentificationType === userIdentificationTypeConstants.INSURANCE_INFO) {
      result.push({name: steps.USER_LOGIN.NAME});
    }
  } else if (insuranceOwnerForm.owner === booleanTypesConstants.NO) {
    result.push({name: steps.APPLICANT_DATA.NAME});
  }

  result.push({name: steps.USER_IDENTIFICATION.NAME}); // step 13
  result.push({name: steps.BANK_ACCOUNTS.NAME}); // step 14
  // result.push({name: homeSteps.HOME_PHOTOS.NAME}); // step 15
  result.push({name: homeSteps.HOME_DOCUMENTS.NAME}); // step 16
  result.push({name: steps.SUMMARY.NAME}); // step 17
  result.push({name: steps.PHONE_NUMBER.NAME}); // step 18
  result.push({name: steps.THANK_YOU.NAME}); // step 19

  return result;
}

function getPersonSteps(flowState) {
  const result = [];
  const steps = flowConstants.steps;
  const personSteps = flowConstants.personSteps;

  const medicalCareTypeForm = flowState.forms[personSteps.MEDICAL_CARE_TYPE.NAME] || {};
  const causeOfDeathForm = flowState.forms[personSteps.CAUSE_OF_DEATH.NAME] || {};
  const accidentOccurrenceTypeForm = flowState.forms[personSteps.ACCIDENT_OCCURRENCE_TYPE.NAME] || {};
  const personResponsibleForm = flowState.forms[personSteps.PERSON_RESPONSIBLE.NAME] || {};
  const insuranceOwnerForm = flowState.forms[steps.INSURANCE_OWNER.NAME] || {};
  const damagesClaimFiledForm = flowState.forms[personSteps.DAMAGES_CLAIM_FILED.NAME] || {};
  const claimCompensationPersonForm = flowState.forms[personSteps.CLAIM_COMPENSATION_PERSON.NAME] || {};
  const participantsNumberForm = flowState.forms[steps.PARTICIPANTS_NUMBER.NAME] || {};
  const accidentDateForm = flowState.forms[steps.ACCIDENT_DATE.NAME] || {};
  const personParticipantsInfoForm = flowState.forms[personSteps.PERSON_PARTICIPANTS_INFO.NAME];
  const ownVehicleInfoWasDriverForm = flowState.forms[steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME] || {};
  const userIdentificationForm = flowState.forms[steps.USER_IDENTIFICATION.NAME] || {};
  const claimCompensationVehicleForm = flowState.forms[personSteps.CLAIM_COMPENSATION_VEHICLE.NAME] || {};
  const accidentInvolvedForm = flowState.forms[personSteps.ACCIDENT_INVOLVED.NAME] || {};

  result.push({name: personSteps.MEDICAL_CARE_TYPE.NAME});
  result.push({name: personSteps.CONDOLENCES.NAME});
  result.push({name: personSteps.SUFFERED_MEDICAL_COSTS.NAME});
  result.push({name: personSteps.MEDICAL_OPERATION_NEEDED.NAME});
  result.push({name: personSteps.MEDICAL_PROBLEM_DESCRIPTION.NAME});
  result.push({name: personSteps.PAST_MEDICAL_CONDITIONS.NAME});
  result.push({
    name: steps.ACCIDENT_DATE.NAME,
    medicalCareType: medicalCareTypeForm.medicalCareType,
    medicalCareTypeDescription: medicalCareTypeForm.medicalCareTypeDescription
  });
  result.push({
    name: steps.ACCIDENT_TIME.NAME,
    medicalCareTypeDescription: medicalCareTypeForm.medicalCareTypeDescription
  });
  result.push({name: personSteps.SICKNESS_DESCRIPTION.NAME});
  result.push({name: steps.ACCIDENT_LOCATION.NAME});
  result.push({name: personSteps.PLACE_OF_DEATH.NAME});
  result.push({name: personSteps.PERSON_DAMAGE.NAME});
  result.push({name: steps.EVENT_DESCRIPTION.NAME});
  result.push({name: personSteps.ACCIDENT_OCCURRENCE_TYPE.NAME});
  result.push({name: personSteps.CAUSE_OF_DEATH.NAME});

  if (medicalCareTypeForm.medicalCareTypeDescription === medicalCareDescriptionTypes.INJURY
    || causeOfDeathForm.causeOfDeath !== causeOfDeathTypes.SICK) {
    result.push({name: personSteps.PERSON_REPORTED_POLICE.NAME});
  }

  if (medicalCareTypeForm.medicalCareTypeDescription !== medicalCareDescriptionTypes.DEATH
    || causeOfDeathForm.causeOfDeath !== causeOfDeathTypes.SICK) {
    result.push({name: personSteps.WITNESSES_PRESENT.NAME});
  }

  result.push({name: personSteps.HAD_OPERATION.NAME});

  result.push({name: personSteps.INJURED_PERSON_WORKING_STATUS.NAME});
  result.push({name: personSteps.PERSON_INSURANCE.NAME});
  result.push({
    name: personSteps.PERSON_RESPONSIBLE.NAME,
    personResponsibleType: personResponsibleTypesMapping[medicalCareTypeForm.medicalCareTypeDescription]
  });

  const personResponsibleCondition = personResponsibleForm.isOtherPersonFault === booleanTypesConstants.NO
    || personResponsibleForm.isOtherPersonFault === booleanTypesConstants.DONT_KNOW
    || personResponsibleForm.isOtherPersonFault === booleanTypesConstants.UNKNOWN;

  if (personResponsibleCondition && (causeOfDeathForm.causeOfDeath === causeOfDeathTypes.CAR_ACCIDENT
    || accidentOccurrenceTypeForm.accidentOccurrenceType === accidentOccurrenceTypes.CAR_ACCIDENT)) {
    result.push({name: personSteps.CLAIM_COMPENSATION_VEHICLE.NAME});
  }

  if (personResponsibleForm.isOtherPersonFault === booleanTypesConstants.YES) {
    result.push({name: personSteps.CLAIM_COMPENSATION_PERSON.NAME});

    if (claimCompensationPersonForm.claimCompensationPerson === booleanTypesConstants.YES) {
      result.push({
        name: personSteps.RESPONSIBLE_PERSON_TYPE.NAME,
        medicalCareTypeDescription: medicalCareTypeForm.medicalCareTypeDescription
      });
      result.push({
        name: personSteps.RESPONSIBLE_PERSON_RELATION.NAME,
        medicalCareTypeDescription: medicalCareTypeForm.medicalCareTypeDescription
      });
    }
  }

  const accidentOccurrenceTypeCondition = accidentOccurrenceTypeForm.accidentOccurrenceType === accidentOccurrenceTypes.OTHER
    || accidentOccurrenceTypeForm.accidentOccurrenceType === accidentOccurrenceTypes.ACCIDENT_PUBLIC
    || accidentOccurrenceTypeForm.accidentOccurrenceType === accidentOccurrenceTypes.FIGHT;
  const claimCompensationCondition = claimCompensationPersonForm.claimCompensationPerson === booleanTypesConstants.NO
    || claimCompensationPersonForm.claimCompensationPerson === booleanTypesConstants.UNKNOWN;

  if ((medicalCareTypeForm.medicalCareTypeDescription === medicalCareDescriptionTypes.INJURY
      && !(accidentOccurrenceTypeCondition && personResponsibleCondition || claimCompensationCondition))
    || (medicalCareTypeForm.medicalCareTypeDescription === medicalCareDescriptionTypes.SICK
      && claimCompensationPersonForm.claimCompensationPerson === booleanTypesConstants.YES)
    || claimCompensationVehicleForm.claimCompensationVehicle === booleanTypesConstants.YES) {
    result.push({name: personSteps.SUFFERED_EVENT_TYPE.NAME});
    result.push({name: personSteps.DAMAGE_DESCRIPTION.NAME});
  }

  const damagesClaimFiledCondition = damagesClaimFiledForm.isDamagesClaimFiled === booleanTypesConstants.NO
    || damagesClaimFiledForm.isDamagesClaimFiled === booleanTypesConstants.UNKNOWN;

  const accidentDate = getAccidentDate(accidentDateForm);

  if (accidentOccurrenceTypeForm.accidentOccurrenceType === accidentOccurrenceTypes.CAR_ACCIDENT
    || causeOfDeathForm.causeOfDeath === causeOfDeathTypes.CAR_ACCIDENT) {
    result.push({name: personSteps.DAMAGES_CLAIM_FILED.NAME});

    result.push({
      name: personSteps.ACCIDENT_INVOLVED.NAME,
      medicalCareTypeDescription: medicalCareTypeForm.medicalCareTypeDescription
    });

    const isVehicleCondition = accidentInvolvedForm.involvementType !== vehicleTypesConstants.BIKE && accidentInvolvedForm.involvementType !== vehicleTypesConstants.PEDESTRIAN;

    result.push({
      name: personSteps.PERSON_PARTICIPANTS_INFO.NAME,
      index: 0,
      accidentDate,
      participant: {
        type: 'car',
        index: 0,
        name: 'A',
        color: '#d36845',
        isOwnVehicle: true,
        isVehicle: true
      },
      carDamageType: carDamageTypeConstants.CAR_ACCIDENT,
      skip: !isVehicleCondition
    });

    if (damagesClaimFiledCondition) {
      result.push({
        name: steps.PARTICIPANTS_NUMBER.NAME,
        claimType: claimTypesConstants.PERSON
      });

      if (!isUndefined(participantsNumberForm.finalParticipants)) {
        participantsNumberForm.finalParticipants.forEach(participant => {
          if (participant.index !== 0) {
            result.push({
              name: personSteps.PERSON_PARTICIPANTS_INFO.NAME,
              index: participant.index,
              accidentDate,
              participant,
              carDamageType: carDamageTypeConstants.CAR_ACCIDENT,
              skip: isUndefined(participant.skip) ? false : participant.skip,
            });
          }
        });
      }

      if (!isUndefinedOrNull(personParticipantsInfoForm)) {

        if (damagesClaimFiledCondition && participantsNumberForm.finalParticipants) {

          const filtered = filterParticipantsInfo(participantsNumberForm.finalParticipants, personParticipantsInfoForm);
          const ownVehicle = find(filtered, participantInfo => participantInfo && participantInfo.info.isOwnVehicle);
          const otherParticipants = filtered.filter(participantInfo => participantInfo && !participantInfo.info.isOwnVehicle);

          const involvementType = _.at(flowState.forms, 'accidentInvolved.involvementType')[0];

          if (involvementType !== vehicleTypesConstants.PEDESTRIAN) {
            result.push({
              name: steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME,
              claimType: claimTypesConstants.PERSON,
              medicalCareTypeDescription: medicalCareTypeForm.medicalCareTypeDescription,
              participant: ownVehicle
            });
          }

          if (!isUndefinedOrNull(participantsNumberForm.multipleParticipants)
            && participantsNumberForm.multipleParticipants !== booleanTypesConstants.NO
            && participantsNumberForm.multipleParticipants !== booleanTypesConstants.DONT_KNOW) {
            otherParticipants.forEach((participant) => {
              if (participant.info.isVehicle) {
                result.push({
                  name: steps.OTHER_PARTICIPANTS_INFO.NAME,
                  index: participant.info.index,
                  participant: participant,
                  isOwnVehicle: true,
                  skip: isUndefined(participant.info.skip) ? false : participant.info.skip
                }); // step 24
              }
            });
          }

          if (personParticipantsInfoForm.length > 1 && participantsNumberForm.multipleParticipants === booleanTypesConstants.YES) {
            result.push({name: steps.RESPONSIBLE_PERSON.NAME});
          }
        }
      }
    }
  }

  const isVehicleCondition = accidentInvolvedForm.involvementType !== vehicleTypesConstants.BIKE && accidentInvolvedForm.involvementType !== vehicleTypesConstants.PEDESTRIAN

  if (ownVehicleInfoWasDriverForm.wasDriver !== wasDriverTypesConstants.YES && (
      medicalCareTypeForm.medicalCareTypeDescription === medicalCareDescriptionTypes.DEATH
      && causeOfDeathForm.causeOfDeath === causeOfDeathTypes.CAR_ACCIDENT
      && damagesClaimFiledCondition
      || medicalCareTypeForm.medicalCareTypeDescription === medicalCareDescriptionTypes.INJURY
      && accidentOccurrenceTypeForm.accidentOccurrenceType === accidentOccurrenceTypes.CAR_ACCIDENT)
    && (accidentInvolvedForm.involvementType && isVehicleCondition)) {
    result.push({
      name: personSteps.ACCIDENT_VEHICLE_INFO.NAME,
      medicalCareTypeDescription: medicalCareTypeForm.medicalCareTypeDescription
    });
  }

  result.push({name: personSteps.REFERRAL_NUMBERS.NAME});

  result.push({
    name: personSteps.PERSON_DOCUMENTS.NAME,
    medicalCareType: medicalCareTypeForm.medicalCareType,
    medicalCareTypeDescription: medicalCareTypeForm.medicalCareTypeDescription
  });

  result.push({name: personSteps.LIFE_INSURANCE_CLAIM.NAME});

  if (medicalCareTypeForm.medicalCareTypeDescription !== medicalCareDescriptionTypes.DEATH) {
    result.push({name: steps.INSURANCE_OWNER.NAME});
  } else {
    result.push({name: personSteps.DECEASED_PERSON_INFORMATION.NAME})
  }

  if (insuranceOwnerForm.owner === booleanTypesConstants.YES || medicalCareTypeForm.medicalCareTypeDescription === medicalCareDescriptionTypes.DEATH) {
    result.push({name: steps.USER_IDENTIFICATION_TYPE.NAME});

    const userIdentificationTypeForm = flowState.forms[steps.USER_IDENTIFICATION_TYPE.NAME] || {};

    if (userIdentificationTypeForm.userIdentificationType === userIdentificationTypeConstants.INSURANCE_INFO) {
      result.push({name: steps.USER_LOGIN.NAME});
    }

  } else if (insuranceOwnerForm.owner === booleanTypesConstants.NO) {
    result.push({name: steps.APPLICANT_DATA.NAME});
  }

  result.push({
    name: steps.USER_IDENTIFICATION.NAME,
    claimType: claimTypesConstants.PERSON,
  });

  if (userIdentificationForm.hasBeneficiaryPersons === booleanTypesConstants.YES) {
    result.push({name: personSteps.BENEFICIARY_PERSONS.NAME});
  }

  result.push({name: steps.BANK_ACCOUNTS.NAME});
  result.push({name: steps.SUMMARY.NAME});
  result.push({name: steps.PHONE_NUMBER.NAME});
  result.push({name: steps.THANK_YOU.NAME});

  return result;
}

function getGeneralSteps(flowState) {
  const result = [];
  const steps = flowConstants.steps;
  const generalSteps = flowConstants.generalSteps;

  result.push({name: steps.HELP_INFORMATION.NAME}); // step 3
  result.push({name: steps.ACCIDENT_DATE.NAME}); // step 4
  result.push({name: steps.ACCIDENT_TIME.NAME}); // step 5
  result.push({name: steps.ACCIDENT_LOCATION.NAME}); // step 6
  result.push({name: steps.EVENT_DESCRIPTION.NAME}); // step 7
  result.push({name: steps.INSURANCE_OWNER.NAME})

  const insuranceOwnerForm = flowState.forms[steps.INSURANCE_OWNER.NAME] || {};
  if (insuranceOwnerForm.owner === booleanTypesConstants.YES && !flowState.mobileUserId) {
    result.push({name: steps.USER_IDENTIFICATION_TYPE.NAME});

    const userIdentificationTypeForm = flowState.forms[steps.USER_IDENTIFICATION_TYPE.NAME] || {};
    if (userIdentificationTypeForm.userIdentificationType === userIdentificationTypeConstants.INSURANCE_INFO) {
      result.push({name: steps.USER_LOGIN.NAME});
    }
  } else if (insuranceOwnerForm.owner === booleanTypesConstants.NO) {
    result.push({name: steps.APPLICANT_DATA.NAME});
  }

  result.push({name: steps.USER_IDENTIFICATION.NAME}); // step 8
  result.push({name: steps.BANK_ACCOUNTS.NAME}); // step 9
  // result.push({name: steps.PHOTOS.NAME}); // step 10
  result.push({name: generalSteps.GENERAL_DOCUMENTS.NAME}); // step 11
  result.push({name: steps.SUMMARY.NAME}); // step 12
  result.push({name: steps.PHONE_NUMBER.NAME}); // step 13
  result.push({name: steps.THANK_YOU.NAME}); // step 14

  return result;
}

function isStepRelevant(configuration, claimType) {
  return isUndefined(claimType) || configuration === null || configuration.claimType === claimType || configuration.claimType === null;
}

function filterWithConfiguration(steps, filterData, configurations) {
  return steps.map(step => calculateStepWithConfiguration(step, filterData, configurations));
}

export function calculateStepWithConfiguration(step, filterData, configurations) {
  const configuration = find(configurations, (value) => value.code === step.name);
  const claimType = filterData.claimType;

  let configurationSkip = false;

  if (claimType === claimTypesConstants.CAR) {
    configurationSkip = filterData.isBikeOwnVehicle && configuration.bicycle === false;
  }

  if (!configurationSkip) {
    const stepRelevant = isStepRelevant(configuration, claimType);
    configurationSkip = !(stepRelevant && (configuration.alwaysShow || some(configuration.rules, (rule) => evaluateRule(rule, filterData))));
  }

  const stepSkip = isUndefined(step.skip) ? false : step.skip;

  return {
    ...step,
    skip: configurationSkip || stepSkip,
  }
}

function isTheftVehicle(flowState) {
  const steps = flowConstants.steps;
  const carDamageForm = flowState.forms[steps.CAR_DAMAGE_TYPE.NAME] || {};
  const theftTypeForm = flowState.forms[steps.THEFT_TYPE.NAME] || {};

  return carDamageForm.carDamageType === carDamageTypeConstants.THEFT && theftTypeForm.theftType === theftTypeConstants.VEHICLE
}

export function getAccidentDate({accidentDateType, accidentDate, dateToday, accidentDateFrom}) {
  switch (accidentDateType) {
    case accidentDateTypeConstants.TODAY:
      return dateToday;
    case accidentDateTypeConstants.YESTERDAY:
      const date = new Date(dateToday);
      date.setDate(date.getDate() - 1);
      return date;
    case accidentDateTypeConstants.CALENDAR:
      return accidentDate;
    case accidentDateTypeConstants.RANGE:
      return accidentDateFrom;
  }
}

function showHasCasco(result, flowState) {

  const steps = flowConstants.steps;

  const carDamageTypeForm = flowState.forms[steps.CAR_DAMAGE_TYPE.NAME] || {};
  const isCascoRelevantDamageType = carDamageTypeForm.carDamageType === carDamageTypeConstants.CAR_ACCIDENT || carDamageTypeForm.carDamageType === carDamageTypeConstants.DAMAGE_PARKING_LOT;

  if (!isCascoRelevantDamageType) {
    return;
  }

  const userIdentificationForm = flowState.forms[steps.USER_IDENTIFICATION.NAME] || {};

  if (!userIdentificationForm.hasCasco) {
    return;
  }

  const responsiblePersonForm = flowState.forms[steps.RESPONSIBLE_PERSON.NAME] || {};
  const participantsNumberForm = flowState.forms[steps.PARTICIPANTS_NUMBER.NAME] || {};

  const responsiblePersonField = responsiblePersonForm.responsiblePerson;
  const responsiblePersonFieldIsArray = isArray(responsiblePersonField);
  const responsiblePersonKnown = participantsNumberForm.finalMultipleParticipants === booleanTypesConstants.NO
    || (responsiblePersonField !== responsiblePersonTypeConstants.CANT_AGREE
      && responsiblePersonField !== responsiblePersonTypeConstants.DONT_KNOW
      && (responsiblePersonFieldIsArray && responsiblePersonField.indexOf(0) !== -1))
    || (participantsNumberForm.finalMultipleParticipants === booleanTypesConstants.YES && responsiblePersonFieldIsArray && responsiblePersonField.length === 1);

  if (responsiblePersonKnown || participantsNumberForm.finalMultipleParticipants === booleanTypesConstants.YES) {
    const otherCompany = !isResponsibleCarInsuredInGenerali(flowState);
    result.push({name: steps.HAS_CASCO.NAME, otherCompany: otherCompany});
  }

}

function shouldReportWithOtherCompany(flowState) {

  const steps = flowConstants.steps;

  const userIdentificationForm = flowState.forms[steps.USER_IDENTIFICATION.NAME] || {};

  if (userIdentificationForm.hasCasco) {
    return false;
  }

  return !isResponsibleCarInsuredInGenerali(flowState);
}

function isResponsibleCarInsuredInGenerali(flowState) {

  const steps = flowConstants.steps;
  const responsiblePersonForm = flowState.forms[steps.RESPONSIBLE_PERSON.NAME] || {};
  const participantsInfoForm = flowState.forms[steps.PARTICIPANTS_INFO.NAME] || [];

  if (responsiblePersonForm.responsiblePerson === responsiblePersonTypeConstants.CANT_AGREE
    || responsiblePersonForm.responsiblePerson === responsiblePersonTypeConstants.DONT_KNOW
    || (isArray(responsiblePersonForm.responsiblePerson) && responsiblePersonForm.responsiblePerson.length === 0)) {
    return true;
  }

  if (isArray(responsiblePersonForm.responsiblePerson)) {
    for (let i = 0; i < responsiblePersonForm.responsiblePerson.length; i++) {

      const responsiblePersonIndex = responsiblePersonForm.responsiblePerson[i];
      const person = participantsInfoForm[responsiblePersonIndex];

      if (hasGeneraliInsurancePolicy(person)) {
        return true;
      }

    }
  } else {
    const person = participantsInfoForm[0];
    if (hasGeneraliInsurancePolicy(person)) {
      return true;
    }
  }

  return false;
}

export function hasGeneraliInsurancePolicy(person) {
  if (isUndefined(person) || isUndefinedOrNull(person.participantInfo) || isUndefinedOrNull(person.participantInfo.vehicleData) || person.participantInfo.inputType !== vehicleInfoInputTypesConstants.REGISTERED_IN_SLOVENIA || person.participantInfo.vehicleData.generaliPolicy) {
    return true;
  }
}
