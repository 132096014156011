import React, {Component} from "react";

import * as Yup from "yup";

import Layout from "components/common/Layout";
import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import TextField from "components/form/TextField";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import Header from "containers/step/Header";
import SplitViewContainer from "containers/view/SplitViewContainer";
import {getMax} from "selectors/yup";

const validationSchema = Yup.object().shape({
  description: getMax(Yup.string().trim(), fieldLengthConstants[flowConstants.personSteps.INJURED_PERSON_WORKING_STATUS.NAME].description),
});

export default class InjuredPersonWorkingStatusStepContainer extends Component {

  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="injuredPersonWorkingStatus.sidebar.title"/>
        <Text element="div" label="injuredPersonWorkingStatus.sidebar.subtitle"/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <Layout flex={1} spacing={10}>
          <Layout.OneColumn>
            <TextField name="description"
                       multiLine={false}
                       label="injuredPersonWorkingStatus.content.description.label"
                       placeholder="injuredPersonWorkingStatus.content.description.placeholder"
                       maxLength={fieldLengthConstants[flowConstants.personSteps.INJURED_PERSON_WORKING_STATUS.NAME].description + 1}/>
          </Layout.OneColumn>
        </Layout>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>
  }
}
