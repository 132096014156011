import React, {Component} from "react";

import {Field} from "formik";
import {isUndefined} from "underscore";

import Layout from "components/common/Layout";
import Link from "components/common/Link";
import Text from "components/common/Text";

const LinkContext = React.createContext();

function LinkField({name, label, value, children, onClick, ...props}) {

  return (
    <Field
      name={name}
      render={(fieldProps) => {
        const linksChildren =
          isUndefined(label)
            ? children
            : <LinkFieldItem label={label} value={!fieldProps.field.value}/>;

        return (
          <InnerLinkField fieldProps={fieldProps} hideSelected={isUndefined(label)} onClick={onClick}>
            {linksChildren}
          </InnerLinkField>
        );
      }}
    />
  );
}

class InnerLinkField extends Component {
  onClick = (value) => (e) => {
    e.preventDefault();

    const {field: {name}, form: {setFieldValue}} = this.props.fieldProps;

    setFieldValue(name, value);

    if (this.props.onClick) {
      this.props.onClick(value);
    }
  };

  state = {
    onClick: this.onClick,
  };

  render() {
    const {fieldProps, hideSelected = false, children} = this.props;

    const filtered =
      hideSelected
        ? React.Children.toArray(children)
            .filter(child => child.props.value !== fieldProps.field.value)
            .filter(child => child.props.condition !== false)
        : children;

    if (filtered.length === 0) {
      return null;
    }

    return (
      <LinkContext.Provider value={this.state}>
        <Layout.OneRow spacing={8}>
          {filtered}
        </Layout.OneRow>
      </LinkContext.Provider>
    );
  }
}

class LinkFieldItem extends Component {
  static contextType = LinkContext;

  render() {
    const {label, value} = this.props;

    return (
      <Link onClick={this.context.onClick(value)}>
        <Text label={label}/>
      </Link>
    );
  }
}

LinkField.Item = LinkFieldItem;

export default LinkField;
