import React from "react";
import ReactDOM from "react-dom";

import cx from "classnames";

import CloseIcon from "components/icon/CloseIcon";

import styles from "components/common/Modal.module.scss";

function Modal(props) {
  if (!props.visible) {
    return null;
  }

  return (
    ReactDOM.createPortal(
      <ModalContent {...props}/>,
      document.body)
  );
}

function ModalContent({visible, onClose, children, fromSummary, map, date, variant, popUp}) {
  if (!visible) {
    return null;
  }

  const className = cx({
    [styles.root]: true,
    [styles.popUp]: popUp,
    [styles.fromSummary]: fromSummary,
    [styles.map]: map,
    [styles.date]: date,
    [styles.variant1]: variant === "variant1"
  });

  return (
    <div className={className}>
      <div className={styles.container}>
        {onClose
          ? <div className={styles.closeBackground}>
            <CloseIcon variant={map ? "variant1" : "variant2"}
                       dark={date}
                       onClose={onClose}/>
          </div>
          : null}
        <div className={styles.inner}>
          {children}
        </div>
      </div>
    </div>
  )
}

Modal.Content = ModalContent;

export default Modal;