import React, {Component} from "react";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import ToggleButton from "components/form/ToggleButton";
import booleanTypesConstants from "constants/booleanTypes";
import wasDriverTypesConstants from "constants/wasDriverTypes";

export default class OwnVehicleInfoDriverHurtRight extends Component {
  render() {
    const {onNextStep, values, isValid} = this.props;

    let suffix;

    if (values.wasDriver === wasDriverTypesConstants.YES) {
      suffix = "wasDriver";
    } else if (values.wasDriver === wasDriverTypesConstants.NO) {
      suffix = "wasNotDriver";
    } else if (values.wasDriver === wasDriverTypesConstants.NO_DRIVER) {
      suffix = "noDriver";
    }

    return (
      <NextStepButton component={ToggleButton}
                      onNextStep={onNextStep}
                      isValid={isValid}
                      buttonProps={{name: `driverHurt.${suffix}`}}>
        <Layout.OneRow spacing={10} flex={1}>
          <ToggleButton.Choice value={booleanTypesConstants.YES} label="button.yes"/>
          <ToggleButton.Choice value={booleanTypesConstants.NO} label="button.no"/>
        </Layout.OneRow>
      </NextStepButton>
    );
  }
}
