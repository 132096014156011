import React from "react";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import TextField from "components/form/TextField";
import ToggleButton from "components/form/ToggleButton";
import FadeView from "components/view/FadeView";
import booleanTypesConstants from "constants/booleanTypes";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";

export default function HomeBurglaryViolentRight(props) {
  const {onNextStep, values} = props;
  const {tracesOfBurglary} = values;
  return (
    <Layout spacing={10}>
      <Layout.OneColumn flex={1}>
        <NextStepButton component={ToggleButton}
                        onNextStep={onNextStep}
                        isValid={true}
                        condition={value => value === booleanTypesConstants.NO}
                        buttonProps={{
                          name: "tracesOfBurglary",
                        }}>
          <Layout spacing={10}>
            <Layout.OneColumn flex={1}>
              <Layout.OneRow spacing={10} flex={1}>
                <ToggleButton.Choice value={booleanTypesConstants.YES} label="button.yes"/>
                <ToggleButton.Choice value={booleanTypesConstants.NO} label="button.no"/>
              </Layout.OneRow>
            </Layout.OneColumn>
          </Layout>
        </NextStepButton>
      </Layout.OneColumn>
      <Layout.OneColumn width="100%" maxWidth={450} justifyContent="center">
        <FadeView step={tracesOfBurglary}>
          {tracesOfBurglary === booleanTypesConstants.YES
            ? <TextField name="description"
                         label="homeBurglaryViolent.content.description.label"
                         placeholder="homeBurglaryViolent.content.description.placeholder"
                         multiLine={true}
                         isRequired={true}
                         maxLength={fieldLengthConstants[flowConstants.homeSteps.HOME_BURGLARY_VIOLENT.NAME].description + 1}/>
            : null
          }
        </FadeView>
      </Layout.OneColumn>
    </Layout>
  );
}