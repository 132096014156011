import React from "react";

import Layout from "components/common/Layout";
import SummaryBankAccounts from "containers/step/summary/bankAccounts/SummaryBankAccounts";
import SummaryParticipants from "containers/step/summary/car/SummaryParticipants";
import SummaryDocuments from "containers/step/summary/documents/SummaryDocuments";
import SummaryBeneficiaryPersons from "containers/step/summary/person/SummaryBeneficiaryPersons";
import SummaryDeceasedPersonInformation from "containers/step/summary/person/SummaryDeceasedPersonInformation";
import SummaryInfoPerson from "containers/step/summary/person/SummaryInfoPerson";
import SummaryWitnesses from "containers/step/summary/person/SummaryWitnesses";
import SummaryResponsiblePerson from "containers/step/summary/responsiblePerson/SummaryResponsiblePerson";
import SummaryApplicant from "containers/step/summary/userInfo/SummaryApplicant";
import SummaryUser from "containers/step/summary/userInfo/SummaryUser";

export default function SummaryPerson({claimType, medicalCareType, owner, isDamagesClaimFiled, accidentInvolved, ...props}) {
  return (
    <>
      <Layout.OneColumn {...props}>
        <SummaryInfoPerson claimType={claimType} medicalCareType={medicalCareType}/>
      </Layout.OneColumn>
      <Layout.OneColumn {...props}>
        <SummaryParticipants claimType={claimType} medicalCareType={medicalCareType} isDamagesClaimFiled={isDamagesClaimFiled}/>
      </Layout.OneColumn>
      <Layout.OneColumn {...props}>
        <SummaryResponsiblePerson accidentInvolved={accidentInvolved}/>
      </Layout.OneColumn>
      <Layout.OneColumn {...props} width="100%">
        <SummaryDocuments claimType={claimType}/>
      </Layout.OneColumn>
      <Layout.OneColumn {...props}>
        <SummaryWitnesses/>
      </Layout.OneColumn>
      <Layout.OneColumn {...props}>
        <SummaryApplicant/>
      </Layout.OneColumn>
      <Layout.OneColumn {...props}>
        <SummaryDeceasedPersonInformation/>
      </Layout.OneColumn>
      <Layout.OneColumn {...props}>
        <SummaryUser claimType={claimType} owner={owner} medicalCareType={medicalCareType}/>
      </Layout.OneColumn>
      <Layout.OneColumn {...props}>
        <SummaryBeneficiaryPersons/>
      </Layout.OneColumn>
      <Layout.OneColumn {...props}>
        <SummaryBankAccounts claimType={claimType}/>
      </Layout.OneColumn>
    </>
  );
}
