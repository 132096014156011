import React, {Component} from "react";
import {connect} from "react-redux";

import cx from "classnames";
import {prefix} from 'inline-style-prefixer'
import {find} from "underscore";

import {isMobile} from "react-device-detect";
import StepView from "components/view/StepView";
import Button from "components/common/Button";
import NextStepButton from "components/common/NextStepButton";
import {GtmContext} from "containers/context/GtmContainer";
import NextStepContainer, {NextStepContext} from "containers/context/NextStepContainer";
import {UploadContext} from "containers/context/UploadContainer";

import styles from "components/view/SplitView.module.scss";

import {isIE} from 'react-device-detect';

class SplitView extends Component {
  static contextType = GtmContext;

  componentDidMount() {
    try {
      this.context.pushStepToDataLayer(this.props.gtmCode, this.props.gtmTitle);
    } catch (e) {
    }

  }

  render() {
    const {children, reverseFlex, fromSummary} = this.props;

    const SplitViewClassName = cx({
      [styles.root]: true,
      [styles.reverseFlex]: reverseFlex,
      [styles.fromSummary]: fromSummary,
    });

    return <div className={SplitViewClassName}>{children}</div>;
  }
}

function SplitViewLeftRight({ background, backgroundColor, noSpacing, map, centerPosition, className, children, maintenance}) {
  let style = {};
  if (background) {
    style = {
      ...style,
      backgroundImage: `url(${background})`,
      backgroundPosition: "50% 50%",
      backgroundSize: "cover",
      backgroundColor: backgroundColor
    };
  }

  const containerClassName = cx({
    [className || ""]: true,
    [styles.spacing]: !noSpacing,
    [styles.map]: map,
    [styles.centerPosition]: centerPosition,
    [styles.backgroundImg]: background,
    [styles.isMobile]: isMobile,
    [styles.maintenance]: maintenance,
  });

  const ieInnerClassCombo = cx({
    [styles.inner]: true,
    [styles.ieInner]: true,
  });

  return (
    <div className={containerClassName} style={prefix(style)}>
      {isIE
        ?
        <div className={ieInnerClassCombo}>
          {children}
        </div>
        : <div className={styles.inner}>
        {children}
      </div>
      }
    </div>
  );
}

function SplitViewLeft({children, ...props}) {
  return <SplitViewLeftRight {...props} className={styles.left} noSpacing={true}>{children}</SplitViewLeftRight>
}

function SplitViewRight({children, ...props}) {
  const {onNextStep, onPreviousStep, hasPreviousStep, fromSummary, isValid, showBack = true, showNavigation = true, showNextStepButton = true, showSpacer= true, submittingClaim, loadingUserData, loadingCascoData} = props;

  return <SplitViewLeftRight {...props} className={styles.right}>
    <NextStepContainer>
    {showSpacer
      ?
        <div className={styles.spacerLeft}></div>
      : null
      }
      {children}
      {showSpacer
        ?
          <div className={styles.spacerRight}></div>
        : null
        }
      {showNavigation
        ?
        <div className={cx(styles.buttonContainer)}>
          {showNextStepButton
            ? <UploadContext.Consumer>
              {uploadContext => (
                <NextStepButton isValid={isValid}
                                onNextStep={onNextStep}
                                condtion={() => !uploadContext.uploading && !submittingClaim && !loadingUserData && !loadingCascoData}
                                component={Button}
                                buttonProps={{
                                  label: "button.next",
                                  isDisabled: uploadContext.uploading || !isValid,
                                  loading: submittingClaim || loadingUserData,
                                }}/>
              )}
            </UploadContext.Consumer>
            : null
          }
          {showBack
            ? <NextStepContext.Consumer>
              {nextStepContext => (
                <Button label="button.back"
                        onClick={onPreviousStep}
                        isDisabled={!hasPreviousStep || !nextStepContext.nextStepEnabled}
                        fromSummary={fromSummary}/>
              )}
            </NextStepContext.Consumer>
            : null
          }
        </div>
        : null
      }
    </NextStepContainer>
  </SplitViewLeftRight>
}

SplitView.Left = SplitViewLeft;
SplitView.Right = SplitViewRight;
SplitView.RightSteps = ({children, ...props}) => (
  <SplitViewRight noSpacing={true} {...props}>
    <StepView {...props}>
      {children}
    </StepView>
  </SplitViewRight>
);
SplitView.Step = StepView.Single;

const mapStateToProps = (state) => ({
  gtmCode: find(state.flow.configuration.data, (value) => value.code === state.flow.step.name).gtmCode,
  gtmTitle: find(state.flow.configuration.data, (value) => value.code === state.flow.step.name).gtmTitle
});

export default connect(mapStateToProps)(SplitView);
