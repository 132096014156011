import React from "react";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import VehicleIcon from "components/step/VehicleIcon";

import styles from "components/step/VehicleInfoHeader.module.scss"

export default function VehicleInfoHeader({info, headerLabel, contentLabel}) {
  return (
    <Layout>
      <Layout.Row>

          <Layout.Column>
            <VehicleIcon {...info} />
          </Layout.Column>

          <Layout.Column flex={1} spacing={10}>
            <Text className={styles.title} element="h4" variant="variant1" label={headerLabel}/>
            <Text element="span" variant="variant7" isHtml={true} label={contentLabel}/>
          </Layout.Column>
          
      </Layout.Row>
      <Layout.OneColumn flex={1} className={styles.spacingBorder} spacing={10}></Layout.OneColumn>
    </Layout>
  );
}
