import React, {Component} from "react";

import {FieldArray} from "formik";

import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import Button from "components/common/Button";
import Layout from "components/common/Layout";
import TextField from "components/form/TextField";
import ReferralNumber from "components/step/ReferralNumber";
import FadeView from "components/view/FadeView";

import zzzs from "images/referralNumbers/zzzs_card.jfif";

export default function ReferralNumbersData() {
  return (
    <FieldArray name="referralNumbers" render={arrayHelpers => (
      <ReferralNumbers arrayHelpers={arrayHelpers}/>
    )}/>
  );
}

class ReferralNumbers extends Component {
  add = () => {
    this.props.arrayHelpers.push({});
  };

  render() {
    const {arrayHelpers} = this.props;
    const referralNumbers = arrayHelpers.form.values.referralNumbers;

    const values =
      referralNumbers.map((referralNumber, i) => (
        <FadeView.Child childKey={i} key={i}>
          <ReferralNumber index={i} onRemove={() => arrayHelpers.remove(i)}
                          showRemove={referralNumbers.length > 1}/>
        </FadeView.Child>
      ));

    return (
      <Layout flex={1} spacing={10}>
        <Layout.OneColumn>
          <FadeView>
            {values}
          </FadeView>
        </Layout.OneColumn>
        <Layout.OneColumn textAlign="right">
          <Button variant="variant10" label="referralNumber.button.add" onClick={this.add}/>
        </Layout.OneColumn>
        <Layout.OneColumn spacing={40}></Layout.OneColumn>
        <Layout.OneColumn>
          <TextField name="card"
                     placeholder="referralNumbers.content.card.placeholder"
                     label="referralNumbers.content.card.label"
                     maxLength={fieldLengthConstants[flowConstants.personSteps.REFERRAL_NUMBERS.NAME].card + 1}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <img src={zzzs} alt="zzzs"/>
        </Layout.OneColumn>
      </Layout>
    );
  }
}
