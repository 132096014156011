import React, {Component} from 'react'

import cx from "classnames";
import {Map as GoogleMap, Marker} from 'google-maps-react';
import {find, isEmpty} from "underscore";
import {isMobile} from "react-device-detect";

import InfoWindowMap from "components/map/InfoWindowMap";
import {CurrentLocationContext} from "containers/context/CurrentLocationContainer";
import {I18nContext} from "containers/context/I18nContainer";
import {MapContext} from "containers/context/MapContainer";

import mark from "images/mark.svg";
import markerIcon from "images/map/markerIconGenerali.png";

import styles from "components/map/Map.module.scss";

export default function Map(props) {
  const containerClassName = cx({
    [styles.container]: true,
    [styles.isMobile]: isMobile,
  });

  return (
    <div className={containerClassName}>
      <MapContext.Consumer>
        {google => (
          <InnerMap google={google} {...props} />
        )}
      </MapContext.Consumer>
    </div>
  );
}

class InnerMap extends Component {
  static contextType = I18nContext;

  static defaultProps = {
    getLocation: (marker) => marker.location,
    markers: [],
  };

  mapRef = React.createRef();
  markerRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return {
      infoWindow: state.infoWindow
        ? state.infoWindow
        : (
          <InfoWindowMap visible={false}>
          </InfoWindowMap>
        ),
      zoom: props.country !== state.country ? 7 : state.zoom,
      country: props.country,
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      markers: this.getMarkers(this.props.google, this.props.selectedMarkerId),
    };
  }

  async componentDidMount() {
    await this.openSelectedMarker();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.markers !== this.state.markers) {
      await this.openSelectedMarker();
    }

    if (prevProps.markers !== this.props.markers || prevProps.selectedMarkerId !== this.props.selectedMarkerId) {
      this.setState({
        markers: this.getMarkers(this.props.google, this.props.selectedMarkerId),
      });
    }
  }

  openSelectedMarker = async () => {
    const {renderMarkerInfo} = this.props;

    if (!isEmpty(this.markerRef.current) && renderMarkerInfo) {
      const marker = await this.markerRef.current.getMarker();

      marker.setMap(this.mapRef.current.map);

      this.setState({
        infoWindow: this.renderMarkerInfo(marker, marker.item, true),
      });
    } else {
      this.setState({
        infoWindow: (
          <InfoWindowMap visible={false}>
          </InfoWindowMap>
        ),
      })
    }
  };

  onClick = (map, screen, click) => {
    if (this.props.onLocationClick) {
      this.props.onLocationClick(click);
    }
  };

  onMarkerClick = (props, marker) => {
    const {focusMarker} = this.props;

    if (focusMarker) {
      focusMarker(marker);
    }

    if (this.props.renderMarkerInfo) {
      this.setState({
        infoWindow: this.renderMarkerInfo(marker, props.item),
      });
    }
  };

  onMouseOverMarker = (props, marker) => {
    if (this.props.renderMarkerInfo) {
      this.setState({
        infoWindow: this.renderMarkerInfo(marker, props.item),
      });
    }
  };

  onClose = () => {
    const {focusMarker} = this.props;

    if (focusMarker) {
      focusMarker(null);
    }

    this.setState({
      markers: this.getMarkers(this.props.google),
    });
  };

  renderMarkerInfo = (marker, item, callOpen = false) => {
    return (
      <InfoWindowMap visible={true} marker={marker} callOpen={callOpen} onClose={this.onClose}>
        <I18nContext.Provider value={this.context}>
          {this.props.renderMarkerInfo(item)}
        </I18nContext.Provider>
      </InfoWindowMap>
    );
  };

  getMarkers = (google, selectedMarkerId) => {
    const {markers, getLocation} = this.props;
    const selectedMarker = find(markers, marker => marker.id === selectedMarkerId) || {};

    return markers.map((marker, index) => (
      <Marker key={index}
              ref={selectedMarker.id === marker.id ? this.markerRef : null}
              item={marker}
              position={getLocation(marker)}
              onClick={this.onMarkerClick}
              onMouseover={this.onMouseOverMarker}
              icon={{
                url: markerIcon,
                anchor: new google.maps.Point(25, 80),
                scaledSize: new google.maps.Size(50, 80)
              }}/>
    ));
  };

  onCurrentLocationClick = (currentLocation) => {
    if (this.props.onLocationClick) {
      this.props.onLocationClick({...currentLocation, loaded: true});
    } else if (this.props.onCurrentLocationClicked) {
      this.props.onCurrentLocationClicked({...currentLocation});
    }

    this.setState({
      zoom: 14,
    });
  };

  onZoomChanged = (google, map) => {
    this.setState({
      zoom: map.getZoom(),
    });
  };

  render() {
    const {center, google} = this.props;

    const containerClassName = cx({
      [styles.container]: true,
      [styles.isMobile]: isMobile,
    });

    return (
      <div className={containerClassName}>
        <CurrentLocationContext.Consumer>
          {currentLocation => (
            <>
              <GoogleMap ref={this.mapRef}
                         google={google}
                         zoom={this.state.zoom}
                         containerStyle={{
                           position: 'absolute',
                           width: '100%',
                         }}
                         initialCenter={center ? center : currentLocation}
                         center={center}
                         onZoom_changed={this.onZoomChanged}
                         onClick={this.onClick}
                         streetViewControl={true}
                         mapType={google.maps.MapTypeId.HYBRID}
                         disableDefaultUI
                         zoomControl={true}>
                {this.state.markers}
                {this.state.infoWindow}
              </GoogleMap>
              <img className={styles.icon}
                   src={mark}
                   alt="mark"
                   onClick={() => this.onCurrentLocationClick(currentLocation)}/>
            </>
          )}
        </CurrentLocationContext.Consumer>
      </div>
    );
  }
}
