import React, {Component} from "react";
import {connect} from "react-redux";

import _ from "lodash";

import {I18nContext} from "containers/context/I18nContainer";
import {RegistryContext} from "containers/context/RegistyContainer";
import dataLayerConstants from "constants/dataLayer";
import flowConstants from "constants/flow";

export const GtmContext = React.createContext({});

function GtmContainer(props) {
  return (
    <RegistryContext.Consumer>
      {registryContext =>
        <I18nContext.Consumer>
          {translationsContext => <InnerGtmContainer translationsContext={translationsContext} registryContext={registryContext} {...props}/>}
        </I18nContext.Consumer>}
    </RegistryContext.Consumer>
  )
}

class InnerGtmContainer extends Component {

  pushStepToDataLayer = (gtmCode, gtmTitle) => {

    const {claimType, translationsContext, registryContext, step} = this.props;

    if(registryContext.registry.gtmStepsEnabled) {
      window.dataLayer = window.dataLayer || [];
      const isWithoutClaimType = step === flowConstants.steps.INTRO.NAME || step === flowConstants.steps.CLAIM_TYPE.NAME;
      const noClaimType = translationsContext.translations["gtm.noClaimType.url"] || "gtm.noClaimType.url";

      const gtmCodeCalculated = gtmCode || step;

      const url = '/' + (isWithoutClaimType ? noClaimType : dataLayerConstants.STEP_PREFIX[claimType]) + '/' + gtmCodeCalculated;

      const vpvTitle = gtmTitle || gtmCodeCalculated;

      window.dataLayer.push({
        event: "vpv",
        vpvUrl: url,
        vpvTitle: vpvTitle
      });
    }
  }

  state = {
    pushStepToDataLayer: this.pushStepToDataLayer,
  };

  render() {
    const {children} = this.props;

    return (
      <GtmContext.Provider value={this.state}>
        {children}
      </GtmContext.Provider>
    )
  }
}

const mapStateToProps = (state) => ({
  claimType: _.at(state.flow.forms, "claimType.choiceType")[0],
  step: _.at(state.flow.step, "name")[0],
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GtmContainer);