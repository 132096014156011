import React from "react";

import Text from "components/common/Text";
import ToggleButton from "components/form/ToggleButton";
import VehicleInfoContainer from "components/step/VehicleInfoContainer";

function VehicleInfoToggleButton({name, onClick, children}) {
  return (
    <ToggleButton name={name} onClick={onClick} multiple={true}>
      {children}
    </ToggleButton>
  );
}

function VehicleInfoToggleButtonChoice({info, ...props}) {
  const labelKey = info.info.isOwnVehicle ? "own" : "other";

  return (
    <ToggleButton.Choice value={info.info.index}
                         variant="variant26"
                         render={({isSelected}) => (
                           <VehicleInfoContainer {...info}
                                                 variant={isSelected ? "variant3" : "variant2"}>
                             <Text element="span" variant="variant1"
                                   label={`vehicleInfo.label.${labelKey}.${info.info.type}`}/>
                           </VehicleInfoContainer>
                         )}/>
  );
}

VehicleInfoToggleButton.Choice = VehicleInfoToggleButtonChoice;

export default VehicleInfoToggleButton;
