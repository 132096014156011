import React from "react";

import Button from "components/common/Button";
import Layout from "components/common/Layout";
import styles from "components/sketch/navigation/SketchBottomNavigation.module.scss";

import rotateLeft from "images/sketch/rotate-left.svg";
import rotateRight from "images/sketch/rotate-right.svg";
import flip from "images/sketch/flip.svg";
import close from "images/close.svg";

export default function SketchBottomNavigation({onRotateRight, onRotateLeft, onFlip, onDelete}) {
  return (
    <div className={styles.root}>
      <Layout.OneRow spacing={6} justifyContent="center">
        <Button icon={<img className={styles.icon} src={rotateLeft} alt="rotate-left"/>}
                onClick={onRotateLeft}
                variant="variant7"/>

        <Button icon={<img className={styles.icon} src={rotateRight} alt="rotate-right"/>}
                onClick={onRotateRight}
                variant="variant7"/>

        <Button icon={<img className={styles.icon} src={flip} alt="flip"/>}
                onClick={onFlip}
                variant="variant7"/>

        <Button icon={<img className={styles.icon} src={close} alt="delete"/>}
                onClick={onDelete}
                variant="variant7"/>
      </Layout.OneRow>
    </div>
  );
}
