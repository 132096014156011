import React, {Component} from "react";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import booleanTypeConstants from "constants/booleanTypes";
import Header from "containers/step/Header";
import WitnessesPresentRight from "containers/step/witnessesPresent/WitnessesPresentRight"
import SplitViewContainer from "containers/view/SplitViewContainer";

export default class WitnessesPresentStepContainer extends Component {
  left = ({fromSummary, step}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="witnessesPresent.sidebar.title"/>
        <Text element="div" label="witnessesPresent.sidebar.subtitle"/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right
        showNextStepButton={props.values.witnessesPresent === booleanTypeConstants.YES} {...props}>
        <WitnessesPresentRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right}/>
    );
  }
}
