import React, {Component} from "react";

import axios from "axios";
import {connect} from "formik";
import _ from "lodash";

import Layout from "components/common/Layout";
import AutoCompleteField from "components/form/AutoCompleteField";
import TextField from "components/form/TextField";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import {ConfigContext} from "containers/context/ConfigContainer";

export default class Banks extends Component {
  static contextType = ConfigContext;

  fetchBanks = async (value) => {
    const response = await axios.get(this.context.url.banks, {params: {term: value}});
    return response.data;
  };

  render() {
    return (
      <InnerBanks {...this.props} fetchBanks={this.fetchBanks}/>
    );
  }
}

class InnerBanks extends Component {
  setBank = (value) => {
    const {formik, name} = this.props;

    if (value) {
      formik.setFieldValue(`${name}`, {name: value.name, bic: value.bic, id: value.id});
    } else {
      formik.setFieldValue(`${name}`, {name: "", bic: ""});
    }
  };

  onSuggestionAborted = (search) => {
    const {formik, name} = this.props;
    const oldValue = _.get(formik.values, name) || {};

    return {
      bic: oldValue.bic || "",
      code: "",
      id: "",
      name: search,
    };
  };

  render() {
    const {fetchBanks, name, variant, isRequired, formik: {values}} = this.props;

    return (
      <>
        <Layout.OneColumn width="100%" spacing={0}>
          <AutoCompleteField
            name={`${name}.name`}
            label="bank.name.label"
            placeholder="bank.name.placeholder"
            isRequired={isRequired}
            onSuggestionSelected={this.setBank}
            onSuggestionAborted={this.onSuggestionAborted}
            fetchSuggestions={fetchBanks}
            renderSuggestionValue={value => value.name}
            variant={variant}
            maxLength={fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].bankName + 1}/>
        </Layout.OneColumn>
        <Layout.OneColumn spacing={5}/>
        <Layout.OneColumn width="100%" spacing={0}>
          <TextField
            name={`${name}.bic`}
            label="bank.bic.label"
            placeholder="bank.bic.placeholder"
            isRequired={isRequired}
            disabled={_.at(values, `${name}.id`)[0] !== ""}
            variant={variant}
            maxLength={fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].swift + 1}/>
        </Layout.OneColumn>
      </>
    );
  }
}

InnerBanks = connect(InnerBanks);
