import React from "react";

import Text from "components/common/Text";
import booleanTypeConstants from "constants/booleanTypes";
import flowConstants from "constants/flow";
import summaryRefConstants from "constants/summaryRef";
import Summary from "containers/step/summary/Summary";

export default function SummaryBeneficiaryPersons() {
  return (
    <Summary title="summary.content.beneficiaryPersonsPresent.beneficiaryPersons.title" onePart={true}
             refName={summaryRefConstants.BENEFICIARY_PERSONS.REF}>
      <Summary.Items formName={{name: flowConstants.personSteps.BENEFICIARY_PERSONS.NAME}}
                     condition={form => form.hasBeneficiaryPersons === booleanTypeConstants.YES}>
        {form => <SummaryBeneficiaryPerson {...form} />}
      </Summary.Items>
    </Summary>
  );
}

function SummaryBeneficiaryPerson({jumpTo, beneficiaryPersons, isApplicantBeneficiary}) {
  return (
    beneficiaryPersons.map((beneficiaryPerson, key) => (
      <Summary.ItemValue key={key}
                         label={<Text label="summary.content.beneficiaryPersonsPresent.beneficiaryPerson.label"
                                      parameters={[isApplicantBeneficiary ? key + 2 : key + 1]}/>}
                         refName={summaryRefConstants.BENEFICIARY_PERSONS.BENEFICIARY_PERSON(key)}
                         formName={jumpTo}>
        {form => beneficiaryPerson}
      </Summary.ItemValue>
    ))
  );
}
