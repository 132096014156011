import React, {Component} from "react";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import TextField from "components/form/TextField";
import ToggleButton from "components/form/ToggleButton";
import Asdom from "components/icon/Asdom";
import FontIcon from "components/icon/FontIcon";
import IcoMoon from "components/icon/IcoMoon";
import ToggleView from "components/view/ToggleView";
import carDamageTypes from "constants/carDamageTypes";
import fontConstants from "constants/font";
import {RegistryContext} from "containers/context/RegistyContainer";

import {isIE} from 'react-device-detect';

import styles from "containers/step/carDamageType/CarDamageTypeStepContainer.module.scss";

export default function CarDamageTypeRight(props) {
  return (
    <RegistryContext.Consumer>
      {context => (
        <InnerCarDamageTypeRight context={context} {...props}/>
      )}
    </RegistryContext.Consumer>
  );
}

class InnerCarDamageTypeRight extends Component {
  render() {

    const {values, onNextStep, context} = this.props;

    const sortedDamageTypes = [];

    context.registry.accidentTypes.map(accidentType => {
      const icon = getDamageTypeIcon(accidentType.accidentType);

      const row = Math.floor((accidentType.order - 1) / 2);
      const isLeft = accidentType.order % 2 === 1;
      const damageType = {
        damageType: accidentType.accidentType,
        icon,
      };

      if (isLeft) {
        sortedDamageTypes[row] = {
          ...sortedDamageTypes[row],
          left: damageType,
        };
      } else {
        sortedDamageTypes[row] = {
          ...sortedDamageTypes[row],
          right: damageType,
        };
      }
    });

    return (
      <Layout>
        {isIE
          ?
          <Layout.OneColumn rowClassName={styles.ieLgScreen}/>
          : null
        }
        <NextStepButton component={ToggleButton}
                        onNextStep={onNextStep}
                        isValid={true}
                        condition={value => value !== carDamageTypes.OTHER}
                        buttonProps={{
                          name: "carDamageType",
                        }}>
          <Layout flex="1 1 45%" minWidth={250}>
            {sortedDamageTypes.map(damageType => (
              <Layout.RowChildren spacing={10}>
                {damageType.left
                  ? <ToggleButton.Choice value={damageType.left.damageType}
                                         icon={damageType.left.icon}
                                         label={`carDamageType.content.button.${damageType.left.damageType}.label`}/>
                  : null}
                {damageType.right
                  ? <ToggleButton.Choice value={damageType.right.damageType}
                                         icon={damageType.right.icon}
                                         label={`carDamageType.content.button.${damageType.right.damageType}.label`}/>
                  : null}
              </Layout.RowChildren>
            ))}
          </Layout>
        </NextStepButton>
        <Layout>
          <ToggleView visible={values.carDamageType === carDamageTypes.OTHER}>
            <Layout.OneColumn width="100%" maxWidth={420} spacing={10} justifyContent="center">
              <Layout spacing={20}>
                <Layout.OneColumn flex={1}>
                  <TextField name="otherName"
                             label="carDamageType.content.other.label"
                             placeholder="carDamageType.content.other.placeholder"
                             className={styles.otherInput}/>
                </Layout.OneColumn>
                <Layout.OneColumn>&nbsp;</Layout.OneColumn>
              </Layout>
            </Layout.OneColumn>
          </ToggleView>
        </Layout>
      </Layout>
    );
  }
}

function getDamageTypeIcon(accidentType) {
  switch (accidentType) {
    case carDamageTypes.CAR_ACCIDENT:
      return <IcoMoon icon={fontConstants.ICOMOON.CAR_ACCIDENT}/>;
    case carDamageTypes.COLLISION:
      return <Asdom icon={fontConstants.ASDOM_ICON.VEHICLE_IMPACT}/>;
    case carDamageTypes.FIRE:
      return <IcoMoon icon={fontConstants.ICOMOON.FIRE}/>;
    case carDamageTypes.THEFT:
      return <IcoMoon icon={fontConstants.ICOMOON.THEFT}/>;
    case carDamageTypes.MALICIOUS_ACTS:
      return <IcoMoon icon={fontConstants.ICOMOON.MALICIOUS_ACTS}/>;
    case carDamageTypes.NATURAL_DISASTER:
      return <IcoMoon icon={fontConstants.ICOMOON.NATURAL_DISASTER}/>;
    case carDamageTypes.BREAKING_GLASS:
      return <IcoMoon icon={fontConstants.ICOMOON.BREAKING_GLASS}/>;
    case carDamageTypes.BREAKING_LIGHTS_MIRRORS:
      return <FontIcon font="GeneraliTocaPoplavaSteklo"
                       icon={fontConstants.TOCA_POPLAVA_STEKLO_ICON.LIGHTS_MIRROR_BREAK}/>
    case carDamageTypes.ANIMAL_ENCOUNTERS:
      return <IcoMoon icon={fontConstants.ICOMOON.ANIMAL_SIGN}/>;
    case carDamageTypes.DAMAGE_PARKING_LOT:
      return <IcoMoon icon={fontConstants.ICOMOON.DAMAGE_PARKING_LOT}/>;
    case carDamageTypes.KEY_LOSS:
      return <IcoMoon icon={fontConstants.ICOMOON.KEY_LOSS}/>;
    case carDamageTypes.HAIL:
      return <FontIcon font="GeneraliTocaPoplavaSteklo"
                       icon={fontConstants.TOCA_POPLAVA_STEKLO_ICON.HAIL}/>;
    case carDamageTypes.MECHANICAL_DAMAGE:
      return <IcoMoon icon={fontConstants.ICOMOON.MECHANICAL_DAMAGE}/>;
    case carDamageTypes.OTHER:
      return <IcoMoon icon={fontConstants.ICOMOON.THREE_DOTS}/>;
  }
}
