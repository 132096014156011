import React, {Component} from "react";

import ChoiceButton from "components/form/ChoiceButton";
import NextStepButton from "components/common/NextStepButton";
import TextField from "components/form/TextField";
import LinkField from "components/form/LinkField";
import RangeField from "components/form/RangeField";
import Layout from "components/common/Layout";
import FadeView from "components/view/FadeView";
import booleanTypesConstants from "constants/booleanTypes";
import {regexHourCheck} from "utility/accidentDate";
import {leaveNumbers} from "utility/textTransform";

export default class AccidentTimeRight extends Component {
  onClick = () => {
    const {setFieldValue, setTouched} = this.props;

    setFieldValue("accidentTimeHour", "");
    setFieldValue("accidentTimeMinute", "");
    setFieldValue("accidentTimeFrom", "");
    setFieldValue("accidentTimeTo", "");
    setTouched({});
  };

  render() {
    const {values, onSkipStep} = this.props;

    const element = (
      <FadeView step={values.accidentTimeRange ? "range" : "specific"}>
        {values.accidentTimeRange ? <Range/> : <Specific/>}
      </FadeView>
    );

    let skipEnabled = true;

    return (
      <Layout spacing={10} justifyContent="center">
        <Layout.OneColumn width="100%" maxWidth={430}>
          {element}
        </Layout.OneColumn>
        <Layout.OneColumn spacing={10}/>
        <Layout.OneColumn>
          <LinkField name="accidentTimeRange" onClick={this.onClick}>
            <LinkField.Item label="accidentTime.range" value={true}/>
            <LinkField.Item label="accidentTime.specific" value={false}/>
          </LinkField>
        </Layout.OneColumn>
        <Layout.OneColumn spacing={10}/>
        <Layout.OneColumn>
          <Layout.OneRow spacing={10}>
            <NextStepButton component={ChoiceButton}
                            isValid={true}
                            onNextStep={onSkipStep}
                            condition={value => skipEnabled}
                            buttonProps={{
                              name: "choice",
                              isDisabled: !skipEnabled,
                              value: booleanTypesConstants.DONT_KNOW,
                              label: "button.dontKnow",
                            }}/>
          </Layout.OneRow>
        </Layout.OneColumn>
      </Layout>
    );
  }
}

class Specific extends Component {
  minuteRef = React.createRef();

  onHourChange = (value) => {
    if (regexHourCheck(value)) {
      this.minuteRef.current.focus();
    }
  };

  render() {
    return (
      <Layout.OneRow spacing={10} flex="1 1 40%" width="100%">
        <TextField name="accidentTimeHour"
                   placeholder="accidentTime.hour.placeholder"
                   label="accidentTime.hour.label"
                   variant="variant3"
                   transform={leaveNumbers}
                   onValueChange={this.onHourChange}/>
        <TextField name="accidentTimeMinute"
                   placeholder="accidentTime.minute.placeholder"
                   label="accidentTime.minute.label"
                   variant="variant3"
                   transform={leaveNumbers}
                   inputRef={this.minuteRef}/>
      </Layout.OneRow>
    );
  }
}

function Range() {
  return (
    <RangeField nameFrom="accidentTimeFrom" nameTo="accidentTimeTo" placeholderFrom="accidentTime.from.placeholder"
                placeholderTo="accidentTime.to.placeholder" labelFrom="accidentTime.from.label"
                labelTo="accidentTime.to.label"/>
  );
}
