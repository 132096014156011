import React, {Component} from "react";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import Header from "containers/step/Header";
import SplitViewContainer from "containers/view/SplitViewContainer";
import AccidentInvolvedRight from "./AccidentInvolvedRight";

export default class AccidentInvolvedStepContainer extends Component {

  left = ({fromSummary, step}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label={`accidentInvolved.sidebar.${step.medicalCareTypeDescription}.title`}/>
        <Text element="div" label={`accidentInvolved.sidebar.${step.medicalCareTypeDescription}.subtitle`}/>
      </SplitViewContainer.Left>
    );
  };


  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <AccidentInvolvedRight {...props}/>
      </SplitViewContainer.Right>);
  }

  render() {
    return (<SplitViewContainer left={this.left} right={this.right}/>)
  }
}
