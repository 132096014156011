import React, {Component} from "react";

import Switch, {Case, Default} from "react-switch-case";

import {FieldArray} from "formik";

import Button from "components/common/Button";
import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import Text from "components/common/Text";
import ToggleButton from "components/form/ToggleButton";
import FadeView from "components/view/FadeView";
import booleanTypesConstants from "constants/booleanTypes";
import InsuranceOwner from "containers/step/personInsurance/InsuranceOwner";

export default class PersonInsuranceRight extends Component {
  render() {
    const {values, onNextStep} = this.props;

    return (
      <Layout flex={1}>
        <Layout.OneColumn>
          <NextStepButton component={ToggleButton}
                          condition={value => value === booleanTypesConstants.NO}
                          isValid={true}
                          buttonProps={{name: "personInsurance"}}
                          onNextStep={onNextStep}>
            <Layout.OneRow spacing={10} flex={1}>
              <ToggleButton.Choice value={booleanTypesConstants.YES} label="button.yes"/>
              <ToggleButton.Choice value={booleanTypesConstants.NO} label="button.no"/>
            </Layout.OneRow>
          </NextStepButton>
        </Layout.OneColumn>
        <Layout.OneColumn width="100%" maxWidth={450} justifyContent="center">
          <FadeView step={values.personInsurance}>
            <Switch condition={values.personInsurance}>
              <Case value={booleanTypesConstants.YES}>
                <InsuranceOwnersData/>
              </Case>
              <Default>
                {null}
              </Default>
            </Switch>
          </FadeView>
        </Layout.OneColumn>
      </Layout>
    );
  }
}

function InsuranceOwnersData() {
  return (
    <FieldArray name="insuranceOwners" render={arrayHelpers => (
      <InsuranceOwners arrayHelpers={arrayHelpers}/>
    )}/>
  );
}

class InsuranceOwners extends Component {
  add = () => {
    this.props.arrayHelpers.push({});
  };

  render() {
    const {arrayHelpers} = this.props;
    const insuranceOwners = arrayHelpers.form.values.insuranceOwners;

    const values =
      insuranceOwners.map((insuranceOwner, i) => (
        <FadeView.Child childKey={i} key={i}>
          <InsuranceOwner index={i} onRemove={() => arrayHelpers.remove(i)}
                          showRemove={insuranceOwners.length > 1}/>
        </FadeView.Child>
      ));

    return (
      <Layout flex={1} spacing={10}>
        <Layout.OneColumn spacing={30}/>
        <Layout.OneColumn>
          <Text element="h5" variant="variant2" label="personInsurance.content.insuranceOwner.data.title"/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <FadeView>
            {values}
          </FadeView>
        </Layout.OneColumn>
        <Layout.OneColumn textAlign="right">
          <Button variant="variant10" label="personInsurance.content.insuranceOwner.button.add" onClick={this.add}/>
        </Layout.OneColumn>
      </Layout>
    );
  }
}
