import React from "react";

import Text from "components/common/Text";
import flowConstants from "constants/flow";
import summaryRefConstants from "constants/summaryRef";
import Summary from "containers/step/summary/Summary";

export default function SummaryDeceasedPersonInformation() {
  return (
    <Summary title="summary.content.deceasedPersonInformation.title"
             onePart={true}
             refName={summaryRefConstants.DECEASED_PERSON_INFORMATION.REF}>

      <Summary.ItemValue label="summary.content.deceasedPersonInformation.name"
                         formName={{name: flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME}}
                         refName={summaryRefConstants.DECEASED_PERSON_INFORMATION.NAME}>
        {form => form.name}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.deceasedPersonInformation.surname"
                         formName={{name: flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME}}
                         refName={summaryRefConstants.DECEASED_PERSON_INFORMATION.SURNAME}>
        {form => form.surname}
      </Summary.ItemValue>

      <Summary.ItemValue label="sex.summary.label"
                         formName={{name: flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME}}
                         refName={summaryRefConstants.DECEASED_PERSON_INFORMATION.SEX}>
        {form => form.sex ? <Text label={`sex.${form.sex}`}/> : <Text label="summary.content.common.boolean.dontKnow"/>}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.deceasedPersonInformation.birthDate"
                         formName={{name: flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME}}
                         refName={summaryRefConstants.DECEASED_PERSON_INFORMATION.BIRTH_DATE}>
        {form => form.birthDate}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.deceasedPersonInformation.country"
                         formName={{name: flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME}}
                         refName={summaryRefConstants.DECEASED_PERSON_INFORMATION.COUNTRY}>
        {form => form.country}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.deceasedPersonInformation.cityAndZip"
                         formName={{name: flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME}}
                         refName={summaryRefConstants.DECEASED_PERSON_INFORMATION.CITY_AND_ZIP}>
        {form => form.cityAndZip}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.deceasedPersonInformation.streetAndStreetNo"
                         formName={{name: flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME}}
                         refName={summaryRefConstants.DECEASED_PERSON_INFORMATION.STREET_AND_NUMBER}>
        {form => form.streetAndStreetNo}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.deceasedPersonInformation.policyNumber"
                         formName={{name: flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME}}
                         refName={summaryRefConstants.DECEASED_PERSON_INFORMATION.POLICY_NUMBER}>
        {form => form.policyNumber}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.deceasedPersonInformation.taxNumber"
                         formName={{name: flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME}}
                         refName={summaryRefConstants.DECEASED_PERSON_INFORMATION.TAX_NUMBER}>
        {form => form.taxNumber}
      </Summary.ItemValue>
        
    </Summary>
  );
}
