import React, {Component} from "react";

import {prefix} from "inline-style-prefixer";
import {DropTarget} from "react-dnd";

import SketchCanvas from "components/sketch/canvas/SketchCanvas";
import {getCanvasItemPosition, transform} from "selectors/sketch";

class SketchCanvasTarget extends Component {
  render() {
    const {connectDropTarget, dimensions} = this.props;

    if (!dimensions) {
      return null;
    }

    const transformContainer = transform(
      dimensions.width, dimensions.height,
      Math.min(dimensions.width, dimensions.height),
      Math.min(dimensions.width, dimensions.height),
    );

    return connectDropTarget(
      <div style={prefix({transform: transformContainer, width: Math.min(dimensions.width, dimensions.height), height: Math.min(dimensions.width, dimensions.height)})}>
        <SketchCanvas {...this.props} {...this.state} />
      </div>
    );
  }
}

const target = {
  drop({dimensions, onDrop}, monitor) {
    const clientOffset = monitor.getClientOffset();
    const {initialClientOffset, item} = monitor.getItem();
    const position = getCanvasItemPosition(clientOffset, initialClientOffset, item, dimensions);

    onDrop({...item, ...position});
  },
};

export default DropTarget(
  "item",
  target,
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  }),
)(SketchCanvasTarget);
