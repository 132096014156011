import React from "react";

import cx from "classnames";

import VehicleFontIcon from "components/icon/VehicleFontIcon";

import styles from "components/step/VehicleIcon.module.scss";

export default function VehicleIcon({className, type, name, color, variant = "variant1"}) {
  const style = {
    backgroundColor: color,
  };

  return (
    <div className={cx(styles.root, className, styles[variant])} style={style}>
      <div className={styles.iconContainer}>
        <VehicleFontIcon className={styles.icon} type={type}/>
      </div>

      <div className={styles.name}>
        {name}
      </div>
    </div>
  );
}
