import React from "react";

import {isUndefined} from "underscore";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import errorSign from "images/errorSign.svg";

import styles from "components/form/FormErrorMessage.module.scss";

export default function FormErrorMessage({error, showError = true, ...props}) {
  if (isUndefined(error) || !showError){
    return null;
  }

  return (
    <div className={styles.root}>
      <Layout.OneRow width="100%" spacing={10}>
        <div className={styles.wrapper}>
          <div className={styles.img}>
            <img src={errorSign} alt="error sign"/>
          </div>
          <Text variant="variant9" element="span" label={error} {...props}/>
        </div>
      </Layout.OneRow>
    </div>
  );
}
