import React, {Component} from "react";

import * as Yup from 'yup';

import Header from "containers/step/Header";
import Layout from "components/common/Layout";
import SplitViewContainer from "containers/view/SplitViewContainer";
import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import TextField from "components/form/TextField";

const validationSchema = Yup.object().shape({
  vehicleDamageCurrentDescription: Yup.string().required("validation.required"),
});

// step ?
export default class VehicleDamageCurrentDescriptionStepContainer extends Component {
  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="vehicleDamageCurrentDescription.sidebar.title"/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <Layout width="100%" maxWidth={450} justifyContent="center">
          <Layout.OneColumn>
            <TextField name="vehicleDamageCurrentDescription"
                      label="vehicleDamageCurrentDescription.content.description.label"
                      placeholder="vehicleDamageCurrentDescription.content.description.placeholder"
                      multiLine={true}/>
          </Layout.OneColumn>
        </Layout>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema} />
    );
  }
}
