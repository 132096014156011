import React from "react";

import {transform} from "selectors/sketch";

export default function SketchSvgContainer({dimensions, className, children, shouldCenter = true}) {
  const minDimension = Math.min(dimensions.width, dimensions.height);

  const transformContainer = transform(
    dimensions.width, dimensions.height,
    minDimension, minDimension,
  );

  const style =
    shouldCenter
      ? {transform: transformContainer, width: minDimension, height: minDimension}
      : {width: dimensions.width, height: dimensions.height};

  return (
    <div className={className} style={style}>
      {children}
    </div>
  )
}
