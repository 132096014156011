import {createAction} from "redux-act";

import axios from "axios";
import _ from "lodash";

import {getDrawingSvg, getParticipantSvg, getPersonSvg, getVehicleDamageSvg} from "actions/flow/submitClaim";
import claimStatusTypeConstants from "constants/claimStatusTypes";
import claimTypes from "constants/claimTypes";
import flowConstants from "constants/flow";
import {transformLoadedState} from "selectors/flow";
import isUndefinedOrNull from "utility/utilityFunctions"
import vehicleInfoInputTypesConstants from "constants/vehicleInfoInputTypes";

export const nextStep = createAction("next step");
export const previousStep = createAction("previous step");
export const jumpStep = createAction("jump step");
export const reloadStep = createAction("reload step");
export const jumpFromSummaryStep = createAction("jump from summary step");
export const resetAfter = createAction("reset after");
export const updateFormState = createAction("update form state");
export const setDirty = createAction("set dirty");

export const loadScreenConfigurationSuccess = createAction("load screen configuration success");
export const loadClaimSuccess = createAction("load claim success");
export const storeUserId = createAction("storeUserId");
export const loadUserInfoSuccess = createAction("load user info success");

export const openSaveDialog = createAction("open save dialog");
export const closeSaveDialog = createAction("close save dialog");
export const closeSummaryDialog = createAction("close summary dialog");
export const storeMail = createAction("store mail");
export const storeSaveId = createAction("store save id");
export const storeSaveVersion = createAction("store version id");
export const startSubmitClaim = createAction("start submit claim");
export const startLoadingUserData = createAction("start loading user data");
export const startLoadingCascoData = createAction("start loading casco data");
export const storeCascoData = createAction("store casco data");
export const storeUserData = createAction("store user data");
export const storeUserDataMobileApp = createAction("store user data mobile app");
export const storeSubmitId = createAction("store submit id");
export const storeElectronicSignatureEnabled = createAction("store electronic signature enabled");
export const storeCompany = createAction("store company");

export const openLogOut = createAction("open counter log out dialog");
export const closeLogOut = createAction("close counter log out dialog");
export const storeCounterUser = createAction("store counter user info");

export const removeParticipants = createAction("remove participants");
export const skipParticipant = createAction("add skip participant");

export const removeDamagedGood = createAction("remove damaged good");

export function loadScreenConfiguration(url) {
  return async (dispatch) => {
    const response = await axios.get(url);

    dispatch(loadScreenConfigurationSuccess(response.data));
  };
}

export function loadClaim(url) {
  return async (dispatch) => {

    const response = await axios.get(url);

    const transformedState = transformLoadedState(response.data);

    const state = {
      ...transformedState,
      status: response.data.status,
      step: response.data.status === claimStatusTypeConstants.INACTIVE ? {name: flowConstants.steps.THANK_YOU.NAME} : transformedState.step,
    };

    dispatch(loadClaimSuccess(state));

  };
}

export function loadUserInfo(url) {
  return async (dispatch) => {
    const response = await axios.get(url);

    dispatch(loadUserInfoSuccess(response.data));
  };
}

export function storeClaim(url, autosave = false, userLoggingIn = false) {

  return async (dispatch, getState) => {
    const {flow} = getState();

    var claimType = null;
    var manufacturer = null;
    var name = null;
    var registrationNumber = null;

    if (!autosave) {

      claimType = _.at(flow.forms, "claimType.choiceType")[0];

      if (claimType === claimTypes.CAR) {

        const inputType = _.at(flow.forms, "participantsInfo[0].participantInfo.inputType")[0];

        if (inputType === vehicleInfoInputTypesConstants.REGISTERED_ABROAD) {
          manufacturer = _.at(flow.forms, "participantsInfo[0].participantInfo.abroad.vehicleMark")[0];
          registrationNumber = _.at(flow.forms, "participantsInfo[0].participantInfo.abroad.registrationNumber")[0];
        } else if (inputType === vehicleInfoInputTypesConstants.REGISTERED_IN_SLOVENIA) {
          manufacturer = _.at(flow.forms, "participantsInfo[0].participantInfo.vehicleData.manufacturer")[0];
          registrationNumber = _.at(flow.forms, "participantsInfo[0].participantInfo.inSlovenia.registrationNumber")[0];
        } else if (inputType === vehicleInfoInputTypesConstants.COMPANY_VEHICLE) {
          manufacturer = _.at(flow.forms, "participantsInfo[0].participantInfo.company.vehicleMark")[0];
        }

        const insuranceOwner = _.at(flow.forms, "insuranceOwner.owner")[0];

        if (insuranceOwner === 'no') {

          const wasDriver = _.at(flow.forms, "applicantData.wasDriver")[0];

          var firstName = null;
          var lastName = null;

          if (wasDriver === 'noDriver') {
            firstName = _.at(flow.forms, "applicantData.general.name")[0];
            lastName = _.at(flow.forms, "applicantData.general.surname")[0];
          } else {
            firstName = _.at(flow.forms, "applicantData.driver.name")[0];
            lastName = _.at(flow.forms, "applicantData.driver.surname")[0];
          }

          name = isUndefinedOrNull(lastName) || lastName === '' ? null : (isUndefinedOrNull(firstName) || firstName === '' ? lastName : firstName + ' ' + lastName);

        } else {

          const personType = _.at(flow.forms, "userIdentification.personType")[0];

          if (personType === 'legal') {
            name = _.at(flow.forms, "userIdentification.legalPerson.name")[0];
          } else {
            const firstName = _.at(flow.forms, "userIdentification.userInfo.name")[0];
            const lastName = _.at(flow.forms, "userIdentification.userInfo.surname")[0];
            name = isUndefinedOrNull(lastName) || lastName === '' ? null : (isUndefinedOrNull(firstName) || firstName === '' ? lastName : firstName + ' ' + lastName);
          }

        }
      }
    }

    const {mail, id, version} = flow.save;
    const {counterUser} = flow;

    if (id) {
      const data = {
        id,
        mail,
        version,
        autosave,
        state: flow,
        claimType,
        name,
        manufacturer,
        registrationNumber,
        counterId: counterUser.authenticated ? counterUser.id : "",
        userLoggingIn: userLoggingIn
      };

      const response = await axios.post(url, data);

      if (response.data.success) {
        dispatch(storeSaveId(response.data.id));
        if (!isUndefinedOrNull(response.data.redirect)) {
          document.body.onbeforeunload = "return false";
          window.location.href = response.data.redirect;
        }
      } else {
        document.body.onbeforeunload = "return false";
        window.location.href = "/";
      }
    }
  };
}

export function submitClaim(url, step, values, shouldWaitBeforeNextStep) {
  return async (dispatch, getState) => {
    const {flow} = getState();
    const mail = _.get(flow.forms, "userIdentification.finalUserInfo.email");
    const loggedInFromCounter = _.get(flow, "counterUser.authenticated") || false;

    if (flow.status !== claimStatusTypeConstants.INACTIVE && (mail || loggedInFromCounter)) {
      dispatch(startSubmitClaim());

      const {forms: {vehicleType, vehicleDamageCurrent, vehicleDamagePrevious, drawingIntro, drawing, participantsNumber, personDamage, claimType, personParticipantsInfo}, counterUser} = flow;
      const {id} = flow.save;
      const {submitId} = flow.submit;

      const [currentVehicleDamageSvg, previousVehicleDamageSvg] = getVehicleDamageSvg(vehicleType, vehicleDamageCurrent, vehicleDamagePrevious);
      const drawingSvg = getDrawingSvg(drawingIntro, drawing);
      const participantsSvg = claimType.choiceType === claimTypes.PERSON
        ? isUndefinedOrNull(personParticipantsInfo) ? null : personParticipantsInfo.map(participant => ({
          svg: getParticipantSvg(participant.info),
          index: participant.info.index
        }))
        : isUndefinedOrNull(participantsNumber.finalParticipants) ? null : participantsNumber.finalParticipants.map(participant => ({
        svg: getParticipantSvg(participant),
        index: participant.index
      }));
      const personSvg = getPersonSvg(personDamage);

      const data = {
        id,
        submitId,
        mail: mail,
        state: flow,
        currentVehicleDamageSvg,
        previousVehicleDamageSvg,
        drawingSvg,
        participantsSvg,
        personSvg,
        counterId: counterUser.authenticated ? counterUser.id : "",
      };

      if (!shouldWaitBeforeNextStep) {
        dispatch(nextStep(step, values));
      }

      const response = await axios.post(url, data);

      if (response.data.success) {
        dispatch(storeSubmitId(response.data.submitId));

        if (!isUndefinedOrNull(response.data.electronicSignatureUrl)) {
          document.body.onbeforeunload = "return false";
          window.location.href = response.data.electronicSignatureUrl;
        } else {
          if (shouldWaitBeforeNextStep) {
            dispatch(nextStep(step, values));
          }
        }
      }
    }
  };
}

export function storeSummaryHTML(url, summaryHTML) {
  return async (dispatch, getState) => {
    const {flow} = getState();
    const {id} = flow.save;

    const data = {
      id,
      summaryHTML,
    };

    await axios.post(url, data);
  };
}

export function getUserData(url, userData) {
  return async (dispatch) => {
    dispatch(startLoadingUserData());

    const response = await axios.post(url, userData);

    dispatch(storeUserData(response.data));

    dispatch(nextStep());
  }
}

export function getLoggedInUserData(url, userData) {

  return async (dispatch) => {
    dispatch(startLoadingUserData());
    const response = await axios.post(url, userData);
    dispatch(storeUserData(response.data));
    dispatch(nextStep());
  }

}

export function getMobileUserData(url, userData) {
  return async (dispatch) => {
    if (userData.userId) {
      dispatch(startLoadingUserData());

      const response = await axios.post(url, userData);

      dispatch(storeUserDataMobileApp(response.data));
    }

    dispatch(nextStep());
  }
}

export function getCascoData(url, policyData) {
  return async (dispatch) => {
    dispatch(startLoadingCascoData());

    const response = await axios.post(url, policyData);

    dispatch(storeCascoData(response.data));

    dispatch(nextStep());
  }
}
