import React, {Component} from "react";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import responsiblePersonTypes from "constants/responsiblePersonTypes";
import {RegistryContext} from "containers/context/RegistyContainer";
import Header from "containers/step/Header";
import HomeResponsiblePersonRight from "containers/step/homeResponsiblePerson/HomeResponsiblePersonRight"
import SplitViewContainer from "containers/view/SplitViewContainer";
import Yup, {getMax} from "selectors/yup";
import {isSloAddress} from "utility/utilityFunctions";


const taxNumberValidationSchema = (slovenia) => Yup.string().when("address", {
  is: address => isSloAddress(address, slovenia),
  then: getMax(Yup.string().validateSloTaxNumber("validation.taxNumber.format"), fieldLengthConstants[flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME].taxNumberSlo),
  otherwise: getMax(Yup.string(), fieldLengthConstants[flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME].taxNumber),
});

const addressValidationSchema = Yup.object().shape({
  address: Yup.object().shape({
    streetNumber: getMax(Yup.string().typeError("validation.address.streetNo"), fieldLengthConstants[flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME].streetNumber),
    streetName: getMax(Yup.string().typeError("validation.address.streetNo"), fieldLengthConstants[flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME].streetName),
  }),
  city: Yup.object().shape({
    zip: getMax(Yup.string(), fieldLengthConstants[flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME].zip),
    city: getMax(Yup.string(), fieldLengthConstants[flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME].city),
  }),
});

const legalPersonValidationSchema = Yup.object().shape({
  name: getMax(Yup.string(), fieldLengthConstants[flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME].name),
  address: addressValidationSchema,
  policyNumber: getMax(Yup.string(), fieldLengthConstants[flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME].policyNumber),
});

const naturalPersonValidationSchema = (slovenia) => Yup.object().shape({
  name: getMax(Yup.string(), fieldLengthConstants[flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME].name),
  surname: getMax(Yup.string(), fieldLengthConstants[flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME].name),
  birthDate: Yup.mixed(),
  address: addressValidationSchema,
  policyNumber: getMax(Yup.string(), fieldLengthConstants[flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME].policyNumber),
  taxNumber: taxNumberValidationSchema(slovenia),
});

const validationSchema = (slovenia) => Yup.object().shape({
  responsiblePersonChoice: Yup.string().trim().required("validation.required"),
  legalPerson: Yup.object().when("responsiblePersonChoice", {
    is: responsiblePersonTypes.LEGAL_PERSON,
    then: legalPersonValidationSchema,
  }),
  naturalPerson: Yup.object().when("responsiblePersonChoice", {
    is: responsiblePersonTypes.NATURAL_PERSON,
    then: naturalPersonValidationSchema(slovenia),
  }),
});

// homeStep 11
export default class HomeResponsiblePersonStepContainer extends Component {
  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="homeResponsiblePerson.sidebar.title"/>
        <Text element="div" isHtml={true} label="homeResponsiblePerson.sidebar.subtitle"/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <HomeResponsiblePersonRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <RegistryContext.Consumer>
        {context => (
          <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema(context.registry.slovenia)}/>
        )}
      </RegistryContext.Consumer>
    );
  }
}