import React, {Component} from "react";

import {LightgalleryProvider} from "@croz/react-lightgallery";
import Switch, {Case, Default} from "react-switch-case";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import claimTypesConstants from "constants/claimTypes";
import medicalCareDescriptionTypes from "constants/medicalCareDescriptionTypes";
import medicalCareTypes from "constants/medicalCareTypes";
import Header from "containers/step/Header";
import DocumentsRight from "containers/step/documents/DocumentsRight";
import GeneralDocumentsRight from "containers/step/documents/GeneralDocumentsRight";
import HomeDocumentsRight from "containers/step/documents/HomeDocumentsRight";
import PersonDeathDocumentsRight from "containers/step/documents/PersonDeathDocumentsRight";
import PersonHealthServiceDocumentsRight from "containers/step/documents/PersonHealthServiceDocumentsRight";
import PersonInjuryDocumentsRight from "containers/step/documents/PersonInjuryDocumentsRight";
import PersonOtherDocumentsRight from "containers/step/documents/PersonOtherDocumentsRight";
import PersonReturnOfCostsDocumentsRight from "containers/step/documents/PersonReturnOfCostsDocumentsRight";
import PersonSicknessDocumentsRight from "containers/step/documents/PersonSicknessDocumentsRight";
import SplitViewContainer from "containers/view/SplitViewContainer";
import UploadContainer from "containers/context/UploadContainer";

// step 30
export default class DocumentsStepContainer extends Component {
  left = ({claimType, fromSummary, step}) => {
    const label = step.medicalCareType === medicalCareTypes.HEALTH_SERVICE ? `${claimType}.${step.medicalCareType}` : claimType;

    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label={`documents.sidebar.${label}.title`}/>
        <Text element="div" label={`documents.sidebar.${label}.subtitle`} isHtml={true}/>
      </SplitViewContainer.Left>
    );
  }

  right(props) {
    const {claimType, step} = props;

    return (
      <UploadContainer>
        <SplitViewContainer.Right {...props}>
          <LightgalleryProvider lightgallerySettings={{download: false, thumbnail: false}}>
            <Switch condition={claimType}>
              <Case value={claimTypesConstants.CAR}>
                <DocumentsRight {...props} />
              </Case>
              <Case value={claimTypesConstants.HOME}>
                <HomeDocumentsRight {...props} />
              </Case>
              <Case value={claimTypesConstants.PERSON}>
                <Switch condition={step.medicalCareType}>
                  <Case value={medicalCareTypes.PAYMENT}>
                    <Switch condition={step.medicalCareTypeDescription}>
                      <Case value={medicalCareDescriptionTypes.SICK}>
                        <PersonSicknessDocumentsRight {...props}/>
                      </Case>
                      <Case value={medicalCareDescriptionTypes.INJURY}>
                        <PersonInjuryDocumentsRight {...props} />
                      </Case>
                      <Case value={medicalCareDescriptionTypes.DEATH}>
                        <PersonDeathDocumentsRight {...props}/>
                      </Case>
                      <Case value={medicalCareDescriptionTypes.RETURN_OF_COSTS}>
                        <PersonReturnOfCostsDocumentsRight {...props}/>
                      </Case>
                    </Switch>
                  </Case>
                  <Case value={medicalCareTypes.HEALTH_SERVICE}>
                    <PersonHealthServiceDocumentsRight {...props}/>
                  </Case>
                  <Default>
                    <PersonOtherDocumentsRight {...props}/>
                  </Default>
                </Switch>
              </Case>
              <Case value={claimTypesConstants.GENERAL}>
                <GeneralDocumentsRight {...props} />
              </Case>
            </Switch>
          </LightgalleryProvider>
        </SplitViewContainer.Right>
      </UploadContainer>
    );
  }

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right}/>
    );
  }
}
