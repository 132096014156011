import React, {Component} from "react";

import Layout from "components/common/Layout";
import AutoCompleteField from "components/form/AutoCompleteField";
import {RegistryContext} from "containers/context/RegistyContainer";

export default class VehicleMark extends Component {
  static contextType = RegistryContext;

  fetchVehicleMark = async (value) => {
    return this.context.registry.vehicleMarks.filter(vehicleMark => vehicleMark.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  };

  render() {
    const {name, isRequired = false} = this.props;

    return (
      <InnerVehicleMark name={name} isRequired={isRequired} fetchVehicleMark={this.fetchVehicleMark}/>
    );
  }
}

class InnerVehicleMark extends Component {
  render() {
    const {name, isRequired, fetchVehicleMark} = this.props;

    return (
      <Layout.OneRow flex={1} spacing={8}>
        <AutoCompleteField name={name}
                           placeholder="vehicleMark.placeholder"
                           label="vehicleMark.label"
                           isRequired={isRequired}
                           fetchSuggestions={fetchVehicleMark}
                           getSuggestionValue={value => value ? value.name : ""}/>
      </Layout.OneRow>
    );
  }
}