import React, {Component} from "react";

export const NextStepContext = React.createContext({});

export default class NextStepContainer extends Component {

  enableNextStep = () => {
    this.setState(state => ({
      ...state,
      nextStepEnabled: true,
    }))
  };

  disableNextStep = () => {
    this.setState(state => ({
      ...state,
      nextStepEnabled: false,
    }))
  };

  toggleDisabled = (disabled) => {
    this.setState(state => ({
      ...state,
      disabled,
    }))
  };

  setOnNextStep = (onNextStep) => {
    this.setState(state => ({
      ...state,
      onNextStep,
    }))
  };

  setOnClick = (onClick) => {
    this.setState(state => ({
      ...state,
      onClick,
    }))
  }

  state = {
    nextStepEnabled: true,
    disabled: false,
    onNextStep: null,
    onClick: null,
    enableNextStep: this.enableNextStep,
    disableNextStep: this.disableNextStep,
    toggleDisabled: this.toggleDisabled,
    setOnNextStep: this.setOnNextStep,
    setOnClick: this.setOnClick,
  };

  render() {
    const {children} = this.props;


    return (
      <NextStepContext.Provider value={this.state}>
        {children}
      </NextStepContext.Provider>
    );
  }
}
