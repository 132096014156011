import React, {Component} from 'react';

import {Field} from "formik";
import _ from "lodash";

import PickMarkerMap from "components/map/PickMarkerMap";

export default function PickMarkerMapField({markers, name, ...props}) {
  return (
    <Field name={name}
           render={(fieldProps) => (
             <InnerPickMarkerMapField markers={markers} fieldProps={fieldProps} {...props}/>
           )}/>
  );
}

class InnerPickMarkerMapField extends Component {
  renderMarkerInfo = (marker) => {
    const {fieldProps, markerInfoComponent: Component} = this.props;
    const selected = fieldProps.field.value ? fieldProps.field.value.id === marker.id : false;

    return (
      <Component marker={marker} onClick={this.onClick(marker)} selected={selected} name={fieldProps.field.name}/>
    );
  };

  onCurrentLocationClicked = (fieldProps) => (click) => {
    const {form: {setFieldValue}} = fieldProps;
    const value = {lat: click.lat, lng: click.lng};

    setFieldValue("focusLocation", value);
  };

  onClick = (value) => () => {
    const {fieldProps, onMarkerClick} = this.props;
    const {field: {name, value: fieldValue}, form: {setFieldValue, validateForm, values}} = fieldProps;

    const selected = fieldValue ? fieldValue.id === value.id : false;

    if (selected) {
      setFieldValue(name, null, false);
    } else {
      setFieldValue(name, value, false);
    }

    const newValues = _.set(_.cloneDeep(values), name, selected ? null : value);

    if (onMarkerClick) {
      onMarkerClick(() => {
        validateForm(newValues);
      });
    } else {
      validateForm(newValues);
    }
  };

  focusMarker = (marker) => {
    const {clearSearch} = this.props;
    const {setFieldValue} = this.props.fieldProps.form;

    const value = marker !== null ? {lat: marker.item.x, lng: marker.item.y, id: marker.item.id} : null;

    setFieldValue("focusLocation", value);

    if (clearSearch) {
      clearSearch();
    }
  };

  render() {
    const {markers, center, fieldProps, ...props} = this.props;

    return (
      <PickMarkerMap center={center} markers={markers} renderMarkerInfo={this.renderMarkerInfo} onCurrentLocationClicked={this.onCurrentLocationClicked(fieldProps)} focusMarker={this.focusMarker} {...props}/>
    );
  }
}