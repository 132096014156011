import licencePlateTypeConstants from "constants/licencePlateTypes";

export function getRegistrationMask(rawValue) {

  switch (detectLicencePlateType(rawValue)) {
    case licencePlateTypeConstants.POLICE:
      return [/[pP]/, ' ', /[oO0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
    case licencePlateTypeConstants.MILITARY:
      return [/[sS]/, /[vVgG]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
    case licencePlateTypeConstants.DIPLOMATIC_M:
      return [/[mM]/, ' ', /[bBsS0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
    case licencePlateTypeConstants.DIPLOMATIC_CMD:
      return [/[cC]/, /[mMeE]/, /[dD]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
    case licencePlateTypeConstants.DIPLOMATIC_CD:
      return [/[cC]/, /[dDeE]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
    case licencePlateTypeConstants.CUSTOM:
      return [/[A-Za-z]/, /[A-Za-z0-9]/, ' ', /[A-Za-z0-9_]/, /[A-Za-z0-9_]/, /[A-Za-z0-9_]/, /[A-Za-z0-9_]/, /[A-Za-z0-9_]/, /[A-Za-z0-9_]/];
  }
}

export function detectLicencePlateType(plateString) {
  if (/(^[pP][0-9])|(^[pP] [0-9])/.test(plateString)) {
    return licencePlateTypeConstants.POLICE;
  } else if (/^[sS][vV]/.test(plateString)) {
    return licencePlateTypeConstants.MILITARY;
  } else if (/(^[mM][0-9])|(^[mM] [0-9])/.test(plateString)) {
    return licencePlateTypeConstants.DIPLOMATIC_M;
  } else if (/(^[cC][mM][dD])|(^[cC][mM] [dD])/.test(plateString)) {
    return licencePlateTypeConstants.DIPLOMATIC_CMD;
  } else if (/(^[cC][dD])/.test(plateString)) {
    return licencePlateTypeConstants.DIPLOMATIC_CD;
  }  else {
    return licencePlateTypeConstants.CUSTOM;
  }
}
