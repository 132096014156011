import React, {Component} from "react";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import regexConstants from "constants/regex";
import vehicleInfoInputTypesConstants from "constants/vehicleInfoInputTypes";
import Header from "containers/step/Header";
import HomeVehicleInfoRight from "containers/step/homeVehicleInfo/HomeVehicleInfoRight";
import SplitViewContainer from "containers/view/SplitViewContainer";
import Yup, {getMax} from "selectors/yup";
import isUndefinedOrNull from "utility/utilityFunctions";

const validationSchema = Yup.object().shape({
  vehicleInfo: Yup.object().shape({
    inSlovenia: Yup.object()
      .when(["inputType", "vehicleData"], {
        is: (inputType, vehicleData) => inputType === vehicleInfoInputTypesConstants.REGISTERED_IN_SLOVENIA && (isUndefinedOrNull(vehicleData) || vehicleData.success),
        then: Yup.object().shape({
          registrationNumber: getMax(Yup.string().typeError("participantsInfo.validation.vehicleData").ensure(), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].registrationNumber),
        }),
      })
      .when(["inputType", "vehicleData"], {
        is: (inputType, vehicleData) => inputType === vehicleInfoInputTypesConstants.REGISTERED_IN_SLOVENIA && vehicleData && !vehicleData.success,
        then: Yup.object().shape({
          registrationNumber: getMax(Yup.string().typeError("participantsInfo.validation.vehicleData").ensure().matches(regexConstants.REGISTRATION, "participantsInfo.validation.vehicleData"), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].registrationNumber),
          vehicleMark: Yup.string(),
          vehicleType: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].vehicleType),
        }),
      }),
    vehicleData: Yup.mixed().when("inputType", {
      is: vehicleInfoInputTypesConstants.REGISTERED_IN_SLOVENIA,
      then: Yup.mixed(),
    }),
    company: Yup.object().when("inputType", {
      is: vehicleInfoInputTypesConstants.COMPANY_VEHICLE,
      then: Yup.object().shape({
        vehicleChassisNumber: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].vehicleChassisNumber),
        vehicleMark: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].vehicleMark),
      }),
    }),
    abroad: Yup.object().when("inputType", {
      is: vehicleInfoInputTypesConstants.REGISTERED_ABROAD,
      then: Yup.object().shape({
        registrationNumber: getMax(Yup.string().nullable(), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].registrationNumber),
        vehicleMark: Yup.string(),
        vehicleType: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].vehicleType),
      }),
    }),
    notRegistered: Yup.object().when("inputType", {
      is: vehicleInfoInputTypesConstants.NOT_REGISTERED,
      then: Yup.object().shape({
        vehicleDescription: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].vehicleDescription),
      }),
    }),
    inputType: Yup.string()
  }),
});

export default class HomeVehicleInfoStepContainer extends Component {
  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <div><Text element="h2" label="homeVehicleInfo.sidebar.title"/></div>
        <div><Text element="div" label="homeVehicleInfo.sidebar.subtitle"/></div>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <HomeVehicleInfoRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>
    );
  }
}
