import {countBy, find, identity, isUndefined, min, negate, values} from "underscore";

import booleanTypesConstants from "constants/booleanTypes";
import participantsColorsConstants from "constants/participantsColors";
import vehicleTypesConstants from "constants/vehicleTypes";

function getUsedColors(participants) {
  const participantsColors = participants.map(participant => participant.color).filter(negate(isUndefined));
  const usedColors = [...participantsColorsConstants, participantsColorsConstants[0], ...participantsColors];

  return countBy(usedColors, identity);
}

function determineNextColor(usedColors) {
  const minCount = min(values(usedColors));
  return find(participantsColorsConstants, color => usedColors[color] === minCount);
}

export function transformParticipantsNumber(participants) {
  const result = [];

  if (!participants) {
    return [];
  }

  participants.forEach(participant => {
    const resultParticipant = {...participant};

    if (isUndefined(resultParticipant.color) && isUndefined(resultParticipant.name)) {
      resultParticipant.color = determineNextColor(getUsedColors(result));
      resultParticipant.name = String.fromCharCode("A".charCodeAt(0) + participant.index);
    }

    resultParticipant.isVehicle = [
      vehicleTypesConstants.CAR, vehicleTypesConstants.SEMI_TRUCK, vehicleTypesConstants.MOTORCYCLE,
      vehicleTypesConstants.SCOOTER, vehicleTypesConstants.TRACTOR
    ].indexOf(resultParticipant.type) !== -1;

    result.push(resultParticipant);
  });

  return result;
}

export default function calculateFinalParticipants(vehicleType, participants, multipleParticipants) {
  const finalParticipants = multipleParticipants === booleanTypesConstants.YES ? participants : [];

  return [
    ownVehicle(vehicleType),
    ...finalParticipants,
  ]
}

export function ownVehicle(vehicleType) {
  return {
    type: vehicleType,
    index: 0,
    name: "A",
    color: participantsColorsConstants[0],
    isOwnVehicle: true,
    isVehicle: !(vehicleType === vehicleTypesConstants.BIKE),
  };
}
