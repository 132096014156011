import React from "react";

import _ from "lodash";
import {isEmpty} from "underscore";

import Text from "components/common/Text";
import flowConstants from "constants/flow";
import summaryRefConstants from "constants/summaryRef";
import Summary from "containers/step/summary/Summary";
import {locationAddress} from "selectors/summary";
import repairServiceTypes from "constants/repairServiceTypes";
import isUndefinedOrNull from "utility/utilityFunctions";

export default function InspectionAndRepair({repairServiceType}) {

  const formName = flowConstants.steps.VEHICLE_DAMAGE_INSPECTION_POINT.NAME;

  return (
    <Summary title={`summary.content.${repairServiceType}.title`} onePart={true}
             refName={summaryRefConstants.INSPECTION_AND_REPAIR.REF}>

      <Summary.ItemValue label="summary.content.repairServiceType.label"
                         refName={summaryRefConstants.MAPS_INTRO}
                         formName={{name: flowConstants.steps.MAPS_INTRO.NAME}}>
        {form => <Text label={`summary.content.repairServiceType.value.${form.repairServiceType}.label`}/>}
      </Summary.ItemValue>

      <Summary.ItemValue label={`summary.content.${repairServiceType}.name`}
                         formName={{name: formName}}
                         refName={summaryRefConstants.INSPECTION_AND_REPAIR.NAME}>
        {form => (
            <VehicleDamageInspectionPointName form={form}/>
        )}
      </Summary.ItemValue>

      <Summary.ItemValue label={`summary.content.${repairServiceType}.address`}
                         formName={{name: formName}}
                         refName={summaryRefConstants.INSPECTION_AND_REPAIR.LOCATION}>
        {form => (
            <VehicleDamageInspectionPointAddress form={form}/>
        )}
      </Summary.ItemValue>

      <Summary.ItemValue label={`summary.content.${repairServiceType}.phoneNumber`}
                         formName={{name: formName}}
                         condition={(form) => !isEmpty(form.phoneNumber)}
                         refName={summaryRefConstants.INSPECTION_AND_REPAIR.LOCATION}>
        {form => form.phoneNumber}
      </Summary.ItemValue>

      <Summary.ItemValue label={`summary.content.${repairServiceType}.email`}
                         formName={{name: formName}}
                         condition={(form) => !isEmpty(form.email)}
                         refName={summaryRefConstants.INSPECTION_AND_REPAIR.LOCATION}>
        {form => form.email}
      </Summary.ItemValue>

      <Summary.ItemValue label={`summary.content.${repairServiceType}.url`}
                         formName={{name: formName}}
                         condition={(form) => !isEmpty(form.url)}
                         refName={summaryRefConstants.INSPECTION_AND_REPAIR.LOCATION}>
        {form => form.url}
      </Summary.ItemValue>

    </Summary>

  );
}

function VehicleDamageInspectionPointName({form}) {
  return (
    isEmpty(form.name[0])
      ? <Text label="summary.content.common.boolean.dontKnow"/>
      : form.name
  )
}

function VehicleDamageInspectionPointAddress({form}) {
  return (
    isEmpty(form.address)
      ? <Text label="summary.content.common.boolean.dontKnow"/>
      : form.address
  )
}