import React from "react";
import ReactDOMServer from "react-dom/server";

import {isUndefined} from "underscore";

import SketchCanvas from "components/sketch/canvas/SketchCanvas";
import booleanTypeConstants from "constants/booleanTypes";
import vehicleDamageConstants from "constants/vehicleDamage";

import PersonSvg from "components/svg/PersonSvg";
import VehicleIconSvg from "components/svg/VehicleIconSvg";

export function getParticipantSvg(participant) {
  return !isUndefined(participant)
    ? ReactDOMServer.renderToStaticMarkup(
      <VehicleIconSvg xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      value={{
                        type: participant.type,
                        name: participant.name,
                        color: participant.color,
                      }}
      />)
    : null;
}

export function getDrawingSvg(drawingIntro, drawing) {
  return !isUndefined(drawingIntro) && (drawingIntro.wantDrawing === booleanTypeConstants.YES)
    ? ReactDOMServer.renderToStaticMarkup(<SketchCanvas crossRoad={drawing.sketch.crossRoad}
                                                        items={drawing.sketch.items}
                                                        dimensions={{width: 5000, height: 5000}} isStatic={true}/>)
    : null;
}

export function getVehicleDamageSvg(vehicleType, vehicleDamageCurrent, vehicleDamagePrevious) {
  const {finalVehicleType} = vehicleType;

  if (isUndefined(vehicleDamageCurrent) || isUndefined(vehicleDamageCurrent[vehicleDamageConstants.FIELD_MAPPING[finalVehicleType]])) {
    return [];
  }

  const VehicleDamageComponent = vehicleDamageConstants.COMPONENT_MAPPING[finalVehicleType];

  if (isUndefined(vehicleDamagePrevious)) {
    return [
      ReactDOMServer.renderToString(<VehicleDamageComponent
        value={vehicleDamageCurrent[vehicleDamageConstants.FIELD_MAPPING[finalVehicleType]] || []}/>),
      ReactDOMServer.renderToString(<VehicleDamageComponent
        value={[]}/>)
    ];
  }

  return [
    ReactDOMServer.renderToString(<VehicleDamageComponent
      value={vehicleDamageCurrent[vehicleDamageConstants.FIELD_MAPPING[finalVehicleType]] || []}/>),
    ReactDOMServer.renderToString(<VehicleDamageComponent
      value={vehicleDamagePrevious[vehicleDamageConstants.FIELD_MAPPING[finalVehicleType]] || []}/>)
  ];

}

export function getPersonSvg(personDamage) {
  if (isUndefined(personDamage) || isUndefined(personDamage.personDamage)) {
    return [];
  }

  return [
    ReactDOMServer.renderToString(<PersonSvg value={personDamage.personDamage || []}/>)
  ]
}
