import React, {Component} from "react";

import axios from "axios";
import {connect} from "formik";
import _ from "lodash";
import {isEmpty} from "underscore";

import Layout from "components/common/Layout";
import AutoCompleteField from "components/form/AutoCompleteField";
import CompositeField from "components/form/CompositeField";
import TextField from "components/form/TextField";
import Country from "components/step/Country";
import fieldLengthConstants from "constants/fieldLength";
import {ConfigContext} from "containers/context/ConfigContainer";
import {RegistryContext} from "containers/context/RegistyContainer";
import {capitalize} from "utility/textTransform";
import isUndefinedOrNull from "utility/utilityFunctions";

export default class Address extends Component {
  static contextType = ConfigContext;

  fetchZip = async (value) => {
    const response = await axios.get(this.context.url.zipCodes, {params: {term: value}});
    return response.data;
  };

  fetchCity = async (value) => {
    const response = await axios.get(this.context.url.cities, {params: {term: value}});
    return response.data;
  };

  fetchStreet = zip => async (value) => {
    const response = await axios.get(this.context.url.streets, {params: {term: value, zipCode: zip}});
    return response.data;
  };

  render() {
    return (
      <RegistryContext.Consumer>
        {registryContext => (
          <InnerAddress {...this.props} fetchZip={this.fetchZip} fetchCity={this.fetchCity}
                        fetchStreet={this.fetchStreet} slovenia={registryContext.registry.slovenia}/>
        )}
      </RegistryContext.Consumer>
    );
  }
}

class InnerAddress extends Component {
  setCountry = (value) => {
    const {formik: {setFieldValue, values}, slovenia, name} = this.props;
    const sloveniaSelected = !isUndefinedOrNull(value) && value.code === slovenia.code;
    const previousCountrySlovenia = _.at(values, `${name}.country.code`)[0] === slovenia.code;

    setFieldValue(`${name}.country`, value);
    if (sloveniaSelected !== previousCountrySlovenia) {
      setFieldValue(`${name}.city`, {zip: "", city: ""});
      setFieldValue(`${name}.address`, {street: "", streetNumber: ""});
    }
  };

  componentDidMount() {
    const {formik: {setFieldValue, values}, slovenia, name} = this.props;

    if (isUndefinedOrNull(_.at(values, `${name}.country`)[0])) {
      setTimeout(() => {
        setFieldValue(`${name}.country`, slovenia);
        setFieldValue(`${name}.countryName`, slovenia.name);
      }, 0);
    }
  }

  render() {
    const {addressLabels, formik: {values}, name, slovenia, variant} = this.props;

    const country = _.at(values, `${name}.country.code`)[0];
    const sloveniaSelected = country === slovenia.code;
    const label = addressLabels && addressLabels.country ? addressLabels.country : null;

    return (
      <>
        <Country name={`${name}.countryName`}
                 label={label}
                 setCountry={this.setCountry}
                 variant={variant}/>
        <Layout.OneColumn spacing={10}/>
        {
          sloveniaSelected
            ? <SlovenianAddress {...this.props} disabled={isEmpty(country)}/>
            : <ForeignAddress {...this.props} disabled={isEmpty(country)}/>
        }
      </>
    );
  }
}

class SlovenianAddress extends Component {
  setCity = (value) => {
    const {formik, name} = this.props;

    if (value) {
      formik.setFieldValue(`${name}.city`, {zip: value.code, city: value.name});
    } else {
      formik.setFieldValue(`${name}.city`, {zip: "", city: ""});
    }

    formik.setFieldValue(`${name}.address`, {street: "", streetNumber: ""});
  };

  setStreet = (value) => {
    const {formik, name} = this.props;
    if (value) {
      formik.setFieldValue(`${name}.address.street`, value);
      formik.setFieldValue(`${name}.address.streetName`, value.name);
    } else {
      formik.setFieldValue(`${name}.address.street`, null);
      formik.setFieldValue(`${name}.address.streetName`, "");
    }
  };

  render() {
    const {
      addressLabels,
      fetchZip,
      fetchCity,
      fetchStreet,
      name,
      isRequired,
      variant,
      disabled,
      formik: {values}
    } = this.props;
    const cityLabel = addressLabels && addressLabels.city ? addressLabels.city : "address.label.city";

    return (
      <>
        <CompositeField name={`${name}.city`}
                        label={cityLabel}
                        variant={variant}>
          <Layout.OneRow flex={1} spacing={5}>
            <CompositeField.AutoCompleteField
              name="city"
              placeholder="address.city.placeholder.city"
              isRequired={isRequired}
              onSuggestionSelected={this.setCity}
              fetchSuggestions={fetchCity}
              renderSuggestionValue={value => value.name}
              disabled={disabled}
              transform={capitalize}
              variant={variant}
              maxLength={fieldLengthConstants.address.city + 1}/>

            <CompositeField.AutoCompleteField
              name="zip"
              placeholder="address.city.placeholder.zip"
              isRequired={isRequired}
              onSuggestionSelected={this.setCity}
              fetchSuggestions={fetchZip}
              renderSuggestionValue={value => value.code}
              disabled={disabled}
              variant={variant}
              maxLength={fieldLengthConstants.address.zip + 1}/>
          </Layout.OneRow>
        </CompositeField>
        <Layout.OneColumn spacing={10}/>
        <Layout.OneColumn width="100%">
          <Layout.OneRow flex={1} spacing={5}>
            <AutoCompleteField
              name={`${name}.address.streetName`}
              label="address.address.label.street"
              placeholder="address.address.placeholder.street"
              isRequired={isRequired}
              transform={capitalize}
              onSuggestionSelected={this.setStreet}
              fetchSuggestions={fetchStreet(_.at(values, `${name}.city.zip`)[0])}
              renderSuggestionValue={value => value ? value.name : ""}
              disabled={disabled || isEmpty(_.at(values, `${name}.city.zip`)[0])}
              variant={variant}
              maxLength={fieldLengthConstants.address.streetName + 1}/>

            <TextField
              name={`${name}.address.streetNumber`}
              label="address.address.label.number"
              placeholder="address.address.placeholder.number"
              isRequired={isRequired}
              disabled={disabled || isEmpty(_.at(values, `${name}.city.zip`)[0])}
              variant={variant}
              maxLength={fieldLengthConstants.address.streetNumber + 1}/>
          </Layout.OneRow>
        </Layout.OneColumn>
      </>
    )
  }
}

class ForeignAddress extends Component {
  setStreet = (value) => {
    const {formik, name} = this.props;

    formik.setFieldValue(`${name}.address.street.name`, value);
  };

  render() {
    const {addressLabels, name, isRequired, variant, disabled} = this.props;
    const cityLabel = addressLabels && addressLabels.city ? addressLabels.city : "address.label.city";

    return (
      <>
        <CompositeField name={`${name}.city`}
                        label={cityLabel}
                        variant={variant}>
          <Layout.OneRow flex={1} spacing={5}>
            <CompositeField.TextField
              name="city"
              placeholder="address.city.placeholder.city"
              isRequired={isRequired}
              disabled={disabled}
              variant={variant}
              transform={capitalize}
              maxLength={fieldLengthConstants.address.city + 1}/>

            <CompositeField.TextField
              name="zip"
              placeholder="address.city.placeholder.zip"
              isRequired={isRequired}
              disabled={disabled}
              variant={variant}
              maxLength={fieldLengthConstants.address.zip + 1}/>
          </Layout.OneRow>
        </CompositeField>
        <Layout.OneColumn spacing={5}></Layout.OneColumn>
        <Layout.OneColumn width="100%">
          <Layout.OneRow flex={1} spacing={5}>
            <TextField
              name={`${name}.address.streetName`}
              label="address.address.label.street"
              placeholder="address.address.placeholder.street"
              isRequired={isRequired}
              disabled={disabled}
              variant={variant}
              maxLength={fieldLengthConstants.address.streetName + 1}
              transform={capitalize}
              onValueChange={this.setStreet}/>

            <TextField
              name={`${name}.address.streetNumber`}
              label="address.address.label.number"
              placeholder="address.address.placeholder.number"
              isRequired={isRequired}
              disabled={disabled}
              variant={variant}
              maxLength={fieldLengthConstants.address.streetNumber + 1}/>
          </Layout.OneRow>
        </Layout.OneColumn>
      </>
    )
  }
}

InnerAddress = connect(InnerAddress);
