import React from "react";

import Box from "components/common/Box";
import Layout from "components/common/Layout";
import Text from "components/common/Text";

export default function BankAccountRepairShop({selectedRepairShop}) {
  return (
    <Layout spacing={10} width="100%" maxWidth={450} justifyContent="center">
      <Layout.OneColumn spacing={25}/>
      <Layout.OneColumn>
        <Text element="div" label="bankAccounts.content.repairShop.title" variant="variant2" />
      </Layout.OneColumn>
      <Layout.OneColumn>
        <Box variant="variant1">
          <Text element="span" variant="variant33">{selectedRepairShop.name}</Text>
          <Text element="span" variant="variant34" label={selectedRepairShop.address}></Text>
        </Box>
      </Layout.OneColumn>
    </Layout>
  );
}

