import React, {Component} from "react";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import medicalCareTypes from "constants/medicalCareTypes";
import Header from "containers/step/Header";
import MedicalCareTypeRight from "containers/step/medicalCareType/MedicalCareTypeRight";
import SplitViewContainer from "containers/view/SplitViewContainer";
import Yup from "selectors/yup";

const validationSchema = Yup.object().shape({
  medicalCareType: Yup.string().required("validation.required"),
  medicalCareTypeDescription: Yup.object().when("medicalCareType", {
    is: medicalCareTypes.PAYMENT || medicalCareTypes.HEALTH_SERVICE,
    then: Yup.string().required("validation.required"),
    otherwise: Yup.string().nullable(),
  }),
});

export default class MedicalCareTypeStepContainer extends Component {

  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="medicalCareType.sidebar.title"/>
        <Text element="div" label="medicalCareType.sidebar.subtitle"/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {

    return (
      <SplitViewContainer.Right {...props} showNextStepButton={false}>
        <MedicalCareTypeRight props={props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>
    );
  }
}
