import React from "react";

import SketchCanvasItem from "components/sketch/item/SketchCanvasItem";
import SketchCrossRoad from "components/sketch/canvas/SketchCrossRoad";
import SketchSvg from "components/sketch/canvas/SketchSvg";

import styles from "components/sketch/canvas/SketchCanvas.module.scss";

export default function SketchCanvas({crossRoad, items = [], dimensions, selected, onChoose, onResetSelected, isOver, canDrop, isReadOnly, isStatic = false}) {
  if (!dimensions) {
    return null;
  }

  const children = items.map((item, index) => (
    <SketchCanvasItem key={item.index} item={item} onClick={onChoose} selected={item.index === selected} draggable={!isStatic && item.draggable !== false}/>
  ));

  return (
    <SketchSvg dimensions={dimensions} useCanvasDimensions={!isReadOnly}>
      {!isStatic ? <Overlay isOver={isOver} canDrop={canDrop} onResetSelected={onResetSelected} /> : null}
      <SketchCrossRoad name={crossRoad}/>
      {children}
    </SketchSvg>
  );
}

function Overlay({isOver, canDrop, onResetSelected}) {
  return (
    <rect width="100%" height="100%"
          className={styles.root}
          fill={"rgb(0, 168, 246)"}
          fillOpacity={isOver && canDrop ? 0.2 : 0}
          onClick={onResetSelected}/>
  );
}
