import {isUndefined, max, min} from "underscore";

import sketchConstants from "constants/sketch";

export function findImage(name) {
  for (const outerKey of sketchConstants.DROP_KEYS) {
    for (const innerKey of Object.keys(sketchConstants[outerKey])) {
      if (sketchConstants[outerKey][innerKey].NAME === name) {
        return sketchConstants[outerKey][innerKey];
      }
    }
  }

  return null;
}

export function getItemDimension(item) {
  switch (item.type) {
    case sketchConstants.TYPES.ITEM:
      return sketchConstants.ITEM_DIMENSION;
    case sketchConstants.TYPES.PARTICIPANT:
      return sketchConstants.PARTICIPANT_DIMENSION;
    case sketchConstants.TYPES.TEXT:
      return sketchConstants.TEXT_DIMENSION;
    case sketchConstants.TYPES.DRAW:
      return {
        SOURCE_WIDTH: item.width, SOURCE_HEIGHT: item.height,
        TARGET_WIDTH: item.width, TARGET_HEIGHT: item.height,
      };
    case sketchConstants.TYPES.ERASER:
      return {
        SOURCE_WIDTH: item.width, SOURCE_HEIGHT: item.height,
        TARGET_WIDTH: item.width, TARGET_HEIGHT: item.height,
      };
    default:
      return {};
  }
}

export function getCanvasItemPosition(offset, initialOffset, item, dimensions, shouldSnap = true) {
  if (!offset || !item) {
    return {x: 0, y: 0};
  }

  const itemDimension = getItemDimension(item);

  const projectedInitialOffset = projectToGlobal(initialOffset, dimensions);
  const projectedOffset = projectToGlobal(offset, dimensions);

  const position = {
    x: projectedOffset.x - (isUndefined(item.x) ? 0 : (projectedInitialOffset.x - item.x)),
    y: projectedOffset.y - (isUndefined(item.y) ? 0 : (projectedInitialOffset.y - item.y)),
  };

  return shouldSnap ? snap(position, itemDimension) : position;
}

function projectToGlobal(offset, dimensions) {
  const {width, height} = dimensions;
  const minDimension = Math.min(width, height);
  const offsetWidth = (width - minDimension) / 2;
  const offsetHeight = (height - minDimension) / 2;

  return {
    x: (offset.x - offsetWidth) * sketchConstants.DIMENSIONS.CANVAS.WIDTH / minDimension,
    y: (offset.y - offsetHeight) * sketchConstants.DIMENSIONS.CANVAS.HEIGHT / minDimension,
  };
}

function snap(position, itemDimension) {
  function helper(coordinate, dimension, canvasDimension) {
    return Math.max(
      dimension / 2,
      Math.min(
        canvasDimension - dimension / 2,
        coordinate
      )
    );
  }

  return {
    x: helper(position.x, itemDimension.TARGET_WIDTH, sketchConstants.DIMENSIONS.CANVAS.WIDTH),
    y: helper(position.y, itemDimension.TARGET_HEIGHT, sketchConstants.DIMENSIONS.CANVAS.HEIGHT),
  };
}

export function minScale(outerWidth, outerHeight, innerWidth, innerHeight) {
  const scaleX = outerWidth / innerWidth;
  const scaleY = outerHeight / innerHeight;
  return Math.min(scaleX, scaleY);
}

export function translate(outerWidth, outerHeight, innerWidth, innerHeight) {
  const translateX = (outerWidth - innerWidth) / 2;
  const translateY = (outerHeight - innerHeight) / 2;

  return [translateX, translateY];
}

export function svgTranslate(outerWidth, outerHeight, innerWidth, innerHeight) {
  const resultScale = minScale(outerWidth, outerHeight, innerWidth, innerHeight);
  const scaledWidth = innerWidth * resultScale;
  const scaledHeight = innerHeight * resultScale;
  const translateX = (outerWidth - scaledWidth) / 2;
  const translateY = (outerHeight - scaledHeight) / 2;

  return [translateX, translateY];
}

export function transform(outerWidth, outerHeight, innerWidth, innerHeight) {
  const resultScale = minScale(outerWidth, outerHeight, innerWidth, innerHeight);
  const [resultTranslateX, resultTranslateY] = translate(outerWidth, outerHeight, innerWidth, innerHeight);

  return `scale(${resultScale}) translate(${resultTranslateX}px, ${resultTranslateY}px)`;
}

export function svgTransform(outerWidth, outerHeight, innerWidth, innerHeight) {
  const resultScale = minScale(outerWidth, outerHeight, innerWidth, innerHeight);
  const [resultTranslateX, resultTranslateY] = svgTranslate(outerWidth, outerHeight, innerWidth, innerHeight);

  return `translate(${resultTranslateX} ${resultTranslateY}) scale(${resultScale})`;
}

export function calculateLine(points, type = sketchConstants.TYPES.DRAW) {
  const minX = min(points, point => point[0])[0];
  const maxX = max(points, point => point[0])[0];
  const minY = min(points, point => point[1])[1];
  const maxY = max(points, point => point[1])[1];

  const width = Math.max(5, maxX - minX);
  const height = Math.max(5, maxY - minY);

  const halfWidth = Math.round(width / 2);
  const halfHeight = Math.round(height / 2);

  const x = minX + halfWidth;
  const y = minY + halfHeight;

  const transformed = points.map(point => [point[0] - minX, point[1] - minY]);

  return {
    type,
    draggable: type === sketchConstants.TYPES.DRAW,
    value: transformed,
    x, y,
    width, height,
  };
}
