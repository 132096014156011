import React from "react"

import Switch, {Case} from "react-switch-case";

import flowConstants from "constants/flow";
import AccidentDateStepContainer from "containers/step/accidentDate/AccidentDateStepContainer";
import AccidentLocationStepContainer from "containers/step/accidentLocation/AccidentLocationStepContainer";
import AccidentOccurrenceTypeStepContainer
  from "containers/step/accidentOccurrenceType/AccidentOccurrenceTypeStepContainer";
import AccidentTimeStepContainer from "containers/step/accidentTime/AccidentTimeStepContainer";
import AccidentVehicleInfoStepContainer from "containers/step/accidentVehicleInfo/AccidentVehicleInfoStepContainer";
import ApplicantDataStepContainer from "containers/step/applicantData/ApplicantDataStepContainer";
import BankAccountsStepContainer from "containers/step/bankAccounts/BankAccountsStepContainer";
import BeneficiaryPersonsStepContainer from "containers/step/beneficiaryPersons/BeneficiaryPersonsStepContainer";
import CauseOfDeathStepContainer from "containers/step/causeOfDeath/CauseOfDeathStepContainer";
import ClaimCompensationPersonStepContainer
  from "containers/step/claimCompensationPerson/ClaimCompensationPersonStepContainer";
import ClaimCompensationVehicleStepContainer
  from "containers/step/claimCompensationVehicle/ClaimCompensationVehicleStepContainer";
import CondolencesStepContainer from "containers/step/condolences/CondolencesStepContainer";
import DamageDescriptionStepContainer from "containers/step/damageDescription/DamageDescriptionStepContainer";
import DamagesClaimFiledStepContainer from "containers/step/damagesClaimFiled/DamagesClaimFiledStepContainer";
import DeceasedPersonInformationStepContainer
  from "containers/step/deceasedPersonInformation/DeceasedPersonInformationStepContainer";
import DocumentsStepContainer from "containers/step/documents/DocumentsStepContainer";
import EventDescriptionStepContainer from "containers/step/eventDescription/EventDescriptionStepContainer";
import HadOperationStepContainer from "containers/step/hadOperation/HadOperationStepContainer";
import InjuredPersonWorkingStatusStepContainer
  from "containers/step/injuredPersonWorkingStatus/InjuredPersonWorkingStatusStepContainer";
import InsuranceOwnerStepContainer from "containers/step/insuranceOwner/InsuranceOwnerStepContainer";
import LifeInsuranceClaimStepContainer from "containers/step/lifeInsuranceClaim/LifeInsuranceClaimStepContainer";
import MedicalCareTypeStepContainer from "containers/step/medicalCareType/MedicalCareTypeStepContainer";
import MedicalOperationNeededStepContainer
  from "containers/step/medicalOperationNeeded/MedicalOperationNeededStepContainer";
import MedicalProblemDescriptionStepContainer
  from "containers/step/medicalProblemDescription/MedicalProblemDescriptionStepContainer";
import OtherParticipantsInfoStepContainer from "containers/step/otherParticipantsInfo/OtherParticipantsInfoStepContainer";
import OwnVehicleInfoWasDriverStepContainer from "containers/step/ownVehicleInfo/OwnVehicleInfoWasDriverStepContainer";
import ParticipantsInfoStepContainer from "containers/step/participantsInfo/ParticipantsInfoStepContainer";
import ParticipantsNumberStepContainer from "containers/step/participantsNumber/ParticipantsNumberStepContainer";
import PastMedicalConditionsStepContainer
  from "containers/step/pastMedicalConditions/PastMedicalConditionsStepContainer";
import PersonDamageStepContainer from "containers/step/personDamage/PersonDamageStepContainer";
import PersonInsuranceStepContainer from "containers/step/personInsurance/PersonInsuranceStepContainer";
import PersonReportedPoliceStepContainer from "containers/step/personReportedPolice/PersonReportedPoliceStepContainer";
import PersonResponsibleStepContainer from "containers/step/personResponsible/PersonResponsibleStepContainer";
import PhoneNumberStepContainer from "containers/step/phoneNumber/PhoneNumberStepContainer";
import PlaceOfDeathStepContainer from "containers/step/placeOfDeath/PlaceOfDeathStepContainer";
import ReferralNumbersStepContainer from "containers/step/referralNumbers/ReferralNumbersStepContainer";
import ResponsiblePersonStepContainer from "containers/step/responsiblePerson/ResponsiblePersonStepContainer";
import ResponsiblePersonRelationStepContainer
  from "containers/step/responsiblePersonRelation/ResponsiblePersonRelationStepContainer";
import ResponsiblePersonTypeStepContainer from "containers/step/responsiblePersonType/ResponsiblePersonTypeStepContainer";
import SicknessDescriptionStepContainer from "containers/step/sicknessDescription/SicknessDescriptionStepContainer";
import SufferedEventTypeStepContainer from "containers/step/sufferedEventType/SufferedEventTypeStepContainer";
import SufferedMedicalCostsStepContainer from "containers/step/sufferedMedicalCosts/SufferedMedicalCostsStepContainer";
import SummaryStepContainer from "containers/step/summary/SummaryStepContainer";
import ThankYouStepContainer from "containers/step/thankYou/ThankYouStepContainer";
import UserIdentificationStepContainer from "containers/step/userIdentification/UserIdentificationStepContainer";
import UserIdentificationTypeStepContainer
  from "containers/step/userIdentificationType/UserIdentificationTypeStepContainer";
import UserLoginStepContainer from "containers/step/userLogin/UserLoginStepContainer";
import WitnessesPresentStepContainer from "containers/step/witnessesPresent/WitnessesPresentStepContainer";
import AccidentInvolvedStepContainer from "./step/accidentInvolved/AccidentInvolvedStepContainer";

export default function StateRouterPersonContainer({step}) {
  return (
    <Switch condition={step.name}>
      <Case value={flowConstants.personSteps.MEDICAL_CARE_TYPE.NAME}>
        <MedicalCareTypeStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.CONDOLENCES.NAME}>
        <CondolencesStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.SUFFERED_MEDICAL_COSTS.NAME}>
        <SufferedMedicalCostsStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.MEDICAL_OPERATION_NEEDED.NAME}>
        <MedicalOperationNeededStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.MEDICAL_PROBLEM_DESCRIPTION.NAME}>
        <MedicalProblemDescriptionStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.PAST_MEDICAL_CONDITIONS.NAME}>
        <PastMedicalConditionsStepContainer/>
      </Case>
      <Case value={flowConstants.steps.ACCIDENT_DATE.NAME}>
        <AccidentDateStepContainer/>
      </Case>
      <Case value={flowConstants.steps.ACCIDENT_TIME.NAME}>
        <AccidentTimeStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.SICKNESS_DESCRIPTION.NAME}>
        <SicknessDescriptionStepContainer/>
      </Case>
      <Case value={flowConstants.steps.ACCIDENT_LOCATION.NAME}>
        <AccidentLocationStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.PLACE_OF_DEATH.NAME}>
        <PlaceOfDeathStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.PERSON_DAMAGE.NAME}>
        <PersonDamageStepContainer/>
      </Case>
      <Case value={flowConstants.steps.EVENT_DESCRIPTION.NAME}>
        <EventDescriptionStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.ACCIDENT_OCCURRENCE_TYPE.NAME}>
        <AccidentOccurrenceTypeStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.CAUSE_OF_DEATH.NAME}>
        <CauseOfDeathStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.PERSON_REPORTED_POLICE.NAME}>
        <PersonReportedPoliceStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.WITNESSES_PRESENT.NAME}>
        <WitnessesPresentStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.HAD_OPERATION.NAME}>
        <HadOperationStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.INJURED_PERSON_WORKING_STATUS.NAME}>
        <InjuredPersonWorkingStatusStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.PERSON_INSURANCE.NAME}>
        <PersonInsuranceStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.PERSON_RESPONSIBLE.NAME}>
        <PersonResponsibleStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.CLAIM_COMPENSATION_VEHICLE.NAME}>
        <ClaimCompensationVehicleStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.CLAIM_COMPENSATION_PERSON.NAME}>
        <ClaimCompensationPersonStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME}>
        <ResponsiblePersonTypeStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.RESPONSIBLE_PERSON_RELATION.NAME}>
        <ResponsiblePersonRelationStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.SUFFERED_EVENT_TYPE.NAME}>
        <SufferedEventTypeStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.DAMAGE_DESCRIPTION.NAME}>
        <DamageDescriptionStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.DAMAGES_CLAIM_FILED.NAME}>
        <DamagesClaimFiledStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.ACCIDENT_INVOLVED.NAME}>
        <AccidentInvolvedStepContainer/>
      </Case>
      <Case value={flowConstants.steps.PARTICIPANTS_NUMBER.NAME}>
        <ParticipantsNumberStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.PERSON_PARTICIPANTS_INFO.NAME}>
        <ParticipantsInfoStepContainer/>
      </Case>
      <Case value={flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME}>
        <OwnVehicleInfoWasDriverStepContainer/>
      </Case>
      <Case value={flowConstants.steps.OTHER_PARTICIPANTS_INFO.NAME}>
        <OtherParticipantsInfoStepContainer/>
      </Case>
      <Case value={flowConstants.steps.RESPONSIBLE_PERSON.NAME}>
        <ResponsiblePersonStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.ACCIDENT_VEHICLE_INFO.NAME}>
        <AccidentVehicleInfoStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.REFERRAL_NUMBERS.NAME}>
        <ReferralNumbersStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.PERSON_DOCUMENTS.NAME}>
        <DocumentsStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.LIFE_INSURANCE_CLAIM.NAME}>
        <LifeInsuranceClaimStepContainer/>
      </Case>
      <Case value={flowConstants.steps.INSURANCE_OWNER.NAME}>
        <InsuranceOwnerStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME}>
        <DeceasedPersonInformationStepContainer/>
      </Case>
      <Case value={flowConstants.steps.USER_IDENTIFICATION_TYPE.NAME}>
        <UserIdentificationTypeStepContainer/>
      </Case>
      <Case value={flowConstants.steps.USER_LOGIN.NAME}>
        <UserLoginStepContainer/>
      </Case>
      <Case value={flowConstants.steps.USER_IDENTIFICATION.NAME}>
        <UserIdentificationStepContainer/>
      </Case>
      <Case value={flowConstants.steps.APPLICANT_DATA.NAME}>
        <ApplicantDataStepContainer/>
      </Case>
      <Case value={flowConstants.personSteps.BENEFICIARY_PERSONS.NAME}>
        <BeneficiaryPersonsStepContainer/>
      </Case>
      <Case value={flowConstants.steps.BANK_ACCOUNTS.NAME}>
        <BankAccountsStepContainer/>
      </Case>
      <Case value={flowConstants.steps.SUMMARY.NAME}>
        <SummaryStepContainer/>
      </Case>
      <Case value={flowConstants.steps.PHONE_NUMBER.NAME}>
        <PhoneNumberStepContainer/>
      </Case>
      <Case value={flowConstants.steps.THANK_YOU.NAME}>
        <ThankYouStepContainer/>
      </Case>
    </Switch>
  );
}
