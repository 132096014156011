import React from "react";
import DocumentsIcon from "components/icon/DocumentsIcon";
import SummaryTable from "components/step/summary/SummaryTable";
import flowConstants from "constants/flow";
import Summary from "containers/step/summary/Summary";
import summaryRefConstants from "constants/summaryRef";

export default function SummaryDocumentsGeneral() {
  return (
    <Summary title="summary.content.documents.general.title"
             refName={summaryRefConstants.GENERAL_DOCUMENTS}
             onePart={true}>
      <Summary.Items formName={{name: flowConstants.generalSteps.GENERAL_DOCUMENTS.NAME}}>
        {form => (
          <SummaryTable.ItemContent jumpTo={form.jumpTo}>
            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
              {form.documents.map((document, index) =>
                <DocumentsIcon key={index} id={document.id} type={document.type} name={document.name}/>
              )}
            </div>
          </SummaryTable.ItemContent>
        )}
      </Summary.Items>
    </Summary>
  );
}
