import React, {Component} from "react";

import Button from "components/common/Button";
import NextStepButton from "components/common/NextStepButton";
import Text from "components/common/Text";
import NextStepContainer, {NextStepContext} from "containers/context/NextStepContainer";
import ViewContainer from "containers/view/ViewContainer";

import generaliLogo from "images/generali-logo.svg";

import styles from "containers/step/condolences/CondolencesStepContainer.module.scss";

export default class CondolencesStepContainer extends Component {
  renderChildren = (props) => {
    return (
      <NextStepContainer>
        <div className={styles.buttonContainer}>
          <NextStepButton isValid={true}
                          onNextStep={props.onNextStep}
                          component={Button}
                          buttonProps={{
                            label: "button.next",
                          }}/>
          <NextStepContext.Consumer>
            {() => (
              <Button label="button.back"
                      onClick={props.onPreviousStep}
                      fromSummary={props.fromSummary}/>
            )}
          </NextStepContext.Consumer>
        </div>
        <Text element="span" variant="variant6" label="condolences.content.condolencesTitle.label" isHtml={true}
              className={styles.condolencesTitle}/>
        <Text element="span" variant="variant6" label="condolences.content.condolences.label" isHtml={true}
              className={styles.condolences}/>
        <img className={styles.generaliLogo} src={generaliLogo} alt="logo"/>
      </NextStepContainer>
    );
  };

  render() {
    return (
      <div className={styles.root}>
        <ViewContainer render={this.renderChildren}/>
      </div>
    );
  }
}
