export function capitalize(value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function leaveNumbers(value){
  if(value){
    return value.replace(/[^0-9]/g, "");
  }
}

export function leaveNumbersAndLetters(value){
  if(value){
    return value.replace(/[^A-Za-z0-9]/g, "");
  }
}