import React from "react";
import styles from "./IntroStepContainer.module.scss";
import generaliLogo from "images/generali-logo.svg";
import wizLogo from "images/wiz-logo.svg";
import generaliIntroLogo from "images/intro-generali-logo.svg";
import flowConstants from "constants/flow";

// step 1
export default function IntroStepRight({ company }) {
  const isWiz = company === flowConstants.companies.WIZ;

  return isWiz ? (
    <>
      <img className={styles.logo} src={wizLogo} alt="wiz logo" />
      <img className={styles.generaliLogo} src={generaliIntroLogo} alt="generali logo" />
    </>
  ) : (
    <img className={styles.logo} src={generaliLogo} alt="logo" />
  );
}
