import React, {Component} from "react";

import {Formik} from "formik";
import axios from "axios";

import Button from "components/common/Button";
import Layout from "components/common/Layout";
import Text from "components/common/Text";
import {ConfigContext} from "containers/context/ConfigContainer";

export default class LogOutDialogContent extends Component {
  static contextType = ConfigContext;

  onSubmit = async () => {
    const {onLogOut, counterUser} = this.props;

    const response = await axios.post(this.context.url.logout, {id: counterUser.id});

    if (response.data.success) {
      onLogOut({authenticated: false});
    }
  };

  render() {
    const {counterUser} = this.props;

    return (
      <Formik initialValues={{}} onSubmit={this.onSubmit}>
        {(formProps) => (
          <Layout flex={1}>
            <Layout.OneColumn>
              <Text element="h2" label="logOut.content.header"/>
            </Layout.OneColumn>

            <Layout.OneColumn>
              <Text element="div" isHtml={true} label="logOut.content.message"
                    parameters={[`${counterUser.firstName} ${counterUser.lastName}`]}/>
            </Layout.OneColumn>

            <Layout.OneColumn spacing={15}></Layout.OneColumn>
            <Layout.OneColumn textAlign="right">
              <Button label="logOut.content.button" onClick={() => formProps.submitForm("neki id")}/>
            </Layout.OneColumn>
          </Layout>
        )}
      </Formik>
    );
  }
}