import React, {Component} from "react";

import {connect, Field} from "formik";
import {DragLayer} from "react-dnd";
import {findIndex, isNull, isUndefined, negate} from "underscore";

import SketchNavigation from "components/sketch/navigation/SketchNavigation";
import SketchBottomNavigation from "components/sketch/navigation/SketchBottomNavigation";
import Sketch from "components/sketch/Sketch";
import sketchConstants from "constants/sketch";
import {calculateLine} from "selectors/sketch";

export const SketchContext = React.createContext();

class SketchContainer extends Component {
  render() {
    return (
      <Field name={this.props.name}
             render={fieldProps => <InnerSketchContainer {...this.props} {...fieldProps} />}
      />
    );
  }
}

class InnerSketchContainer extends Component {
  state = {
    name: this.props.field.name,
    onNextStep: this.props.onNextStep,
    onPreviousStep: this.props.onPreviousStep,
    hasPreviousStep: this.props.hasPreviousStep,
  };

  onDrop = (droppedValue) => {
    const {form: {setFieldValue}, field: {name, value = {}}} = this.props;
    const {count = 0, items = []} = value;

    let newItems = [...items], newCount = count;
    if (isUndefined(droppedValue.index)) {
      newCount++;
      newItems.push({...droppedValue, index: count});
    } else {
      const foundIndex = findIndex(items, item => item.index === droppedValue.index);
      delete newItems[foundIndex];
      newItems.push(droppedValue);
    }

    setFieldValue(name, {
      ...value,
      count: newCount,
      items: sortItems(newItems),
      selected: droppedValue.index || count,
    });
  };

  onChoose = (item) => {
    const {form: {setFieldValue}, field: {name, value = {}}} = this.props;

    setFieldValue(name, {
      ...value,
      selected: item.index,
    });
  };

  onResetSelected = (item) => {
    const {form: {setFieldValue}, field: {name, value = {}}} = this.props;

    setFieldValue(name, {
      ...value,
      selected: undefined,
    });
  };

  onRotateRight = () => {
    const {form: {setFieldValue}, field: {name, value = {}}} = this.props;

    if (isUndefined(value.selected)) {
      return;
    }

    const newItems = [...value.items];
    const foundIndex = findIndex(value.items, item => item.index === value.selected);
    newItems[foundIndex] = {
      ...(newItems[foundIndex]),
      rotation: (newItems[foundIndex].rotation || 0) + 45,
    };
    setFieldValue(name, {
      ...value,
      items: newItems,
    });
  };

  onRotateLeft = () => {
    const {form: {setFieldValue}, field: {name, value = {}}} = this.props;

    if (isUndefined(value.selected)) {
      return;
    }

    const newItems = [...value.items];
    const foundIndex = findIndex(value.items, item => item.index === value.selected);
    newItems[foundIndex] = {
      ...(newItems[foundIndex]),
      rotation: (newItems[foundIndex].rotation || 0) - 45,
    };
    setFieldValue(name, {
      ...value,
      items: newItems,
    });
  };

  onFlip = () => {
    const {form: {setFieldValue}, field: {name, value = {}}} = this.props;

    if (isUndefined(value.selected)) {
      return;
    }

    const newItems = [...value.items];
    const foundIndex = findIndex(value.items, item => item.index === value.selected);
    newItems[foundIndex] = {
      ...(newItems[foundIndex]),
      flipped: !(newItems[foundIndex].flipped),
    };
    setFieldValue(name, {
      ...value,
      items: newItems,
    });
  };

  onDelete = () => {
    const {form: {setFieldValue}, field: {name, value = {}}} = this.props;

    if (isUndefined(value.selected)) {
      return;
    }

    const newItems = [...value.items];
    const foundIndex = findIndex(value.items, item => item.index === value.selected);
    delete newItems[foundIndex];

    setFieldValue(name, {
      ...value,
      items: newItems.filter(negate(isNull)),
    });
  };

  onFinishedDrawing = (points) => {
    const {form: {setFieldValue}, field: {name, value = {}}, formik: {values}} = this.props;
    const {count = 0, items = []} = value;

    const erasing = values.navigationChoiceType === sketchConstants.NAVIGATION.ERASER.NAME;
    const itemType = erasing ? sketchConstants.TYPES.ERASER : sketchConstants.TYPES.DRAW;

    const item = calculateLine(points, itemType);

    const newItems = [...items];
    newItems.push({...item, index: count});

    setFieldValue(name, {
      ...value,
      count: count + 1,
      items: sortItems(newItems),
    });
  };

  render() {
    const {field: {value = {}}, isDragging, formik: {values}, isReadOnly = false} = this.props;

    const isDrawing = [sketchConstants.NAVIGATION.DRAW.NAME, sketchConstants.NAVIGATION.ERASER.NAME].indexOf(values.navigationChoiceType) !== -1;

    return (
      <SketchContext.Provider value={this.state}>
        <Sketch crossRoad={value.crossRoad} items={value.items} selected={value.selected} isReadOnly={isReadOnly}
                onDrop={this.onDrop} onChoose={this.onChoose} onResetSelected={this.onResetSelected}
                onFinishedDrawing={this.onFinishedDrawing}/>
        <SketchNavigation isDragging={isDragging}/>
        {isDrawing
          ? null
          : (
            <SketchBottomNavigation onRotateRight={this.onRotateRight}
                                    onRotateLeft={this.onRotateLeft}
                                    onFlip={this.onFlip}
                                    onDelete={this.onDelete}/>
          )
        }
      </SketchContext.Provider>
    );
  }
}

function collect(monitor) {
  return {
    isDragging: monitor.isDragging()
  };
}

function sortItems(oldItems) {
  const eraserItems = [];
  const otherItems = [];
  const items = oldItems.filter(negate(isNull));

  items.map(item => {
    if (item.type === sketchConstants.NAVIGATION.ERASER.NAME) {
      eraserItems.push(item);
    } else {
      otherItems.push(item);
    }
  });

  return [...otherItems, ...eraserItems];
}

export default DragLayer(collect)(connect(SketchContainer));
