import React from "react";

import cx from "classnames";
import {prefix} from 'inline-style-prefixer'
import {isNull, negate} from "underscore";

import styles from "components/common/Layout.module.scss";

function Layout({layoutClassName, layoutStyle = {}, layoutAutoSize = false, children, ...props}) {
  const childrenArray = React.Children.toArray(children).filter(negate(isNull));
  const layoutChildren = childrenArray.map((child, index) => (
    React.cloneElement(child, {
      ...props,
      ...child.props,
      firstRow: index === 0,
      lastRow: index === childrenArray.length - 1,
    })
  ));

  const style = {
    marginLeft: -(props.spacing || 0),
    marginRight: -(props.spacing || 0),
    marginBottom: -(props.spacing || 0),
    ...layoutStyle,
  };

  const layoutSizing = cx({
    [styles.mainColumnSize]: !layoutAutoSize,
  });

  return (
    <div className={cx(styles.root, layoutSizing, layoutClassName)} style={prefix(style)}>
      {layoutChildren}
    </div>
  );
}

function Row({rowClassName, children, justifyContent, alignItems, onClick, ...props}) {
  const childrenArray = React.Children.toArray(children).filter(negate(isNull));
  const layoutChildren = childrenArray.map((child, index) => (
    React.cloneElement(child, {
      ...props,
      ...child.props,
      firstColumn: index === 0,
      lastColumn: index === childrenArray.length - 1,
    })
  ));

  const style = {
    justifyContent,
    alignItems,
  };

  return (
    <div className={cx(styles.row, rowClassName)} style={prefix(style)} onClick={onClick}>
      {layoutChildren}
    </div>
  );
}

function Column(props) {
  const {className, style = {}, children} = props;
  const {flex = null, alignItems = "stretch", spacing = 0, textAlign = "left", width = "auto", maxWidth = "auto", minWidth = "auto"} = props;

  const divStyle = {
    flex,
    alignItems,
    textAlign,
    width,
    maxWidth,
    minWidth,
    paddingLeft: spacing,
    paddingRight: spacing,
    paddingBottom: spacing,
    ...style,
  };

  const divClassName = cx({
    [className]: true,
    [styles.column]: true,
  });

  return (
    <div className={divClassName} style={prefix(divStyle)}>
      {children}
    </div>
  );
}

function OneColumn({children, ...props}) {
  return (
    <Row {...props}>
      <Column>
        {children}
      </Column>
    </Row>
  );
}

function RowChildren({children, ...props}) {
  return (
    <Row {...props}>
      {React.Children.map(
        children,
        (child, index) => (
          child ? (
            <Column key={index}>
              {child}
            </Column>
          ) : null
        ),
        this,
      )}
    </Row>
  );
}

function OneRow({children, ...props}) {
  return (
    <Layout {...props}>
      <RowChildren>
        {children}
      </RowChildren>
    </Layout>
  );
}

function Fill() {
  return (
    <div className={styles.fill} />
  );
}

Layout.Row = Row;
Layout.Column = Column;
Layout.OneColumn = OneColumn;
Layout.RowChildren = RowChildren;
Layout.OneRow = OneRow;
Layout.Fill = Fill;

export default Layout;
