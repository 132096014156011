import React, {Component} from "react";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import BeneficiaryPersonsRight from "containers/step/beneficiaryPersons/BeneficiaryPersonsRight";
import Header from "containers/step/Header";
import SplitViewContainer from "containers/view/SplitViewContainer";
import Yup, {getMax} from "selectors/yup";

const validationSchema = () => Yup.object().shape({
  beneficiaryPersons: Yup.array().min(1).of(arrayObjectValidationSchema),
});

const addressValidationSchema = Yup.object().shape({
  countryName: Yup.string().trim().required("validation.address.country"),
  address: Yup.object().shape({
    streetNumber: getMax(Yup.string().typeError("validation.address.streetNo").trim().required("validation.address.streetNo"), fieldLengthConstants[flowConstants.personSteps.BENEFICIARY_PERSONS.NAME].streetNumber),
    streetName: getMax(Yup.string().typeError("validation.address.streetNo").trim().required("validation.address.streetNo"), fieldLengthConstants[flowConstants.personSteps.BENEFICIARY_PERSONS.NAME].streetName),
  }),
  city: Yup.object().shape({
    zip: getMax(Yup.string().trim().required("validation.address.zip"), fieldLengthConstants[flowConstants.personSteps.BENEFICIARY_PERSONS.NAME].zip),
    city: getMax(Yup.string().trim().required("validation.address.city"), fieldLengthConstants[flowConstants.personSteps.BENEFICIARY_PERSONS.NAME].city),
  }),
});

const arrayObjectValidationSchema = Yup.object().shape({
  name: getMax(Yup.string().trim().required("validation.name"), fieldLengthConstants[flowConstants.personSteps.BENEFICIARY_PERSONS.NAME].name),
  surname: getMax(Yup.string().trim().required("validation.surname"), fieldLengthConstants[flowConstants.personSteps.BENEFICIARY_PERSONS.NAME].surname),
  birthDate: Yup.mixed().nullable().required("validation.required"),
  sex: Yup.string().trim().required("validation.required"),
  address: addressValidationSchema,
  telephoneNumber: getMax(Yup.string().trim().validatePhoneNumber("validation.telephoneNumber.format").required("validation.telephoneNumber"), fieldLengthConstants[flowConstants.personSteps.BENEFICIARY_PERSONS.NAME].telephoneNumber),
});

export default class BeneficiaryPersonsStepContainer extends Component {
  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="beneficiaryPersons.sidebar.title"/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <BeneficiaryPersonsRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>
    );
  }
}
