import React, {Component} from "react";

import cx from "classnames";
import * as dateFns from "date-fns";
import {Field} from "formik";
import _ from "lodash";
import NumberFormat from "react-number-format"
import DatePicker from "react-datepicker";
import MaskedInput from "react-text-mask"
import {isUndefined, isEmpty} from "underscore";

import Translation from "components/common/Translation";
import CompositeField from "components/form/CompositeField";

import styles from "components/form/TextField.module.scss";

export default class TextField extends Component {
  render() {
    const {name} = this.props;

    return (
      <Field
        name={name}
        render={(fieldProps) => (
          <CompositeField {...this.props}>
            {context => (
              <InnerTextField {...fieldProps.field} form={fieldProps.form} {...this.props} context={context}/>
            )}
          </CompositeField>
        )}
      />
    );
  }
}

export class InnerTextField extends Component {
  render() {
    const {placeholder, ...props} = this.props;

    if (isUndefined(placeholder)) {
      return <InnerInput {...props} />;
    }

    return (
      <Translation label={placeholder}
                   render={({value}) => <InnerInput placeholder={value} {...props} />}/>
    );
  }
}

class InnerInput extends Component {
  static defaultProps = {
    disabled: false,
  };

  componentDidMount() {
    const {value, context, name} = this.props;

    if (!isEmpty(value)) {
      context.label.initialNotEmpty(name);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const {value, context, name} = this.props;

    if (isEmpty(prevProps.value) && !isEmpty(value)) {
      context.label.initialNotEmpty(name);
    }
  }

  onChange = (e) => {
    const {onChange, onValueChange, transform, name, form} = this.props;
    const shouldTransformValue = !isUndefined(transform);
    const value = shouldTransformValue ? transform(e.target.value) : e.target.value;

    onChange(e);

    if (onValueChange) {
      onValueChange(value);
    }

    if (shouldTransformValue) {
      form.setFieldValue(name, value);
    }
  };

  onDatePickerChange = (date) => {
    const {form, name} = this.props;

    form.setFieldValue(name, dateFns.isAfter(date, new Date()) ? new Date() : date);
  };

  onBlur = (e) => {
    const {form, name, context, value} = this.props;
    const {label: {handleBlur}} = context;

    handleBlur(value, name);

    if (this.props.handleBlur) {
      this.props.handleBlur(e);
    }

    if (form) {
      form.setTouched(_.set(_.cloneDeep(form.touched), name, true));
    }
  };

  onFocus = (e) => {
    const {onFocus, context, name} = this.props;
    const {label: {handleFocus}} = context;

    if (onFocus) {
      onFocus(e);
    }

    handleFocus(name);
  };

  onKeyPress = (e) => {
    const {onEnterPressed} = this.props;

    if (e.charCode === 13) {
      e.preventDefault();
      if (onEnterPressed) {
        onEnterPressed(e);
      }
      return false;
    }
  };

  render() {
    const {
      className,
      multiLine,
      placeholder,
      password,
      currency,
      number,
      calendar,
      isRequired,
      value,
      children,
      mask,
      onValueChange,
      handleBlur,
      variant,
      disabled,
      guide,
      inputRef,
      context,
      transform,
      onEnterPressed,
      ...props
    } = this.props;

    const inputClassName = cx({
      [styles.root]: true,
      [styles.multiLine]: multiLine,
      [styles.variant1]: multiLine && variant === "variant1",
      [styles.variant2]: variant === "variant2",
      [styles.variant3]: variant === "variant3",
      [styles.variant4]: variant === "variant4",
      [styles.variant5]: variant === "variant5",
      [styles.variant6]: variant === "variant6",
      [styles.variant7]: variant === "variant7",
      [styles.variant8]: variant === "variant8",
      [styles.invalid]: context.invalid,
      [styles.disabled]: disabled,
      [className]: true,
    });

    const inputPlaceholder = !context.label.show ? (isRequired ? `${placeholder} *` : placeholder) : null;

    let result = null;
    if (mask) {
      result = <MaskedInput {...props}
                            className={inputClassName}
                            placeholder={inputPlaceholder}
                            value={value || ""}
                            mask={mask}
                            disabled={disabled}
                            onKeyPress={this.onKeyPress}
                            onChange={this.onChange}
                            onBlur={this.onBlur}
                            onFocus={this.onFocus}
                            guide={guide}/>;
    } else if (multiLine) {
      result = <textarea {...props}
                         className={inputClassName}
                         placeholder={inputPlaceholder}
                         value={value || ""}
                         disabled={disabled}
                         onBlur={this.onBlur}
                         onFocus={this.onFocus}
                         onChange={this.onChange}/>
    } else if (calendar) {
      result = <DatePicker className={inputClassName}
                           placeholderText={inputPlaceholder}
                           disabled={disabled}
                           maxDate={new Date()}
                           selected={value}
                           onChange={this.onDatePickerChange}
                           useWeekdaysShort={true}
                           onBlur={this.onBlur}
                           onFocus={this.onFocus}
                           dateFormat="d.M.yyyy"/>
    } else if (password) {
      result = <input {...props}
                      className={inputClassName}
                      type="password"
                      placeholder={inputPlaceholder}
                      value={value || ""}
                      disabled={disabled}
                      onKeyPress={this.onKeyPress}
                      onBlur={this.onBlur}
                      onFocus={this.onFocus}
                      onChange={this.onChange}/>;
    } else if (currency) {
      result = <NumberFormat {...props}
                             className={inputClassName}
                             type="text"
                             placeholder={inputPlaceholder}
                             value={value || ""}
                             disabled={disabled}
                             onKeyPress={this.onKeyPress}
                             onBlur={this.onBlur}
                             onFocus={this.onFocus}
                             onChange={this.onChange}
                             thousandSeparator="."
                             decimalSeparator=","
                             decimalScale={2}
                             fixedDecimalScale={true}
                             suffix={" €"}/>;
    } else if (number) {
      result = <NumberFormat {...props}
                             className={inputClassName}
                             type="text"
                             placeholder={inputPlaceholder}
                             value={value || ""}
                             disabled={disabled}
                             onKeyPress={this.onKeyPress}
                             onBlur={this.onBlur}
                             onFocus={this.onFocus}
                             onChange={this.onChange}
                             allowNegative={false}/>;
    } else {
      result = <input {...props}
                      ref={inputRef}
                      className={inputClassName}
                      type="text"
                      placeholder={inputPlaceholder}
                      value={value || ""}
                      disabled={disabled}
                      onKeyPress={this.onKeyPress}
                      onBlur={this.onBlur}
                      onFocus={this.onFocus}
                      onChange={this.onChange}/>;
    }

    return children ? children : result;
  }
}
