import React, {Component} from "react";

import Layout from "components/common/Layout";
import TextField from "components/form/TextField";
import {regexFullTimeCheck} from "utility/accidentDate";

import rightArrow from "images/right-arrow.svg";

export default class RangeField extends Component {
  timeToRef = React.createRef();

  onTimeFromChanged = (value) => {
    if (regexFullTimeCheck(value)) {
      this.timeToRef.current.focus();
    }
  };

  render() {
    const {nameFrom, nameTo, placeholderFrom, placeholderTo, labelFrom, labelTo} = this.props;
    return (
      <Layout spacing={10}>
        <Layout.Row>
          <Layout.Column spacing={10} flex="2 1 30%" width="100%">
            <TextField name={nameFrom}
                       placeholder={placeholderFrom}
                       label={labelFrom}
                       variant="variant3"
                       onValueChange={this.onTimeFromChanged}/>
          </Layout.Column>
          <Layout.Column spacing={0} flex="1 1 7%" width="100%">
            <Layout.OneColumn spacing={20}></Layout.OneColumn>
            <RightArrow/>
          </Layout.Column>
          <Layout.Column spacing={10} flex="2 1 30%" width="100%">
            <TextField name={nameTo}
                       placeholder={placeholderTo}
                       label={labelTo}
                       variant="variant3"
                       inputRef={this.timeToRef}/>
          </Layout.Column>
        </Layout.Row>
      </Layout>
    );
  }
}

function RightArrow() {
  return (
    <img src={rightArrow} alt="right-arrow"/>
  );
}
