import React from "react";

import {FieldArray} from "formik";

import Layout from "components/common/Layout";
import ConditionalField from "components/form/ConditionalField";
import FormErrorMessage from "components/form/FormErrorMessage";
import UploadField from "components/form/UploadField";
import IcoMoon from "components/icon/IcoMoon";
import Documents from "components/step/Documents";
import fontConstants from "constants/font";
import {UploadContext} from "containers/context/UploadContainer"

export default function PersonHealthServiceDocumentsRight(props) {
  const {values, saveId} = props;
  const {
    referrals = [],
    check = [],
    workOrder = [],
    report = [],
    dismissalLetter = [],
    other = []
  } = values;
  const acceptedTypes = ["doc", "docx", "pdf", "jpg", "jpeg", "bmp", "tiff", "png", "xlsx", "xls"].map(x => `.${x}`).join(", ");

  return (
    <Layout spacing={10} width="100%" maxWidth={450} justifyContent="center">
      <Layout.OneColumn spacing={40}/>
      <ConditionalField name="documents">
        <Layout.OneColumn>
          <UploadField name="referrals"
                       label="personDocuments.button.uploadReferrals"
                       saveId={saveId}
                       icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                       accept={acceptedTypes}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <FieldArray name="referrals" render={arrayHelpers => (
            <Documents arrayHelpers={arrayHelpers}
                       documents={referrals}/>
          )}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <UploadField name="check"
                       label="personDocuments.button.uploadCheck"
                       saveId={saveId}
                       icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                       accept={acceptedTypes}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <FieldArray name="check" render={arrayHelpers => (
            <Documents arrayHelpers={arrayHelpers}
                       documents={check}/>
          )}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <UploadField name="workOrder"
                       label="personDocuments.button.uploadWorkOrder"
                       saveId={saveId}
                       icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                       accept={acceptedTypes}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <FieldArray name="workOrder" render={arrayHelpers => (
            <Documents arrayHelpers={arrayHelpers}
                       documents={workOrder}/>
          )}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <UploadField name="report"
                       label="personDocuments.button.uploadReport"
                       saveId={saveId}
                       icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                       accept={acceptedTypes}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <FieldArray name="report" render={arrayHelpers => (
            <Documents arrayHelpers={arrayHelpers}
                       documents={report}/>
          )}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <UploadField name="dismissalLetter"
                       label="personDocuments.button.uploadDismissalLetterHospital"
                       saveId={saveId}
                       icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                       accept={acceptedTypes}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <FieldArray name="dismissalLetter" render={arrayHelpers => (
            <Documents arrayHelpers={arrayHelpers}
                       documents={dismissalLetter}/>
          )}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <UploadField name="other"
                       label="personDocuments.button.uploadOther"
                       saveId={saveId}
                       icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                       accept={acceptedTypes}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <FieldArray name="other" render={arrayHelpers => (
            <Documents arrayHelpers={arrayHelpers}
                       documents={other}/>
          )}/>
        </Layout.OneColumn>
      </ConditionalField>
      <UploadContext.Consumer>
        {context => (<FormErrorMessage error={context.error} showError={context.error.length > 0}/>)}
      </UploadContext.Consumer>
      <Layout.OneColumn spacing={10}></Layout.OneColumn>
    </Layout>
  );
}
