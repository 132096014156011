import React from "react";

import cx from "classnames";

import Text from "components/common/Text";
import isUndefinedOrNull from "utility/utilityFunctions";

import styles from "components/form/FieldLabel.module.scss";

export default function FieldLabel({label, variant, show = true}) {
  const ClassName = cx({
    [styles.root]: true,
    [styles.variant2]: variant === "variant2",
  });

  if (isUndefinedOrNull(label)) {
    return null;
  }

  if (!show) {
    return (
      <Text element="span" className={ClassName}>&nbsp;</Text>
    );
  }

  return (
    <Text element="span" className={ClassName} label={label}/>
  );
}
