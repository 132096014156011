import {contains, find, findKey, isArray, isEmpty, isUndefined, some} from "underscore";

import _ from "lodash";

import booleanTypeConstants from "constants/booleanTypes"
import claimTypesConstants from "constants/claimTypes";
import flowConstants from "constants/flow";
import homeDamagedGoodsInfoTypeConstants from "constants/homeDamagedGoodsInfoTypes";
import homeDamagedGoodsTypesConstants from "constants/homeDamagedGoodsTypes";
import homeDamageTypeConstants from "constants/homeDamageTypes";
import homeGoodsTypes from "constants/homeGoodsTypes";
import medicalCareDescriptionTypes from "constants/medicalCareDescriptionTypes";
import repairServiceTypeConstants from "constants/repairServiceTypes";
import sketchConstants from "constants/sketch";
import vehicleInfoInputTypesConstants from "constants/vehicleInfoInputTypes";
import vehicleTypeConstants from "constants/vehicleTypes";
import filterParticipantsInfo from "selectors/participantsInfo";
import {
  arraysContainSameElements,
  preCalculateAccidentDate,
  preCalculateAccidentTime,
  preCalculateApplicantData,
  preCalculateBankAccounts,
  preCalculateHasCasco,
  preCalculateHelpInformation,
  preCalculateHomeAccidentTime,
  preCalculateHomeVehicleInfo,
  preCalculatePersonAccidentTime,
  preCalculateReportWithOtherCompany,
  preCalculateSummary,
  preCalculateUserIdentification,
  preCalculateUserIdentificationDeath,
  preCalculateUserLogin
} from "selectors/flow/preCalculateFormState/preCalculateUtilityFunctions";
import {locationAddress} from "selectors/summary";

const steps = flowConstants.steps;

export function preCalculateFormState(step, forms, configuration) {
  let initialFormState;
  const choiceType = _.at(forms, `${steps.CLAIM_TYPE.NAME}.choiceType`)[0];

  if (choiceType === claimTypesConstants.CAR) {
    initialFormState = preCalculateCarFormState(step, forms, configuration);
  } else if (choiceType === claimTypesConstants.HOME) {
    initialFormState = preCalculateHomeFormState(step, forms, configuration);
  } else if (choiceType === claimTypesConstants.PERSON) {
    initialFormState = preCalculatePersonFormState(step, forms, configuration);
  } else if (choiceType === claimTypesConstants.GENERAL) {
    initialFormState = preCalculateGeneralFormState(step, forms, configuration);
  }

  if (initialFormState) {
    return {
      ...forms,
      [step.name]: initialFormState,
    };
  } else {
    return forms;
  }
}

function preCalculateCarFormState(step, forms, configuration) {
  const carDamageTypeForm = forms[steps.CAR_DAMAGE_TYPE.NAME] || {};
  const vehicleTypeForm = forms[steps.VEHICLE_TYPE.NAME] || {};
  const ableToDriveForm = forms[steps.ABLE_TO_DRIVE.NAME] || {};
  const vehicleDamageCurrentForm = forms[steps.VEHICLE_DAMAGE_CURRENT.NAME] || {};
  const vehicleDamagePreviousForm = forms[steps.VEHICLE_DAMAGE_PREVIOUS.NAME] || {};
  const claimTypeForm = forms[steps.CLAIM_TYPE.NAME] || {};
  const ownVehiclePassengersForm = forms[steps.OWN_VEHICLE_INFO_PASSENGERS.NAME] || {};
  const participantsInfoForm = forms[steps.PARTICIPANTS_INFO.NAME] || [];
  const mapsInfoForm = forms[steps.MAPS_INTRO.NAME] || [];
  const participantsNumberForm = forms[steps.PARTICIPANTS_NUMBER.NAME] || {};
  const ownVehicleInfoWasDriverForm = forms[steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME] || {};
  const ownVehicleInfoDriverHurtForm = forms[steps.OWN_VEHICLE_INFO_DRIVER_HURT.NAME] || {};
  const responsiblePersonForm = forms[steps.RESPONSIBLE_PERSON.NAME] || {};
  const vehicleDamageInspectionPointForm = forms[steps.VEHICLE_DAMAGE_INSPECTION_POINT.NAME] || {};
  const drawingForm = forms[steps.DRAWING.NAME] || {};
  const parkedForm = forms[steps.PARKED.NAME] || {};

  let initialFormState = null;

  if (step.name === steps.HELP_INFORMATION.NAME) {
    initialFormState = preCalculateHelpInformation(forms);
  } else if (step.name === steps.ACCIDENT_DATE.NAME) {
    initialFormState = preCalculateAccidentDate(forms);
  } else if (step.name === steps.ACCIDENT_TIME.NAME) {
    initialFormState = preCalculateAccidentTime(forms);
  } else if (step.name === steps.VEHICLE_TYPE.NAME) {
    initialFormState = {
      ...vehicleTypeForm,
      carDamageType: carDamageTypeForm.carDamageType,
    };

  } else if (step.name === steps.VEHICLE_DAMAGE_CURRENT.NAME) {
    const vehicleType = vehicleTypeForm.finalVehicleType;

    initialFormState = {
      carDamageSelectAll: false,
      semiTruckDamageSelectAll: false,
      motorcycleDamageSelectAll: false,
      bikeDamageSelectAll: false,
      ...vehicleDamageCurrentForm,
      vehicleType,
      claimType: claimTypeForm.choiceType,
    };
  } else if (step.name === steps.VEHICLE_DAMAGE_PREVIOUS.NAME) {
    initialFormState = {
      ...vehicleDamagePreviousForm,
      vehicleType: vehicleTypeForm.finalVehicleType,
    };
  } else if (step.name === steps.PARTICIPANTS_NUMBER.NAME) {
    const count = participantsNumberForm.count || 1;

    if (isEmpty(participantsNumberForm.participants)) {
      initialFormState = {
        ...participantsNumberForm,
        count: count + 1,
        participants: [{type: vehicleTypeConstants.CAR, index: count}],
      };
    } else {
      initialFormState = {
        ...participantsNumberForm,
        count: count,
      };
    }
  } else if (step.name === steps.DRAWING.NAME) {
    const {finalParked} = parkedForm;

    const roadCrossingsSelected = drawingForm.navigationChoiceType === sketchConstants.NAVIGATION.ROAD_CROSSINGS.NAME;
    const shouldResetNavigationType = !drawingForm.navigationChoiceType || (finalParked && roadCrossingsSelected);

    initialFormState = {
      sketch: {
        items: [],
      },
      ...drawingForm,
      navigationChoiceType: shouldResetNavigationType
        ? finalParked
          ? sketchConstants.NAVIGATION.SHAPES.NAME
          : sketchConstants.NAVIGATION.ROAD_CROSSINGS.NAME
        : drawingForm.navigationChoiceType,
      participants: participantsNumberForm.finalParticipants,
      parked: finalParked,
    };
  } else if (step.name === steps.PARTICIPANTS_INFO.NAME) {
    initialFormState = [...participantsInfoForm];

    const info = find(participantsNumberForm.finalParticipants, participant => participant.index === step.index) || {};
    if (isUndefined(initialFormState[step.index]) || initialFormState[step.index].info.type !== info.type) {
      const participant = {info};

      participant.participantInfo = {
        choice: booleanTypeConstants.YES,
        receiptChoice: booleanTypeConstants.NO,
        photos: [],
      };

      initialFormState[step.index] = participant;
    } else if (initialFormState[step.index].info.type === vehicleTypeConstants.MOTORCYCLE && initialFormState[step.index].participantInfo.inputType === vehicleInfoInputTypesConstants.COMPANY_VEHICLE) {
      initialFormState[step.index] = {
        ...initialFormState[step.index],
        participantInfo: {
          ...initialFormState[step.index].participantInfo,
          inputType: vehicleInfoInputTypesConstants.REGISTERED_IN_SLOVENIA,
          choice: true,
        }
      };
    } else if (initialFormState[step.index].info.type === vehicleTypeConstants.COMPANY && initialFormState[step.index].participantInfo.inputType !== vehicleInfoInputTypesConstants.COMPANY_VEHICLE) {
      initialFormState[step.index] = {
        ...initialFormState[step.index],
        participantInfo: {
          ...initialFormState[step.index].participantInfo,
          inputType: vehicleInfoInputTypesConstants.COMPANY_VEHICLE,
          choice: true,
        }
      };
    }
  } else if (step.name === steps.OWN_VEHICLE_INFO_DRIVER_HURT.NAME) {
    initialFormState = {
      ...ownVehicleInfoDriverHurtForm,
      wasDriver: ownVehicleInfoWasDriverForm.wasDriver,
    }
  } else if (step.name === steps.OWN_VEHICLE_INFO_PASSENGERS.NAME) {
    initialFormState = {
      passengers: [{}],
      ...ownVehiclePassengersForm,
    };
  } else if (step.name === steps.RESPONSIBLE_PERSON.NAME) {
    const resetResponsiblePerson =
      isUndefined(responsiblePersonForm.responsiblePerson)
      || (
        isArray(responsiblePersonForm.responsiblePerson)
        && !some(responsiblePersonForm.responsiblePerson, person => some(participantsNumberForm.finalParticipants, finalParticipant => person === finalParticipant.index && !finalParticipant.skip))
      );

    const responsiblePerson = isArray(responsiblePersonForm.responsiblePerson)
      ? responsiblePersonForm.responsiblePerson.filter(person => some(participantsNumberForm.finalParticipants, finalParticipant => person === finalParticipant.index && !finalParticipant.skip))
      : responsiblePersonForm.responsiblePerson;

    initialFormState = {
      ...responsiblePersonForm,
      participants: filterParticipantsInfo(participantsNumberForm.finalParticipants, participantsInfoForm),
      responsiblePerson: resetResponsiblePerson ? undefined : responsiblePerson,
    };
  } else if (step.name === steps.VEHICLE_DAMAGE_INSPECTION_POINT.NAME) {
    initialFormState = {
      ...vehicleDamageInspectionPointForm,
      ableToDrive: ableToDriveForm.ableToDrive,
      repairServiceType: mapsInfoForm.repairServiceType,
    }
  } else if (step.name === steps.USER_LOGIN.NAME) {
    initialFormState = preCalculateUserLogin(forms)
  } else if (step.name === steps.APPLICANT_DATA.NAME) {
    initialFormState = preCalculateApplicantData(forms)
  } else if (step.name === steps.USER_IDENTIFICATION.NAME) {
    initialFormState = preCalculateUserIdentification(forms)
  } else if (step.name === steps.BANK_ACCOUNTS.NAME) {
    initialFormState = preCalculateBankAccounts(forms, configuration);
  } else if (step.name === steps.HAS_CASCO.NAME) {
    initialFormState = preCalculateHasCasco(forms);
  } else if (step.name === steps.REPORT_WITH_OTHER_COMPANY.NAME) {
    initialFormState = preCalculateReportWithOtherCompany(forms);
  } else if (step.name === steps.SUMMARY.NAME) {
    initialFormState = preCalculateSummary(forms, configuration);
  } else if (step.name === steps.THANK_YOU.NAME) {
    const carDamageType = carDamageTypeForm.carDamageType;
    const repairServiceType = mapsInfoForm.repairServiceType;
    let repairServiceSelectionNameAndAddress = "";

    if (repairServiceType === repairServiceTypeConstants.VEHICLE_DAMAGE_INSPECTION_POINT && vehicleDamageInspectionPointForm.vehicleDamageInspectionPoint) {
      repairServiceSelectionNameAndAddress = `${vehicleDamageInspectionPointForm.vehicleDamageInspectionPoint.name}, ${locationAddress(vehicleDamageInspectionPointForm.vehicleDamageInspectionPoint)}`;
    } else if (repairServiceType === repairServiceTypeConstants.BUSINESS_UNIT && vehicleDamageInspectionPointForm.businessUnit) {
      repairServiceSelectionNameAndAddress = `${vehicleDamageInspectionPointForm.businessUnit.name}, ${locationAddress(vehicleDamageInspectionPointForm.businessUnit)}`;
    }

    initialFormState = {
      repairServiceType,
      repairServiceSelectionNameAndAddress,
      carDamageType,
    }
  }

  return initialFormState;
}

function preCalculateHomeFormState(step, forms, configuration) {
  const {homeSteps} = flowConstants;
  const homeDamageTypeForm = forms[homeSteps.HOME_DAMAGE_TYPE.NAME] || {};
  const homeDamagedGoodsForm = forms[homeSteps.HOME_DAMAGED_GOODS.NAME] || {};
  const homeDamagedGoodsInfoForm = forms[homeSteps.HOME_DAMAGED_GOODS_INFO.NAME] || {};
  const homeReportedPoliceForm = forms[homeSteps.HOME_REPORTED_POLICE.NAME] || {};

  let initialFormState = null;

  if (step.name === homeSteps.HOME_DAMAGE_TYPE.NAME) {
    initialFormState = {
      ...homeDamageTypeForm,
      homeDamageType: {
        ...homeDamageTypeForm.homeDamageType,
        expanded: [],
        selected: homeDamageTypeForm.homeDamageType ? homeDamageTypeForm.homeDamageType.selected : [],
      }
    }
  } else if (step.name === homeSteps.HOME_DAMAGED_GOODS.NAME) {
    const homeDamagedGoods = homeDamagedGoodsForm.homeDamagedGoods || {};
    const damageType = homeDamageTypeForm.finalSelected;
    const showGlassAndCeramics = contains(damageType, homeDamageTypeConstants.DESTRUCTION.NAME);
    const lightningSelected = findKey(homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.LIGHTNING.CHILDREN, child => contains(damageType, child.NAME));
    const showDevices = contains(damageType, homeDamageTypeConstants.BREAKDOWN.NAME);
    const damageTypeChanged = !arraysContainSameElements(damageType, homeDamagedGoodsForm.damageType);

    let homeDamagedGoodsType = homeDamagedGoodsTypesConstants.REGULAR;
    const expanded = [];

    if (showGlassAndCeramics) {
      expanded.push(homeGoodsTypes.GLASS_AND_CERAMICS.NAME);
      homeDamagedGoodsType = homeDamagedGoodsTypesConstants.GLASS;
    } else if (showDevices) {
      expanded.push(homeGoodsTypes.DEVICES.NAME);
      homeDamagedGoodsType = homeDamagedGoodsTypesConstants.DEVICES;
    }

    initialFormState = {
      ...homeDamagedGoodsForm,
      homeDamagedGoods: {
        selected: damageTypeChanged ? [] : [...homeDamagedGoods.selected],
        expanded: homeDamagedGoodsType === homeDamagedGoodsTypesConstants.REGULAR ? [] : expanded,
      },
      damageType,
      homeDamagedGoodsType,
      lightningSelected,
    }
  } else if (step.name === homeSteps.HOME_DAMAGED_GOODS_INFO.NAME) {
    const count = homeDamagedGoodsInfoForm.count || 1;
    const damageType = homeDamageTypeForm.finalSelected;
    const listDamageTypes = [
      homeDamageTypeConstants.BURGLARY.NAME,
      homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.LIGHTNING.CHILDREN.LIGHTNING_DIRECT.NAME,
      homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.LIGHTNING.CHILDREN.LIGHTNING_INDIRECT.NAME,
      homeDamageTypeConstants.BREAKDOWN.NAME,
    ];

    let homeDamagedGoodsInfoType = homeDamagedGoodsInfoTypeConstants.ESTIMATE;

    listDamageTypes.forEach(listDamageType => {
      if (contains(damageType, listDamageType)) {
        homeDamagedGoodsInfoType = homeDamagedGoodsInfoTypeConstants.LIST;
      }
    });

    if (isEmpty(homeDamagedGoodsInfoForm.damagedGoods)) {
      initialFormState = {
        ...homeDamagedGoodsInfoForm,
        count: count + 1,
        damagedGoods: [{index: count}],
        homeDamagedGoodsInfoType,
      };
    } else {
      initialFormState = {
        ...homeDamagedGoodsInfoForm,
        count: count,
        homeDamagedGoodsInfoType,
      };
    }
  } else if (step.name === steps.ACCIDENT_DATE.NAME) {
    initialFormState = preCalculateAccidentDate(forms);
  } else if (step.name === steps.ACCIDENT_TIME.NAME) {
    initialFormState = preCalculateHomeAccidentTime(forms);
  } else if (step.name === homeSteps.HOME_REPORTED_POLICE.NAME) {
    initialFormState = {
      witnesses: [{}],
      ...homeReportedPoliceForm,
    };
  } else if (step.name === homeSteps.HOME_VEHICLE_INFO.NAME) {
    initialFormState = preCalculateHomeVehicleInfo(forms);
  } else if (step.name === steps.USER_LOGIN.NAME) {
    initialFormState = preCalculateUserLogin(forms)
  } else if (step.name === steps.APPLICANT_DATA.NAME) {
    initialFormState = preCalculateApplicantData(forms)
  } else if (step.name === steps.USER_IDENTIFICATION.NAME) {
    initialFormState = preCalculateUserIdentification(forms)
  } else if (step.name === steps.BANK_ACCOUNTS.NAME) {
    initialFormState = preCalculateBankAccounts(forms, configuration);
  } else if (step.name === steps.SUMMARY.NAME) {
    initialFormState = preCalculateSummary(forms, configuration);
  }

  return initialFormState;
}

function preCalculatePersonFormState(step, forms, configuration) {
  const {personSteps} = flowConstants;
  const medicalCareTypeForm = forms[personSteps.MEDICAL_CARE_TYPE.NAME] || {};
  const personDamageForm = forms[personSteps.PERSON_DAMAGE.NAME] || {};
  const witnessesPresentForm = forms[personSteps.WITNESSES_PRESENT.NAME] || {};
  const personInsuranceForm = forms[personSteps.PERSON_INSURANCE.NAME] || {};
  const responsiblePersonTypeForm = forms[personSteps.RESPONSIBLE_PERSON_TYPE.NAME] || {};
  const referralNumbersForm = forms[personSteps.REFERRAL_NUMBERS.NAME] || {};
  const personResponsibleForm = forms[personSteps.PERSON_RESPONSIBLE.NAME] || {};
  const participantsNumberForm = forms[steps.PARTICIPANTS_NUMBER.NAME] || {};
  const lifeInsuranceClaimForm = forms[personSteps.LIFE_INSURANCE_CLAIM.NAME] || {};
  const personParticipantsInfoForm = forms[personSteps.PERSON_PARTICIPANTS_INFO.NAME] || [];
  const responsiblePersonForm = forms[steps.RESPONSIBLE_PERSON.NAME] || {};
  const beneficiaryPersonsForm = forms[personSteps.BENEFICIARY_PERSONS.NAME] || {};
  const accidentInvolvedForm = forms[personSteps.ACCIDENT_INVOLVED.NAME] || {};

  let initialFormState = null;

  if (step.name === personSteps.PERSON_DAMAGE.NAME) {
    initialFormState = {
      personDamageSelectAll: false,
      ...personDamageForm
    }
  }

  if (step.name === personSteps.WITNESSES_PRESENT.NAME) {
    initialFormState = {
      witnesses: [{}],
      ...witnessesPresentForm,
    };
  }

  if (step.name === personSteps.PERSON_INSURANCE.NAME) {
    initialFormState = {
      insuranceOwners: [{}],
      ...personInsuranceForm,
    };
  }

  if (step.name === personSteps.RESPONSIBLE_PERSON_TYPE.NAME) {
    initialFormState = {
      naturalPersons: [{}],
      ...responsiblePersonTypeForm,
    };
  }

  if (step.name === personSteps.REFERRAL_NUMBERS.NAME) {
    initialFormState = {
      referralNumbers: [{}],
      ...referralNumbersForm,
    };
  } else if (step.name === steps.ACCIDENT_DATE.NAME) {
    initialFormState = preCalculateAccidentDate(forms);
  } else if (step.name === steps.ACCIDENT_TIME.NAME) {
    initialFormState = preCalculatePersonAccidentTime(forms);
  }

  if (step.name === personSteps.PERSON_RESPONSIBLE.NAME) {
    initialFormState = {
      personResponsibleType: step.personResponsibleType,
      ...personResponsibleForm,
    }
  }

  if (step.name === steps.PARTICIPANTS_NUMBER.NAME) {
    const count = participantsNumberForm.count || 1;

    if (isEmpty(participantsNumberForm.participants)) {
      initialFormState = {
        ...participantsNumberForm,
        count: count + 1,
        participants: [{type: vehicleTypeConstants.CAR, index: count}],
      };
    } else {
      initialFormState = {
        ...participantsNumberForm,
        count: count,
      };
    }
  }

  if (step.name === personSteps.PERSON_PARTICIPANTS_INFO.NAME) {
    initialFormState = [...personParticipantsInfoForm];

    const info = find(participantsNumberForm.finalParticipants, participant => participant.index === step.index) || {};

    if (isUndefined(initialFormState[0])) {
      initialFormState[0] = {
        info: {
          color: "#d36845",
          index: 0,
          isOwnVehicle: true,
          isVehicle: accidentInvolvedForm.involvementType !== vehicleTypeConstants.BIKE && accidentInvolvedForm.involvementType !== vehicleTypeConstants.PEDESTRIAN,
          name: "A",
          type: accidentInvolvedForm.involvementType
        },
        participantInfo: {
          choice: booleanTypeConstants.YES,
          photos: [],
          receiptChoice: booleanTypeConstants.NO
        }
      }
    } else {
      initialFormState[0] = {
        ...initialFormState[0],
        info: {
          ...initialFormState[0].info,
          isVehicle: accidentInvolvedForm.involvementType !== vehicleTypeConstants.BIKE && accidentInvolvedForm.involvementType !== vehicleTypeConstants.PEDESTRIAN,
          type: accidentInvolvedForm.involvementType
        },
        participantInfo: initialFormState[0].info.type === accidentInvolvedForm.involvementType ? initialFormState[0].participantInfo :
          {
            choice: booleanTypeConstants.YES,
            photos: [],
            receiptChoice: booleanTypeConstants.NO
          }
      }
    }

    if (step.index !== 0) {

      if (isUndefined(initialFormState[step.index]) || initialFormState[step.index].info.type !== info.type) {
        const participant = {
          info: {
            ...info,
            type: info.isOwnVehicle ? vehicleTypeConstants.CAR : info.type
          }
        };

        participant.participantInfo = {
          choice: booleanTypeConstants.YES,
          receiptChoice: booleanTypeConstants.NO,
          photos: [],
        };

        initialFormState[step.index] = participant;
      } else if (initialFormState[step.index].info.type === vehicleTypeConstants.MOTORCYCLE && initialFormState[step.index].participantInfo.inputType === vehicleInfoInputTypesConstants.COMPANY_VEHICLE) {
        initialFormState[step.index] = {
          ...initialFormState[step.index],
          participantInfo: {
            ...initialFormState[step.index].participantInfo,
            inputType: vehicleInfoInputTypesConstants.REGISTERED_IN_SLOVENIA,
            choice: true,
          }
        };
      } else if (initialFormState[step.index].info.type === vehicleTypeConstants.COMPANY && initialFormState[step.index].participantInfo.inputType !== vehicleInfoInputTypesConstants.COMPANY_VEHICLE) {
        initialFormState[step.index] = {
          ...initialFormState[step.index],
          participantInfo: {
            ...initialFormState[step.index].participantInfo,
            inputType: vehicleInfoInputTypesConstants.COMPANY_VEHICLE,
            choice: true,
          }
        };
      }
    }
  }

  if (step.name === steps.RESPONSIBLE_PERSON.NAME) {
    const resetResponsiblePerson =
      isUndefined(responsiblePersonForm.responsiblePerson)
      || (
        isArray(responsiblePersonForm.responsiblePerson)
        && !some(responsiblePersonForm.responsiblePerson, person => some(participantsNumberForm.finalParticipants, finalParticipant => person === finalParticipant.index && !finalParticipant.skip))
      );

    const responsiblePerson = isArray(responsiblePersonForm.responsiblePerson)
      ? responsiblePersonForm.responsiblePerson.filter(person => some(participantsNumberForm.finalParticipants, finalParticipant => person === finalParticipant.index && !finalParticipant.skip))
      : responsiblePersonForm.responsiblePerson;

    initialFormState = {
      ...responsiblePersonForm,
      participants: filterParticipantsInfo(participantsNumberForm.finalParticipants, personParticipantsInfoForm),
      responsiblePerson: resetResponsiblePerson ? undefined : responsiblePerson,
    };
  }

  if (step.name === steps.USER_LOGIN.NAME) {
    initialFormState = preCalculateUserLogin(forms)
  } else if (step.name === steps.APPLICANT_DATA.NAME) {
    initialFormState = preCalculateApplicantData(forms)
  } else if (step.name === steps.USER_IDENTIFICATION.NAME) {
    initialFormState = medicalCareTypeForm.medicalCareTypeDescription === medicalCareDescriptionTypes.DEATH
      ? preCalculateUserIdentificationDeath(forms) : preCalculateUserIdentification(forms);
    initialFormState = {
      ...initialFormState,
      isBeneficiariesButton: booleanTypeConstants.NO,
    }
  }

  if (step.name === steps.BANK_ACCOUNTS.NAME) {
    initialFormState = preCalculateBankAccounts(forms, configuration);
  }

  if (step.name === steps.SUMMARY.NAME) {
    initialFormState = preCalculateSummary(forms, configuration);
  }

  if (step.name === personSteps.LIFE_INSURANCE_CLAIM.NAME) {
    initialFormState = {
      taxInfos: [{}],
      ...lifeInsuranceClaimForm,
    }
  }

  if (step.name === personSteps.BENEFICIARY_PERSONS.NAME) {
    initialFormState = {
      beneficiaryPersons: [{}],
      ...beneficiaryPersonsForm
    }
  }

  if (step.name === personSteps.ACCIDENT_INVOLVED.NAME) {
    if (isEmpty(accidentInvolvedForm.involvementType)) {
      initialFormState = {
        involvementType: vehicleTypeConstants.CAR
      }
    }
  }

  return initialFormState;
}

function preCalculateGeneralFormState(step, forms, configuration) {
  let initialFormState = null;
  const claimTypeForm = forms[steps.CLAIM_TYPE.NAME] || {};

  if (step.name === steps.HELP_INFORMATION.NAME) {
    initialFormState = preCalculateHelpInformation(forms);
  } else if (step.name === steps.ACCIDENT_DATE.NAME) {
    initialFormState = preCalculateAccidentDate(forms);
  } else if (step.name === steps.ACCIDENT_TIME.NAME) {
    initialFormState = preCalculateAccidentTime(forms);
  } else if (step.name === steps.USER_LOGIN.NAME) {
    initialFormState = preCalculateUserLogin(forms)
  } else if (step.name === steps.APPLICANT_DATA.NAME) {
    initialFormState = preCalculateApplicantData(forms)
  } else if (step.name === steps.USER_IDENTIFICATION.NAME) {
    initialFormState = preCalculateUserIdentification(forms)
  } else if (step.name === steps.BANK_ACCOUNTS.NAME) {
    initialFormState = preCalculateBankAccounts(forms, configuration);
  } else if (step.name === steps.SUMMARY.NAME) {
    initialFormState = preCalculateSummary(forms, configuration);
  } else if (step.name === steps.THANK_YOU.NAME) {
    initialFormState = {
      claimType: claimTypeForm.choiceType,
    }
  }

  return initialFormState;
}