import claimTypeConstants from "./claimTypes";

export default {
  EVENT: {
    COOKIE_BUTTON: "cookie_btn",
  },
  COOKIES: {
    REQUIRED: "required",
    ADVERTS: "advertising",
    FUNCTIONAL: "functional",
    ANALYTICS: "analytics",
    OTHER: "other",
  },
  STEP_PREFIX: {
    [claimTypeConstants.CAR]: "Avto",
    [claimTypeConstants.HOME]: "Dom",
    [claimTypeConstants.GENERAL]: "Ostalo",
    [claimTypeConstants.PERSON]: "Oseba",
  },
}