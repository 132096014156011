import React, {Component} from "react";

import Switch, {Case, Default} from "react-switch-case";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import Text from "components/common/Text";
import ToggleButton from "components/form/ToggleButton";
import TextField from "components/form/TextField";
import FadeView from "components/view/FadeView";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";

export default class AbleToDriveRight extends Component {
  render() {
    const {values, onNextStep} = this.props;

    return (
      <Layout flex={1}>
        <Layout.OneColumn>
          <NextStepButton component={ToggleButton}
                          condition={value => value}
                          isValid={true}
                          buttonProps={{name: "ableToDrive"}}
                          onNextStep={onNextStep}>
            <Layout.OneRow spacing={10} flex={1}>
              <ToggleButton.Choice value={true} label="button.yes"/>
              <ToggleButton.Choice value={false} label="button.no"/>
            </Layout.OneRow>
          </NextStepButton>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <FadeView step={values.ableToDrive}>
            <Switch condition={values.ableToDrive}>
              <Case value={false}>
                <Layout width="100%" maxWidth={450} justifyContent="center">
                  <Layout.OneColumn spacing={35}/>
                  <Layout.OneColumn>
                    <Text element="span" variant="variant3" label="ableToDrive.content.locationDescription.title"/>
                  </Layout.OneColumn>
                  <Layout.OneColumn>
                    <TextField name="vehicleLocationDescription"
                               placeholder="ableToDrive.content.locationDescription.placeholder"
                               multiLine={true}
                               variant="variant5"
                               maxLength={fieldLengthConstants[flowConstants.steps.ABLE_TO_DRIVE.NAME].description + 1}/>
                  </Layout.OneColumn>
                </Layout>
              </Case>
              <Default>
                {null}
              </Default>
            </Switch>
          </FadeView>
        </Layout.OneColumn>
      </Layout>
    );
  }
}
