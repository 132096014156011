import React from "react";

import Text from "components/common/Text";
import VehicleIconLabel from "components/step/VehicleIconLabel";
import SummaryTable from "components/step/summary/SummaryTable";
import booleanTypesConstants from "constants/booleanTypes";
import carDamageTypes from "constants/carDamageTypes";
import claimTypes from "constants/claimTypes";
import flowConstants from "constants/flow";
import summaryRefConstants from "constants/summaryRef";
import Summary from "containers/step/summary/Summary";
import SummaryOwnVehicleInfo from "containers/step/summary/car/SummaryOwnVehicleInfo";
import SummaryPedestrianInfo from "containers/step/summary/car/SummaryPedestrianInfo";
import SummaryVehicleInfo from "containers/step/summary/car/SummaryVehicleInfo";

export default function SummaryParticipants({claimType, medicalCareType, isDamagesClaimFiled}) {
  const stepName = claimType === claimTypes.CAR
    ? flowConstants.steps.PARTICIPANTS_INFO.NAME
      : flowConstants.personSteps.PERSON_PARTICIPANTS_INFO.NAME;

  return (
    <Summary.Items
      formName={{name: stepName}}
      condition={form => form.length > 0}>
      {participantForms => (
        <Summary
          title={claimType !== claimTypes.PERSON && participantForms[0].carDamageType === carDamageTypes.KEY_LOSS
            ? "summary.content.participantsInfo.vehicle.keyLoss.title" : "summary.content.participantsInfo.title"}
          refName={summaryRefConstants.PARTICIPANTS.REF}>
          <Summary.Items formName={{name: stepName}}
                         condition={form => form.length > 0}>
            {form => (
              form.map((participantInfo, index) => (
                <SummaryParticipant key={index} participantInfo={participantInfo} medicalCareType={medicalCareType}/>
              ))
            )}
          </Summary.Items>
        </Summary>
      )}
    </Summary.Items>
  );
}

function SummaryParticipant({participantInfo, medicalCareType}) {
  const {participant, jumpTo} = participantInfo;
  const {info: {index}} = participant;
  const headerText =
    participant.info.isVehicle
      ? <SummaryTable.HeaderText
        label={`participantsInfo.vehicleInfo.inputType.${participant.participantInfo.inputType}`}/>
      : null;

  return (
    <SummaryTable.Part refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.REF(index)}>
      <SummaryTable.HeaderContent>
        <VehicleIconLabel info={participant.info}/>
      </SummaryTable.HeaderContent>
      {headerText}
      <SummaryParticipantInfo participant={participant} jumpTo={jumpTo} medicalCareType={medicalCareType}/>
    </SummaryTable.Part>
  )
}

function SummaryParticipantInfo({participant, ...props}) {
  if (participant.info.isOwnVehicle) {
    return (
      <SummaryOwnVehicleInfo participant={participant} {...props}/>
    );
  } else if (participant.info.isVehicle) {
    return (
      <SummaryVehicleInfo participant={participant} {...props}/>
    );
  } else {
    return (
      <SummaryPedestrianInfo participant={participant} {...props}/>
    );
  }
}

export function SummaryParticipantItemValue({label, jumpTo, participant, children, refName}) {
  if (participant.participantInfo.choice === booleanTypesConstants.DONT_KNOW) {
    return (
      <Summary.ItemValue label={label} formName={jumpTo} refName={refName}>
        {form => <Text label="vehicleInfo.label.dontKnow"/>}
      </Summary.ItemValue>
    );
  }

  return (
    <Summary.ItemValue label={label} formName={jumpTo} refName={refName}>
      {children}
    </Summary.ItemValue>
  )
}