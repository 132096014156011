import React, {Component} from "react";

import Layout from "components/common/Layout";
import FormErrorMessage from "components/form/FormErrorMessage";
import ToggleButton from "components/form/ToggleButton";
import VehicleInfoToggleButton from "components/step/VehicleInfoToggleButton";
import responsiblePersonConstants from "constants/responsiblePerson";

export default class ResponsiblePersonRight extends Component {
  render() {
    const {values: {participants}} = this.props;

    return (
      <VehicleInfoToggleButton name="responsiblePerson">
        <Layout flex={1} spacing={20}>
          <Layout.RowChildren>
            <Participants participants={participants}/>
          </Layout.RowChildren>
          <Layout.OneColumn spacing={30}></Layout.OneColumn>
          <Layout.RowChildren minWidth={210}>
            <ToggleButton.Choice value={responsiblePersonConstants.CANT_AGREE}
                                 label="responsiblePerson.content.button.cantagree"
                                 resetMultiple={true}/>
            <ToggleButton.Choice value={responsiblePersonConstants.DONT_KNOW}
                                 label="button.dontKnow"
                                 resetMultiple={true}/>
          </Layout.RowChildren>

          <Layout.RowChildren>
            <FormErrorMessage/>
          </Layout.RowChildren>
        </Layout>
      </VehicleInfoToggleButton>
    );
  }
}

function Participants({participants}) {
  return (
    <Layout flex={1} spacing={20}>
      {participants.map((participant, index) => (
        <Layout.OneColumn key={index}>
          <VehicleInfoToggleButton.Choice info={participant}/>
        </Layout.OneColumn>
      ))}
    </Layout>
  );
}
