import React, {Component} from "react";

import * as Yup from "yup";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import Header from "containers/step/Header";
import HomeDamageTypeRight from "containers/step/homeDamageType/HomeDamageTypeRight";
import SplitViewContainer from "containers/view/SplitViewContainer";

const validationSchema = Yup.object().shape({
  homeDamageType: Yup.object().shape({
    selected: Yup.array().required("validation.required").min(1, "validation.homeDamageType.min"),
  }),
});

// homeStep 3
export default class HomeDamageTypeStepContainer extends Component {
  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="homeDamageType.sidebar.title"/>
        <Text element="div" isHtml={true} label="homeDamageType.sidebar.subtitle"/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <HomeDamageTypeRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>
    );
  }
}
