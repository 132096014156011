import React, {Component} from "react";

import Layout from "components/common/Layout";
import TextField from "components/form/TextField";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";

export default class PhoneNumberRight extends Component {
  render() {
    return (
      <Layout width="100%" spacing={8} maxWidth={450} justifyContent="center">
        <Layout.OneColumn>
          <TextField name="message"
                     placeholder="phoneNumber.content.message.placeholder"
                     multiLine={true}
                     maxLength={fieldLengthConstants[flowConstants.steps.PHONE_NUMBER.NAME].message + 1}/>
        </Layout.OneColumn>
      </Layout>
    );
  }
}