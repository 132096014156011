import React from "react";

import cx from "classnames";

import Link from "components/common/Link";
import RemoveIcon from "components/icon/RemoveIcon";

import styles from "components/form/RemoveButton.module.scss";

export default function RemoveButton({label, onClick, variant}) {
  const className = cx({
    [styles.root]: true,
    [styles.light]: variant === "light",
    [styles.dark]: variant === "dark",
  });

  return (
    <div onClick={onClick}>
      <RemoveIcon variant={variant}/>
      <Link className={className} variant="variant4" label={label}/>
    </div>
  );
}
