import React from "react";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import ToggleButton from "components/form/ToggleButton";
import otherPersonRelationshipTypeConstants from "constants/otherPersonRelationshipTypes";

export default function RelationshipToggleButton({name, label}) {
  return (
    <Layout width="100%" spacing={10} maxWidth={450}>
      <Layout.OneColumn spacing={30}></Layout.OneColumn>
      <Layout.OneColumn>
        <Text element="h5" variant="variant1" label={`${label}.content.relationship.title`}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <Text element="span" variant="variant12" label={`${label}.content.relationship.subtitle`}/>
      </Layout.OneColumn>
      <Layout.RowChildren>
        <ToggleButton name={`${name}.relationship`}>
          <Layout.OneRow spacing={5} flex={1}>
            <ToggleButton.Choice label={`${label}.content.button.parent`}
                                 value={otherPersonRelationshipTypeConstants.PARENT}
                                 variant="variant11"/>
            <ToggleButton.Choice label={`${label}.content.button.child`}
                                 value={otherPersonRelationshipTypeConstants.CHILD}
                                 variant="variant11"/>
          </Layout.OneRow>
          <Layout.OneColumn spacing={10}/>
          <Layout.OneRow spacing={5} flex="1">
            <ToggleButton.Choice label={`${label}.content.button.partner`}
                                 value={otherPersonRelationshipTypeConstants.PARTNER}
                                 variant="variant11"/>
            <ToggleButton.Choice label={`${label}.content.button.other`}
                                 value={otherPersonRelationshipTypeConstants.OTHER}
                                 variant="variant11"/>
          </Layout.OneRow>
          <Layout.OneColumn spacing={30}></Layout.OneColumn>
        </ToggleButton>
      </Layout.RowChildren>
    </Layout>
  );
}
