import React, {Component} from "react";

import {CounterUserContext} from "containers/context/CounterUserContainer";
import {MapContext} from "./MapContainer";

export const CurrentLocationContext = React.createContext({});

export default function CurrentLocationContainer(props) {
  return (
    <CounterUserContext.Consumer>
      {counterUserContext => (
        <MapContext.Consumer>
          {mapContext => (
            <InnerCurrentLocationContainer counterUserContext={counterUserContext} mapContext={mapContext} {...props}/>
          )}
        </MapContext.Consumer>
      )}
    </CounterUserContext.Consumer>
  );
}

class InnerCurrentLocationContainer extends Component {
  state = {
    loaded: false,
    lat: 46.063227,
    lng: 14.510466,
  };

  async componentDidMount() {
    const {counterUserContext, mapContext} = this.props;

    if (navigator && navigator.geolocation) {
      if (counterUserContext.authenticated) {
        const geocoder = new mapContext.maps.Geocoder();

        geocoder.geocode({address: `${counterUserContext.city}, Slovenija`}, value => {
          this.setState({
            loaded: true,
            lat: value[0].geometry.location.lat(),
            lng: value[0].geometry.location.lng(),
          })
        });
      } else {
        navigator.geolocation.getCurrentPosition(pos => {
          const coords = pos.coords;
          this.setState({
            loaded: true,
            lat: coords.latitude,
            lng: coords.longitude,
          });
        });
      }
    }
  }

  render() {
    const {children} = this.props;

    return (
      <CurrentLocationContext.Provider value={this.state}>
        {children}
      </CurrentLocationContext.Provider>
    );
  }
}