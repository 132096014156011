import React, {Component} from 'react';

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import AccidentLocationRight from "containers/step/accidentLocation/AccidentLocationRight";
import Header from "containers/step/Header";
import SplitViewContainer from "containers/view/SplitViewContainer";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import Yup, {getMax} from "selectors/yup";

const validationSchema = Yup.object().shape({
  accidentLocation: Yup.object().nullable(),
  locationDescription: getMax(Yup.string().trim(), fieldLengthConstants[flowConstants.steps.ACCIDENT_LOCATION.NAME].description),
}).atLeastOneRequired(["accidentLocation", "locationDescription"], "accidentLocation.validation.locationDescription");

// step 8
export default class AccidentLocationStepContainer extends Component {
  left = ({claimType, fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label={`accidentLocation.sidebar.${claimType}.title`}/>
        <Text element="div" label={`accidentLocation.sidebar.${claimType}.subtitle`} isHtml={true}/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return <AccidentLocationRight {...props} />
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>
    );
  }
}
