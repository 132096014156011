import React from "react";

import {isMobile} from "react-device-detect";

import Layout from "components/common/Layout";
import Text from "components/common/Text";

export default function VehicleDamageInspectionPointContent({vehicleDamageInspectionPoint, vehicleDamageInspectionPointSelected, repairServiceType}) {
  if (!vehicleDamageInspectionPointSelected) {
    return (
      <VehicleDamageInspectionPointNotSelected repairServiceType={repairServiceType}/>
    );
  }

  return (
    <VehicleDamageInspectionPointSelected vehicleDamageInspectionPoint={vehicleDamageInspectionPoint} repairServiceType={repairServiceType}/>
  );
}

function VehicleDamageInspectionPointNotSelected({repairServiceType}) {
  return (
    <Layout flex={1}>
      <Layout.OneColumn textAlign="center" spacing={20}>
        {isMobile
          ? null
          : <Text element="span" variant="variant2" label={`${repairServiceType}.content.vehicleDamageInspectionPointNotSelected.title`}/>}
      </Layout.OneColumn>
      <Layout.OneColumn textAlign="center" spacing={35}>
        <Text element="span" variant="variant7" label={`${repairServiceType}.content.vehicleDamageInspectionPointNotSelected.label`}/>
      </Layout.OneColumn>
    </Layout>
  );
}

function VehicleDamageInspectionPointSelected({vehicleDamageInspectionPoint, repairServiceType}) {
  return (
    <Layout flex={1} spacing={3} textAlign="center">
      <Layout.OneColumn spacing={5}>
        <Text element="span" variant="variant2" label={`${repairServiceType}.content.vehicleDamageInspectionPointSelected.label`}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <Text variant="variant3">{vehicleDamageInspectionPoint.name}</Text>
      </Layout.OneColumn>
      <Layout.OneColumn spacing={15}>
        <Text variant="variant3">{vehicleDamageInspectionPoint.address}, {vehicleDamageInspectionPoint.zip} {vehicleDamageInspectionPoint.city}</Text>
      </Layout.OneColumn>
      <Layout.OneColumn spacing={15}/>
    </Layout>
  );
}
