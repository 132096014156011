import React from "react";

import Text from "components/common/Text";
import booleanTypeConstants from "constants/booleanTypes";
import flowConstants from "constants/flow";
import summaryRefConstants from "constants/summaryRef";
import Summary from "containers/step/summary/Summary";

export default function SummaryWitnesses() {
  return (
    <Summary title="summary.content.witnessesPresent.witnesses.title" onePart={true}
             refName={summaryRefConstants.PERSON_WITNESSES.REF}>
      <Summary.Items formName={{name: flowConstants.personSteps.WITNESSES_PRESENT.NAME}}
                     condition={form => form.witnessesPresent === booleanTypeConstants.YES}>
        {form => <SummaryWitness {...form} />}
      </Summary.Items>
    </Summary>
  );
}

function SummaryWitness({jumpTo, witnesses}) {
  return (
    witnesses.map((witness, key) => (
      <Summary.ItemValue key={key}
                         label={<Text label="summary.content.witnessesPresent.witness.label" parameters={[key + 1]}/>}
                         refName={summaryRefConstants.PERSON_WITNESSES.WITNESS(key)}
                         formName={jumpTo}>
        {form => witness}
      </Summary.ItemValue>
    ))
  );
}
