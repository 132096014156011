import React from "react";

import cx from "classnames";

import Text from "components/common/Text";

import styles from "components/common/Link.module.scss";

export default function Link({className, variant, label, onClick, children}) {
  const buttonChildren = label ? <Text variant="variant3" label={label}/> : children;
  
  const linkClass = cx({
    [styles.root]: true,
    [styles.variant1]: variant === "variant1",
    [styles.variant2]: variant === "variant2",
    [styles.variant3]: variant === "variant3",
    [styles.variant4]: variant === "variant4",
    [styles.variant5]: variant === "variant5",
  }, className);

  return (
    <button className={linkClass} onClick={onClick} type="button">
      {buttonChildren}
    </button>
  );
}
