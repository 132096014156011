export default
  [/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,
    ' ', /[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,
    ' ', /[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,
    ' ', /[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,
    ' ', /[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,
    ' ', /[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,
    ' ', /[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,
    ' ', /[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,
    ' ', /[A-Za-z0-9]/,/[A-Za-z0-9]/]
;