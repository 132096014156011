import React, {Component} from "react";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import Header from "containers/step/Header";
import SplitViewContainer from "containers/view/SplitViewContainer";
import VehicleTypeRight from "containers/step/vehicleType/VehicleTypeRight";

// step 9
export default class VehicleTypeStepContainer extends Component {
  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="vehicleType.sidebar.title"/>
        <Text element="div" label="vehicleType.sidebar.subtitle" isHtml={true}/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right showNextStepButton={false} {...props}>
        <VehicleTypeRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right}/>
    );
  }
}
