export default {
  SEMI_TRUCK: "semiTruck",
  CAR: "car",
  MOTORCYCLE: "motorcycle",
  SCOOTER: "scooter",
  BIKE: "bike",
  PEDESTRIAN: "pedestrian",
  TRACTOR: "tractor",
  COMPANY: "company",
  WORK_OTHER: "workVehicleOther",
};
