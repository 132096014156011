import React, {Component} from "react";

import {connect} from "formik";
import Measure from "react-measure";

import SketchCanvasTarget from "components/sketch/canvas/SketchCanvasTarget";
import SketchCanvasPreview from "components/sketch/canvas/SketchCanvasPreview";
import SketchDrawing from "components/sketch/drawing/SketchDrawing";
import sketchConstants from "constants/sketch";

class SketchCanvasContainer extends Component {
  state = {
    dimensions: null,
  };

  onResize = (contentRect) => {
    this.setState({
      dimensions: contentRect.bounds,
    });
  };

  render() {
    const {formik: {values: {navigationChoiceType}}, isReadOnly = false} = this.props;

    const isDrawing = [sketchConstants.NAVIGATION.DRAW.NAME, sketchConstants.NAVIGATION.ERASER.NAME].indexOf(navigationChoiceType) !== -1;

    return (
      <Measure bounds
               onResize={this.onResize}>
        {({measureRef}) => (
          <div ref={measureRef} style={{width: "100%", height: "100%"}}>
            <SketchCanvasTarget {...this.props} {...this.state} />
            {!isReadOnly ? <SketchCanvasPreview {...this.state} /> : null}
            {(isDrawing && !isReadOnly) ? <SketchDrawing {...this.props} {...this.state}/> : null}
          </div>
        )}
      </Measure>
    );
  }
}

export default connect(SketchCanvasContainer);
