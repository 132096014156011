import React, {Component} from "react";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import ToggleButton from "components/form/ToggleButton";

export default class ParkedStepRight extends Component {
  render() {
    const {onNextStep, isValid} = this.props;

    return (
      <NextStepButton component={ToggleButton}
                      isValid={isValid}
                      buttonProps={{name: "parked"}}
                      onNextStep={onNextStep}>
        <Layout.OneRow spacing={10} flex={1}>
          <ToggleButton.Choice value={true} label="parked.content.parked"/>
          <ToggleButton.Choice value={false} label="parked.content.drove"/>
        </Layout.OneRow>
      </NextStepButton>
    );
  }
}
