import React, {Component} from "react";

import Yup, {getMax} from "selectors/yup";

import Text from "components/common/Text";
import SummaryHeader from "components/common/SummaryHeader";
import booleanTypesConstants from "constants/booleanTypes"
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import medicalCareDescriptionTypes from "constants/medicalCareDescriptionTypes";
import Header from "containers/step/Header";
import ResponsiblePersonRelationRight from "containers/step/responsiblePersonRelation/ResponsiblePersonRelationRight";
import SplitViewContainer from "containers/view/SplitViewContainer";

const validationSchema = Yup.object().shape({
  description: Yup.string().when("responsiblePersonRelation", {
    is: booleanTypesConstants.YES,
    then: getMax(Yup.string().trim(), fieldLengthConstants[flowConstants.personSteps.RESPONSIBLE_PERSON_RELATION.NAME].description),
  }),
});

export default class ResponsiblePersonRelationStepContainer extends Component {
  left = ({fromSummary, step}) => {
    const label = step.medicalCareTypeDescription === medicalCareDescriptionTypes.DEATH
      ? "responsiblePersonRelation.sidebar.death.title" : "responsiblePersonRelation.sidebar.title";
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <div><Text element="h2" label={label}/></div>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <ResponsiblePersonRelationRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>
    );
  }
}
