import React, {Component} from "react";

import {connect} from "react-redux";

import _ from "lodash";

import {getMobileUserData} from "actions/flow";
import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import ToggleButton from "components/form/ToggleButton";
import booleanTypesConstants from "constants/booleanTypes";
import {ConfigContext} from "containers/context/ConfigContainer";
import Header from "containers/step/Header";
import SplitViewContainer from "containers/view/SplitViewContainer";
import {formatDate} from "utility/utilityFunctions";

class InsuranceOwnerStepContainer extends Component {
  left({claimType, fromSummary}) {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label={`insuranceOwner.sidebar.${claimType}.title`}/>
      </SplitViewContainer.Left>
    );
  }

  right(props) {
    const {onNextStep} = props;

    return (
      <SplitViewContainer.Right showNextStepButton={false} {...props}>
        <Layout spacing={10}>
          <Layout.OneColumn flex={1}>
            <NextStepButton component={ToggleButton}
                            onNextStep={onNextStep}
                            isValid={true}
                            buttonProps={{
                              name: "owner",
                            }}>
              <Layout spacing={10}>
                <Layout.OneColumn flex={1}>
                  <Layout.OneRow spacing={10} flex={1}>
                    <ToggleButton.Choice value={booleanTypesConstants.YES} label="button.yes"/>
                    <ToggleButton.Choice value={booleanTypesConstants.NO} label="button.no"/>
                  </Layout.OneRow>
                </Layout.OneColumn>
              </Layout>
            </NextStepButton>
          </Layout.OneColumn>
        </Layout>
      </SplitViewContainer.Right>
    );
  }

  handleSubmit = (url) => async (values) => {

    const {accidentDateForm, claimType, mobileUserId} = this.props;

      const userData = {
        userId: mobileUserId,
        type: claimType,
        accidentDate: accidentDateForm ? formatDate(accidentDateForm) : null
      }
      this.props.getMobileUserData(url.getMobileAppUser, userData);
  }

  render() {
    return (
      <ConfigContext.Consumer>
        {context =>
          <SplitViewContainer left={this.left}
                              right={this.right}
                              onSubmit={this.handleSubmit(context.url)}/>
        }
      </ConfigContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => ({
  mobileUserId: state.flow.mobileUserId,
  claimType: _.at(state.flow.forms, "claimType.choiceType")[0],
  accidentDateForm: _.at(state.flow.forms, "accidentDate")[0]
});

const mapDispatchToProps = (dispatch) => ({
  getMobileUserData: (url, userData) => dispatch(getMobileUserData(url, userData )),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceOwnerStepContainer);