import React, {Component} from "react";

import {isUndefined} from "underscore";

export const SummaryRefContext = React.createContext([]);

export default class SummaryRefContainer extends Component {
  render() {
    const {refName, children} = this.props;

    return (
      <SummaryRefContext.Consumer>
        { parent => (
          <SummaryRefContext.Provider value={isUndefined(refName) ? [...parent] : [...parent, refName]}>
            {children}
          </SummaryRefContext.Provider>
        )}
      </SummaryRefContext.Consumer>
    );
  }
}
