import React from "react";

import cookie from "constants/cookie";
import {RegistryContext} from "containers/context/RegistyContainer";
import LoadStateContainer from "containers/LoadStateContainer";
import LoadSummaryContainer from "containers/LoadSummaryContainer";
import LoadUserInfoContainer from "containers/LoadUserInfoContainer";
import Login from "containers/Login";
import Maintenance from "containers/Maintenance";
import ReportAuthentication from "containers/ReportAuthentication";
import StateAppContainer from "containers/StateAppContainer";
import ReportAuthenticationExpired from "./ReportAuthenticationExpired";
import ClaimExpired from "./ClaimExpired";

export default function AppRouter() {

    let searchStr = window.location.search;
    let params = {};

    searchStr.replace(
        new RegExp("([^?=&]+)(=([^&]*))?", "g"),
        function ($0, $1, $2, $3) {
            params[$1] = $3;
        }
    );

    const {id, claimId, esig, type, us} = params;
    const submitId = sessionStorage.getItem(cookie.SUBMIT_ID);
    const finalSubmitId = submitId !== "false" ? submitId : false;

    return (
            <RegistryContext.Consumer>
                {context => {
                    if (context.registry.maintenance.maintenanceMode) {
                        return <Maintenance type={type} maintenance={context.registry.maintenance}/>;
                    } else if (params && ('login' in params)) {
                        return <Login/>;
                    } else if (params && ('report' in params)) {
                        if (window.asSkodeConfig && window.asSkodeConfig.claimExpired) {
                            return <ReportAuthenticationExpired/>;
                        } else {
                            return <ReportAuthentication id={id}/>;
                        }
                    } else if (id) {
                        if (window.asSkodeConfig && window.asSkodeConfig.claimExpired) {
                            return <ClaimExpired/>;
                        }
                        else {
                            return <LoadStateContainer id={id} esig={esig} user={us}/>;
                        }
                    } else if (claimId) {
                        return <LoadSummaryContainer claimId={claimId}/>;
                    } else if (submitId) {
                        return <LoadUserInfoContainer submitId={finalSubmitId}/>;
                    } else {
                        return <StateAppContainer/>;
                    }
                }}
            </RegistryContext.Consumer>
    )
}