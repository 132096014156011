import React, {Component} from "react";

import TextField from "components/form/TextField";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";

export default class TaxNumber extends Component {
  render() {
    const {name, placeholder, label, isRequired, isSlovenia} = this.props;
    const maxLength = isSlovenia ? fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].taxNumberSlo + 1 : fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].taxNumber + 1;

    return (
      <TextField name={name}
                 placeholder={placeholder}
                 label={label}
                 isRequired={isRequired}
                 maxLength={maxLength}
                 number={isSlovenia}/>
    )
  }
}