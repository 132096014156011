import React, {Component} from "react"

import cx from "classnames";
import close from "images/close.svg";
import darkClose from "images/darkClose.svg";

import styles from "components/icon/CloseIcon.module.scss";

export default class CloseIcon extends Component {
  static defaultProps = {
    onClose: () => null,
  };



  render() {
    const {onClose, dark = false, variant} = this.props;
    const className = cx({
      [styles.root]: true,
      [styles.variant1]: variant === "variant1",
      [styles.variant2]: variant === "variant2",
    });

    return (
      <img className={className} src={dark ? darkClose : close} onClick={onClose} alt="close"/>
    );
  }
}