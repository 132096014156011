import React, {Component} from "react";

import cx from "classnames";
import {Field} from "formik";
import _ from "lodash";
import {contains, identity, isArray, isEmpty, sortBy, without} from "underscore";

import Button from "components/common/Button";

import styles from "components/form/ToggleButton.module.scss";

const ToggleButtonContext = React.createContext();

export default class ToggleButton extends Component {
  render() {
    const {name, onClick, choice = true, multiple = false, children} = this.props;

    return (
      <Field
        name={name}
        render={(fieldProps) => (
          <ToggleButtonContext.Provider value={{fieldProps, onClick, choice, multiple}}>
            {children}
          </ToggleButtonContext.Provider>
        )}
      />
    );
  }
}

class ToggleChoice extends Component {
  static contextType = ToggleButtonContext;

  render() {
    return (
      <ToggleChoiceInner {...this.props}
                         context={this.context}/>
    );
  }
}

class ToggleChoiceInner extends Component {
  onClick = () => {
    const {value, context, resetMultiple = false} = this.props;
    const {fieldProps, onClick} = context;
    const {field: {name, value: currentValue}, form: {setFieldValue, validateForm, values}} = fieldProps;

    let newValue = value;
    if (context.multiple && !resetMultiple) {
      newValue = isArray(currentValue) ? currentValue : [];

      if (contains(newValue, value)) {
        newValue = without(newValue, value);
      } else {
        newValue = sortBy([...newValue, value], identity);
      }

      if (isEmpty(newValue)) {
        newValue = null;
      }
    }

    setFieldValue(name, newValue, false);

    const newValues = _.set(_.cloneDeep(values), name, newValue);

    if (onClick) {
      onClick(newValue, () => {
        validateForm(newValues);
      });
    } else {
      validateForm(newValues);
    }
  };

  render() {
    const {value, context, variant, className, condition} = this.props;
    const {field: {value: currentValue}} = context.fieldProps;
    const buttonClassName = cx({
      [styles.variant3]: variant === "variant3",
      [styles.variant4]: variant === "variant4",
      [styles.variant19]: variant === "variant19",
      [styles.variant21]: variant === "variant21",
      [styles.choice]: context.choice,
      [className]: true,
    });

    if(condition === false){
      return null;
    }

    const isSelected =
      isArray(currentValue)
        ? contains(currentValue, value)
        : value === currentValue;

    return (
      <Button {...this.props}
              variant={variant}
              className={buttonClassName}
              isSelected={isSelected}
              onClick={this.onClick}/>
    );
  }
}

ToggleButton.Choice = ToggleChoice;
