import React, {Component} from "react";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import Text from "components/common/Text";
import ToggleButton from "components/form/ToggleButton";
import Witnesses from "components/step/Witnesses";
import FadeView from "components/view/FadeView";
import booleanTypesConstants from "constants/booleanTypes";
import flowConstants from "constants/flow";

export default class WitnessesChoice extends Component {

  render() {
    const {reportedToPolice, values, onNextStep} = this.props;

    if (reportedToPolice !== booleanTypesConstants.NO) {
      return null;
    }

    return (
      <Layout width="100%" spacing={10} maxWidth={450}>
        <Layout.OneColumn spacing={40}/>
        <Layout.OneColumn>
          <Text element="p" label="homeReportedPolice.content.witnesses.present.title" variant="variant1"/>
        </Layout.OneColumn>
        <Layout.OneColumn flex={1}>
          <NextStepButton component={ToggleButton}
                          onNextStep={onNextStep}
                          isValid={true}
                          condition={value => value === booleanTypesConstants.NO}
                          buttonProps={{
                            name: "witnessesPresent",
                          }}>
            <Layout spacing={10}>
              <Layout.OneColumn flex={1}>
                <Layout.OneRow spacing={10} flex={1}>
                  <ToggleButton.Choice value={booleanTypesConstants.YES} label="button.yes" variant="variant22"/>
                  <ToggleButton.Choice value={booleanTypesConstants.NO} label="button.no" variant="variant22"/>
                </Layout.OneRow>
              </Layout.OneColumn>
            </Layout>
          </NextStepButton>
        </Layout.OneColumn>
        <Layout.OneColumn width="100%" maxWidth={450} justifyContent="center">
          <FadeView step={values.witnessesPresent}>
            <Witnesses screenName={flowConstants.homeSteps.HOME_REPORTED_POLICE.NAME}
                       witnessesPresent={values.witnessesPresent}/>
          </FadeView>
        </Layout.OneColumn>
      </Layout>
    );
  }
}
