import React, {Component} from "react";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import TextField from "components/form/TextField";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";

export default class DamageDescriptionRight extends Component {
  render() {
    return (
      <Layout flex={1} spacing={5}>
        <Layout.OneColumn>
          <TextField name="description"
                     placeholder="damageDescription.content.description.placeholder"
                     multiLine={true}
                     maxLength={fieldLengthConstants[flowConstants.personSteps.DAMAGE_DESCRIPTION.NAME].description + 1}/>
          <Layout.OneColumn spacing={30}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <Text element="span" variant="variant2" label="damageDescription.content.amount.title"/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <TextField name="amount"
                     label="damageDescription.content.amount.label"
                     placeholder="damageDescription.content.amount.placeholder"
                     maxLength={fieldLengthConstants[flowConstants.personSteps.DAMAGE_DESCRIPTION.NAME].amount + 1}
                     currency={true}/>
        </Layout.OneColumn>
      </Layout>
    );
  }
}
