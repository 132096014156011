import React from 'react';

import Map from "components/map/Map";

export default function PickLocationMap({center, location, onLocationClick, country}) {
  const markers = location ? [{location}] : [];

  return (
    <Map center={center} markers={markers} onLocationClick={onLocationClick} country={country}/>
  );
}