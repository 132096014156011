import React, {Component} from "react";

import {connect} from "react-redux";

import {loadClaim} from "actions/flow";
import Loading from "components/common/Loading";
import StateAppContainer from "containers/StateAppContainer";
import {ConfigContext} from "containers/context/ConfigContainer";

class LoadStateContainer extends Component {
  static contextType = ConfigContext;

  componentDidMount() {
    const {id, esig, user, loadClaim} = this.props;

    loadClaim(this.context.url.claim(id, esig, user));
  }

  render() {
    if (!this.props.loaded) {
      return <Loading/>;
    }

    return (
      <StateAppContainer/>
    );
  }
}


const mapStateToProps = (state) => ({
  loaded: state.flow.claimLoaded || false,
});

const mapDispatchToProps = (dispatch) => ({
  loadClaim: (url) => dispatch(loadClaim(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadStateContainer);
