import React from "react";

import Svg, {Circle, Path, Polygon, Rect, Text} from "components/svg/Svg";

export default function SemiTruckSvg(props) {
  return (

    <Svg {...props} viewBox="0 0 405.5 592.57">
      <g>
        <Text x="50%" y="20" textAnchor="middle">Spredaj</Text>
      </g>
      <g transform="translate(0 33)">
        <Circle part={0} cx="367.5" cy="58.31" r="37.5"/>
        <Circle part={1} cx="367.5" cy="58.31" r="19.5"/>
        <Circle part={2} cx="367.5" cy="350.14" r="37.5"/>
        <Circle part={3} cx="367.5" cy="350.14" r="19.5"/>
        <Circle part={4} cx="38" cy="58.31" r="37.5"/>
        <Circle part={5} cx="38" cy="58.31" r="19.5"/>
        <Circle part={6} cx="38" cy="350.14" r="37.5"/>
        <Circle part={7} cx="38" cy="350.14" r="19.5"/>
        <Circle part={8} cx="367.5" cy="442.14" r="37.5"/>
        <Circle part={9} cx="367.5" cy="442.14" r="19.5"/>
        <Circle part={10} cx="38" cy="442.14" r="37.5"/>
        <Circle part={11} cx="38" cy="442.14" r="19.5"/>
        <g>
          <Path part={12} d="M347.4,111.6c0-14.8-32-12.7-32-12.7v15.5h32V111.6z"/>
          <g>
            <Path part={13}
                  d="M216.6,12.8h34c-0.4-4.1-0.6-8.2-0.6-12.3h-94.2c0,3.6-0.2,7.8-0.6,12.3L216.6,12.8L216.6,12.8z"/>
            <Path part={14} d="M58.1,111.6v2.8h32V98.9C90.1,98.9,58.1,96.8,58.1,111.6z"/>
            <Path part={15}
                  d="M138.6,103.4c1-1.8,1.8-3.4,2.6-4.9l-17-27.5c-8,5.1-17,8.6-26.4,10.2c-1.4,9.5-2.1,19.1-2.1,28.7v35.7h31.5C130.3,134.9,134.1,111.5,138.6,103.4z"/>
            <Path part={16}
                  d="M310.1,109.9c0-9.6-0.7-19.2-2.1-28.7c-9.4-1.6-18.4-5-26.4-10.2l-17,27.6c0.8,1.5,1.6,3.1,2.6,4.9c4.4,8,8.2,31.4,11.5,42.2h31.5L310.1,109.9z"/>
            <Path part={17}
                  d="M262.5,104.7c-1.1-2-2-3.8-2.9-5.5c-1.5-3.1-6.3-4.1-10-4.1h-93.5c-3.7,0-8.5,1.1-10,4.1c-0.9,1.7-1.8,3.5-2.9,5.5c-4.2,7.6-7.9,30.6-11,40.9h141.2C270.4,135.3,266.7,112.3,262.5,104.7z"/>
            <Path part={18}
                  d="M128,68.2l16.3,26.5c3.2-2.3,8-3.2,11.9-3.2h93.5c3.9,0,8.6,0.9,11.9,3.2l16.3-26.5c-17.9-13.8-24.5-34.9-26.8-51.9h-96.3C152.5,33.4,145.9,54.4,128,68.2z"/>
            <Path part={19}
                  d="M133.4,31.5c0.8-1.7,1.2-3.5,1.2-5.3l0,0c0-5.8-4.2-11.1-11.2-14.1c-7.9,6.3-15.1,15.8-19.8,29.8C116,44.9,129.3,40.2,133.4,31.5z"/>
            <Path part={20}
                  d="M150.7,0.7c-8.5,1.1-16.6,4.1-23.7,8.8c13.1,6.2,16.6,18.7,7.8,27.9c-7,7.4-20.1,10.7-32.2,8c-2.8,10.4-4.2,21.1-4.2,31.9c9.4-1.8,18.3-5.7,25.9-11.5C146.9,48.4,150.6,17.6,150.7,0.7z"/>
            <Path part={21}
                  d="M270.1,26.2L270.1,26.2c0,1.9,0.4,3.7,1.3,5.4c4.2,8.7,17.5,13.3,29.7,10.3c-4.6-14.1-11.9-23.5-19.8-29.8C274.4,15.1,270.1,20.5,270.1,26.2z"/>
            <Path part={22}
                  d="M280.5,65.9c7.6,5.8,16.5,9.7,25.9,11.5c0-10.8-1.4-21.5-4.3-31.9c-12.1,2.7-25.2-0.6-32.2-8c-8.8-9.2-5.2-21.7,7.9-27.9c-7.1-4.7-15.2-7.8-23.7-8.9C254.1,17.6,257.8,48.4,280.5,65.9z"/>
          </g>
        </g>
        <g>
          <Rect part={23} x="278.8" y="151.5" width="30.9" height="350.7"/>
          <Path part={25} d="M125,534v-25.9H96.1C96.1,508.1,97.2,534,125,534z"/>
          <Path part={26} d="M278.8,534c30.1,0,30.9-25.8,30.9-25.8h-30.9V534z"/>
          <Rect part={28} x="131" y="508.1" width="141.8" height="25.8"/>
          <Polygon part={29}
                   points="131,502.1 171.9,502.1 231.9,502.1 272.8,502.1 272.8,151.5 131,151.5"/>
          <Rect part={30} x="96.1" y="151.5" width="28.8" height="350.7"/>
          <Polygon part={31}
                   points="278.8,151 272.8,151 272.8,151 272.8,151.5 278.8,151.5 278.8,151"/>
        </g>
      </g>
      <g>
        <Text x="50%" y="585.57" textAnchor="middle">Zadaj</Text>
      </g>
    </Svg>
  );
}
