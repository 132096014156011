import React, {Component} from "react";

import Text from "components/common/Text";
import SummaryHeader from "components/common/SummaryHeader";
import SplitViewContainer from "containers/view/SplitViewContainer";
import HasCascoRight from "containers/step/hasCasco/HasCascoRight";
import Header from "containers/step/Header";

// step 10
export default class HasCascoStepContainer extends Component {
  left = ({fromSummary, step}) => {
    const {otherCompany} = step;
    const titleAddon = otherCompany ? "otherCompany" : "unknownCompany";

    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <div><Text element="h2" label={`hasCasco.${titleAddon}.sidebar.title`}/></div>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <HasCascoRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right}/>
    );
  }
}
