import React, {Component} from "react";
import {Provider} from "react-redux";

import AppRouter from "containers/AppRouter";
import ConfigContainer from "containers/context/ConfigContainer";
import CounterUserContainer from "containers/context/CounterUserContainer";
import CurrentLocationContainer from "containers/context/CurrentLocationContainer";
import DragDropContainer from "containers/context/DragDropContainer";
import GtmContainer from "containers/context/GtmContainer";
import I18nContainer from "containers/context/I18nContainer";
import MapContainer from "containers/context/MapContainer";
import RegistryContainer from "containers/context/RegistyContainer";
import ScreenConfigurationContainer from "containers/context/ScreenConfigurationContainer";
import {CookiesProvider} from "react-cookie";
import ErrorBoundary from "./ErrorBoundary";

class App extends Component {
  render() {
    const {store} = this.props;

    return (
      <CookiesProvider>
        <DragDropContainer>
          <Provider store={store}>
            <ConfigContainer>
              <CounterUserContainer>
                <MapContainer>
                  <CurrentLocationContainer>
                    <I18nContainer>
                      <ErrorBoundary>
                        <ScreenConfigurationContainer>
                          <RegistryContainer>
                            <GtmContainer>
                              <AppRouter/>
                            </GtmContainer>
                          </RegistryContainer>
                        </ScreenConfigurationContainer>
                      </ErrorBoundary>
                    </I18nContainer>
                  </CurrentLocationContainer>
                </MapContainer>
              </CounterUserContainer>
            </ConfigContainer>
          </Provider>
        </DragDropContainer>
      </CookiesProvider>
    );
  }
}

export default App;
