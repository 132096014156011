import React from "react";

import VehicleInfo from "components/step/VehicleInfo";
import VehicleInfoLogic from "components/step/VehicleInfoLogic";

export default function VehicleInfoContainer({variant, info, participantInfo = {}, children}) {
  return (
    <VehicleInfo variant={variant || "variant1"} {...info} choice={participantInfo.choice}>
      {children}
      <VehicleInfoLogic info={info} participantInfo={participantInfo}/>
    </VehicleInfo>
  );
}
