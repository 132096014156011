import React from "react";

import {initial, tail, zip} from "underscore";

export default function SketchPath({value, selected, dimensions, onClick, ...props}) {
  const lines = zip(initial(value), tail(value));
  const paths = lines.map(([from, to]) => `M${from[0]} ${from[1]} L${to[0]} ${to[1]}`);

  const strokeOpacity = selected ? 1 : 0;

  return (
    <svg {...props} width={dimensions.TARGET_WIDTH} height={dimensions.TARGET_HEIGHT}>
      {
        paths.map((path, i) => (
          <path key={`first-${i}`} d={path} stroke={"#ddd"} strokeOpacity={strokeOpacity} strokeWidth={15 * 5} fillOpacity={0} onClick={onClick} />
        ))
      }

      {
        paths.map((path, i) => (
          <path key={`second-${i}`} d={path} stroke={"#222"} strokeWidth="15" fillOpacity={0} onClick={onClick} />
        ))
      }
    </svg>
  );
}
