import React from "react";

import _ from "lodash";

import Text from "components/common/Text";
import DocumentsIcon from "components/icon/DocumentsIcon";
import SummaryTable from "components/step/summary/SummaryTable";
import SummaryRefContainer from "components/step/summary/SummaryRefContainer";
import booleanTypesConstants from "constants/booleanTypes";
import summaryRefConstants from "constants/summaryRef";
import Summary from "containers/step/summary/Summary";
import {SummaryParticipantItemValue} from "containers/step/summary/car/SummaryParticipants";

export default function SummaryBikeOwnVehicleInfo({participant, jumpTo}) {
  const {participantInfo} = participant;

  switch (participantInfo.receiptChoice) {
    case booleanTypesConstants.NO:
      return (
        <SummaryRefContainer refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_BIKE_INFO.REF}>
          <SummaryParticipantItemValue
            label="summary.content.participantsInfo.bikeOwnVehicle.bikeDescription.description"
            jumpTo={jumpTo} participant={participant}
            refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_BIKE_INFO.DESCRIPTION}>
            {form => _.get(form, "participant.participantInfo.bikeDescription.description") || ""}
          </SummaryParticipantItemValue>
          <SummaryParticipantItemValue
            label="summary.content.participantsInfo.bikeOwnVehicle.bikeDescription.purchaseYear"
            jumpTo={jumpTo} participant={participant}
            refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_BIKE_INFO.PURCHASE_YEAR}>
            {form => _.get(form, "participant.participantInfo.bikeDescription.purchaseYear") || ""}
          </SummaryParticipantItemValue>
          <SummaryParticipantItemValue
            label="summary.content.participantsInfo.bikeOwnVehicle.bikeDescription.currentValue"
            jumpTo={jumpTo} participant={participant}
            refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_BIKE_INFO.CURRENT_VALUE}>
            {form => _.get(form, "participant.participantInfo.bikeDescription.currentValue") || ""}
          </SummaryParticipantItemValue>
        </SummaryRefContainer>
      );
    case booleanTypesConstants.YES:
      return (
        <SummaryRefContainer refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_BIKE_INFO.REF}>
          <SummaryTable.HeaderContent>
            <Text element="span" label="summary.content.participantsInfo.bikeOwnVehicle.photos.title"/>
          </SummaryTable.HeaderContent>
          <Summary.Items formName={jumpTo}>
            {() => (
              <SummaryTable.ItemContent jumpTo={jumpTo}
                                        refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_BIKE_INFO.PHOTOS}>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                  {
                    [...participantInfo.photos || []].map((document, index) =>
                      <DocumentsIcon key={index} id={document.id} type={document.type} name={document.name}/>
                    )
                  }
                </div>
              </SummaryTable.ItemContent>
            )}
          </Summary.Items>
        </SummaryRefContainer>
      );
    default:
      return null;
  }
}