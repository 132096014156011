import React from "react";

import Layout from "components/common/Layout";
import Link from "components/common/Link";
import Text from "components/common/Text";
import TextField from "components/form/TextField";
import Address from "components/step/Address";
import fieldLengthConstants from "constants/fieldLength";
import {capitalize} from "utility/textTransform";

export default function Witness({index, onRemove, showRemove, screenName}) {
  return (
    <Layout width="100%" spacing={10} maxWidth={450}>
      <Layout.OneColumn spacing={30}></Layout.OneColumn>
      <Layout.OneColumn>
        <Text element="h5" variant="variant2" label={`${screenName}.content.witness.data.title`}
              parameters={[index + 1]}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name={`witnesses[${index}].name`}
                   transform={capitalize}
                   placeholder={`${screenName}.content.witness.name.placeholder`}
                   label={`${screenName}.content.witness.name.label`}
                   maxLength={fieldLengthConstants[screenName].name + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name={`witnesses[${index}].surname`}
                   transform={capitalize}
                   placeholder={`${screenName}.content.witness.surname.placeholder`}
                   label={`${screenName}.content.witness.surname.label`}
                   maxLength={fieldLengthConstants[screenName].surname + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <Address name={`witnesses[${index}].address`}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name={`witnesses[${index}].telephoneNumber`}
                   placeholder={`${screenName}.content.witness.telephoneNumber.placeholder`}
                   label={`${screenName}.content.witness.telephoneNumber.label`}
                   maxLength={fieldLengthConstants[screenName].telephoneNumber + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name={`witnesses[${index}].email`}
                   placeholder={`${screenName}.content.witness.email.placeholder`}
                   label={`${screenName}.content.witness.email.label`}
                   maxLength={fieldLengthConstants[screenName].email + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        {showRemove ? <Link label={`${screenName}.content.witness.button.remove`} onClick={onRemove}/> : null}
      </Layout.OneColumn>
    </Layout>
  );
}
