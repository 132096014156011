import React, {Component} from "react";

import {contains, sortBy, without} from "underscore";

const SvgContext = React.createContext();

const styles = {
  part: {
    stroke: '#facdbc',
    strokeMiterlimit: 10,
    transition: 'fill 200ms ease-in-out',
    cursor: 'pointer',
  },
  unselected: {
    fill: '#fff',
  },
  selected: {
    fill: '#c21b17',
  },
  text: {
    fill: '#000000',
  },
  hover: {
    fill: '#facdbc',
  }
};

export default class Svg extends Component {
  onClick = (part) => () => {
    const {value, onClick} = this.props;

    const newValue = contains(value, part) ? without(value, part) : sortBy([...value, part], (x) => x);
    if (onClick) {
      onClick(newValue);
    }
  };

  state = {
    value: this.props.value,
    onClick: this.onClick,
  };

  componentDidMount() {
    const {value, onClick} = this.props;

    if (onClick) {
      // https://github.com/jaredpalmer/formik/issues/930
      setTimeout(() => onClick(value), 0);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState(state => ({
        ...state,
        value: this.props.value,
      }));
    }
  }

  render() {
    const {children, onClick, value, ...props} = this.props;

    return (
      <SvgContext.Provider value={this.state}>
        <svg {...props} className={styles.svg} xmlns="http://www.w3.org/2000/svg">
          {children}
        </svg>
      </SvgContext.Provider>
    );
  }
}

export function Path(props) {
  return (
    <Element {...props} component={<path/>}/>
  );
}

export function Circle(props) {
  return (
    <Element {...props} component={<circle/>}/>
  );
}

export function Polygon(props) {
  return (
    <Element {...props} component={<polygon/>}/>
  );
}

export function Rect(props) {
  return (
    <Element {...props} component={<rect/>}/>
  );
}

export function Text(props) {
  const style = {
    ...styles.text,
  };

  return React.cloneElement(<text/>, {style, ...props});
}

class Element extends Component {
  state = {
    mouseOver: false,
  };

  render() {
    const {part, component, ...props} = this.props;

    return (
      <SvgContext.Consumer>
        {context => {
          let style = {};
          if (contains(context.value, part)) {
            style = {
              ...styles.part,
              ...styles.selected,
            };
          } else if (this.state.mouseOver) {
            style = {
              ...styles.part,
              ...styles.hover,
            };
          } else {
            style = {
              ...styles.part,
              ...styles.unselected,
            };
          }

          return React.cloneElement(component, {
            style,
            onClick: context.onClick(part),
            onMouseEnter: () => {
              this.setState({
                mouseOver: true,
              })
            },
            onMouseLeave: () => {
              this.setState({
                mouseOver: false,
              })
            }, ...props
          });
        }}
      </SvgContext.Consumer>
    );
  }
}
