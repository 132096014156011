import React from "react";

import Button from "components/common/Button";
import SketchCanvasContainer from "components/sketch/canvas/SketchCanvasContainer";
import {SketchContext} from "components/sketch/SketchContainer";

import styles from "components/sketch/Sketch.module.scss";

import leftArrow from "images/left-arrow.svg";

export default function Sketch(props) {
  return (
    <SketchContext.Consumer>
      {({onPreviousStep, hasPreviousStep}) => (
        <div className={styles.root}>
          <Back onClick={onPreviousStep} hasPreviousStep={hasPreviousStep}/>
          <SketchCanvasContainer {...props} />
        </div>
      )}
    </SketchContext.Consumer>
  );
}

function Back({onClick, hasPreviousStep}) {
  return (
    <Button className={styles.back}
            icon={<img className={styles.icon} src={leftArrow} alt="back"/>}
            onClick={onClick}
            variant="variant6"
            isDisabled={!hasPreviousStep}/>
  )
}
