import React, {Component} from "react";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import ToggleButton from "components/form/ToggleButton";
import booleanTypesConstants from "constants/booleanTypes";

// step 9c
export default class PreviousDamageRight extends Component {
  render() {
    const {onNextStep, isValid} = this.props;

    return (
      <NextStepButton component={ToggleButton}
                      onNextStep={onNextStep}
                      isValid={isValid}
                      buttonProps={{name: "hasPreviousDamage"}}>
        <Layout.OneRow spacing={10} flex={1}>
          <ToggleButton.Choice value={booleanTypesConstants.YES} label="previousDamage.content.button.yes"/>
          <ToggleButton.Choice value={booleanTypesConstants.NO} label="previousDamage.content.button.no"/>
        </Layout.OneRow>
      </NextStepButton>
    );
  }
}
