import React from "react";

import Switch, {Case, Default} from "react-switch-case";

import NextStepButton from "components/common/NextStepButton";
import Layout from "components/common/Layout";
import ToggleButton from "components/form/ToggleButton";
import PersonFontIcon from "components/icon/PersonFontIcon";
import IcoMoon from "components/icon/IcoMoon";
import FadeView from "components/view/FadeView";
import fontConstants from "constants/font";
import medicalCareTypes from "constants/medicalCareTypes";
import medicalCareDescriptionTypes from "constants/medicalCareDescriptionTypes";

export default function MedicalCareTypeRight({props}) {

  const {values, onNextStep} = props;

  return (
    <Layout flex={1}>
      <Layout.OneColumn>
        <NextStepButton component={ToggleButton}
                        condition={value => value === medicalCareTypes.OTHER}
                        isValid={true}
                        buttonProps={{name: "medicalCareType"}}
                        onNextStep={onNextStep}>
          <Layout.OneRow spacing={10} flex={1}>
            <ToggleButton.Choice value={medicalCareTypes.PAYMENT}
                                 icon={<IcoMoon icon={fontConstants.ICOMOON.INJURED_PERSON}/>}
                                 label="medicalCareType.button.payment.label"
                                 descriptionLabel="medicalCareType.button.payment.descriptionLabel"/>
            <ToggleButton.Choice value={medicalCareTypes.HEALTH_SERVICE}
                                 icon={<IcoMoon icon={fontConstants.ICOMOON.HAND_SHAKE}/>}
                                 label="medicalCareType.button.healthService.label"
                                 descriptionLabel="medicalCareType.button.healthService.descriptionLabel"/>
            <ToggleButton.Choice value={medicalCareTypes.OTHER}
                                 icon={<IcoMoon icon={fontConstants.ICOMOON.THREE_DOTS}/>}
                                 label="medicalCareType.button.other.label"/>
          </Layout.OneRow>
        </NextStepButton>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <FadeView step={values.medicalCareType}>
          <Switch condition={values.medicalCareType}>
            <Case value={medicalCareTypes.PAYMENT}>
              <Layout width="100%" maxWidth={450} justifyContent="center">
                <Layout.OneColumn spacing={35}/>
                <Layout.OneColumn>
                  <NextStepButton component={ToggleButton}
                                  onNextStep={onNextStep}
                                  isValid={true}
                                  condition={value => value}
                                  buttonProps={{name: "medicalCareTypeDescription"}}>
                    <Layout spacing={10} flex={1}>
                      <Layout.RowChildren>
                        <ToggleButton.Choice value={medicalCareDescriptionTypes.SICK}
                                             icon={<PersonFontIcon icon={fontConstants.PERSON_ICON.BEING_SICK}/>}
                                             label="medicalCareType.content.button.sick.label"/>
                        <ToggleButton.Choice value={medicalCareDescriptionTypes.INJURY}
                                             icon={<PersonFontIcon icon={fontConstants.PERSON_ICON.ACCIDENT}/>}
                                             label="medicalCareType.content.button.accident.label"/>
                      </Layout.RowChildren>
                      <Layout.RowChildren>
                        <ToggleButton.Choice value={medicalCareDescriptionTypes.DEATH}
                                             icon={<PersonFontIcon icon={fontConstants.PERSON_ICON.CEMETARY}/>}
                                             label="medicalCareType.content.button.death.label"/>
                        <ToggleButton.Choice value={medicalCareDescriptionTypes.RETURN_OF_COSTS}
                                             icon={<PersonFontIcon icon={fontConstants.PERSON_ICON.PILLS}/>}
                                             label="medicalCareType.content.button.returnOfCosts.label"/>
                      </Layout.RowChildren>
                    </Layout>
                  </NextStepButton>
                </Layout.OneColumn>
              </Layout>
            </Case>
            <Case value={medicalCareTypes.HEALTH_SERVICE}>
              <Layout width="100%" maxWidth={450} justifyContent="center">
                <Layout.OneColumn spacing={35}/>
                <Layout.OneColumn>
                  <NextStepButton component={ToggleButton}
                                  onNextStep={onNextStep}
                                  isValid={true}
                                  condition={value => value}
                                  buttonProps={{name: "medicalCareTypeDescription"}}>
                    <Layout spacing={10} flex={1}>
                      <Layout.RowChildren>
                        <ToggleButton.Choice value={medicalCareDescriptionTypes.SICK}
                                             icon={<PersonFontIcon icon={fontConstants.PERSON_ICON.BEING_SICK}/>}
                                             label="medicalCareType.content.button.sick.label"/>
                        <ToggleButton.Choice value={medicalCareDescriptionTypes.INJURY}
                                             icon={<PersonFontIcon icon={fontConstants.PERSON_ICON.ACCIDENT}/>}
                                             label="medicalCareType.content.button.accident.label"/>
                      </Layout.RowChildren>
                    </Layout>
                  </NextStepButton>
                </Layout.OneColumn>
              </Layout>
            </Case>
            <Default>
              {null}
            </Default>
          </Switch>
        </FadeView>
      </Layout.OneColumn>
    </Layout>
  );
}
