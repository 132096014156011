import React, {Component} from "react";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import Header from "containers/step/Header";
import DamageDescriptionRight from "containers/step/damageDescription/DamageDescriptionRight";
import SplitViewContainer from "containers/view/SplitViewContainer";
import Yup, {getMax} from "selectors/yup";

const validationSchema = Yup.object().shape({
  description: getMax(Yup.string().trim(), fieldLengthConstants[flowConstants.personSteps.DAMAGE_DESCRIPTION.NAME].description),
  amount: getMax(Yup.string().trim(), fieldLengthConstants[flowConstants.personSteps.DAMAGE_DESCRIPTION.NAME].amount),
});

export default class DamageDescriptionStepContainer extends Component {
  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <div><Text element="h2" label={`damageDescription.sidebar.title`}/></div>
        <div><Text element="div" label={`damageDescription.sidebar.subtitle`}/></div>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <DamageDescriptionRight/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>
    );
  }
}
