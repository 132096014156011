import React, {Component} from "react";

import axios from "axios";

import Button from "components/common/Button";
import Text from "components/common/Text";
import ExclamationMark from "components/icon/ExclamationMark";
import {ConfigContext} from "containers/context/ConfigContainer";
import {UploadContext} from "containers/context/UploadContainer";

import styles from "components/common/UploadButton.module.scss";

export default function UploadButton(props) {
  return (
    <ConfigContext.Consumer>
      {configContext => (
        <UploadContext.Consumer>
          {uploadContext => (
            <InnerUploadButton {...props} configContext={configContext} uploadContext={uploadContext}/>
          )}
        </UploadContext.Consumer>
      )}
    </ConfigContext.Consumer>
  )
}

class InnerUploadButton extends Component {
  state = {
    progress: null,
  };

  inputRef = React.createRef();

  onClick = () => {
    this.inputRef.current.click();
  };

  onChange = async (e) => {
    const {configContext, uploadContext, saveId} = this.props;

    if (e.target.files.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < e.target.files.length; i++) {
        formData.append("file[" + i + "]", e.target.files[i]);
      }
      formData.append("id", saveId);

      const config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.min(99, Math.round((progressEvent.loaded * 100) / progressEvent.total));
          this.setState({
            progress: `${percentCompleted}%`,
          })
        }
      };

      uploadContext.startUpload();

      const response = await axios.post(configContext.url.upload, formData, config);

      this.setState({
        progress: response.data.success ? "100%" : <ExclamationMark/>,
      });

      uploadContext.finishUpload(response.data.success ? "" : response.data.error);

      this.props.onChange(response.data.files);

      this.inputRef.current.value = null;
    }
  };

  render() {
    const {label, icon, accept} = this.props;

    return (
      <>
        <input ref={this.inputRef}
               type="file"
               multiple={true}
               className={styles.input}
               onChange={this.onChange}
               accept={accept}/>
        <Button icon={icon}
                onClick={this.onClick}
                variant="variant14">
          <div className={styles.icon}>
            <Text element="div" className={styles.label} label={label} variant="variant8"/>
            {this.state.progress ? <Text element="div" className="progress">{this.state.progress}</Text> : null}
          </div>
        </Button>
      </>
    );
  }
}