import React, {Component} from "react";

import {FieldArray} from "formik";
import {range} from "underscore";

import Button from "components/common/Button";
import Layout from "components/common/Layout";
import SelectField from "components/form/SelectField";
import TextField from "components/form/TextField";
import FadeView from "components/view/FadeView";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";

export default function HomeDamagedGoodsInfoList({values, ...props}) {
  return (
    <Layout flex={1} spacing={10}>
      <Layout.OneColumn>
        <FieldArray name="damagedGoods" render={arrayHelpers => (
          <DamagedGoodsForm {...props}
                            arrayHelpers={arrayHelpers}
                            count={values.count || 0}
                            damagedGoods={values.damagedGoods || []}/>
        )}/>
      </Layout.OneColumn>
    </Layout>
  );
}

class DamagedGoodsForm extends Component {
  add = () => {
    const {arrayHelpers, setFieldValue, count} = this.props;

    arrayHelpers.push({index: count});
    setFieldValue("count", count + 1);
  };

  render() {
    const {damagedGoods} = this.props;

    return (
      <Layout flex={1} spacing={10}>
        <Layout.OneColumn>
          <FadeView>
            {damagedGoods.map((value, i) => (
              <DamagedGood key={i} index={i}/>
            ))}
          </FadeView>
        </Layout.OneColumn>

        <Layout.OneColumn spacing={5}></Layout.OneColumn>
        <Layout.OneColumn textAlign="right">
          <Button variant="variant10" label="homeDamagedGoodsInfo.content.goodsInfo.button.add" onClick={this.add}/>
        </Layout.OneColumn>
        <Layout.OneColumn spacing={15}></Layout.OneColumn>
      </Layout>
    );
  }

}

function DamagedGood({index}) {
  return (
    <FadeView.Child childKey={index}>
      <Layout flex={1} spacing={10}>
        <Layout.RowChildren>
          <TextField name={`damagedGoods[${index}].name`}
                     placeholder="homeDamagedGoodsInfo.content.goodsInfo.name.placeholder"
                     label="homeDamagedGoodsInfo.content.goodsInfo.name.label"/>
          <SelectField name={`damagedGoods[${index}].purchaseYear`}
                       placeholder="homeDamagedGoodsInfo.content.goodsInfo.purchaseYear.placeholder"
                       label="homeDamagedGoodsInfo.content.goodsInfo.purchaseYear.label">
            <SelectField.Option value=""
                                label="homeDamagedGoodsInfo.content.goodsInfo.purchaseYear.label"
                                disabled
                                hidden/>
            {range(new Date().getFullYear(), 1900).map(year => (
              <SelectField.Option key={year} value={year}>{year}</SelectField.Option>
            ))}
          </SelectField>
          <TextField name={`damagedGoods[${index}].purchasePrice`}
                     number={true}
                     maxLength={fieldLengthConstants[flowConstants.homeSteps.HOME_DAMAGED_GOODS_INFO.NAME].purchasePrice + 1}
                     placeholder="homeDamagedGoodsInfo.content.goodsInfo.purchasePrice.placeholder"
                     label="homeDamagedGoodsInfo.content.goodsInfo.purchasePrice.label"
                     currency={true} />
        </Layout.RowChildren>
      </Layout>
    </FadeView.Child>
  )
}
