import React, {Component} from "react";
import {connect} from "react-redux";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import ToggleButton from "components/form/ToggleButton";
import VehicleFontIcon from "components/icon/VehicleFontIcon";
import booleanTypes from "constants/booleanTypes";
import vehicleTypesConstants from "constants/vehicleTypes";

import _ from "lodash";

class AccidentInvolvedRight extends Component {
  render() {
    const isVehicle = this.props.claimCompensationVehicle !== booleanTypes.YES;

    return (
      <ToggleButton name="involvementType">
        <Layout spacing={10}>
          <Layout.RowChildren>
            <Text label="accidentInvolved.content.chooseParticipant"/>
          </Layout.RowChildren>
          <Layout.OneColumn spacing={10}></Layout.OneColumn>
          <Layout.RowChildren justifyContent="center">
            <ToggleButton.Choice value={vehicleTypesConstants.CAR}
                                 icon={<VehicleFontIcon type={vehicleTypesConstants.CAR}/>}/>
            <ToggleButton.Choice value={vehicleTypesConstants.SEMI_TRUCK}
                                 icon={<VehicleFontIcon type={vehicleTypesConstants.SEMI_TRUCK}/>}/>
            <ToggleButton.Choice value={vehicleTypesConstants.MOTORCYCLE}
                                 icon={<VehicleFontIcon type={vehicleTypesConstants.MOTORCYCLE}/>}/>
            {isVehicle ? <ToggleButton.Choice value={vehicleTypesConstants.BIKE}
                                  icon={<VehicleFontIcon type={vehicleTypesConstants.BIKE}/>}/> : null}
            {isVehicle ? <ToggleButton.Choice value={vehicleTypesConstants.PEDESTRIAN}
                                 icon={<VehicleFontIcon type={vehicleTypesConstants.PEDESTRIAN}/>}/> : null}
          </Layout.RowChildren>
          <Layout.OneColumn spacing={20}></Layout.OneColumn>
        </Layout>
      </ToggleButton>
    );
  }
}

const mapStateToProps = (state) => ({
  claimCompensationVehicle: _.at(state.flow.forms, "claimCompensationVehicle.claimCompensationVehicle")[0],
});

export default connect(mapStateToProps)(AccidentInvolvedRight);
