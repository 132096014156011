import React, {Component} from "react";

import Switch, {Case} from "react-switch-case";
import {contains, values} from "underscore";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import ToggleButton from "components/form/ToggleButton";
import FadeView from "components/view/FadeView";
import booleanTypesConstants from "constants/booleanTypes";
import OtherParticipantsInfo from "containers/step/otherParticipantsInfo/OtherParticipantsInfo";
import OtherParticipantsInfoNoInfo from "containers/step/otherParticipantsInfo/OtherParticipantsInfoNoInfo";

export default class OtherParticipantsInfoRight extends Component {
  render() {
    const {onNextStep, values: {otherParticipantsInfoType}, isValid, fieldConfiguration} = this.props;

    return (
      <Layout spacing={10}>
        <Layout.RowChildren flex={1}>
          <Layout flex={1} spacing={8}>
            <NextStepButton component={ToggleButton}
                            onNextStep={onNextStep}
                            isValid={true}
                            condition={value => value === booleanTypesConstants.NO && !contains(values(fieldConfiguration), true)}
                            buttonProps={{name: "otherParticipantsInfoType"}}>
              <Layout.OneRow flex={1} spacing={10}>
                <ToggleButton.Choice label="otherParticipantsInfo.button.yes"
                                     value={booleanTypesConstants.YES}/>
                <ToggleButton.Choice label="otherParticipantsInfo.button.no"
                                     value={booleanTypesConstants.NO}/>
              </Layout.OneRow>
            </NextStepButton>
          </Layout>
        </Layout.RowChildren>
        <Layout maxWidth={450} width="100%" justifyContent="center">
          <Layout.RowChildren>
            <FadeView>
              <OtherParticipantsInfoContent name="otherParticipantsInfo"
                                            otherParticipantsInfoType={otherParticipantsInfoType}
                                            onNextStep={onNextStep} isValid={isValid}/>
            </FadeView>
          </Layout.RowChildren>
        </Layout>
      </Layout>
    );
  }
}

function OtherParticipantsInfoContent({otherParticipantsInfoType, ...props}) {
  return (
    <FadeView step={otherParticipantsInfoType}>
      <Switch condition={otherParticipantsInfoType}>
        <Case value={booleanTypesConstants.YES}>
          <OtherParticipantsInfo {...props}/>
        </Case>
        <Case value={booleanTypesConstants.NO}>
          <OtherParticipantsInfoNoInfo {...props}/>
        </Case>
      </Switch>
    </FadeView>
  );
}
