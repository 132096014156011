import React, {Component} from "react";

import {prefix} from 'inline-style-prefixer'
import simplify from "simplify-js";
import {isUndefined} from "underscore";

import sketchConstants from "constants/sketch";
import {transform} from "selectors/sketch";

import styles from "components/sketch/drawing/SketchDrawing.module.scss";

const strokeStyle = "#222222";
const eraserStrokeStyle = "#ffffff";

export default class SketchDrawing extends Component {
  canvasRef = React.createRef();

  isDrawing = false;
  points = [];
  canvasContext = null;

  componentDidMount() {
    this.initialize();

    const canvas = this.canvasRef.current;

    if (canvas) {
      canvas.addEventListener("touchstart", this.onTouchStart, {passive: false});
      canvas.addEventListener("touchend", this.onTouchEnd, {passive: false});
      canvas.addEventListener("touchcancel", this.onTouchCancel, {passive: false});
      canvas.addEventListener("touchmove", this.onTouchMove, {passive: false});
    }
  }

  componentDidUpdate() {
    this.finishDrawing();
    this.initialize();
  }

  componentWillUnmount() {
    const canvas = this.canvasRef.current;

    if (canvas) {
      canvas.removeEventListener("touchstart", this.onTouchStart);
      canvas.removeEventListener("touchend", this.onTouchEnd);
      canvas.removeEventListener("touchcancel", this.onTouchCancel);
      canvas.removeEventListener("touchmove", this.onTouchMove);
    }
  }

  onMouseDown = ({nativeEvent: {pageX, pageY}}) => {
    this.startDrawing(pageX, pageY);
  };

  onMouseMove = ({nativeEvent: {pageX, pageY}}) => {
    this.draw(pageX, pageY);
  };

  onMouseLeave = () => {
    this.finishDrawing();
  };

  onMouseUp = () => {
    this.finishDrawing();
  };

  onTouchStart = (e) => {
    e.preventDefault();
    this.startDrawing(e.changedTouches[0].pageX, e.changedTouches[0].pageY);
  };

  onTouchMove = (e) => {
    e.preventDefault();
    this.draw(e.changedTouches[0].pageX, e.changedTouches[0].pageY);
  };

  onTouchEnd = (e) => {
    e.preventDefault();
    this.finishDrawing();
  };

  onTouchCancel = (e) => {
    e.preventDefault();
    this.finishDrawing();
  };

  initialize() {
    if (!this.canvasRef.current) {
      return;
    }

    this.canvasContext = this.canvasRef.current.getContext("2d");
  }

  startDrawing(x, y) {
    this.isDrawing = true;

    this.pushPoint(x, y);
    this.draw();
  }

  draw(x, y) {
    const {formik: {values: {navigationChoiceType}}} = this.props;

    if (!this.isDrawing) {
      return;
    }

    this.pushPoint(x, y);

    if (this.points.length <= 1) {
      return;
    }

    const previous = this.points[this.points.length - 2];
    const current = this.points[this.points.length - 1];
    const eraserStroke = navigationChoiceType === sketchConstants.NAVIGATION.ERASER.NAME;

    this.canvasContext.beginPath();
    this.canvasContext.moveTo(previous.x, previous.y);
    this.canvasContext.lineWidth = eraserStroke ? 10 : 1;
    this.canvasContext.strokeStyle = eraserStroke ? eraserStrokeStyle : strokeStyle;
    this.canvasContext.lineTo(current.x, current.y);
    this.canvasContext.stroke();
  }

  finishDrawing = () => {
    const {dimensions, onFinishedDrawing} = this.props;

    if (this.isDrawing) {
      if (onFinishedDrawing && this.points.length > 1) {
        onFinishedDrawing(this.transformedPoints());
      }

      this.isDrawing = false;
      this.points = [];

      const minDimension = Math.min(dimensions.width, dimensions.height);
      this.canvasContext.clearRect(0, 0, minDimension, minDimension);
    }
  };

  pushPoint(x, y) {
    const {dimensions} = this.props;
    const minDimension = Math.min(dimensions.width, dimensions.height);

    if (isUndefined(x) || isUndefined(y)) {
      return;
    }

    this.points.push({
      x: x - (dimensions.width - minDimension) / 2,
      y: y - (dimensions.height - minDimension) / 2,
    });
  }

  transformedPoints() {
    const {dimensions} = this.props;
    const minDimension = Math.min(dimensions.width, dimensions.height);

    return simplify(this.points, 5).map(({x, y}) => ([
      Math.round(x * sketchConstants.DIMENSIONS.CANVAS.WIDTH / minDimension),
      Math.round(y * sketchConstants.DIMENSIONS.CANVAS.HEIGHT / minDimension),
    ]));
  }

  render() {
    const {dimensions} = this.props;

    if (!dimensions) {
      return null;
    }

    const minDimension = Math.min(dimensions.width, dimensions.height);
    const transformContainer = transform(
      dimensions.width, dimensions.height,
      minDimension, minDimension,
    );

    return (
      <div className={styles.root}
           style={prefix({transform: transformContainer, width: minDimension, height: minDimension})}>
        <canvas id="drawCanvas" ref={this.canvasRef}
                width={minDimension} height={minDimension}
                onMouseDown={this.onMouseDown}
                onMouseMove={this.onMouseMove}
                onMouseLeave={this.onMouseLeave}
                onMouseUp={this.onMouseUp}/>
      </div>
    );
  }
}