import React, {Component} from "react";

import axios from "axios";

import Loading from "components/common/Loading";
import {ConfigContext} from "containers/context/ConfigContainer";

export const I18nContext = React.createContext({});

export default class I18nContainer extends Component {
  static contextType = ConfigContext;

  state = {
    loaded: false,
    translations: {},
  };

  async componentDidMount() {
    const response = await axios.get(this.context.url.translations);

    this.setState({
      loaded: true,
      translations: response.data,
    });
  }

  render() {
    const {children} = this.props;
    const {loaded} = this.state;

    if (!loaded) {
      return <Loading />;
    }

    return (
      <I18nContext.Provider value={this.state}>
        {children}
      </I18nContext.Provider>
    );
  }
}
