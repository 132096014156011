import React, {Component} from "react";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import causeOfDeathTypes from "constants/causeOfDeathTypes";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import CauseOfDeathRight from "containers/step/causeOfDeath/CauseOfDeathRight";
import Header from "containers/step/Header";
import SplitViewContainer from "containers/view/SplitViewContainer";
import Yup, {getMax} from "selectors/yup";

const validationSchema = Yup.object().shape({
  causeOfDeath: Yup.string().required(),
  description: Yup.string()
    .when("causeOfDeath", {
      is: causeOfDeathTypes.OTHER,
      then: getMax(Yup.string().trim(),
        fieldLengthConstants[flowConstants.personSteps.CAUSE_OF_DEATH.NAME].description),
    }),
});

export default class CauseOfDeathStepContainer extends Component {

  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="causeOfDeath.sidebar.title"/>
        <Text element="div" label="causeOfDeath.sidebar.subtitle"/>
      </SplitViewContainer.Left>
    );
  };


  right = (props) => {
    const {values, onNextStep} = props;

    return (
      <SplitViewContainer.Right {...props} showNextStepButton={values.causeOfDeath === causeOfDeathTypes.OTHER}>
        <CauseOfDeathRight {...props}/>
      </SplitViewContainer.Right>);
  }

  render() {
    return (<SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>)
  }
}
