import React from "react";

import Svg, {Path, Polygon, Text} from "components/svg/Svg";

const styles = {
  part: {
    strokeWidth: 2,
  },
  text: {
    fontSize: "150%",
  }
};

export default function PersonSvg(props) {
  return (
    <Svg {...props} viewBox="70 100 900 800" style={styles.part}>
      <g>
        <Text x="29%" y="140" height="180%" textAnchor="middle" style={styles.text}>Spredaj</Text>
      </g>
      <g>
        <Text x="87%" y="140" height="150%" textAnchor="middle" style={styles.text}>Zadaj</Text>
      </g>
      <g>
        <Path part={0} d="M230.7,203.1c0-16.1,14.1-29.2,31.4-29.2c17.3,0,31.4,13.1,31.4,29.2v5.3h-62.8V203.1z"/>
        <Polygon part={1} points="230.5,213.8 294.1,213.8 290.6,247.7 262.3,261.1 235.1,247.7 "/>
        <Path part={2} d="M240.3,257.6l21.9,11.5l23.3-11.5V269v3.6l23,10.6c0,0-24.3,13.5-34.1,15.1c-9.8,1.5-25.4,0-25.4,0
	s-25.7-7.9-31.7-15.1l22.9-10.6V257.6z"/>
        <Path part={3} d="M262.5,372.9c-36.9,0.9-53.9,40.8-53.9,40.8c-15.7-22-4.1-89.7-4.1-89.7c20-4.8,17.7-33.2,17.7-33.2
	c13.7,10.9,27.6,14.7,40.2,14.8h0.1c12.6-0.1,26.5-3.8,40.2-14.8c0,0-2.2,28.5,17.7,33.2c0,0,11.6,67.7-4.1,89.7
	c0,0-17-39.9-53.9-40.8"/>
        <Path part={4} d="M262.5,313.1"/>
        <Path part={5}
              d="M314.3,419c0,0-16.1-39.9-50.9-40.8h0.1c-34.8,0.9-50.9,40.8-50.9,40.8l-6.3,18.8h113.6L314.3,419z"/>
        <Path part={6}
              d="M205,442.5h114.8c0,0,15.2,47.9,3.3,84.7h-56.8l-3.3-17.5l-3.2,17.5h-59.5C200.4,527.2,191.1,490.9,205,442.5z"
        />
        <Path part={7} d="M201.1,531.6h57.4v92.2H222C222,623.7,201.6,561.7,201.1,531.6z"/>
        <Path part={8} d="M323,531.6h-57.4v92.2h36.6C302.2,623.7,322.5,561.7,323,531.6z"/>
        <Path part={9} d="M258.5,627.9h-36.8l-2.3,57.7h31.4C250.8,685.5,265.8,657.7,258.5,627.9z"/>
        <Path part={10} d="M266.4,627.9h36.8l2.3,57.7h-31.4C274.1,685.5,259.2,657.7,266.4,627.9z"/>
        <Path part={11} d="M219.4,690.4h31.4c0,0,13.1,44.5,1.5,77.8h-22.1C230.2,768.2,207.6,716.9,219.4,690.4z"/>
        <Path part={12} d="M305.3,690.4h-31.4c0,0-13.1,44.5-1.5,77.8h22.1C294.5,768.2,317.2,716.9,305.3,690.4z"/>
        <Path part={13}
              d="M231,773.3h20.3c0,0,0.5,23.7,5.8,25l1.4,31.2l-22-24.2C236.6,805.3,234.3,776.9,231,773.3z"/>
        <Path part={14}
              d="M292.7,773.3h-20.3c0,0-0.5,23.7-5.8,25l-1.4,31.2l22-24.2C287.2,805.3,289.4,776.9,292.7,773.3z"/>
        <Path part={15}
              d="M236.6,815.3l20.6,24.5c0,0,8.6,41.5-3.7,44c0,0-24.7-6.4-26-25.5C227.4,858.3,237.2,820.2,236.6,815.3z"/>
        <Path part={16}
              d="M288,815.3l-20.6,24.5c0,0-8.6,41.5,3.7,44c0,0,24.7-6.4,26-25.5C297.1,858.3,287.3,820.2,288,815.3z"/>
        <Path part={17} d="M189.2,293.8c0,0,32.8-20.2,25.5,2.1c0,0-8.8,28.1-29.1,35.1c0,0-14.9,8.4-16.1,10.6
	C169.5,341.5,162.5,310.2,189.2,293.8z"/>
        <Path part={18} d="M336.6,293.8c0,0-32.8-20.2-25.5,2.1c0,0,8.8,28.1,29.1,35.1c0,0,14.9,8.4,16.1,10.6
	C356.2,341.5,363.2,310.2,336.6,293.8z"/>
        <Path part={19} d="M161.9,372.6c0,0-2.4-16.8,6.7-23.9l28.7-18.2c0,0-0.3,40.9-8,52.7L161.9,372.6z"/>
        <Path part={20}
              d="M161.6,379.7l25.6,8.7c0,0,2.1,28.2-7.7,36.9l-33-14.1C146.5,411.2,150.9,385.1,161.6,379.7z"/>
        <Polygon part={21} points="144.5,417.6 177.8,430.9 153.4,481.7 131.6,473.6 "/>
        <Path part={22}
              d="M128.5,478.2l21.2,7.7c0,0,2.3,14.9-3.3,21l-30.3-13.5C116.2,493.4,122.3,491.7,128.5,478.2z"/>
        <Path part={23} d="M112.3,498.1l31.9,13.1c0,0,0.6,41.9-40.9,47.9c0,0-15.3-2.7-7.9-17.6l13.3-24.7c0,0-22.8,5.2-17.6-3.5
	C96.3,504.6,112.3,498.1,112.3,498.1z"/>
        <Path part={24} d="M363.2,372.7c0,0,2.4-16.8-6.7-23.9l-28.7-18.2c0,0,0.3,40.9,8,52.7L363.2,372.7z"/>
        <Path part={25}
              d="M363.5,379.9l-25.6,8.7c0,0-2.1,28.2,7.7,36.9l33-14.1C378.6,411.3,374.2,385.3,363.5,379.9z"/>
        <Polygon part={26} points="380.6,417.7 347.4,431 371.7,481.8 393.5,473.7 "/>
        <Path part={27}
              d="M396.6,478.3l-21.2,7.7c0,0-2.3,14.9,3.3,21l30.3-13.5C409,493.6,402.8,491.9,396.6,478.3z"/>
        <Path part={28} d="M412.8,498.2L381,511.4c0,0-0.6,41.9,40.9,47.9c0,0,15.3-2.7,7.9-17.6L416.5,517c0,0,22.8,5.2,17.6-3.5
	C428.9,504.8,412.8,498.2,412.8,498.2z"/>
        <Path part={29} d="M779.2,392.3"/>
        <Path part={30} d="M782,308.6"/>
        <Path part={31} d="M786.2,525.8V618h36.6c0,0,20.3-62.1,20.9-92.2C843.6,525.8,825.2,545.3,786.2,525.8z"/>
        <Path part={32} d="M778,623.3h-36.8l-2.3,57.7h31.4C770.3,681,785.2,653.2,778,623.3z"/>
        <Path part={33} d="M785.9,623.3h36.8L825,681h-31.4C793.6,681,778.7,653.2,785.9,623.3z"/>
        <Path part={34} d="M771.8,763.6c11.6-33.2-1.5-77.8-1.5-77.8h-31.4c-11.8,26.5,10.8,77.8,10.8,77.8l9.3,28.2l13.5-7.7L771.8,763.6
	z"/>
        <Path part={35} d="M755.8,812.1c-0.5,4.9-9.2,43-9.2,43c1.3,19.1,26,25.5,26,25.5c6.3-2.6,6.3-43.7,6.3-43.7s0.7-43.7-9.7-43.7
	C769.2,793.2,757.6,794.1,755.8,812.1z"/>
        <Path part={36} d="M708.6,289.2c0,0,32.8-20.2,25.5,2.1c0,0-8.8,28.1-29.1,35.1c0,0-14.9,8.4-16.1,10.6
	C688.9,337,682,305.7,708.6,289.2z"/>
        <Path part={37} d="M856,289.2c0,0-32.8-20.2-25.5,2.1c0,0,8.8,28.1,29.1,35.1c0,0,14.9,8.4,16.1,10.6
	C875.7,337,882.7,305.7,856,289.2z"/>
        <Path part={38} d="M681.4,368c0,0-2.4-16.8,6.7-23.9l28.7-18.2c0,0-0.3,40.9-8,52.7L681.4,368z"/>
        <Path part={39}
              d="M681.1,375.1l25.6,8.7c0,0,2.1,28.2-7.7,36.9l-33-14.1C666,406.6,670.4,380.5,681.1,375.1z"/>
        <Polygon part={40} points="664,413 697.2,426.3 672.9,477.1 651.1,469 "/>
        <Path part={41}
              d="M648,473.6l21.2,7.7c0,0,2.3,14.9-3.3,21l-30.3-13.5C635.6,488.9,641.8,487.1,648,473.6z"/>
        <Path part={42} d="M631.8,493.5l31.9,13.1c0,0,0.6,41.9-40.9,47.9c0,0-15.3-2.7-7.9-17.6l13.3-24.7c0,0-22.8,5.2-17.6-3.5
	S631.8,493.5,631.8,493.5z"/>
        <Path part={43} d="M882.7,368.1c0,0,2.4-16.8-6.7-23.9L847.3,326c0,0,0.3,40.9,8,52.7L882.7,368.1z"/>
        <Path part={44}
              d="M883,375.3l-25.6,8.7c0,0-2.1,28.2,7.7,36.9l33-14.1C898.1,406.8,893.7,380.7,883,375.3z"/>
        <Polygon part={45} points="900.1,413.1 866.8,426.5 891.2,477.2 913,469.1 "/>
        <Path part={46}
              d="M916.1,473.8l-21.2,7.7c0,0-2.3,14.9,3.3,21l30.3-13.5C928.4,489,922.3,487.3,916.1,473.8z"/>
        <Path part={47} d="M932.3,493.7l-31.9,13.1c0,0-0.6,41.9,40.9,47.9c0,0,15.3-2.7,7.9-17.6L936,512.4c0,0,22.8,5.2,17.6-3.5
	C948.3,500.2,932.3,493.7,932.3,493.7z"/>
        <Path part={48} d="M755.4,248.2c0,0,3.1-27,27-27c0,0,20.1,1.2,24.3,26.6l6.3-46.9c0-16.1-14.1-29.2-31.4-29.2
	s-31.4,13.1-31.4,29.2L755.4,248.2"/>
        <Path part={49} d="M781.6,228.1c19.9,0,21.1,23.8,21.1,23.8l1,16.2l22.9,10.6c0,0-39.6-3.5-44.8,6.1h-0.3
	c-5.2-9.7-44.8-6.1-44.8-6.1l22.9-10.6l1-16.2c0,0,1.2-23.8,21.1-23.8"/>
        <Path part={50} d="M782.7,292.8c10-12.6,39.9-6.6,39.9-6.6s-2.2,28.5,17.7,33.2c0,0,11.6,43.2-4.1,65.2c0,0-19.7,8.5-56.6,7.6
	h-0.4c-36.9,0.9-51.2-7.6-51.2-7.6c-15.7-22-4.1-65.2-4.1-65.2c20-4.8,17.7-33.2,17.7-33.2s29.9-6,39.9,6.6"/>
        <Path part={51} d="M777.8,525.8V618h-36.6c0,0-20.3-62.1-20.9-92.2C720.4,525.8,738.8,545.3,777.8,525.8z"/>
        <Path part={52} d="M791.5,763.6c-11.6-33.2,1.5-77.8,1.5-77.8h31.4c11.8,26.5-10.8,77.8-10.8,77.8l-9.3,28.2l-13.5-7.7
	L791.5,763.6z"/>
        <Path part={53} d="M806.4,812.1c0.5,4.9,9.2,43,9.2,43c-1.3,19.1-26,25.5-26,25.5c-6.3-2.6-6.3-43.7-6.3-43.7s-0.7-43.7,9.7-43.7
	C793,793.2,804.6,794.1,806.4,812.1z"/>
        <Path part={54} d="M782.2,399.5l32.9-0.1c17.5,1.2,27.5-12.7,27.5-12.7v78.6c-6.2-24.5-60.4-20.2-60.4-20.2H782
	c0,0-54.3-4.3-60.4,20.2v-78.6c0,0,10.1,13.9,27.5,12.7l32.9,0.1"/>
        <Path part={55} d="M776.8,519.2c-39,19.4-57.4,0-57.4,0s-10.1-59.2,29.7-67.9h26.7L776.8,519.2z"/>
        <Path part={56} d="M785.6,519.2c39,19.4,57.4,0,57.4,0s10.1-59.2-29.7-67.9h-26.7L785.6,519.2z"/>
      </g>
    </Svg>
  );
}
