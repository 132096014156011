import React from "react";

import {isUndefined, isNull, isEmpty, isFinite} from "underscore";
import * as dateFns from "date-fns";

import accidentDateTypeConstants from "constants/accidentDateTypes";

export default function isUndefinedOrNull(o) {
  return isUndefined(o) || isNull(o);
}

export function isValidSloTaxNumber(taxNumber) {
  //validated elsewhere with appropriate message
  if (isUndefinedOrNull(taxNumber) || isEmpty(taxNumber) || taxNumber.length > 8) {
    return true;
  }

  if (isUndefinedOrNull(taxNumber) || isEmpty(taxNumber) || taxNumber.length !== 8 || !isFinite(taxNumber)) {
    return false;
  }

  const factorMap = [8, 7, 6, 5, 4, 3, 2];
  const controlNumber = Number(taxNumber.substring(7, 8));

  let sum = 0;
  for (let i = 0; i < factorMap.length; i++) {
    let number = Number(taxNumber.substring(i, i + 1)) * factorMap[i];
    sum += number;
  }

  const mod = sum % 11;
  let calculatedControlNumber = 11 - mod;

  if (calculatedControlNumber === 10 || calculatedControlNumber === 11) {
    calculatedControlNumber = 0;
  }

  return controlNumber === calculatedControlNumber;
}

export function isSloAddress(address, slovenia) {
  if (isUndefinedOrNull(address) || isUndefinedOrNull(address.country)) {
    return false;
  }

  return address.country.code === slovenia.code;
}

export function getLastLabelComponent(label) {
  const labelComponents = label.split(".");

  return labelComponents[labelComponents.length - 1];
}

export function isCharNumber(char) {
  const a = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  return !!a[char];
}

export function filterSelectedHomeDamageTypeChildren(options, selected, value) {
  let valueSiblings = [];
  options.forEach(function (option) {
    //two levels
    const optionChildren = (option.props.children
      ? React.Children.map(option.props.children, c => c.props.children
        ? React.Children.map(c.props.children, c => c.props.name)
        : c.props.name)
      : []);
    if (optionChildren.includes(value)) {
      valueSiblings = optionChildren;
    }
  });

  return selected.filter(selectedName => valueSiblings.includes(selectedName));
}


export function getHomeDamageTypeHierarchyPath(homeDamageTypes, hierarchyPath, name) {
  Object.keys(homeDamageTypes).forEach(function (damageTypeKey) {
    const damageType = homeDamageTypes[damageTypeKey];
    if (damageType.NAME === name) {
      hierarchyPath.push(name);
      return;
    }
    const hierarchyPathSizeBefore = hierarchyPath.length;
    if (damageType.CHILDREN) {
      getHomeDamageTypeHierarchyPath(damageType.CHILDREN, hierarchyPath, name);
      if (hierarchyPath.includes(name) && hierarchyPathSizeBefore < hierarchyPath.length) {
        hierarchyPath.push(damageType.NAME)
      }
    }
  });
}

export function timeStringLaterThan(timeFrom, timeTo) {
  if(!timeFrom || !timeTo){
    return true;
  }

  const finalTimeFrom = timeFrom.length <= 2 && timeFrom.length > 0 ? timeFrom + ":00" : timeFrom;
  const finalTimeTo = timeTo.length <= 2 && timeTo.length > 0 ? timeTo + ":00" : timeTo;

  const [finalHoursFrom, finalMinutesFrom] = finalTimeFrom.split(':').map(value => parseInt(value));
  const [finalHoursTo, finalMinutesTo] = finalTimeTo.split(':').map(value => parseInt(value));

  if (finalHoursFrom < finalHoursTo) {
    return true;
  } else if (finalHoursFrom === finalHoursTo) {
      return finalMinutesFrom < finalMinutesTo;
  }

  return false;
}

export function addToUrl(url, path){
  return url.concat("/", path)
}

export function formatDate(dateForm) {
  const {accidentDateType} = dateForm;

  if (accidentDateType === accidentDateTypeConstants.TODAY) {
    return dateForm.dateToday;
  } else if (accidentDateType === accidentDateTypeConstants.YESTERDAY) {
    const dateToday = typeof dateForm.dateToday === 'string' ? dateFns.parseISO(dateForm.dateToday) : dateForm.dateToday;
    return dateFns.subDays(dateToday, 1);
  } else if (accidentDateType === accidentDateTypeConstants.CALENDAR) {
    return dateForm.accidentDate;
  } else if (accidentDateType === accidentDateTypeConstants.RANGE) {
    return dateForm.accidentDateFrom;
  }
}