import React, {Component} from "react";

import Layout from "components/common/Layout";
import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import TextField from "components/form/TextField";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import Header from "containers/step/Header";
import SplitViewContainer from "containers/view/SplitViewContainer";
import Yup, {getMax} from "selectors/yup";

const validationSchema = Yup.object().shape({
  description: getMax(Yup.string().trim().required("eventDescription.validation.description"), fieldLengthConstants[flowConstants.homeSteps.HOME_BURGLARY_PROPERTY_TYPE.NAME].description),
});

export default class HomeBurglaryPropertyTypeStepContainer extends Component {
  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="homeBurglaryPropertyType.sidebar.title"/>
        <Text element="div" label="homeBurglaryPropertyType.sidebar.subtitle" isHtml={true}/>
      </SplitViewContainer.Left>
    );
  };

  right(props) {
    return (
      <SplitViewContainer.Right {...props}>
        <Layout flex={1} spacing={10}>
          <Layout.OneColumn>
            <TextField name="description"
                       label="homeBurglaryPropertyType.content.description.label"
                       placeholder="homeBurglaryPropertyType.content.description.placeholder"
                       multiLine={true}
                       isRequired={true}
                       maxLength={fieldLengthConstants[flowConstants.homeSteps.HOME_BURGLARY_PROPERTY_TYPE.NAME].description + 1}/>
          </Layout.OneColumn>
        </Layout>
      </SplitViewContainer.Right>
    );
  }

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>
    );
  }
}