import React from "react";

import Switch, {Case, Default} from "react-switch-case";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import TextField from "components/form/TextField";
import ToggleButton from "components/form/ToggleButton";
import FadeView from "components/view/FadeView";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import placeOfDeathTypes from "constants/placeOfDeathTypes";

export default function PlaceOfDeathRight(props) {
  const {values, onNextStep} = props;

  return (
    <Layout flex={1} spacing={10}>
      <Layout.OneColumn>
        <NextStepButton component={ToggleButton}
                        onNextStep={onNextStep}
                        isValid={true}
                        condition={value => value !== placeOfDeathTypes.OTHER}
                        buttonProps={{name: "placeOfDeath"}}>
          <Layout.OneRow spacing={10} flex={1}>
            <ToggleButton.Choice value={placeOfDeathTypes.HOME}
                                 label="placeOfDeath.content.button.home.label"/>
            <ToggleButton.Choice value={placeOfDeathTypes.HOSPITAL}
                                 label="placeOfDeath.content.button.hospital.label"/>
            <ToggleButton.Choice value={placeOfDeathTypes.OTHER}
                                 label="placeOfDeath.content.button.other.label"/>
          </Layout.OneRow>
        </NextStepButton>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <FadeView step={values.placeOfDeath}>
          <Switch condition={values.placeOfDeath}>
            <Case value={placeOfDeathTypes.OTHER}>
              <Layout width="100%" maxWidth={450} justifyContent="center">
                <Layout.OneColumn spacing={35}/>
                <Layout.OneColumn>
                  <TextField name="description"
                             placeholder="placeOfDeath.content.placeOfDeathDescription.placeholder"
                             multiLine={true}
                             variant="variant5"
                             maxLength={fieldLengthConstants[flowConstants.personSteps.PLACE_OF_DEATH.NAME].description + 1}/>
                </Layout.OneColumn>
              </Layout>
            </Case>
            <Default>
              {null}
            </Default>
          </Switch>
        </FadeView>
      </Layout.OneColumn>
    </Layout>
  );
}
