import React, {Component} from "react";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import fieldLengthsConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import homeDamagedGoodsInfoTypeConstants from "constants/homeDamagedGoodsInfoTypes";
import UploadContainer from "containers/context/UploadContainer";
import Header from "containers/step/Header";
import HomeDamagedGoodsInfoRight from "containers/step/homeDamagedGoodsInfo/HomeDamagedGoodsInfoRight";
import SplitViewContainer from "containers/view/SplitViewContainer";
import Yup, {getMax} from "selectors/yup";

const damagedGoodsObjectSchema = Yup.object().shape({
  purchasePrice: getMax(Yup.string(), fieldLengthsConstants[flowConstants.homeSteps.HOME_DAMAGED_GOODS_INFO.NAME].purchasePrice),
});

const validationSchema = Yup.object().shape({
  estimateDescription: Yup.string().when("homeDamagedGoodsInfoType", {
    is: homeDamagedGoodsInfoType => homeDamagedGoodsInfoType === homeDamagedGoodsInfoTypeConstants.ESTIMATE,
    then: getMax(Yup.string().trim().required("validation.required"), fieldLengthsConstants[flowConstants.homeSteps.HOME_DAMAGED_GOODS_INFO.NAME].description),
  }),
  damageEstimate: Yup.string().when("homeDamagedGoodsInfoType", {
    is: homeDamagedGoodsInfoType => homeDamagedGoodsInfoType === homeDamagedGoodsInfoTypeConstants.ESTIMATE,
    then: getMax(Yup.string(), fieldLengthsConstants[flowConstants.homeSteps.HOME_DAMAGED_GOODS_INFO.NAME].damageEstimate),
  }),
  listDescription: Yup.string().when("homeDamagedGoodsInfoType", {
    is: homeDamagedGoodsInfoType => homeDamagedGoodsInfoType === homeDamagedGoodsInfoTypeConstants.LIST,
    then: getMax(Yup.string().trim().required("validation.required"), fieldLengthsConstants[flowConstants.homeSteps.HOME_DAMAGED_GOODS_INFO.NAME].description),
  }),
  damagedGoods: Yup.array().when("homeDamagedGoodsInfoType", {
    is: homeDamagedGoodsInfoType => homeDamagedGoodsInfoType === homeDamagedGoodsInfoTypeConstants.LIST,
    then: Yup.array().of(damagedGoodsObjectSchema),
  })
});

// homeStep 5
export default class HomeDamagedGoodsInfoStepContainer extends Component {
  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="homeDamagedGoodsInfo.sidebar.title"/>
        <Text element="div" isHtml={true} label="homeDamagedGoodsInfo.sidebar.subtitle"/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <UploadContainer>
        <SplitViewContainer.Right {...props}>
          <HomeDamagedGoodsInfoRight {...props}/>
        </SplitViewContainer.Right>
      </UploadContainer>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>
    );
  }
}
