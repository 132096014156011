import React, {Component} from "react";

import * as Yup from "yup";

import {submitClaim} from "actions/flow";
import Text from "components/common/Text";
import Header from "containers/step/Header";
import {ConfigContext} from "containers/context/ConfigContainer";
import SummaryRight from "containers/step/summary/SummaryRight";
import SplitViewContainer from "containers/view/SplitViewContainer";
import {connect} from "react-redux";

const validationSchema = ({loggedInFromCounter}) => Yup.object().shape({
  dataAccuracy: Yup.boolean().oneOf([true]).required("validation.gdpr")
});

// step 35
class SummaryStepContainer extends Component {
  left = ({claimType}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <Text element="h2" label={`summary.sidebar.${claimType}.title`}/>
        <Text element="div" label={`summary.sidebar.${claimType}.subtitle`} isHtml={true}/>
      </SplitViewContainer.Left>
    );
  }

  right(props) {
    return (
      <SplitViewContainer.Right {...props}>
        <SummaryRight {...props}/>
      </SplitViewContainer.Right>
    );
  }

  handleSubmit = (url) => (values) => {
    this.props.submitClaim(url, this.props.step, values, true);
  };

  render() {
    return (
      <ConfigContext.Consumer>
        {context =>
          <SplitViewContainer left={this.left}
                              right={this.right}
                              validationSchema={validationSchema}
                              onSubmit={this.handleSubmit(context.url.submitClaim)}/>
        }
      </ConfigContext.Consumer>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  submitClaim: (url, step, values, shouldWaitBeforeNextStep) => dispatch(submitClaim(url, step, values, shouldWaitBeforeNextStep)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SummaryStepContainer);