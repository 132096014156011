import React, {Component} from "react";

import cx from "classnames";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import TextField from "components/form/TextField";
import CloseIcon from "components/icon/CloseIcon";
import {filterMarkers, formatMarkerSuggestionValue} from "utility/mapSearch";

import styles from "components/step/map/MapSlider.module.scss";

export default class MapSlider extends Component {
  state = {
    locations: this.props.locations,
  };

  onValueChange = (value) => {
    const {locations} = this.props;

    this.setState({
      locations: filterMarkers(value, locations),
    })
  };

  onLocationClick = (location) => {
    const {onLocationClick} = this.props;

    if (onLocationClick) {
      onLocationClick(location);
    }
  };

  render() {
    const {isOpened, onClose} = this.props;

    const className = cx({
      [styles.root]: true,
      [styles.closed]: !isOpened,
    });

    return (
      <div className={className}>
        <CloseIcon onClose={onClose} dark={true}/>
        <Layout flex={1} spacing={8}>
          <Layout.OneColumn spacing={90}></Layout.OneColumn>
          <Layout.OneColumn>
            <div className={styles.header}>
              <Text label="mapSlider.title" variant="variant1" element="h3"/>
            </div>
          </Layout.OneColumn>
          <Layout.OneColumn>
            <div className={styles.search}>
              <TextField name="search"
                         variant="variant7"
                         onValueChange={this.onValueChange}/>
            </div>
          </Layout.OneColumn>
          <Layout.OneColumn flex={1} spacing={8}>
            <LocationsList locations={this.state.locations} onClick={this.onLocationClick}/>
          </Layout.OneColumn>
        </Layout>
      </div>
    );
  }
}

function LocationsList({locations, onClick}) {
  return locations.map((location, index) => <Location key={index} location={location} onClick={onClick}/>)
}

function Location({location, onClick}) {
  return (
    <div className={styles.item}>
      <Layout.OneColumn flex={1} spacing={8} onClick={() => onClick(location)}>
        <Layout.OneRow>
          <Text className={styles.title}>{location.name}</Text>
        </Layout.OneRow>
        <Layout.OneRow>
          <Text className={styles.address}>{formatMarkerSuggestionValue(location)}</Text>
        </Layout.OneRow>
      </Layout.OneColumn>
    </div>
  )
}