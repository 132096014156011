import React from "react";

import {isEmpty} from "underscore";

import Text from "components/common/Text";
import booleanTypesConstants from "constants/booleanTypes";
import flowConstants from "constants/flow";
import summaryRefConstants from "constants/summaryRef";
import SummaryVehicleInfo from "containers/step/summary/home/SummaryVehicleInfo";
import Summary from "containers/step/summary/Summary";

export default function SummaryInfoHome({claimType}) {
  return (
    <Summary title="summary.content.info.home.title"
             onePart={true}
             refName={summaryRefConstants.HOME_INFO.REF}>

      {/* step 3*/}
      <Summary.ItemValue label="summary.content.homeDamageType.label"
                         refName={summaryRefConstants.HOME_INFO.HOME_DAMAGE_TYPE}
                         formName={{name: flowConstants.homeSteps.HOME_DAMAGE_TYPE.NAME}}>
        {form => form.finalSelected.map((damageType, index) =>
          <div key={index}>
            <Text label={`summary.content.homeDamageType.${damageType}`}/>
          </div>)}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.homeBurglaryWho.label"
                         refName={summaryRefConstants.HOME_INFO.HOME_BURGLARY_WHO}
                         formName={{name: flowConstants.homeSteps.HOME_BURGLARY_WHO.NAME}}>
        {form => form.description}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.homeBurglaryPropertyType.label"
                         refName={summaryRefConstants.HOME_INFO.HOME_BURGLARY_PROPERTY_TYPE}
                         formName={{name: flowConstants.homeSteps.HOME_BURGLARY_PROPERTY_TYPE.NAME}}>
        {form => form.description}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.homeBurglaryViolent.label"
                         refName={summaryRefConstants.HOME_INFO.HOME_BURGLARY_VIOLENT}
                         formName={{name: flowConstants.homeSteps.HOME_BURGLARY_VIOLENT.NAME}}>
        {form => <Text label={`summary.content.common.boolean.${form.tracesOfBurglary}`}/>}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.homeBurglaryViolent.description.label"
                         refName={summaryRefConstants.HOME_INFO.HOME_BURGLARY_VIOLENT_DESCRIPTION}
                         formName={{name: flowConstants.homeSteps.HOME_BURGLARY_VIOLENT.NAME}}
                         condition={form => form.tracesOfBurglary === booleanTypesConstants.YES}>
        {form => form.description}
      </Summary.ItemValue>

      {/* step 4*/}
      <Summary.ItemValue label="summary.content.homeDamagedGoods.label"
                         refName={summaryRefConstants.HOME_INFO.HOME_DAMAGED_GOODS}
                         formName={{name: flowConstants.homeSteps.HOME_DAMAGED_GOODS.NAME}}>
        {form => form.selected.map((damagedGood, index) =>
          <div key={index}>
            <Text label={`summary.content.homeDamagedGoods.${damagedGood}`}/>
          </div>)}
      </Summary.ItemValue>

      {/* step 6*/}
      <Summary.ItemValue label={`summary.content.accidentDate.${claimType}.label`}
                         refName={summaryRefConstants.HOME_INFO.ACCIDENT_DATE}
                         formName={{name: flowConstants.steps.ACCIDENT_DATE.NAME}}>
        {form => form.date}
      </Summary.ItemValue>

      {/* step 7*/}
      <Summary.ItemValue label={`summary.content.accidentTime.${claimType}.label`}
                         refName={summaryRefConstants.HOME_INFO.ACCIDENT_TIME}
                         formName={{name: flowConstants.steps.ACCIDENT_TIME.NAME}}>
        {form => (
          form.choice === booleanTypesConstants.DONT_KNOW
            ? <Text label="summary.content.common.boolean.dontKnow"/>
            : form.time
        )}
      </Summary.ItemValue>

      {/* step 8*/}
      <Summary.ItemValue label={`summary.content.accidentLocation.${claimType}.label`}
                         refName={summaryRefConstants.HOME_INFO.ACCIDENT_LOCATION}
                         formName={{name: flowConstants.steps.ACCIDENT_LOCATION.NAME}}>
        {form => isEmpty(form.selectedLocation) ?
          <Text label="summary.content.common.boolean.dontKnow"/> : form.selectedLocation}
      </Summary.ItemValue>

      {/* step 8*/}
      <Summary.ItemValue label={`summary.content.accidentLocation.description.${claimType}.label`}
                         refName={summaryRefConstants.INFO.ACCIDENT_DESCRIPTION}
                         formName={{name: flowConstants.steps.ACCIDENT_LOCATION.NAME}}>
        {form => isEmpty(form.locationDescription) ?
          <Text label="summary.content.common.boolean.dontKnow"/> : form.locationDescription}
      </Summary.ItemValue>

      {/* step 9*/}
      <Summary.Items formName={{name: flowConstants.homeSteps.HOME_VEHICLE_INFO.NAME}}>
          {form => <SummaryVehicleInfo {...form}/>}
      </Summary.Items>

      {/* step 10*/}
      <Summary.ItemValue label="summary.content.homeReportedPolice.label"
                         refName={summaryRefConstants.HOME_REPORTED_POLICE}
                         formName={{name: flowConstants.homeSteps.HOME_REPORTED_POLICE.NAME}}>
        {form => <Text label={`summary.content.common.boolean.${form.reportedToPolice}`}/>}
      </Summary.ItemValue>

      {/* step 10*/}
      <Summary.ItemValue label="summary.content.homeReportedPolice.policeStation.label"
                         refName={summaryRefConstants.HOME_REPORTED_POLICE_POLICE_STATION}
                         formName={{name: flowConstants.homeSteps.HOME_REPORTED_POLICE.NAME}}
                         condition={form => form.reportedToPolice === booleanTypesConstants.YES}>
        {form => isEmpty(form.policeStation) ? <Text label="summary.content.common.boolean.dontKnow"/> : form.policeStation}
      </Summary.ItemValue>

      {/* step 10*/}
      <Summary.ItemValue label="summary.content.homeCalculationType.label"
                         refName={summaryRefConstants.HOME_CALCULATION_TYPE}
                         formName={{name: flowConstants.homeSteps.HOME_CALCULATION_TYPE.NAME}}>
        {form => <Text label={`summary.content.homeCalculationType.${form.calculationType}`}/>}
      </Summary.ItemValue>
    </Summary>
  );
}