import React, {Component} from "react";

import {isUndefined} from "underscore";

export const ConditionalContext = React.createContext({});

export default class ConditionalField extends Component {
  static contextType = ConditionalContext;

  render() {
    const {name, children, ...props} = this.props;

    if (isUndefined(this.context[name]) || this.context[name]) {
      return React.Children.map(children, child => React.cloneElement(child, props), this);
    }

    return null;
  }
}
