import React, {Component} from "react";

import * as Yup from "yup";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import carDamageTypes from "constants/carDamageTypes";
import CarDamageTypeRight from "containers/step/carDamageType/CarDamageTypeRight";
import Header from "containers/step/Header";
import SplitViewContainer from "containers/view/SplitViewContainer";

const validationSchema = Yup.object().shape({
  carDamageType: Yup.string().trim().required("validation.required"),
  otherName:
    Yup.string()
      .when("carDamageType", {
        is: carDamageTypes.OTHER,
        then: Yup.string().trim().required("validation.required")
      }),
});

// step 5
export default class CarDamageTypeStepContainer extends Component {
  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="carDamageType.sidebar.title"/>
        <Text element="div" label="carDamageType.sidebar.subtitle"/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <CarDamageTypeRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>
    );
  }
}
