import React, {Component} from "react";

import Layout from "components/common/Layout";
import TextField from "components/form/TextField";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";

export default class EventDescriptionRight extends Component {
  render() {
    const {claimType} = this.props;

    return (
      <Layout flex={1} spacing={10}>
        <Layout.OneColumn>
          <TextField name="description"
                     placeholder={`eventDescription.content.${claimType}.description.placeholder`}
                     label={`eventDescription.content.${claimType}.description.label`}
                     multiLine={true}
                     isRequired={true}
                     maxLength={fieldLengthConstants[flowConstants.steps.EVENT_DESCRIPTION.NAME].description + 1}/>
        </Layout.OneColumn>
      </Layout>
    );
  }
}
