import vehicleInfoInputTypes from "constants/vehicleInfoInputTypes";
import summaryRefConstants from "constants/summaryRef";

export default {
  [vehicleInfoInputTypes.REGISTERED_IN_SLOVENIA]: {
    REF: summaryRefConstants.RESPONSIBLE_PERSON.PERSON.REGISTERED_IN_SLOVENIA.REF,
    registrationNumber: summaryRefConstants.RESPONSIBLE_PERSON.PERSON.REGISTERED_IN_SLOVENIA.REGISTRATION_NUMBER,
    manufacturer: summaryRefConstants.RESPONSIBLE_PERSON.PERSON.REGISTERED_IN_SLOVENIA.MANUFACTURER,
    model: summaryRefConstants.RESPONSIBLE_PERSON.PERSON.REGISTERED_IN_SLOVENIA.MODEL,
    insuranceCompany: summaryRefConstants.RESPONSIBLE_PERSON.PERSON.REGISTERED_IN_SLOVENIA.INSURANCE_COMPANY,
    firstName: summaryRefConstants.RESPONSIBLE_PERSON.PERSON.PEDESTRIAN.NAME,
    lastName: summaryRefConstants.RESPONSIBLE_PERSON.PERSON.PEDESTRIAN.SURNAME,
  },
  [vehicleInfoInputTypes.REGISTERED_ABROAD]: {
    REF: summaryRefConstants.RESPONSIBLE_PERSON.PERSON.REGISTERED_ABROAD.REF,
    registrationNumber: summaryRefConstants.RESPONSIBLE_PERSON.PERSON.REGISTERED_ABROAD.REGISTRATION_NUMBER,
    vehicleMark: summaryRefConstants.RESPONSIBLE_PERSON.PERSON.REGISTERED_ABROAD.VEHICLE_MARK,
    vehicleType: summaryRefConstants.RESPONSIBLE_PERSON.PERSON.REGISTERED_ABROAD.VEHICLE_TYPE,
  },
  [vehicleInfoInputTypes.COMPANY_VEHICLE]: {
    REF: summaryRefConstants.RESPONSIBLE_PERSON.PERSON.COMPANY_VEHICLE.REF,
    vehicleType: summaryRefConstants.RESPONSIBLE_PERSON.PERSON.COMPANY_VEHICLE.VEHICLE_TYPE,
    vehicleChassisNumber: summaryRefConstants.RESPONSIBLE_PERSON.PERSON.COMPANY_VEHICLE.VEHICLE_CHASSIS_NUMBER,
    vehicleMark: summaryRefConstants.RESPONSIBLE_PERSON.PERSON.COMPANY_VEHICLE.VEHICLE_MARK,
  },
  [vehicleInfoInputTypes.NOT_REGISTERED]: {
    REF: summaryRefConstants.RESPONSIBLE_PERSON.PERSON.NOT_REGISTERED.REF,
    registrationNumber: summaryRefConstants.RESPONSIBLE_PERSON.PERSON.NOT_REGISTERED.REGISTRATION_NUMBER,
    vehicleDescription: summaryRefConstants.RESPONSIBLE_PERSON.PERSON.NOT_REGISTERED.DESCRIPTION,
  },
}
