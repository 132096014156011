import React from "react";

import {isEmpty} from "underscore";

import Text from "components/common/Text";
import booleanTypeConstants from "constants/booleanTypes";
import claimTypes from "constants/claimTypes";
import flowConstants from "constants/flow";
import medicalCareDescriptionTypes from "constants/medicalCareDescriptionTypes";
import summaryRefConstants from "constants/summaryRef";
import Summary from "containers/step/summary/Summary";

import {isUndefined} from "lodash";

export default function SummaryUser({claimType, owner, medicalCareType}) {
  return (
    <Summary title="summary.content.user.title" onePart={true} refName={summaryRefConstants.USER.REF}>
      <Summary.ItemValue label="summary.content.user.name"
                         formName={{name: flowConstants.steps.USER_IDENTIFICATION.NAME}}
                         refName={summaryRefConstants.USER.NAME}
                         condition={form => !form.isLegalPerson}>
        {form => form.name}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.legalUser.name"
                         formName={{name: flowConstants.steps.USER_IDENTIFICATION.NAME}}
                         refName={summaryRefConstants.USER.NAME}
                         condition={form => form.isLegalPerson}>
        {form => form.name}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.user.surname"
                         formName={{name: flowConstants.steps.USER_IDENTIFICATION.NAME}}
                         refName={summaryRefConstants.USER.SURNAME}
                         condition={form => !form.isLegalPerson}>
        {form => form.surname}
      </Summary.ItemValue>

      <Summary.ItemValue label="sex.summary.label"
                         formName={{name: flowConstants.steps.USER_IDENTIFICATION.NAME}}
                         refName={summaryRefConstants.USER.SURNAME}
                         condition={form => !form.isLegalPerson}>
        {form => <Text label={`sex.${form.sex}`}/>}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.user.birthDate"
                         formName={{name: flowConstants.steps.USER_IDENTIFICATION.NAME}}
                         refName={summaryRefConstants.USER.BIRTH_DATE}
                         condition={form => !form.isLegalPerson}>
        {form => form.birthDate}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.user.country"
                         formName={{name: flowConstants.steps.USER_IDENTIFICATION.NAME}}
                         refName={summaryRefConstants.USER.COUNTRY}>
        {form => form.country}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.user.cityAndZip"
                         formName={{name: flowConstants.steps.USER_IDENTIFICATION.NAME}}
                         refName={summaryRefConstants.USER.CITY_AND_ZIP}>
        {form => form.cityAndZip}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.user.streetAndStreetNo"
                         formName={{name: flowConstants.steps.USER_IDENTIFICATION.NAME}}
                         refName={summaryRefConstants.USER.STREET_AND_NUMBER}>
        {form => form.streetAndStreetNo}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.user.telephoneNumber"
                         formName={{name: flowConstants.steps.USER_IDENTIFICATION.NAME}}
                         refName={summaryRefConstants.USER.TELEPHONE_NUMBER}>
        {form => form.telephoneNumber}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.user.email"
                         formName={{name: flowConstants.steps.USER_IDENTIFICATION.NAME}}
                         refName={summaryRefConstants.USER.EMAIL}>
        {form => form.email}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.user.taxNumber"
                         formName={{name: flowConstants.steps.USER_IDENTIFICATION.NAME}}
                         refName={summaryRefConstants.USER.TAX_NUMBER}>
        {form => form.taxNumber}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.user.licenceNumber"
                         formName={{name: flowConstants.steps.USER_IDENTIFICATION.NAME}}
                         refName={summaryRefConstants.USER.LICENCE_NUMBER}
                         condition={(form) => form.wasDriver && !form.isLegalPerson}>
        {form => form.licenceNumber}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.user.policyNumber"
                         formName={{name: flowConstants.steps.USER_IDENTIFICATION.NAME}}
                         refName={summaryRefConstants.USER.POLICY_NUMBER}>
        {form => form.policyNumber}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.user.relationship"
                         formName={{name: flowConstants.steps.USER_IDENTIFICATION.NAME}}
                         refName={summaryRefConstants.USER.RELATIONSHIP}
                         condition={(form) => claimType === claimTypes.PERSON
                           && !form.isLegalPerson
                           && owner === booleanTypeConstants.NO}>
        {form => !isEmpty(form.relationship) ? <Text label={`summary.content.common.${form.relationship}`}/> :
          <Text label="summary.content.common.boolean.dontKnow"/>}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.user.isBeneficiary"
                         formName={{name: flowConstants.steps.USER_IDENTIFICATION.NAME}}
                         refName={summaryRefConstants.USER.IS_BENEFICIARY}
                         condition={(form) => !isUndefined(medicalCareType) && medicalCareType.medicalCareTypeDescription === medicalCareDescriptionTypes.DEATH}>
        {form => <Text label={`summary.content.common.boolean.${form.isBeneficiary}`}/>}
      </Summary.ItemValue>
    </Summary>
  );
}