import _ from "lodash";

import isUndefinedOrNull from "utility/utilityFunctions";
import {isEmpty} from "underscore";

import booleanTypesConstants from "constants/booleanTypes"
import claimTypes from "constants/claimTypes";
import flowConstants from "constants/flow";
import medicalCareDescriptionTypes from "constants/medicalCareDescriptionTypes";
import medicalCareTypes from "constants/medicalCareTypes";
import vehicleInfoInputTypesConstants from "constants/vehicleInfoInputTypes"
import vehicleTypes from "constants/vehicleTypes";
import calculateFinalBankAccount from "selectors/bankAccounts";
import calculateFinalParticipants, {transformParticipantsNumber} from "selectors/participantsNumber";
import {formatUserInfo} from "selectors/summary";

export function postCalculateFormState(step, forms) {
  let initialFormState = null;
  const steps = flowConstants.steps;
  const homeSteps = flowConstants.homeSteps;
  const personSteps = flowConstants.personSteps;

  const claimTypeForm = forms[flowConstants.steps.CLAIM_TYPE.NAME] || {};
  const accidentTimeForm = forms[flowConstants.steps.ACCIDENT_TIME.NAME] || {};
  const accidentLocationForm = forms[flowConstants.steps.ACCIDENT_LOCATION.NAME] || {};
  const vehicleTypeForm = forms[flowConstants.steps.VEHICLE_TYPE.NAME] || {};
  const vehicleDamageCurrentForm = forms[flowConstants.steps.VEHICLE_DAMAGE_CURRENT.NAME] || {};
  const parkedForm = forms[flowConstants.steps.PARKED.NAME] || {};
  const participantsNumberForm = forms[flowConstants.steps.PARTICIPANTS_NUMBER.NAME] || {};
  const participantsInfoForm = claimTypeForm.choiceType === claimTypes.PERSON
    ? forms[flowConstants.personSteps.PERSON_PARTICIPANTS_INFO.NAME] || [] : forms[flowConstants.steps.PARTICIPANTS_INFO.NAME];
  const ownVehiclePassengersForm = forms[flowConstants.steps.OWN_VEHICLE_INFO_PASSENGERS.NAME] || {};
  const otherParticipantsInfoForm = forms[flowConstants.steps.OTHER_PARTICIPANTS_INFO.NAME] || [];
  const userIdentificationInfoForm = forms[flowConstants.steps.USER_IDENTIFICATION.NAME] || {};
  const bankAccountsForm = forms[flowConstants.steps.BANK_ACCOUNTS.NAME] || {};

  const homeDamageTypeForm = forms[homeSteps.HOME_DAMAGE_TYPE.NAME] || {};

  const medicalCareTypeForm = forms[personSteps.MEDICAL_CARE_TYPE.NAME] || {};
  const pastMedicalConditionsForm = forms[personSteps.PAST_MEDICAL_CONDITIONS.NAME] || {};
  const hadOperationForm = forms[personSteps.HAD_OPERATION.NAME] || {};
  const personInsuranceForm = forms[personSteps.PERSON_INSURANCE.NAME] || {};
  const personResponsibleForm = forms[personSteps.PERSON_RESPONSIBLE.NAME] || {};
  const claimCompensationVehicleForm = forms[personSteps.CLAIM_COMPENSATION_VEHICLE.NAME] || {};
  const claimCompensationPersonForm = forms[personSteps.CLAIM_COMPENSATION_PERSON.NAME] || {};
  const responsiblePersonTypeForm = forms[personSteps.RESPONSIBLE_PERSON_TYPE.NAME] || {};
  const responsiblePersonRelationForm = forms[personSteps.RESPONSIBLE_PERSON_RELATION.NAME] || {};
  const damagesClaimFiledForm = forms[personSteps.DAMAGES_CLAIM_FILED.NAME] || {};
  const ownVehicleInfoWasDriverForm = forms[steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME] || {};
  const lifeInsuranceClaimForm = forms[personSteps.LIFE_INSURANCE_CLAIM.NAME] || {};
  const accidentInvolvedForm = forms[personSteps.ACCIDENT_INVOLVED.NAME] || {};

  if (step.name === steps.CLAIM_TYPE.NAME) {
    if (!isUndefinedOrNull(claimTypeForm.finalChoiceType) && claimTypeForm.finalChoiceType !== claimTypeForm.choiceType) {
      return {
        [steps.INTRO.NAME]: forms[flowConstants.steps.INTRO.NAME],
        [steps.CLAIM_TYPE.NAME]: {
          ...claimTypeForm,
          finalChoiceType: claimTypeForm.choiceType,
        },
      };
    }

    initialFormState = {
      ...claimTypeForm,
      finalChoiceType: claimTypeForm.choiceType,
    }
  }

  if (step.name === steps.ACCIDENT_TIME.NAME) {
    initialFormState = formatAccidentTime(accidentTimeForm);
  } else if (step.name === steps.VEHICLE_TYPE.NAME) {
    initialFormState = {
      ...vehicleTypeForm,
      finalVehicleType: vehicleTypeForm.vehicleType,
    }
  } else if (step.name === steps.VEHICLE_DAMAGE_CURRENT.NAME) {
    initialFormState = {
      ...vehicleDamageCurrentForm,
    }
  } else if (step.name === steps.ACCIDENT_LOCATION.NAME) {
    const {focusLocation, accidentLocation} = accidentLocationForm;

    initialFormState = {
      ...accidentLocationForm,
      focusLocation: focusLocation ? focusLocation : accidentLocation,
    }
  } else if (step.name === steps.PARKED.NAME) {
    initialFormState = {
      ...parkedForm,
      finalParked: parkedForm.parked,
    }
  } else if (step.name === steps.PARTICIPANTS_NUMBER.NAME) {
    const transformedParticipants = transformParticipantsNumber(participantsNumberForm.participants);
    const finalVehicleType = claimTypeForm.choiceType === claimTypes.PERSON ? accidentInvolvedForm.involvementType : vehicleTypeForm.finalVehicleType;

    if (claimTypeForm.choiceType === claimTypes.PERSON && isUndefinedOrNull(participantsNumberForm.multipleParticipants)) {
      participantsNumberForm.multipleParticipants = booleanTypesConstants.UNKNOWN;
    }

    initialFormState = {
      ...participantsNumberForm,
      participants: transformedParticipants,
      finalMultipleParticipants: participantsNumberForm.multipleParticipants,
      finalParticipants: calculateFinalParticipants(
        finalVehicleType,
        transformedParticipants,
        participantsNumberForm.multipleParticipants,
        claimTypeForm.choiceType
      ),
    };
  } else if (step.name === steps.PARTICIPANTS_INFO.NAME || step.name === personSteps.PERSON_PARTICIPANTS_INFO.NAME) {
    const registrationNumberInSlovenia = (_.at(participantsInfoForm, `[${step.index}].participantInfo.inSlovenia.registrationNumber`)[0] || "").toUpperCase();
    const registrationNumberAbroad = (_.at(participantsInfoForm, `[${step.index}].participantInfo.abroad.registrationNumber`)[0] || "").toUpperCase();
    const inputType = (_.at(participantsInfoForm, `[${step.index}].participantInfo.inputType`)[0] || false);
    const finalInputType = inputType ? inputType : vehicleInfoInputTypesConstants.REGISTERED_IN_SLOVENIA;
    const newParticipantInfo = _.set(_.set(_.set(_.cloneDeep(participantsInfoForm), `[${step.index}].participantInfo.inSlovenia.registrationNumber`, registrationNumberInSlovenia), `[${step.index}].participantInfo.abroad.registrationNumber`, registrationNumberAbroad), `[${step.index}].participantInfo.inputType`, finalInputType);
    initialFormState = [...newParticipantInfo];
  } else if (step.name === steps.OWN_VEHICLE_INFO_PASSENGERS.NAME) {
    initialFormState = {
      ...ownVehiclePassengersForm,
      finalPassengers:
        ownVehiclePassengersForm.exist === booleanTypesConstants.YES
          ? ownVehiclePassengersForm.passengers
          : [],
    }
  } else if (step.name === steps.OTHER_PARTICIPANTS_INFO.NAME) {
    initialFormState = otherParticipantsInfoForm.map(otherParticipant => {
      if (!otherParticipant) {
        return otherParticipant;
      }

      return {
        ...otherParticipant,
        finalInfo:
          otherParticipant.otherParticipantsInfoType === booleanTypesConstants.YES
            ? otherParticipant.otherParticipantsInfo || {}
            : otherParticipant.otherParticipantsInfoNoInfo || {},
      };
    });
  } else if (step.name === steps.BANK_ACCOUNTS.NAME) {
    initialFormState = {
      ...bankAccountsForm,
      finalBankAccount: calculateFinalBankAccount(bankAccountsForm),
    }
  } else if (step.name === homeSteps.HOME_DAMAGE_TYPE.NAME) {
    initialFormState = {
      ...homeDamageTypeForm,
      finalSelected: _.at(homeDamageTypeForm, "homeDamageType.selected")[0],
    }
  } else if (step.name === steps.ACCIDENT_TIME.NAME) {
    initialFormState = formatAccidentTime(accidentTimeForm);
  } else if (step.name === personSteps.MEDICAL_CARE_TYPE.NAME && medicalCareTypeForm.medicalCareType === medicalCareTypes.OTHER) {
    initialFormState = {
      ...medicalCareTypeForm,
      medicalCareTypeDescription: "",
    }
  } else if (step.name === steps.ACCIDENT_TIME.NAME) {
    initialFormState = formatAccidentTime(accidentTimeForm);
  } else if (step.name === personSteps.PAST_MEDICAL_CONDITIONS.NAME) {
    if (isUndefinedOrNull(pastMedicalConditionsForm.pastMedicalConditions)) {
      pastMedicalConditionsForm.pastMedicalConditions = booleanTypesConstants.UNKNOWN;
    }
  } else if (step.name === personSteps.HAD_OPERATION.NAME) {
    if (isUndefinedOrNull(hadOperationForm.hadOperation)) {
      hadOperationForm.hadOperation = booleanTypesConstants.UNKNOWN;
    }
  } else if (step.name === personSteps.PERSON_INSURANCE.NAME) {
    if (isUndefinedOrNull(personInsuranceForm.personInsurance)) {
      personInsuranceForm.personInsurance = booleanTypesConstants.UNKNOWN;
    }
  } else if (step.name === personSteps.PERSON_RESPONSIBLE.NAME) {
    if (isUndefinedOrNull(personResponsibleForm.isOtherPersonFault)) {
      personResponsibleForm.isOtherPersonFault = booleanTypesConstants.UNKNOWN;
    }
  } else if (step.name === personSteps.CLAIM_COMPENSATION_VEHICLE.NAME) {
    if (isUndefinedOrNull(claimCompensationVehicleForm.claimCompensationVehicle)) {
      claimCompensationVehicleForm.claimCompensationVehicle = booleanTypesConstants.UNKNOWN;
    }
  } else if (step.name === personSteps.CLAIM_COMPENSATION_PERSON.NAME) {
    if (isUndefinedOrNull(claimCompensationPersonForm.claimCompensationPerson)) {
      claimCompensationPersonForm.claimCompensationPerson = booleanTypesConstants.UNKNOWN;
    }
  } else if (step.name === personSteps.RESPONSIBLE_PERSON_TYPE.NAME) {
    if (isUndefinedOrNull(responsiblePersonTypeForm.responsiblePersonChoice)) {
      responsiblePersonTypeForm.responsiblePersonChoice = booleanTypesConstants.UNKNOWN;
    }
  } else if (step.name === personSteps.RESPONSIBLE_PERSON_RELATION.NAME) {
    if (isUndefinedOrNull(responsiblePersonRelationForm.responsiblePersonRelation)) {
      responsiblePersonRelationForm.responsiblePersonRelation = booleanTypesConstants.UNKNOWN;
    }
  } else if (step.name === personSteps.DAMAGES_CLAIM_FILED.NAME) {
    if (isUndefinedOrNull(damagesClaimFiledForm.isDamagesClaimFiled)) {
      damagesClaimFiledForm.isDamagesClaimFiled = booleanTypesConstants.UNKNOWN;
    }
  } else if (step.name === steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME) {
    if (isUndefinedOrNull(ownVehicleInfoWasDriverForm.wasDriver)) {
      ownVehicleInfoWasDriverForm.wasDriver = booleanTypesConstants.UNKNOWN;
    }
  } else if (step.name === personSteps.LIFE_INSURANCE_CLAIM.NAME) {
    if (isUndefinedOrNull(lifeInsuranceClaimForm.lifeInsuranceClaim)) {
      lifeInsuranceClaimForm.lifeInsuranceClaim = booleanTypesConstants.UNKNOWN;
    }
    if (isUndefinedOrNull(lifeInsuranceClaimForm.isSlovenia)) {
      lifeInsuranceClaimForm.isSlovenia = booleanTypesConstants.UNKNOWN;
    }
  } else if (step.name === steps.USER_IDENTIFICATION.NAME) {
    initialFormState = {
      ...userIdentificationInfoForm,
      finalUserInfo: formatUserInfo(userIdentificationInfoForm),
    }
  }
    else if (step.name === personSteps.ACCIDENT_INVOLVED.NAME) {
      const personParticipantsInfoForm = forms[personSteps.PERSON_PARTICIPANTS_INFO.NAME] || {};

      if (!isEmpty(personParticipantsInfoForm)) {
        personParticipantsInfoForm[0].info.type = accidentInvolvedForm.involvementType;

        if (!isEmpty(participantsNumberForm)) {
          participantsNumberForm.finalParticipants[0].type = accidentInvolvedForm.involvementType;
        }
      }
  }

  if (initialFormState !== null) {
    return {
      ...forms,
      [step.name]: initialFormState,
    };
  } else {
    return forms;
  }
}

function formatFullTime(time) {
  if (isEmpty(time)) {
    return time;
  }

  const [hours, minutes] = time.split(":");

  if (isEmpty(hours)) {
    return time;
  }

  return (hours.length === 1 ? "0" + hours : hours) + ":" + (isEmpty(minutes) ? "00" : minutes.length === 1 ? "0" + minutes : minutes);
}

function formatTime(time) {
  if (isEmpty(time)) {
    return time;
  }

  return time.length === 1 ? "0" + time : time;
}

function formatAccidentTime(form) {
  if (form.accidentTimeRange === true && form.choice !== booleanTypesConstants.DONT_KNOW) {
    const accidentTimeFrom = formatFullTime(form.accidentTimeFrom);
    const accidentTimeTo = formatFullTime(form.accidentTimeTo);

    return {
      ...form,
      accidentTimeFrom,
      accidentTimeTo,
    }
  } else if (form.accidentTimeRange === false && form.choice !== booleanTypesConstants.DONT_KNOW) {
    const accidentTimeHour = formatTime(form.accidentTimeHour);
    const accidentTimeMinute = formatTime(form.accidentTimeMinute);

    return {
      ...form,
      accidentTimeHour,
      accidentTimeMinute,
    }
  }

  return {
    ...form,
    accidentTimeRange: false,
    choice: booleanTypesConstants.DONT_KNOW,
  };
}
