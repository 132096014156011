import React, {Component} from "react";

import Button from "components/common/Button";
import Text from "components/common/Text";
import SplitViewContainer from "containers/view/SplitViewContainer";

import styles from "containers/Maintenance.module.scss"
import claimTypeConstants from "constants/claimTypes";

import generaliLogo from "images/generali-logo.svg";

const maintenanceImage = require("images/maintenance.svg");

export default class Maintenance extends Component {
  onButtonClick = () => {
    const {type, maintenance} = this.props;

    if(type === claimTypeConstants.CAR){
      window.open(maintenance.carPath);
    } else if (type === claimTypeConstants.HOME){
      window.open(maintenance.homePath);
    } else {
      window.open(maintenance.defaultPath);
    }
  };

  left = (onButtonClick) => () => {
    return (
      <SplitViewContainer.Left>
        <Text element="h2" label="maintenance.sidebar.title" className={styles.maintenanceTitle}/>
        <Text element="div" label="maintenance.sidebar.subtitle" variant="variant23"/>
        <Button onClick={onButtonClick} label="button.back" variant="variant1"/>
      </SplitViewContainer.Left>
    );
  };

  right() {
    return (
      <SplitViewContainer.Right maintenance={true}>
        <img className={styles.logo}
             src={generaliLogo}
             alt="logo"/>
        <img className={styles.image}
             src={maintenanceImage}
             alt="maintenance"/>
      </SplitViewContainer.Right>
    );
  }

  render() {
    return (
      <SplitViewContainer left={this.left(this.onButtonClick)} right={this.right} reverseFlex={true}/>
    );
  }
}
