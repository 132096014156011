import React from "react";

import * as dateFns from "date-fns";
import _ from "lodash";

import isUndefinedOrNull from "utility/utilityFunctions"
import Text from "components/common/Text";
import summaryConstants from "constants/summary";
import summaryRefConstants from "constants/summaryRef";
import SummaryRefContainer from "components/step/summary/SummaryRefContainer";
import {formatParticipantAddress} from "selectors/participantsInfo";
import {SummaryParticipantItemValue} from "containers/step/summary/car/SummaryParticipants";


export default function SummaryPedestrianInfo({participant, jumpTo}) {
  return (
    <SummaryRefContainer refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OTHER_PARTICIPANTS_INFO_PEDESTRIAN.REF}>
      <SummaryParticipantItemValue label="summary.content.participantsInfo.pedestrian.name"
                                   jumpTo={jumpTo} participant={participant}
                                   refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OTHER_PARTICIPANTS_INFO_PEDESTRIAN.NAME}>
        {form => _.get(form, "participant.participantInfo.nameAndSurname.name") || <Text label="summary.content.common.boolean.dontKnow"/>}
      </SummaryParticipantItemValue>
      <SummaryParticipantItemValue label="summary.content.participantsInfo.pedestrian.surname"
                                   jumpTo={jumpTo} participant={participant}
                                   refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OTHER_PARTICIPANTS_INFO_PEDESTRIAN.SURNAME}>
        {form => _.get(form, "participant.participantInfo.nameAndSurname.surname") || <Text label="summary.content.common.boolean.dontKnow"/>}
      </SummaryParticipantItemValue>
      <SummaryParticipantItemValue label="summary.content.participantsInfo.pedestrian.birthDate"
                                   jumpTo={jumpTo} participant={participant}
                                   refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OTHER_PARTICIPANTS_INFO_PEDESTRIAN.BIRTH_DATE}>
        {form => {
          const {birthDate} = _.get(form, "participant.participantInfo") || {};
          return birthDate ? dateFns.format(new Date(birthDate), summaryConstants.DATE_FORMAT) : <Text label="summary.content.common.boolean.dontKnow"/>;
        }}
      </SummaryParticipantItemValue>
      <SummaryParticipantItemValue label="sex.summary.label"
                                   jumpTo={jumpTo} participant={participant}
                                   refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OTHER_PARTICIPANTS_INFO_PEDESTRIAN.SEX}>
        {form => !isUndefinedOrNull(_.get(form, "participant.participantInfo.sex")) ? <Text label={`sex.${_.get(form, "participant.participantInfo.sex")}`}/> : <Text label="summary.content.common.boolean.dontKnow"/>}
      </SummaryParticipantItemValue>
      <SummaryParticipantItemValue label="summary.content.participantsInfo.pedestrian.address"
                                   jumpTo={jumpTo} participant={participant}
                                   refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OTHER_PARTICIPANTS_INFO_PEDESTRIAN.ADDRESS}>
        {form => formatParticipantAddress(_.get(form, "participant.participantInfo") || <Text label="summary.content.common.boolean.dontKnow"/>)}
      </SummaryParticipantItemValue>
      <SummaryParticipantItemValue
        label={`summary.content.participantsInfo.pedestrian.${participant.info.type}.heavyInjuryType`}
        jumpTo={jumpTo} participant={participant}
        refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OTHER_PARTICIPANTS_INFO_PEDESTRIAN.HEAVY_INJURY_TYPE}>
        {form => {
          const {heavyInjuryType} = _.get(form, "participant.participantInfo") || {};
          return heavyInjuryType
            ? <Text label={`summary.content.common.boolean.${heavyInjuryType}`}/>
            : <Text label="summary.content.common.boolean.dontKnow"/>;
        }}
      </SummaryParticipantItemValue>
    </SummaryRefContainer>
  );
}