import React from "react";

import Address from "components/step/Address";
import booleanTypesConstants from "constants/booleanTypes";
import CompositeField from "components/form/CompositeField";
import DateField from "components/form/DateField";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import Layout from "components/common/Layout";
import SexField from "components/form/SexField";
import Text from "components/common/Text";
import ToggleButton from "components/form/ToggleButton";
import vehicleTypesConstants from "constants/vehicleTypes";
import {capitalize} from "utility/textTransform";

export default function PedestrianInfo({name, info}) {
  return (
    <Layout flex={1} spacing={10}>
      <Layout.OneColumn spacing={5}></Layout.OneColumn>
      <Layout.RowChildren>
        <Text
          label={info.type === vehicleTypesConstants.BIKE ? "pedestrianInfo.bike.title" : "pedestrianInfo.pedestrian.title"}
          element="span"
          variant="variant2"/>
      </Layout.RowChildren>
      <Layout.RowChildren>
        <CompositeField name={`${name}.nameAndSurname`} label="pedestrianInfo.nameAndSurname.label">
          <Layout.OneRow flex={1} spacing={5}>
            <CompositeField.TextField name="name"
                                      transform={capitalize}
                                      placeholder="pedestrianInfo.name.placeholder"
                                      maxLength={fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].name+ 1}/>
            <CompositeField.TextField name="surname"
                                      transform={capitalize}
                                      placeholder="pedestrianInfo.surname.placeholder"
                                      maxLength={fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].surname+ 1}/>
          </Layout.OneRow>
        </CompositeField>
      </Layout.RowChildren>
      <Layout.RowChildren>
        <DateField name={`${name}.birthDate`}
                   label="pedestrianInfo.birthDate.label"
                   placeholder="pedestrianInfo.birthDate.placeholder"
                   legalAgeField={true}/>
      </Layout.RowChildren>

      <Layout.RowChildren>
        <SexField name={`${name}.sex`} />
      </Layout.RowChildren>

      <Layout.OneColumn>
        <Address name={`${name}.address`}/>
      </Layout.OneColumn>

      <Layout.RowChildren>
        <Text element="h5" variant="variant2" label={`pedestrianInfo.heavyInjury.${info.type}.title`}/>
      </Layout.RowChildren>
      <Layout.RowChildren>
        <ToggleButton name={`${name}.heavyInjuryType`}>
          <Layout.OneRow flex={1} spacing={10}>
            <ToggleButton.Choice label="button.yes"
                                 value={booleanTypesConstants.YES}
                                 variant="variant11"/>
            <ToggleButton.Choice label="button.no"
                                 value={booleanTypesConstants.NO}
                                 variant="variant11"/>
            <ToggleButton.Choice label="button.dontKnow"
                                 value={booleanTypesConstants.DONT_KNOW}
                                 variant="variant11"/>
          </Layout.OneRow>
        </ToggleButton>
      </Layout.RowChildren>
      <Layout.OneColumn spacing={30}></Layout.OneColumn>
    </Layout>
  );
}
