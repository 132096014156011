import React from "react";

import cx from "classnames";
import {range} from "underscore";

import styles from "components/step/ProgressBar.module.scss";

export default function ProgressBar({value, size, isWiz}) {

  const activeClassNames = cx({
    [styles.step]: true,
    [styles.active]: true,
    [styles.wiz]: isWiz
  });

  const lastActiveClassNames = cx({
    [styles.step]: true,
    [styles.active]: true,
    [styles.lastActive]: true,
    [styles.wiz]: isWiz,
  });

  const active = range(0, value - 1).map((i) => <div key={i} className={activeClassNames}>&nbsp;</div>);
  const lastActive = <div key={value} className={lastActiveClassNames}>&nbsp;</div>;
  const rest = range(value, size).map((i) => <div key={i} className={styles.step}>&nbsp;</div>);

  return (
    <div className={styles.root}>
      {active}
      {lastActive}
      {rest}
    </div>
  );
}
