import React, {Component} from "react";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import Header from "containers/step/Header";
import HomeCalculationTypeRight from "containers/step/homeCalculationType/HomeCalculationTypeRight";
import SplitViewContainer from "containers/view/SplitViewContainer";

// step 14 - home
export default class HomeCalculationTypeStepContainer extends Component {
  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="homeCalculationType.sidebar.title"/>
        <Text element="div" isHtml={true} label="homeCalculationType.sidebar.subtitle"/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right showNextStepButton={false} {...props}>
        <HomeCalculationTypeRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right}/>
    );
  }
}
