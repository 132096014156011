import {Component} from "react";

import {connect} from "formik";
import {isEqual} from "underscore";

class FormikOnChange extends Component {
  componentDidUpdate(prevProps) {
    const {onChange, formik} = this.props;

    if (!isEqual(prevProps.formik.values, formik.values)) {
      onChange(formik.values);
    }
  }

  render() {
    return null;
  }
}

export default connect(FormikOnChange);
