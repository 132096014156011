import React from "react";

import cx from "classnames";

import styles from "components/icon/RemoveIcon.module.scss";

export default function RemoveIcon({variant}) {
  const className = cx({
    [styles.root]: true,
    [styles.light]: variant === "light",
    [styles.dark]: variant === "dark",
  });

  return (
    <div className={className}>x</div>
  );
}
