import React, {Component} from "react";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import TextField from "components/form/TextField";
import Address from "components/step/Address";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import responsiblePersonTypes from "constants/responsiblePersonTypes";
import {capitalize} from "utility/textTransform";

export default class LegalPerson extends Component {
  render() {
    const {responsiblePersonChoice, stepName} = this.props;

    if (responsiblePersonChoice !== responsiblePersonTypes.LEGAL_PERSON) {
      return null;
    }

    const addressLabels = stepName === flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME ? {
      country: "responsiblePersonType.content.naturalPerson.address.country.label"
    } : null;


    return (
      <Layout width="100%" spacing={10} maxWidth={450}>
        <Layout.OneColumn spacing={20}/>
        <Layout.OneColumn>
          <Text element="h5" variant="variant2" label={`${stepName}.content.legalPerson.data.title`}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <TextField name="legalPerson.name"
                     transform={capitalize}
                     isRequired={true}
                     placeholder={`${stepName}.content.legalPerson.name.placeholder`}
                     label={`${stepName}.content.legalPerson.name.label`}
                     maxLength={fieldLengthConstants[stepName].name + 1}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <Address name="legalPerson.address" isRequired={true} addressLabels={addressLabels}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <TextField name="legalPerson.policyNumber"
                     placeholder={`${stepName}.content.legalPerson.policyNumber.placeholder`}
                     label={`${stepName}.content.legalPerson.policyNumber.label`}
                     maxLength={fieldLengthConstants[stepName].policyNumber + 1}/>
        </Layout.OneColumn>
      </Layout>
    );
  }
}
