import React, {Component} from "react";

import * as Yup from "yup";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import VehicleInfoContainer from "components/step/VehicleInfoContainer";
import booleanTypesConstants from "constants/booleanTypes";
import flowConstants from "constants/flow";
import fieldLengthConstants from "constants/fieldLength";
import Header from "containers/step/Header";
import OwnVehiclePassengersRight from "containers/step/ownVehiclePassengers/OwnVehiclePassengersRight";
import SplitViewContainer from "containers/view/SplitViewContainer";
import {getMax} from "selectors/yup";

const arrayObjectValidationSchema = Yup.object().shape({
  name: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.OWN_VEHICLE_INFO_PASSENGERS.NAME].name),
  surname: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.OWN_VEHICLE_INFO_PASSENGERS.NAME].surname),
});

const validationSchema = Yup.object().shape({
  exist: Yup.string().ensure().required("validation.exist"),
  passengers: Yup.array().when("exist", {
    is: booleanTypesConstants.YES,
    then: Yup.array().required("validation.required").of(arrayObjectValidationSchema),
  })
});

// step 22
export default class OwnVehiclePassengersStepContainer extends Component {
  left = ({fromSummary, step}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="ownVehiclePassenger.sidebar.title"/>
        <Text element="div" label="ownVehiclePassenger.sidebar.subtitle"/>
        <VehicleInfoContainer {...step.participant}/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <OwnVehiclePassengersRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>
    );
  }
}
