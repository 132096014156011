import React, {Component} from "react";

import {Field} from "formik";
import {contains} from "underscore";

import CheckBoxTree, {CheckBoxTreeOption} from "components/form/CheckBox/CheckBoxTree";
import {filterSelectedHomeDamageTypeChildren} from "utility/utilityFunctions";

const CheckBoxTreeContext = React.createContext({});
const ExpandParentsContext = React.createContext(false);


export default function CheckBoxTreeField(props) {
  return (
    <Field
      name={props.name}
      render={fieldProps => (
        <InnerCheckBoxTreeField fieldProps={fieldProps} {...props}/>
      )}/>
  );
}

class InnerCheckBoxTreeField extends Component {
  toggleExpanded = (value) => {
    const {fieldProps: {field: {name}, form: {setFieldValue}}} = this.props;

    this.setState(
      state => {
        const expanded = isExpanded(value, state.value.expanded)
          ? state.value.expanded.filter(expandedName => expandedName !== value)
          : [...state.value.expanded, value];

        return {value: {...state.value, expanded}}
      },
      () => setFieldValue(name, this.state.value),
    );
  };

  toggleSelected = (value) => {
    const {fieldProps: {field: {name}, form: {setFieldValue}}, multiple = false, multipleOptionChildren = false, children} = this.props;

    this.setState(
      state => {
        const selected = isSelected(value, state.value.selected)
          ? state.value.selected.filter(selectedName => selectedName !== value)
          : multipleOptionChildren
            ? [...filterSelectedHomeDamageTypeChildren(children, state.value.selected, value), value]
            : [...(multiple ? state.value.selected : []), value];
        return {value: {...state.value, selected}}
      },
      () => setFieldValue(name, this.state.value),
    );
  };

  state = {
    value: this.props.fieldProps.field.value || {
      expanded: [],
      selected: [],
    },
    toggleExpanded: this.toggleExpanded,
    toggleSelected: this.toggleSelected,
  };

  constructor(props) {
    super(props);

    this.ref = React.createRef();
    this.ref.current = [...this.state.value.expanded];
  }

  componentWillUnmount() {
    this.ref.current = [];
  }

  onClick = (name, child) => () => {
    if (child.props.children) {
      this.toggleExpanded(name);
    } else {
      this.toggleSelected(name);
    }
  };

  expand = (value) => () => {
    if (this.ref.current.indexOf(value) === -1) {
      this.ref.current.push(value);
      this.toggleExpanded(value);
    }
  };

  render() {
    const {children} = this.props;

    const trees = React.Children.map(
      children,
      child => {
        if (child === null) {
          return null;
        }

        const childName = child.props.name;
        const treeSelected = child.props.children ? isExpanded(childName, this.state.value.expanded) : isSelected(childName, this.state.value.selected);

        return (
          <ExpandParentsContext.Provider value={{expand: this.expand(childName)}}>
            <CheckBoxTree treeSelected={treeSelected} onClick={this.onClick(childName, child)} {...child.props} />
          </ExpandParentsContext.Provider>
        );
      }
    );

    return (
      <CheckBoxTreeContext.Provider value={this.state}>
        {trees}
      </CheckBoxTreeContext.Provider>
    );
  }
}

function CheckBoxTreeFieldOption(props) {
  return (
    <CheckBoxTreeContext.Consumer>
      {context => (
        <ExpandParentsContext.Consumer>
          {expandParentContext => (
            <InnerCheckBoxTreeFieldOption context={context} expandParentContext={expandParentContext} {...props}/>
          )}
        </ExpandParentsContext.Consumer>
      )}
    </CheckBoxTreeContext.Consumer>
  );
}

class InnerCheckBoxTreeFieldOption extends Component {
  componentDidMount() {
    const {name, context, expandParentContext} = this.props;

    if (isSelected(name, context.value.selected)) {
      expandParentContext.expand();
    }
  }

  onClick = (e) => {
    const {context: {toggleExpanded, toggleSelected}, name, children} = this.props;

    if (children) {
      toggleExpanded(name);
    } else {
      toggleSelected(name);
    }
  };

  expand = () => {
    const {context: {toggleExpanded, value: {expanded}}, name, expandParentContext} = this.props;

    if (!isExpanded(name, expanded)) {
      toggleExpanded(name);
    }

    if (expandParentContext) {
      expandParentContext.expand();
    }
  };

  render() {
    const {name, children, context, ...props} = this.props;
    const childSelected = children ? isChildSelected(children, context.value.selected) : isSelected(name, context.value.selected);
    const expanded = isExpanded(name, context.value.expanded)  || (children && isChildSelected(children, context.value.selected));
    
    return (
      <ExpandParentsContext.Provider value={{expand: this.expand}}>
        <CheckBoxTreeOption {...props} childSelected={childSelected} expanded={expanded} onClick={this.onClick}>
          {children}
        </CheckBoxTreeOption>
      </ExpandParentsContext.Provider>
    );
  }
}

function isChildSelected(children, selected) {
  return children.some(child => isSelected(child.props.name, selected));
}

function isExpanded(name, expended) {
  return contains(expended, name);
}

function isSelected(name, selected) {
  return contains(selected, name);
}

CheckBoxTreeField.Option = CheckBoxTreeFieldOption;