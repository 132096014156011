import React, {Component} from 'react';

import {Field} from "formik";

import PickLocationMap from "components/map/PickLocationMap";

export default class PickLocationMapField extends Component {
  onLocationClick = (fieldProps) => (click) => {
    const value = click.loaded
      ? {lat: click.lat, lng: click.lng}
      : {lat: click.latLng.lat(), lng: click.latLng.lng()};

    if (click.loaded) {
      fieldProps.form.setFieldValue("focusLocation", value);
    }

    if (this.props.onLocationClick) {
      this.props.onLocationClick(value, () => fieldProps.form.setFieldValue(fieldProps.field.name, value));
    }
  };

  render() {
    const {name, center, country} = this.props;

    return (
      <Field name={name}
             render={(fieldProps) => (
               <PickLocationMap center={center}
                                country={country}
                                location={fieldProps.field.value}
                                onLocationClick={this.onLocationClick(fieldProps)}/>
             )}/>
    );
  }
}
