import {Component} from "react";

import {DragSource} from "react-dnd";

class SketchDragSource extends Component {
  render() {
    const {connectDragSource, isDragging, children} = this.props;

    return connectDragSource(
      children({isDragging}),
    );
  }
}

const source = {
  beginDrag(props, monitor) {
    return {item: props.value, initialClientOffset: monitor.getInitialClientOffset()};
  },
};

export default DragSource("item", source, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))(SketchDragSource);
