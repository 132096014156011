import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";
import withReduxEnhancer from "addon-redux/enhancer"

import root from "reducers/root";

const createMiddlewareEnhancer = () => {
  const middleware = [];
  middleware.push(thunk);
  return applyMiddleware(...middleware);
};

const createEnhancer = () => {
  const enhancers = [];
  enhancers.push(createMiddlewareEnhancer());
  if (process.env.NODE_ENV !== "production") {
    enhancers.push(withReduxEnhancer);
  }
  return composeWithDevTools(...enhancers);
};

export default function createInitStore() {
  const store = createStore(root, createEnhancer());

  if (process.env.NODE_ENV !== "production") {
    if (module.hot) {
      module.hot.accept("./reducers/root", () => {
        store.replaceReducer(root);
      });
    }
  }

  return store;
}
