import React from "react";

import cx from "classnames";
import Switch, {Case} from "react-switch-case";
import {isUndefined} from "underscore";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import TextField from "components/form/TextField";
import ToggleButton from "components/form/ToggleButton";
import {SketchContext} from "components/sketch/SketchContainer";
import SketchIcon from "components/sketch/icon/SketchIcon";
import SketchDragSource from "components/sketch/item/SketchDragSource";
import SketchNavigationButton from "components/sketch/navigation/SketchNavigationButton";
import FadeView from "components/view/FadeView";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import sketchConstants from "constants/sketch";

import close from "images/close.svg";

import styles from "components/sketch/navigation/SketchSubNavigation.module.scss";

export default function SketchSubNavigation({mainChoice, text, onClose, participants = []}) {
  if (isUndefined(mainChoice)) {
    return (
      <div className={styles.root}>&nbsp;</div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Close onClick={onClose}/>
      </div>
      <div>
        <FadeView step={mainChoice}>
          <Switch condition={mainChoice}>
            <Case value={sketchConstants.NAVIGATION.PARTICIPANTS.NAME}>
              <Participants participants={participants}/>
            </Case>
            <Case value={sketchConstants.NAVIGATION.WEATHER.NAME}>
              <Weather/>
            </Case>
            <Case value={sketchConstants.NAVIGATION.ROAD_CROSSINGS.NAME}>
              <RoadCrossings/>
            </Case>
            <Case value={sketchConstants.NAVIGATION.SHAPES.NAME}>
              <Shapes/>
            </Case>
            <Case value={sketchConstants.NAVIGATION.TEXT.NAME}>
              <NavigationText text={text}/>
            </Case>
          </Switch>
        </FadeView>
      </div>
    </div>
  );
}

function Participants({participants}) {
  return (
    <Items>
      {participants.map((participant, index) => (
        <Participant key={index} participant={participant}/>
      ))}
      <Item item={sketchConstants.PARTICIPANTS.PERSON}/>
      <Item item={sketchConstants.PARTICIPANTS.CYCLING}/>
      <Item item={sketchConstants.PARTICIPANTS.DEER} iconClassName={styles.deer}/>
    </Items>
  )
}

function Weather() {
  return (
    <Items>
      <Item item={sketchConstants.WEATHER.SUN}/>
      <Item item={sketchConstants.WEATHER.CLOUDY}/>
      <Item item={sketchConstants.WEATHER.LIGHT_RAIN}/>
      <Item item={sketchConstants.WEATHER.STORM}/>
      <Item item={sketchConstants.WEATHER.RAIN}/>
      <Item item={sketchConstants.WEATHER.WIND}/>
      <Item item={sketchConstants.WEATHER.SNOW_STORM}/>
    </Items>
  )
}

function RoadCrossings() {
  return (
    <SketchContext.Consumer>
      {(value) => (
        <Items>
          <ToggleButton name={`${value.name}.crossRoad`}>
            <Choice item={sketchConstants.ROAD_CROSSINGS.FOUR_ROADS} iconClassName={styles.roadCrossing}/>
            <Choice item={sketchConstants.ROAD_CROSSINGS.DOUBLE_RIGHT_DIAGONAL} iconClassName={styles.roadCrossing}/>
            <Choice item={sketchConstants.ROAD_CROSSINGS.DOUBLE_LEFT} iconClassName={styles.roadCrossing}/>
            <Choice item={sketchConstants.ROAD_CROSSINGS.SINGLE_LEFT_DIAGONAL} iconClassName={styles.roadCrossing}/>
            <Choice item={sketchConstants.ROAD_CROSSINGS.FOUR_ROADS_CIRCLE} iconClassName={styles.roadCrossing}/>
            <Choice item={sketchConstants.ROAD_CROSSINGS.SINGLE_LEFT} iconClassName={styles.roadCrossing}/>
            <Choice item={sketchConstants.ROAD_CROSSINGS.SINGLE_ROAD} iconClassName={styles.roadCrossing}/>
            <Choice item={sketchConstants.ROAD_CROSSINGS.DOUBLE_ROAD} iconClassName={styles.roadCrossing}/>
          </ToggleButton>
        </Items>
      )}
    </SketchContext.Consumer>
  )
}

function Shapes() {
  return (
    <Items>
      <Item item={sketchConstants.SHAPES.STOP_SIGN} iconClassName={styles.shape}/>
      <Item item={sketchConstants.SHAPES.GIVE_WAY_SIGN} iconClassName={styles.shape}/>
      <Item item={sketchConstants.SHAPES.WRONG_WAY} iconClassName={styles.shape}/>
      <Item item={sketchConstants.SHAPES.PRIORITY_SIGN} iconClassName={styles.shape}/>
      <Item item={sketchConstants.SHAPES.TRAFFIC_LIGHT_RED} iconClassName={styles.shape}/>
      <Item item={sketchConstants.SHAPES.TRAFFIC_LIGHT_YELLOW} iconClassName={styles.shape}/>
      <Item item={sketchConstants.SHAPES.TRAFFIC_LIGHT_GREEN} iconClassName={styles.shape}/>
      <Item item={sketchConstants.SHAPES.MAIN_RIGHT_SIGN} iconClassName={styles.shape}/>
      <Item item={sketchConstants.SHAPES.MAIN_LEFT_SIGN} iconClassName={styles.shape}/>
      <Item item={sketchConstants.SHAPES.MAIN_RIGHT_BOTTOM_SIGN} iconClassName={styles.shape}/>
      <Item item={sketchConstants.SHAPES.MAIN_LEFT_BOTTOM_SIGN} iconClassName={styles.shape}/>
      <Item item={sketchConstants.SHAPES.PARKED} iconClassName={styles.shape}/>
      <Item item={sketchConstants.SHAPES.DRIVING} iconClassName={styles.shape}/>
      <Item item={sketchConstants.SHAPES.CROSSWALK_HORIZONTAL} iconClassName={styles.shape}/>
      <Item item={sketchConstants.SHAPES.CROSSWALK_VERTICAL} iconClassName={styles.shape}/>
      <Item item={sketchConstants.SHAPES.T_LINE} iconClassName={styles.shape}/>
      <Item item={sketchConstants.SHAPES.DOTTED_SQUARE} iconClassName={styles.shape}/>
      <Item item={sketchConstants.SHAPES.RIGHT_TURN} iconClassName={styles.shape}/>
      <Item item={sketchConstants.SHAPES.LEFT_TURN} iconClassName={styles.shape}/>
      <Item item={sketchConstants.SHAPES.U_TURN_RIGHT} iconClassName={styles.shape}/>
      <Item item={sketchConstants.SHAPES.U_TURN_LEFT} iconClassName={styles.shape}/>
      <Item item={sketchConstants.SHAPES.CIRCLE} iconClassName={styles.shape}/>
      <Item item={sketchConstants.SHAPES.TRIANGLE} iconClassName={styles.shape}/>
      <Item item={sketchConstants.SHAPES.SQUARE} iconClassName={styles.shape}/>
    </Items>
  )
}

function NavigationText({text}) {
  const item = {type: sketchConstants.TYPES.TEXT, value: {text: text || ""}};

  return (
    <Layout layoutClassName={styles.text} flex={1}>
      <Layout.OneColumn>
        <SketchDragSource value={item}>
          {() => (
            <div>
              <TextField variant="variant4" name="text" multiLine={true} maxLength={fieldLengthConstants[flowConstants.steps.DRAWING.NAME].text}/>
            </div>
          )}
        </SketchDragSource>
      </Layout.OneColumn>
      <Layout.OneColumn spacing={10}>
        <Text element="p" variant="variant14" label="drawing.navigation.text"/>
      </Layout.OneColumn>
    </Layout>
  )
}

function Participant({participant}) {
  return (
    <SketchNavigationButton iconClassName={styles.participant}
                            type={sketchConstants.TYPES.PARTICIPANT}
                            value={participant}/>
  );
}

function Items({iconClassName, children}) {
  return (
    <div className={styles.items}>
      {children}
    </div>
  );
}

function Item({iconClassName, item}) {
  return (
    <SketchNavigationButton iconClassName={iconClassName}
                            type={sketchConstants.TYPES.ITEM}
                            value={item.NAME}/>
  );
}

function Choice({iconClassName, item}) {
  return (
    <ToggleButton.Choice className={cx(styles.item)}
                         value={item.NAME}
                         icon={<SketchIcon className={cx(styles.icon, iconClassName)} icon={item.IMAGE_NAVIGATION}/>}
                         variant="variant4"/>
  )
}

function Close({onClick}) {
  return (
    <img className={styles.close} src={close} onClick={onClick} alt="close"/>
  );
}
