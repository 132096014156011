import React from "react";

import {isEmpty, isUndefined} from "underscore";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import SummaryRefContainer from "components/step/summary/SummaryRefContainer";
import booleanTypesConstants from "constants/booleanTypes";
import flowConstants from "constants/flow";
import medicalCareDescriptionTypes from "constants/medicalCareDescriptionTypes";
import vehicleTypeConstants from "constants/vehicleTypes"
import wasDriverTypesConstants from "constants/wasDriverTypes";
import summaryRefConstants from "constants/summaryRef";
import Summary from "containers/step/summary/Summary";
import SummaryBikeOwnVehicleInfo from "containers/step/summary/car/SummaryBikeOwnVehicleInfo";
import SummaryCommonVehicleInfo from "containers/step/summary/car/SummaryCommonVehicleInfo";

export default function SummaryOwnVehicleInfo({participant, medicalCareType, ...props}) {
  return (
    <>
      {
        participant.info.type === vehicleTypeConstants.BIKE
          ? <SummaryBikeOwnVehicleInfo participant={participant} {...props}/>
          : <SummaryCommonVehicleInfo participant={participant} {...props}/>
      }

      <SummaryOwnVehicleInfoWasDriver medicalCareType={medicalCareType}/>

      <SummaryOwnVehicleInfoDriverHurt/>

      <SummaryRefContainer refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_VEHICLE_INFO_REPORTED_POLICE.REF}>
        <Summary.ItemValue label="summary.content.participantsInfo.ownVehicleInfoReportedPolice.reportedPolice"
                           formName={{name: flowConstants.steps.OWN_VEHICLE_INFO_REPORTED_POLICE.NAME}}
                           refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_VEHICLE_INFO_REPORTED_POLICE.REPORTED_POLICE}>
          {form => <Text label={`summary.content.common.boolean.${form.reportedPolice}`}/>}
        </Summary.ItemValue>

        <Summary.ItemValue label="summary.content.participantsInfo.ownVehicleInfoReportedPolice.reportedPoliceStation"
                           formName={{name: flowConstants.steps.OWN_VEHICLE_INFO_REPORTED_POLICE.NAME}}
                           refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_VEHICLE_INFO_REPORTED_POLICE.REPORTED_POLICE_STATION}
                           condition={(form) => form.reportedPolice === booleanTypesConstants.YES}>
          {form => isEmpty(form.reportedPoliceStation) ?
            <Text label="summary.content.common.boolean.dontKnow"/> : form.reportedPoliceStation}
        </Summary.ItemValue>
      </SummaryRefContainer>

      <SummaryOwnVehicleInfoPassengers/>

      <Summary.ItemValue label="summary.content.participantsInfo.ownVehiclePassengers.hurt"
                         formName={{name: flowConstants.steps.OWN_VEHICLE_INFO_PASSENGERS.NAME}}
                         refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_VEHICLE_INFO_WAS_DRIVER.REF}>
        {form =>
          <Layout>
            {isEmpty(form.hurt) && isEmpty(form.notHurt) && isEmpty(form.dontKnow)
              ?
              <Layout.RowChildren>
                <Text label="summary.content.common.boolean.dontKnow"/>
              </Layout.RowChildren>
              : <>
                <Layout.RowChildren>
                  <Text {...form.hurt}/>
                </Layout.RowChildren>
                <Layout.RowChildren>
                  <Text {...form.notHurt}/>
                </Layout.RowChildren>
                <Layout.RowChildren>
                  <Text {...form.dontKnow}/>
                </Layout.RowChildren>
              </>
            }
          </Layout>
        }
      </Summary.ItemValue>
    </>
  );
}

function SummaryOwnVehicleInfoWasDriver({medicalCareType}) {
  const label = !isUndefined(medicalCareType) && medicalCareType.medicalCareTypeDescription === medicalCareDescriptionTypes.DEATH
    ? `.${medicalCareType.medicalCareTypeDescription}` : "";

  return (
    <SummaryRefContainer refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_VEHICLE_INFO_WAS_DRIVER.REF}>
      <Summary.ItemValue
        label={`summary.content.participantsInfo.ownVehicleInfoWasDriver.wasDriver${label}`}
        formName={{name: flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME}}
        refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_VEHICLE_INFO_WAS_DRIVER.WAS_DRIVER}>
        {form => <Text label={`summary.content.wasDriver.${form.wasDriver}`}/>}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.participantsInfo.ownVehicleInfoWasDriver.wasDriver.name"
                         formName={{name: flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME}}
                         refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_VEHICLE_INFO_WAS_DRIVER.NAME}
                         condition={(form) => form.wasDriver === wasDriverTypesConstants.NO}>
        {form => form.name}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.participantsInfo.ownVehicleInfoWasDriver.wasDriver.surname"
                         formName={{name: flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME}}
                         refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_VEHICLE_INFO_WAS_DRIVER.SURNAME}
                         condition={(form) => form.wasDriver === wasDriverTypesConstants.NO}>
        {form => form.surname}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.participantsInfo.ownVehicleInfoWasDriver.wasDriver.birthDate"
                         formName={{name: flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME}}
                         refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_VEHICLE_INFO_WAS_DRIVER.BIRTH_DATE}
                         condition={(form) => form.wasDriver === wasDriverTypesConstants.NO}>
        {form => form.birthDate}
      </Summary.ItemValue>

      <Summary.ItemValue label="sex.summary.label"
                         formName={{name: flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME}}
                         refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_VEHICLE_INFO_WAS_DRIVER.SEX}
                         condition={(form) => form.wasDriver === booleanTypesConstants.NO}>
        {form => <Text label={`sex.${form.sex}`}/>}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.participantsInfo.ownVehicleInfoWasDriver.wasDriver.country"
                         formName={{name: flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME}}
                         refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_VEHICLE_INFO_WAS_DRIVER.COUNTRY}
                         condition={(form) => form.wasDriver === wasDriverTypesConstants.NO}>
        {form => form.country}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.participantsInfo.ownVehicleInfoWasDriver.wasDriver.cityAndZip"
                         formName={{name: flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME}}
                         refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_VEHICLE_INFO_WAS_DRIVER.CITY_AND_ZIP}
                         condition={(form) => form.wasDriver === wasDriverTypesConstants.NO}>
        {form => form.cityAndZip}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.participantsInfo.ownVehicleInfoWasDriver.wasDriver.streetAndStreetNo"
                         formName={{name: flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME}}
                         refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_VEHICLE_INFO_WAS_DRIVER.STREET_AND_NUMBER}
                         condition={(form) => form.wasDriver === wasDriverTypesConstants.NO}>
        {form => form.streetAndStreetNo}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.participantsInfo.ownVehicleInfoWasDriver.wasDriver.telephoneNumber"
                         formName={{name: flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME}}
                         refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_VEHICLE_INFO_WAS_DRIVER.TELEPHONE_NUMBER}
                         condition={(form) => form.wasDriver === wasDriverTypesConstants.NO}>
        {form => isEmpty(form.telephoneNumber) ?
          <Text label="summary.content.common.boolean.dontKnow"/> : form.telephoneNumber}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.participantsInfo.ownVehicleInfoWasDriver.wasDriver.licenceNumber"
                         formName={{name: flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME}}
                         refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_VEHICLE_INFO_WAS_DRIVER.LICENCE_NUMBER}
                         condition={(form) => form.wasDriver === wasDriverTypesConstants.NO}>
        {form => isEmpty(form.licenceNumber) ?
          <Text label="summary.content.common.boolean.dontKnow"/> : form.licenceNumber}
      </Summary.ItemValue>
    </SummaryRefContainer>
  )
}

function SummaryOwnVehicleInfoDriverHurt() {
  return (
    <SummaryRefContainer refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_VEHICLE_INFO_DRIVER_HURT.REF}>
      <Summary.ItemValue label="summary.content.participantsInfo.ownVehicleInfoDriverHurt.driverHurt.wasDriver"
                         formName={{name: flowConstants.steps.OWN_VEHICLE_INFO_DRIVER_HURT.NAME}}
                         refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_VEHICLE_INFO_DRIVER_HURT.WAS_DRIVER}
                         condition={form => form.wasDriver === wasDriverTypesConstants.YES}>
        {form => <Text label={`summary.content.common.boolean.${form.driverHurt.wasDriver}`}/>}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.participantsInfo.ownVehicleInfoDriverHurt.driverHurt.wasNotDriver"
                         formName={{name: flowConstants.steps.OWN_VEHICLE_INFO_DRIVER_HURT.NAME}}
                         refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_VEHICLE_INFO_DRIVER_HURT.WAS_NOT_DRIVER}
                         condition={form => form.wasDriver === wasDriverTypesConstants.NO}>
        {form => <Text label={`summary.content.common.boolean.${form.driverHurt.wasNotDriver}`}/>}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.participantsInfo.ownVehicleInfoDriverHurt.driverHurt.noDriver"
                         formName={{name: flowConstants.steps.OWN_VEHICLE_INFO_DRIVER_HURT.NAME}}
                         refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_VEHICLE_INFO_DRIVER_HURT.WAS_NOT_DRIVER}
                         condition={form => form.wasDriver === wasDriverTypesConstants.NO_DRIVER}>
        {form => <Text label={`summary.content.common.boolean.${form.driverHurt.noDriver}`}/>}
      </Summary.ItemValue>
    </SummaryRefContainer>
  )
}

function SummaryOwnVehicleInfoPassengers() {
  return (
    <SummaryRefContainer refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_VEHICLE_INFO_PASSENGERS.REF}>
      <Summary.ItemValue label="summary.content.participantsInfo.ownVehiclePassengers.exist"
                         formName={{name: flowConstants.steps.OWN_VEHICLE_INFO_PASSENGERS.NAME}}
                         refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_VEHICLE_INFO_PASSENGERS.EXISTS}>
        {form => <Text label={`summary.content.common.boolean.${form.exist}`}/>}
      </Summary.ItemValue>

      <SummaryRefContainer
        refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_VEHICLE_INFO_PASSENGERS.PASSENGERS.REF}>
        <Summary.Items formName={{name: flowConstants.steps.OWN_VEHICLE_INFO_PASSENGERS.NAME}}
                       condition={form => form.passengers.length > 0}>
          {form => (
            form.passengers.map((passenger, index) => (
              <Summary.ItemValue key={index}
                                 label={<Text label="summary.content.participantsInfo.ownVehiclePassengers.passenger"
                                              parameters={[index + 1]}/>}
                                 formName={{name: flowConstants.steps.OWN_VEHICLE_INFO_PASSENGERS.NAME}}
                                 refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OWN_VEHICLE_INFO_PASSENGERS.PASSENGERS.PASSENGER(index)}>
                {form => passenger}
              </Summary.ItemValue>
            ))
          )}
        </Summary.Items>
      </SummaryRefContainer>
    </SummaryRefContainer>
  )
}
