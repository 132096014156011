import React, {Component} from "react";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import booleanTypeConstants from "constants/booleanTypes";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import Header from "containers/step/Header";
import HomeBurglaryViolentRight from "containers/step/homeBurglary/HomeBurglaryViolentRight";
import SplitViewContainer from "containers/view/SplitViewContainer";
import Yup, {getMax} from "selectors/yup";

const validationSchema = Yup.object().shape({
  tracesOfBurglary: Yup.string().required("validation.required"),
  description: Yup.string().when("tracesOfBurglary", {
    is: tracesOfBurglary => tracesOfBurglary === booleanTypeConstants.YES,
    then: getMax(Yup.string().trim().required("eventDescription.validation.description"), fieldLengthConstants[flowConstants.homeSteps.HOME_BURGLARY_VIOLENT.NAME].description),
  }),
});

export default class HomeBurglaryViolentStepContainer extends Component {
  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="homeBurglaryViolent.sidebar.title"/>
        <Text element="div" label="homeBurglaryViolent.sidebar.subtitle" isHtml={true}/>
      </SplitViewContainer.Left>
    );
  };

  right(props) {
    return (
      <SplitViewContainer.Right {...props}>
        <HomeBurglaryViolentRight {...props}/>
      </SplitViewContainer.Right>
    );
  }

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>
    );
  }
}